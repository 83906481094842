import { echoApi } from '@apis';
import { ApplicationCommandItem, ApplicationItem, TeamEchoConfigurationClinicalSystemItem, UserFeedbackResponseItem } from '@interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type EchoState = {
  tokenReplacement: string | null;
  teamEchoConfiguration: TeamEchoConfigurationClinicalSystemItem | null;
  applications: ApplicationItem[];
  commands: ApplicationCommandItem[];
  feedbacks: UserFeedbackResponseItem[];
};

const initialState: EchoState = {
  tokenReplacement: null,
  teamEchoConfiguration: null,
  applications: [],
  commands: [],
  feedbacks: [],
};

export const echoSlice = createSlice({
  name: 'echo',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(echoApi.endpoints.getTokenReplacement.matchFulfilled, (state, { payload }) => {
      state.tokenReplacement = payload.dateFormat;
    });
    builder.addMatcher(echoApi.endpoints.getTeamEchoConfiguration.matchFulfilled, (state, { payload }) => {
      state.teamEchoConfiguration = payload;
    });
    builder.addMatcher(echoApi.endpoints.getApplications.matchFulfilled, (state, { payload }) => {
      state.applications = payload;
    });
    builder.addMatcher(echoApi.endpoints.getCommands.matchFulfilled, (state, { payload }) => {
      state.commands = payload;
    });
    builder.addMatcher(echoApi.endpoints.getFeedback.matchFulfilled, (state, { payload }) => {
      state.feedbacks = payload;
    });
  },
  reducers: {
    resetCommands: (state, action: PayloadAction<ApplicationCommandItem[]>) => {
      state.commands = action.payload;
    },
  },
});

export const { resetCommands } = echoSlice.actions;
