export const iconsList = [
  { id: 63040, unicode: 'f640', label: 'Abacus', name: 'fa-abacus' },
  { id: 96, unicode: '60', label: 'Accent Grave', name: 'fa-accent-grave' },
  { id: 62312, unicode: 'f368', label: 'Accessible Icon', name: 'fa-accessible-icon' },
  { id: 62313, unicode: 'f369', label: 'Accusoft', name: 'fa-accusoft' },
  { id: 63150, unicode: 'f6ae', label: 'Acorn', name: 'fa-acorn' },
  { id: 62137, unicode: 'f2b9', label: 'Address Book', name: 'fa-address-book' },
  { id: 62139, unicode: 'f2bb', label: 'Address Card', name: 'fa-address-card' },
  { id: 61808, unicode: 'f170', label: 'App Net', name: 'fa-app-net' },
  { id: 62314, unicode: 'f36a', label: 'Adversal', name: 'fa-adversal' },
  { id: 62315, unicode: 'f36b', label: 'Affiliatetheme', name: 'fa-affiliatetheme' },
  { id: 63732, unicode: 'f8f4', label: 'Air Conditioner', name: 'fa-air-conditioner' },
  { id: 63540, unicode: 'f834', label: 'Airbnb', name: 'fa-airbnb' },
  { id: 57481, unicode: 'e089', label: 'Airplay', name: 'fa-airplay' },
  { id: 62286, unicode: 'f34e', label: 'Alarm Clock', name: 'fa-alarm-clock' },
  { id: 63555, unicode: 'f843', label: 'Alarm Exclamation', name: 'fa-alarm-exclamation' },
  { id: 63556, unicode: 'f844', label: 'Alarm Plus', name: 'fa-alarm-plus' },
  { id: 63557, unicode: 'f845', label: 'Alarm Snooze', name: 'fa-alarm-snooze' },
  { id: 63647, unicode: 'f89f', label: 'Album', name: 'fa-album' },
  { id: 58508, unicode: 'e48c', label: 'Album Circle Plus', name: 'fa-album-circle-plus' },
  { id: 58509, unicode: 'e48d', label: 'Album Circle User', name: 'fa-album-circle-user' },
  { id: 63648, unicode: 'f8a0', label: 'Album Collection', name: 'fa-album-collection' },
  { id: 58510, unicode: 'e48e', label: 'Album Collection Circle Plus', name: 'fa-album-collection-circle-plus' },
  { id: 58511, unicode: 'e48f', label: 'Album Collection Circle User', name: 'fa-album-collection-circle-user' },
  { id: 62316, unicode: 'f36c', label: 'Algolia', name: 'fa-algolia' },
  { id: 63152, unicode: 'f6b0', label: 'Alicorn', name: 'fa-alicorn' },
  { id: 63733, unicode: 'f8f5', label: 'Alien', name: 'fa-alien' },
  { id: 63734, unicode: 'f8f6', label: 'Alien 8Bit', name: 'fa-alien-8bit' },
  { id: 61495, unicode: 'f037', label: 'Align Center', name: 'fa-align-center' },
  { id: 61497, unicode: 'f039', label: 'Align Justify', name: 'fa-align-justify' },
  { id: 61494, unicode: 'f036', label: 'Align Left', name: 'fa-align-left' },
  { id: 61496, unicode: 'f038', label: 'Align Right', name: 'fa-align-right' },
  { id: 63558, unicode: 'f846', label: 'Align Slash', name: 'fa-align-slash' },
  { id: 63042, unicode: 'f642', label: 'Alipay', name: 'fa-alipay' },
  { id: 57482, unicode: 'e08a', label: 'Alt', name: 'fa-alt' },
  { id: 62064, unicode: 'f270', label: 'Amazon', name: 'fa-amazon' },
  { id: 62508, unicode: 'f42c', label: 'Amazon Pay', name: 'fa-amazon-pay' },
  { id: 62317, unicode: 'f36d', label: 'Amilia', name: 'fa-amilia' },
  { id: 63649, unicode: 'f8a1', label: 'Guitar Amplifier', name: 'fa-guitar-amplifier' },
  { id: 38, unicode: '26', label: 'Ampersand', name: 'fa-ampersand' },
  { id: 61757, unicode: 'f13d', label: 'Anchor', name: 'fa-anchor' },
  { id: 58538, unicode: 'e4aa', label: 'Anchor Circle Check', name: 'fa-anchor-circle-check' },
  { id: 58539, unicode: 'e4ab', label: 'Anchor Circle Exclamation', name: 'fa-anchor-circle-exclamation' },
  { id: 58540, unicode: 'e4ac', label: 'Anchor Circle Xmark', name: 'fa-anchor-circle-xmark' },
  { id: 58541, unicode: 'e4ad', label: 'Anchor Lock', name: 'fa-anchor-lock' },
  { id: 61819, unicode: 'f17b', label: 'Android', name: 'fa-android' },
  { id: 63353, unicode: 'f779', label: 'Angel', name: 'fa-angel' },
  { id: 61961, unicode: 'f209', label: 'Angellist', name: 'fa-angellist' },
  { id: 57484, unicode: 'e08c', label: 'Angle', name: 'fa-angle' },
  { id: 57485, unicode: 'e08d', label: 'Angle 90', name: 'fa-angle-90' },
  { id: 61703, unicode: 'f107', label: 'Angle Down', name: 'fa-angle-down' },
  { id: 61700, unicode: 'f104', label: 'Angle Left', name: 'fa-angle-left' },
  { id: 61701, unicode: 'f105', label: 'Angle Right', name: 'fa-angle-right' },
  { id: 61702, unicode: 'f106', label: 'Angle Up', name: 'fa-angle-up' },
  { id: 61699, unicode: 'f103', label: 'Angles Down', name: 'fa-angles-down' },
  { id: 61696, unicode: 'f100', label: 'Angles Left', name: 'fa-angles-left' },
  { id: 61697, unicode: 'f101', label: 'Angles Right', name: 'fa-angles-right' },
  { id: 61698, unicode: 'f102', label: 'Angles Up', name: 'fa-angles-up' },
  { id: 62318, unicode: 'f36e', label: 'Angry Creative', name: 'fa-angry-creative' },
  { id: 62496, unicode: 'f420', label: 'Angular', name: 'fa-angular' },
  { id: 63044, unicode: 'f644', label: 'Ankh', name: 'fa-ankh' },
  { id: 58472, unicode: 'e468', label: 'Apartment', name: 'fa-apartment' },
  { id: 58079, unicode: 'e2df', label: 'Aperture', name: 'fa-aperture' },
  { id: 39, unicode: '27', label: 'Apostrophe', name: 'fa-apostrophe' },
  { id: 62319, unicode: 'f36f', label: 'App Store', name: 'fa-app-store' },
  { id: 62320, unicode: 'f370', label: 'Ios App Store', name: 'fa-ios-app-store' },
  { id: 62321, unicode: 'f371', label: 'Apper Systems AB', name: 'fa-apper-systems-ab' },
  { id: 61817, unicode: 'f179', label: 'Apple', name: 'fa-apple' },
  { id: 57487, unicode: 'e08f', label: 'Apple Core', name: 'fa-apple-core' },
  { id: 62485, unicode: 'f415', label: 'Apple Pay', name: 'fa-apple-pay' },
  { id: 62929, unicode: 'f5d1', label: 'Apple Whole', name: 'fa-apple-whole' },
  { id: 62807, unicode: 'f557', label: 'Archway', name: 'fa-archway' },
  { id: 61539, unicode: 'f063', label: 'Arrow Down', name: 'fa-arrow-down' },
  { id: 61794, unicode: 'f162', label: 'Arrow Down 1 9', name: 'fa-arrow-down-1-9' },
  { id: 63622, unicode: 'f886', label: 'Arrow Down 9 1', name: 'fa-arrow-down-9-1' },
  { id: 61789, unicode: 'f15d', label: 'Arrow Down A Z', name: 'fa-arrow-down-a-z' },
  { id: 63619, unicode: 'f883', label: 'Arrow Down Arrow Up', name: 'fa-arrow-down-arrow-up' },
  { id: 63628, unicode: 'f88c', label: 'Arrow Down Big Small', name: 'fa-arrow-down-big-small' },
  { id: 57488, unicode: 'e090', label: 'Arrow Down From Dotted Line', name: 'fa-arrow-down-from-dotted-line' },
  { id: 62277, unicode: 'f345', label: 'Arrow Down From Line', name: 'fa-arrow-down-from-line' },
  { id: 57489, unicode: 'e091', label: 'Arrow Down Left', name: 'fa-arrow-down-left' },
  { id: 57490, unicode: 'e092', label: 'Arrow Down Left And Arrow Up Right To Center', name: 'fa-arrow-down-left-and-arrow-up-right-to-center' },
  { id: 61813, unicode: 'f175', label: 'Arrow Down Long', name: 'fa-arrow-down-long' },
  { id: 57491, unicode: 'e093', label: 'Arrow Down Right', name: 'fa-arrow-down-right' },
  { id: 63620, unicode: 'f884', label: 'Arrow Down Short Wide', name: 'fa-arrow-down-short-wide' },
  { id: 63629, unicode: 'f88d', label: 'Arrow Down Small Big', name: 'fa-arrow-down-small-big' },
  { id: 63625, unicode: 'f889', label: 'Arrow Down Square Triangle', name: 'fa-arrow-down-square-triangle' },
  { id: 58542, unicode: 'e4ae', label: 'Arrow Down To Arc', name: 'fa-arrow-down-to-arc' },
  { id: 57492, unicode: 'e094', label: 'Arrow Down To Bracket', name: 'fa-arrow-down-to-bracket' },
  { id: 57493, unicode: 'e095', label: 'Arrow Down To Dotted Line', name: 'fa-arrow-down-to-dotted-line' },
  { id: 62269, unicode: 'f33d', label: 'Arrow Down To Line', name: 'fa-arrow-down-to-line' },
  { id: 57494, unicode: 'e096', label: 'Arrow Down To Square', name: 'fa-arrow-down-to-square' },
  { id: 63624, unicode: 'f888', label: 'Arrow Down Triangle Square', name: 'fa-arrow-down-triangle-square' },
  { id: 58543, unicode: 'e4af', label: 'Arrow Down Up Across Line', name: 'fa-arrow-down-up-across-line' },
  { id: 58544, unicode: 'e4b0', label: 'Arrow Down Up Lock', name: 'fa-arrow-down-up-lock' },
  { id: 61792, unicode: 'f160', label: 'Arrow Down Wide Short', name: 'fa-arrow-down-wide-short' },
  { id: 63617, unicode: 'f881', label: 'Arrow Down Z A', name: 'fa-arrow-down-z-a' },
  { id: 61536, unicode: 'f060', label: 'Arrow Left', name: 'fa-arrow-left' },
  { id: 62276, unicode: 'f344', label: 'Arrow Left From Line', name: 'fa-arrow-left-from-line' },
  { id: 61815, unicode: 'f177', label: 'Arrow Left Long', name: 'fa-arrow-left-long' },
  { id: 58324, unicode: 'e3d4', label: 'Arrow Left Long To Line', name: 'fa-arrow-left-long-to-line' },
  { id: 62270, unicode: 'f33e', label: 'Arrow Left To Line', name: 'fa-arrow-left-to-line' },
  { id: 62021, unicode: 'f245', label: 'Arrow Pointer', name: 'fa-arrow-pointer' },
  { id: 61537, unicode: 'f061', label: 'Arrow Right', name: 'fa-arrow-right' },
  { id: 61676, unicode: 'f0ec', label: 'Arrow Right Arrow Left', name: 'fa-arrow-right-arrow-left' },
  { id: 58545, unicode: 'e4b1', label: 'Arrow Right From Arc', name: 'fa-arrow-right-from-arc' },
  { id: 61579, unicode: 'f08b', label: 'Arrow Right From Bracket', name: 'fa-arrow-right-from-bracket' },
  { id: 62275, unicode: 'f343', label: 'Arrow Right From Line', name: 'fa-arrow-right-from-line' },
  { id: 61816, unicode: 'f178', label: 'Arrow Right Long', name: 'fa-arrow-right-long' },
  { id: 58325, unicode: 'e3d5', label: 'Arrow Right Long To Line', name: 'fa-arrow-right-long-to-line' },
  { id: 58546, unicode: 'e4b2', label: 'Arrow Right To Arc', name: 'fa-arrow-right-to-arc' },
  { id: 61584, unicode: 'f090', label: 'Arrow Right To Bracket', name: 'fa-arrow-right-to-bracket' },
  { id: 58547, unicode: 'e4b3', label: 'Arrow Right To City', name: 'fa-arrow-right-to-city' },
  { id: 62272, unicode: 'f340', label: 'Arrow Right To Line', name: 'fa-arrow-right-to-line' },
  { id: 61666, unicode: 'f0e2', label: 'Arrow Rotate Left', name: 'fa-arrow-rotate-left' },
  { id: 61470, unicode: 'f01e', label: 'Arrow Rotate Right', name: 'fa-arrow-rotate-right' },
  { id: 57495, unicode: 'e097', label: 'Arrow Trend Down', name: 'fa-arrow-trend-down' },
  { id: 57496, unicode: 'e098', label: 'Arrow Trend Up', name: 'fa-arrow-trend-up' },
  { id: 61769, unicode: 'f149', label: 'Arrow Turn Down', name: 'fa-arrow-turn-down' },
  { id: 58081, unicode: 'e2e1', label: 'Arrow Turn Down Left', name: 'fa-arrow-turn-down-left' },
  { id: 58326, unicode: 'e3d6', label: 'Arrow Turn Down Right', name: 'fa-arrow-turn-down-right' },
  { id: 61768, unicode: 'f148', label: 'Arrow Turn Up', name: 'fa-arrow-turn-up' },
  { id: 61538, unicode: 'f062', label: 'Arrow Up', name: 'fa-arrow-up' },
  { id: 61795, unicode: 'f163', label: 'Arrow Up 1 9', name: 'fa-arrow-up-1-9' },
  { id: 63623, unicode: 'f887', label: 'Arrow Up 9 1', name: 'fa-arrow-up-9-1' },
  { id: 61790, unicode: 'f15e', label: 'Arrow Up A Z', name: 'fa-arrow-up-a-z' },
  { id: 57497, unicode: 'e099', label: 'Arrow Up Arrow Down', name: 'fa-arrow-up-arrow-down' },
  { id: 63630, unicode: 'f88e', label: 'Arrow Up Big Small', name: 'fa-arrow-up-big-small' },
  { id: 58548, unicode: 'e4b4', label: 'Arrow Up From Arc', name: 'fa-arrow-up-from-arc' },
  { id: 57498, unicode: 'e09a', label: 'Arrow Up From Bracket', name: 'fa-arrow-up-from-bracket' },
  { id: 57499, unicode: 'e09b', label: 'Arrow Up From Dotted Line', name: 'fa-arrow-up-from-dotted-line' },
  { id: 58549, unicode: 'e4b5', label: 'Arrow Up From Ground Water', name: 'fa-arrow-up-from-ground-water' },
  { id: 62274, unicode: 'f342', label: 'Arrow Up From Line', name: 'fa-arrow-up-from-line' },
  { id: 57500, unicode: 'e09c', label: 'Arrow Up From Square', name: 'fa-arrow-up-from-square' },
  { id: 58550, unicode: 'e4b6', label: 'Arrow Up From Water Pump', name: 'fa-arrow-up-from-water-pump' },
  { id: 57501, unicode: 'e09d', label: 'Arrow Up Left', name: 'fa-arrow-up-left' },
  { id: 57502, unicode: 'e09e', label: 'Arrow Up Left From Circle', name: 'fa-arrow-up-left-from-circle' },
  { id: 61814, unicode: 'f176', label: 'Arrow Up Long', name: 'fa-arrow-up-long' },
  { id: 57503, unicode: 'e09f', label: 'Arrow Up Right', name: 'fa-arrow-up-right' },
  { id: 57504, unicode: 'e0a0', label: 'Arrow Up Right And Arrow Down Left From Center', name: 'fa-arrow-up-right-and-arrow-down-left-from-center' },
  { id: 58551, unicode: 'e4b7', label: 'Arrow Up Right Dots', name: 'fa-arrow-up-right-dots' },
  { id: 61582, unicode: 'f08e', label: 'Arrow Up Right From Square', name: 'fa-arrow-up-right-from-square' },
  { id: 63621, unicode: 'f885', label: 'Arrow Up Short Wide', name: 'fa-arrow-up-short-wide' },
  { id: 63631, unicode: 'f88f', label: 'Arrow Up Small Big', name: 'fa-arrow-up-small-big' },
  { id: 63627, unicode: 'f88b', label: 'Arrow Up Square Triangle', name: 'fa-arrow-up-square-triangle' },
  { id: 57505, unicode: 'e0a1', label: 'Arrow Up To Dotted Line', name: 'fa-arrow-up-to-dotted-line' },
  { id: 62273, unicode: 'f341', label: 'Arrow Up To Line', name: 'fa-arrow-up-to-line' },
  { id: 63626, unicode: 'f88a', label: 'Arrow Up Triangle Square', name: 'fa-arrow-up-triangle-square' },
  { id: 61793, unicode: 'f161', label: 'Arrow Up Wide Short', name: 'fa-arrow-up-wide-short' },
  { id: 63618, unicode: 'f882', label: 'Arrow Up Z A', name: 'fa-arrow-up-z-a' },
  { id: 57506, unicode: 'e0a2', label: 'Arrows Cross', name: 'fa-arrows-cross' },
  { id: 58552, unicode: 'e4b8', label: 'Arrows Down To Line', name: 'fa-arrows-down-to-line' },
  { id: 58553, unicode: 'e4b9', label: 'Arrows Down To People', name: 'fa-arrows-down-to-people' },
  { id: 57507, unicode: 'e0a3', label: 'Arrows From Dotted Line', name: 'fa-arrows-from-dotted-line' },
  { id: 57508, unicode: 'e0a4', label: 'Arrows From Line', name: 'fa-arrows-from-line' },
  { id: 61566, unicode: 'f07e', label: 'Arrows Left Right', name: 'fa-arrows-left-right' },
  { id: 58554, unicode: 'e4ba', label: 'Arrows Left Right To Line', name: 'fa-arrows-left-right-to-line' },
  { id: 62237, unicode: 'f31d', label: 'Arrows Maximize', name: 'fa-arrows-maximize' },
  { id: 57509, unicode: 'e0a5', label: 'Arrows Minimize', name: 'fa-arrows-minimize' },
  { id: 62308, unicode: 'f364', label: 'Arrows Repeat', name: 'fa-arrows-repeat' },
  { id: 62310, unicode: 'f366', label: 'Arrows Repeat 1', name: 'fa-arrows-repeat-1' },
  { id: 62305, unicode: 'f361', label: 'Arrows Retweet', name: 'fa-arrows-retweet' },
  { id: 61473, unicode: 'f021', label: 'Arrows Rotate', name: 'fa-arrows-rotate' },
  { id: 58555, unicode: 'e4bb', label: 'Arrows Spin', name: 'fa-arrows-spin' },
  { id: 58556, unicode: 'e4bc', label: 'Arrows Split Up And Left', name: 'fa-arrows-split-up-and-left' },
  { id: 58557, unicode: 'e4bd', label: 'Arrows To Circle', name: 'fa-arrows-to-circle' },
  { id: 58558, unicode: 'e4be', label: 'Arrows To Dot', name: 'fa-arrows-to-dot' },
  { id: 57510, unicode: 'e0a6', label: 'Arrows To Dotted Line', name: 'fa-arrows-to-dotted-line' },
  { id: 58559, unicode: 'e4bf', label: 'Arrows To Eye', name: 'fa-arrows-to-eye' },
  { id: 57511, unicode: 'e0a7', label: 'Arrows To Line', name: 'fa-arrows-to-line' },
  { id: 58560, unicode: 'e4c0', label: 'Arrows Turn Right', name: 'fa-arrows-turn-right' },
  { id: 58561, unicode: 'e4c1', label: 'Arrows Turn To Dots', name: 'fa-arrows-turn-to-dots' },
  { id: 61565, unicode: 'f07d', label: 'Arrows Up Down', name: 'fa-arrows-up-down' },
  { id: 61511, unicode: 'f047', label: 'Arrows Up Down Left Right', name: 'fa-arrows-up-down-left-right' },
  { id: 58562, unicode: 'e4c2', label: 'Arrows Up To Line', name: 'fa-arrows-up-to-line' },
  { id: 63354, unicode: 'f77a', label: 'Artstation', name: 'fa-artstation' },
  { id: 42, unicode: '2a', label: 'Asterisk', name: 'fa-asterisk' },
  { id: 62322, unicode: 'f372', label: 'Asymmetrik', name: 'fa-asymmetrik--ltd-' },
  { id: 64, unicode: '40', label: 'At', name: 'fa-at' },
  { id: 63355, unicode: 'f77b', label: 'Atlassian', name: 'fa-atlassian' },
  { id: 62930, unicode: 'f5d2', label: 'Atom', name: 'fa-atom' },
  { id: 62931, unicode: 'f5d3', label: 'Atom Simple', name: 'fa-atom-simple' },
  { id: 62323, unicode: 'f373', label: 'Audible', name: 'fa-audible' },
  { id: 62110, unicode: 'f29e', label: 'Rectangle Audio Description', name: 'fa-rectangle-audio-description' },
  { id: 57512, unicode: 'e0a8', label: 'Audio Description Slash', name: 'fa-audio-description-slash' },
  { id: 57513, unicode: 'e0a9', label: 'Austral Sign', name: 'fa-austral-sign' },
  { id: 62492, unicode: 'f41c', label: 'Autoprefixer', name: 'fa-autoprefixer' },
  { id: 62324, unicode: 'f374', label: 'Avianex', name: 'fa-avianex' },
  { id: 62497, unicode: 'f421', label: 'Aviato', name: 'fa-aviato' },
  { id: 57514, unicode: 'e0aa', label: 'Avocado', name: 'fa-avocado' },
  { id: 62809, unicode: 'f559', label: 'Award', name: 'fa-award' },
  { id: 57515, unicode: 'e0ab', label: 'Award Simple', name: 'fa-award-simple' },
  { id: 62325, unicode: 'f375', label: 'Amazon Web Services  AWS ', name: 'fa-amazon-web-services--aws-' },
  { id: 63154, unicode: 'f6b2', label: 'Axe', name: 'fa-axe' },
  { id: 63155, unicode: 'f6b3', label: 'Axe Battle', name: 'fa-axe-battle' },
  { id: 66, unicode: '42', label: 'B', name: 'fa-b' },
  { id: 63356, unicode: 'f77c', label: 'Baby', name: 'fa-baby' },
  { id: 63357, unicode: 'f77d', label: 'Baby Carriage', name: 'fa-baby-carriage' },
  { id: 62932, unicode: 'f5d4', label: 'Backpack', name: 'fa-backpack' },
  { id: 61514, unicode: 'f04a', label: 'Backward', name: 'fa-backward' },
  { id: 61513, unicode: 'f049', label: 'Backward Fast', name: 'fa-backward-fast' },
  { id: 61512, unicode: 'f048', label: 'Backward Step', name: 'fa-backward-step' },
  { id: 63461, unicode: 'f7e5', label: 'Bacon', name: 'fa-bacon' },
  { id: 57433, unicode: 'e059', label: 'Bacteria', name: 'fa-bacteria' },
  { id: 57434, unicode: 'e05a', label: 'Bacterium', name: 'fa-bacterium' },
  { id: 62261, unicode: 'f335', label: 'Badge', name: 'fa-badge' },
  { id: 62262, unicode: 'f336', label: 'Check Badge', name: 'fa-check-badge' },
  { id: 63045, unicode: 'f645', label: 'Badge Dollar', name: 'fa-badge-dollar' },
  { id: 63046, unicode: 'f646', label: 'Badge Percent', name: 'fa-badge-percent' },
  { id: 63650, unicode: 'f8a2', label: 'Sheriff Badge', name: 'fa-sheriff-badge' },
  { id: 63156, unicode: 'f6b4', label: 'Honey Badger', name: 'fa-honey-badger' },
  { id: 58170, unicode: 'e33a', label: 'Badminton', name: 'fa-badminton' },
  { id: 62096, unicode: 'f290', label: 'Bag Shopping', name: 'fa-bag-shopping' },
  { id: 58327, unicode: 'e3d7', label: 'Bagel', name: 'fa-bagel' },
  { id: 63559, unicode: 'f847', label: 'Shopping Bags', name: 'fa-shopping-bags' },
  { id: 58328, unicode: 'e3d8', label: 'Baguette', name: 'fa-baguette' },
  { id: 57516, unicode: 'e0ac', label: 'Baht Sign', name: 'fa-baht-sign' },
  { id: 63358, unicode: 'f77e', label: 'Ball Pile', name: 'fa-ball-pile' },
  { id: 58083, unicode: 'e2e3', label: 'Balloon', name: 'fa-balloon' },
  { id: 58084, unicode: 'e2e4', label: 'Balloons', name: 'fa-balloons' },
  { id: 63282, unicode: 'f732', label: 'Ballot', name: 'fa-ballot' },
  { id: 63283, unicode: 'f733', label: 'Ballot Check', name: 'fa-ballot-check' },
  { id: 61534, unicode: 'f05e', label: 'Ban', name: 'fa-ban' },
  { id: 63481, unicode: 'f7f9', label: 'Ban Bug', name: 'fa-ban-bug' },
  { id: 62998, unicode: 'f616', label: 'Ban Parking', name: 'fa-ban-parking' },
  { id: 62797, unicode: 'f54d', label: 'Ban Smoking', name: 'fa-ban-smoking' },
  { id: 58085, unicode: 'e2e5', label: 'Banana', name: 'fa-banana' },
  { id: 62562, unicode: 'f462', label: 'Bandage', name: 'fa-bandage' },
  { id: 62165, unicode: 'f2d5', label: 'Bandcamp', name: 'fa-bandcamp' },
  { id: 58086, unicode: 'e2e6', label: 'Bangladeshi Taka Sign', name: 'fa-bangladeshi-taka-sign' },
  { id: 63651, unicode: 'f8a3', label: 'Banjo', name: 'fa-banjo' },
  { id: 61482, unicode: 'f02a', label: 'Barcode', name: 'fa-barcode' },
  { id: 62564, unicode: 'f464', label: 'Barcode Read', name: 'fa-barcode-read' },
  { id: 62565, unicode: 'f465', label: 'Barcode Scan', name: 'fa-barcode-scan' },
  { id: 61641, unicode: 'f0c9', label: 'Bars', name: 'fa-bars' },
  { id: 57517, unicode: 'e0ad', label: 'Bars Filter', name: 'fa-bars-filter' },
  { id: 63528, unicode: 'f828', label: 'Bars Progress', name: 'fa-bars-progress' },
  { id: 57518, unicode: 'e0ae', label: 'Bars Sort', name: 'fa-bars-sort' },
  { id: 62800, unicode: 'f550', label: 'Bars Staggered', name: 'fa-bars-staggered' },
  { id: 62515, unicode: 'f433', label: 'Baseball Ball', name: 'fa-baseball-ball' },
  { id: 62514, unicode: 'f432', label: 'Baseball Bat Ball', name: 'fa-baseball-bat-ball' },
  { id: 62097, unicode: 'f291', label: 'Basket Shopping', name: 'fa-basket-shopping' },
  { id: 57519, unicode: 'e0af', label: 'Basket Shopping Simple', name: 'fa-basket-shopping-simple' },
  { id: 62516, unicode: 'f434', label: 'Basketball Ball', name: 'fa-basketball-ball' },
  { id: 62517, unicode: 'f435', label: 'Basketball Hoop', name: 'fa-basketball-hoop' },
  { id: 63157, unicode: 'f6b5', label: 'Bat', name: 'fa-bat' },
  { id: 62157, unicode: 'f2cd', label: 'Bath', name: 'fa-bath' },
  { id: 62326, unicode: 'f376', label: 'Battery Bolt', name: 'fa-battery-bolt' },
  { id: 62020, unicode: 'f244', label: 'Battery Empty', name: 'fa-battery-empty' },
  { id: 57520, unicode: 'e0b0', label: 'Battery Exclamation', name: 'fa-battery-exclamation' },
  { id: 62016, unicode: 'f240', label: 'Battery Full', name: 'fa-battery-full' },
  { id: 62018, unicode: 'f242', label: 'Battery 1 2 Full', name: 'fa-battery-1-2-full' },
  { id: 57521, unicode: 'e0b1', label: 'Battery Low', name: 'fa-battery-low' },
  { id: 62019, unicode: 'f243', label: 'Battery 1 4 Full', name: 'fa-battery-1-4-full' },
  { id: 62327, unicode: 'f377', label: 'Battery Slash', name: 'fa-battery-slash' },
  { id: 62017, unicode: 'f241', label: 'Battery 3 4 Full', name: 'fa-battery-3-4-full' },
  { id: 63541, unicode: 'f835', label: 'Battle Net', name: 'fa-battle-net' },
  { id: 62006, unicode: 'f236', label: 'Bed', name: 'fa-bed' },
  { id: 63736, unicode: 'f8f8', label: 'Bunkbed', name: 'fa-bunkbed' },
  { id: 63737, unicode: 'f8f9', label: 'Bed Empty', name: 'fa-bed-empty' },
  { id: 63735, unicode: 'f8f7', label: 'Bed Front', name: 'fa-bed-front' },
  { id: 62599, unicode: 'f487', label: 'Bed Pulse', name: 'fa-bed-pulse' },
  { id: 57522, unicode: 'e0b2', label: 'Bee', name: 'fa-bee' },
  { id: 57523, unicode: 'e0b3', label: 'Beer Mug', name: 'fa-beer-mug' },
  { id: 61692, unicode: 'f0fc', label: 'Beer Mug Empty', name: 'fa-beer-mug-empty' },
  { id: 61876, unicode: 'f1b4', label: 'Behance', name: 'fa-behance' },
  { id: 61683, unicode: 'f0f3', label: 'Bell', name: 'fa-bell' },
  { id: 62818, unicode: 'f562', label: 'Bell Concierge', name: 'fa-bell-concierge' },
  { id: 63560, unicode: 'f848', label: 'Bell Exclamation', name: 'fa-bell-exclamation' },
  { id: 63738, unicode: 'f8fa', label: 'Bell On', name: 'fa-bell-on' },
  { id: 63561, unicode: 'f849', label: 'Bell Plus', name: 'fa-bell-plus' },
  { id: 62933, unicode: 'f5d5', label: 'Bell School', name: 'fa-bell-school' },
  { id: 62934, unicode: 'f5d6', label: 'Bell School Slash', name: 'fa-bell-school-slash' },
  { id: 61942, unicode: 'f1f6', label: 'Bell Slash', name: 'fa-bell-slash' },
  { id: 63359, unicode: 'f77f', label: 'Bells', name: 'fa-bells' },
  { id: 58087, unicode: 'e2e7', label: 'Bench Tree', name: 'fa-bench-tree' },
  { id: 62811, unicode: 'f55b', label: 'Bezier Curve', name: 'fa-bezier-curve' },
  { id: 61958, unicode: 'f206', label: 'Bicycle', name: 'fa-bicycle' },
  { id: 58329, unicode: 'e3d9', label: 'Bilibili', name: 'fa-bilibili' },
  { id: 62328, unicode: 'f378', label: 'Bimobject', name: 'fa-bimobject' },
  { id: 58171, unicode: 'e33b', label: 'Binary', name: 'fa-binary' },
  { id: 58172, unicode: 'e33c', label: 'Binary Circle Check', name: 'fa-binary-circle-check' },
  { id: 58173, unicode: 'e33d', label: 'Binary Lock', name: 'fa-binary-lock' },
  { id: 58174, unicode: 'e33e', label: 'Binary Slash', name: 'fa-binary-slash' },
  { id: 61925, unicode: 'f1e5', label: 'Binoculars', name: 'fa-binoculars' },
  { id: 63360, unicode: 'f780', label: 'Biohazard', name: 'fa-biohazard' },
  { id: 58473, unicode: 'e469', label: 'Bird', name: 'fa-bird' },
  { id: 61809, unicode: 'f171', label: 'Bitbucket', name: 'fa-bitbucket' },
  { id: 62329, unicode: 'f379', label: 'Bitcoin', name: 'fa-bitcoin' },
  { id: 57524, unicode: 'e0b4', label: 'Bitcoin Sign', name: 'fa-bitcoin-sign' },
  { id: 62330, unicode: 'f37a', label: 'Bity', name: 'fa-bity' },
  { id: 62078, unicode: 'f27e', label: 'Font Awesome Black Tie', name: 'fa-font-awesome-black-tie' },
  { id: 62331, unicode: 'f37b', label: 'Blackberry', name: 'fa-blackberry' },
  { id: 62616, unicode: 'f498', label: 'Blanket', name: 'fa-blanket' },
  { id: 58330, unicode: 'e3da', label: 'Blanket Fire', name: 'fa-blanket-fire' },
  { id: 62743, unicode: 'f517', label: 'Blender', name: 'fa-blender' },
  { id: 63158, unicode: 'f6b6', label: 'Blender Phone', name: 'fa-blender-phone' },
  { id: 63739, unicode: 'f8fb', label: 'Blinds', name: 'fa-blinds' },
  { id: 63740, unicode: 'f8fc', label: 'Blinds Open', name: 'fa-blinds-open' },
  { id: 63741, unicode: 'f8fd', label: 'Blinds Raised', name: 'fa-blinds-raised' },
  { id: 58474, unicode: 'e46a', label: 'Block', name: 'fa-block' },
  { id: 58331, unicode: 'e3db', label: 'Brick Block', name: 'fa-brick-block' },
  { id: 58332, unicode: 'e3dc', label: 'Block Brick Fire', name: 'fa-block-brick-fire' },
  { id: 58333, unicode: 'e3dd', label: 'Block Question', name: 'fa-block-question' },
  { id: 57525, unicode: 'e0b5', label: 'Block Quote', name: 'fa-block-quote' },
  { id: 63361, unicode: 'f781', label: 'Blog', name: 'fa-blog' },
  { id: 62332, unicode: 'f37c', label: 'Blogger', name: 'fa-blogger' },
  { id: 62333, unicode: 'f37d', label: 'Blogger B', name: 'fa-blogger-b' },
  { id: 58088, unicode: 'e2e8', label: 'Blueberries', name: 'fa-blueberries' },
  { id: 62099, unicode: 'f293', label: 'Bluetooth', name: 'fa-bluetooth' },
  { id: 62100, unicode: 'f294', label: 'Bluetooth', name: 'fa-bluetooth' },
  { id: 61490, unicode: 'f032', label: 'Bold', name: 'fa-bold' },
  { id: 61671, unicode: 'f0e7', label: 'Bolt', name: 'fa-bolt' },
  { id: 57526, unicode: 'e0b6', label: 'Bolt Auto', name: 'fa-bolt-auto' },
  { id: 57527, unicode: 'e0b7', label: 'Lightning Bolt', name: 'fa-lightning-bolt' },
  { id: 57528, unicode: 'e0b8', label: 'Bolt Slash', name: 'fa-bolt-slash' },
  { id: 61922, unicode: 'f1e2', label: 'Bomb', name: 'fa-bomb' },
  { id: 62935, unicode: 'f5d7', label: 'Bone', name: 'fa-bone' },
  { id: 62936, unicode: 'f5d8', label: 'Bone Break', name: 'fa-bone-break' },
  { id: 62812, unicode: 'f55c', label: 'Bong', name: 'fa-bong' },
  { id: 61485, unicode: 'f02d', label: 'Book', name: 'fa-book' },
  { id: 57529, unicode: 'e0b9', label: 'Book Arrow Right', name: 'fa-book-arrow-right' },
  { id: 57530, unicode: 'e0ba', label: 'Book Arrow Up', name: 'fa-book-arrow-up' },
  { id: 62808, unicode: 'f558', label: 'Book Atlas', name: 'fa-book-atlas' },
  { id: 63047, unicode: 'f647', label: 'Book Bible', name: 'fa-book-bible' },
  { id: 62937, unicode: 'f5d9', label: 'Book Blank', name: 'fa-book-blank' },
  { id: 57531, unicode: 'e0bb', label: 'Book Bookmark', name: 'fa-book-bookmark' },
  { id: 57532, unicode: 'e0bc', label: 'Book Circle Arrow Right', name: 'fa-book-circle-arrow-right' },
  { id: 57533, unicode: 'e0bd', label: 'Book Circle Arrow Up', name: 'fa-book-circle-arrow-up' },
  { id: 57534, unicode: 'e0be', label: 'Book Copy', name: 'fa-book-copy' },
  { id: 57535, unicode: 'e0bf', label: 'Book Font', name: 'fa-book-font' },
  { id: 62617, unicode: 'f499', label: 'Book With Heart', name: 'fa-book-with-heart' },
  { id: 63082, unicode: 'f66a', label: 'Book Journal Whills', name: 'fa-book-journal-whills' },
  { id: 63462, unicode: 'f7e6', label: 'Medical Book', name: 'fa-medical-book' },
  { id: 62744, unicode: 'f518', label: 'Book Open', name: 'fa-book-open' },
  { id: 57536, unicode: 'e0c0', label: 'Book Open Cover', name: 'fa-book-open-cover' },
  { id: 62938, unicode: 'f5da', label: 'Book Open Reader', name: 'fa-book-open-reader' },
  { id: 63111, unicode: 'f687', label: 'Book Quran', name: 'fa-book-quran' },
  { id: 57537, unicode: 'e0c1', label: 'Law Book', name: 'fa-law-book' },
  { id: 63159, unicode: 'f6b7', label: 'Book Skull', name: 'fa-book-skull' },
  { id: 63160, unicode: 'f6b8', label: 'Book Sparkles', name: 'fa-book-sparkles' },
  { id: 63527, unicode: 'f827', label: 'Book Tanakh', name: 'fa-book-tanakh' },
  { id: 63463, unicode: 'f7e7', label: 'Book With User', name: 'fa-book-with-user' },
  { id: 61486, unicode: 'f02e', label: 'Bookmark', name: 'fa-bookmark' },
  { id: 57538, unicode: 'e0c2', label: 'Bookmark Slash', name: 'fa-bookmark-slash' },
  { id: 62939, unicode: 'f5db', label: 'Books', name: 'fa-books' },
  { id: 63464, unicode: 'f7e8', label: 'Medical Books', name: 'fa-medical-books' },
  { id: 63653, unicode: 'f8a5', label: 'Boombox', name: 'fa-boombox' },
  { id: 63362, unicode: 'f782', label: 'Boot', name: 'fa-boot' },
  { id: 58175, unicode: 'e33f', label: 'Boot Heeled', name: 'fa-boot-heeled' },
  { id: 63284, unicode: 'f734', label: 'Booth With Curtain', name: 'fa-booth-with-curtain' },
  { id: 63542, unicode: 'f836', label: 'Bootstrap', name: 'fa-bootstrap' },
  { id: 63564, unicode: 'f84c', label: 'Border All', name: 'fa-border-all' },
  { id: 63565, unicode: 'f84d', label: 'Border Bottom', name: 'fa-border-bottom' },
  { id: 63572, unicode: 'f854', label: 'Border Bottom Right', name: 'fa-border-bottom-right' },
  { id: 63644, unicode: 'f89c', label: 'Border Center Horizontal', name: 'fa-border-center-horizontal' },
  { id: 63645, unicode: 'f89d', label: 'Border Center Vertical', name: 'fa-border-center-vertical' },
  { id: 63566, unicode: 'f84e', label: 'Border Inner', name: 'fa-border-inner' },
  { id: 63567, unicode: 'f84f', label: 'Border Left', name: 'fa-border-left' },
  { id: 63568, unicode: 'f850', label: 'Border None', name: 'fa-border-none' },
  { id: 63569, unicode: 'f851', label: 'Border Outer', name: 'fa-border-outer' },
  { id: 63570, unicode: 'f852', label: 'Border Right', name: 'fa-border-right' },
  { id: 63573, unicode: 'f855', label: 'Border Top', name: 'fa-border-top' },
  { id: 63571, unicode: 'f853', label: 'Border Top Left', name: 'fa-border-top-left' },
  { id: 58563, unicode: 'e4c3', label: 'Bore Hole', name: 'fa-bore-hole' },
  { id: 58176, unicode: 'e340', label: 'Bots', name: 'fa-bots' },
  { id: 58564, unicode: 'e4c4', label: 'Bottle Droplet', name: 'fa-bottle-droplet' },
  { id: 58565, unicode: 'e4c5', label: 'Bottle Water', name: 'fa-bottle-water' },
  { id: 63161, unicode: 'f6b9', label: 'Bow Arrow', name: 'fa-bow-arrow' },
  { id: 58089, unicode: 'e2e9', label: 'Bowl Chopsticks', name: 'fa-bowl-chopsticks' },
  { id: 58090, unicode: 'e2ea', label: 'Bowl Chopsticks Noodles', name: 'fa-bowl-chopsticks-noodles' },
  { id: 58566, unicode: 'e4c6', label: 'Bowl Food', name: 'fa-bowl-food' },
  { id: 63523, unicode: 'f823', label: 'Bowl Hot', name: 'fa-bowl-hot' },
  { id: 58091, unicode: 'e2eb', label: 'Bowl Rice', name: 'fa-bowl-rice' },
  { id: 58334, unicode: 'e3de', label: 'Bowl Scoop', name: 'fa-bowl-scoop' },
  { id: 58335, unicode: 'e3df', label: 'Bowl With Scoops', name: 'fa-bowl-with-scoops' },
  { id: 58475, unicode: 'e46b', label: 'Bowl Soft Serve', name: 'fa-bowl-soft-serve' },
  { id: 58336, unicode: 'e3e0', label: 'Bowl Spoon', name: 'fa-bowl-spoon' },
  { id: 62518, unicode: 'f436', label: 'Bowling Ball', name: 'fa-bowling-ball' },
  { id: 57539, unicode: 'e0c3', label: 'Bowling Ball Pin', name: 'fa-bowling-ball-pin' },
  { id: 62519, unicode: 'f437', label: 'Bowling Pins', name: 'fa-bowling-pins' },
  { id: 62566, unicode: 'f466', label: 'Box', name: 'fa-box' },
  { id: 61831, unicode: 'f187', label: 'Box Archive', name: 'fa-box-archive' },
  { id: 63285, unicode: 'f735', label: 'Box Ballot', name: 'fa-box-ballot' },
  { id: 62567, unicode: 'f467', label: 'Box Check', name: 'fa-box-check' },
  { id: 57540, unicode: 'e0c4', label: 'Box Circle Check', name: 'fa-box-circle-check' },
  { id: 62624, unicode: 'f4a0', label: 'Box Dollar', name: 'fa-box-dollar' },
  { id: 62621, unicode: 'f49d', label: 'Box With Heart', name: 'fa-box-with-heart' },
  { id: 62622, unicode: 'f49e', label: 'Box Open', name: 'fa-box-open' },
  { id: 62620, unicode: 'f49c', label: 'Box Open Full', name: 'fa-box-open-full' },
  { id: 62618, unicode: 'f49a', label: 'Box Taped', name: 'fa-box-taped' },
  { id: 57435, unicode: 'e05b', label: 'Tissue Box', name: 'fa-tissue-box' },
  { id: 58567, unicode: 'e4c7', label: 'Boxes Packing', name: 'fa-boxes-packing' },
  { id: 62568, unicode: 'f468', label: 'Boxes Stacked', name: 'fa-boxes-stacked' },
  { id: 62520, unicode: 'f438', label: 'Boxing Glove', name: 'fa-boxing-glove' },
  { id: 123, unicode: '7b', label: 'Bracket Curly', name: 'fa-bracket-curly' },
  { id: 125, unicode: '7d', label: 'Bracket Curly Right', name: 'fa-bracket-curly-right' },
  { id: 40, unicode: '28', label: 'Bracket Round', name: 'fa-bracket-round' },
  { id: 41, unicode: '29', label: 'Bracket Round Right', name: 'fa-bracket-round-right' },
  { id: 91, unicode: '5b', label: 'Bracket Square', name: 'fa-bracket-square' },
  { id: 93, unicode: '5d', label: 'Bracket Square Right', name: 'fa-bracket-square-right' },
  { id: 63466, unicode: 'f7ea', label: 'Curly Brackets', name: 'fa-curly-brackets' },
  { id: 57541, unicode: 'e0c5', label: 'Brackets Round', name: 'fa-brackets-round' },
  { id: 63465, unicode: 'f7e9', label: 'Brackets Square', name: 'fa-brackets-square' },
  { id: 62113, unicode: 'f2a1', label: 'Braille', name: 'fa-braille' },
  { id: 62940, unicode: 'f5dc', label: 'Brain', name: 'fa-brain' },
  { id: 63095, unicode: 'f677', label: 'Brain Arrow Curved Right', name: 'fa-brain-arrow-curved-right' },
  { id: 57542, unicode: 'e0c6', label: 'Brain Circuit', name: 'fa-brain-circuit' },
  { id: 57543, unicode: 'e0c7', label: 'Brake Warning', name: 'fa-brake-warning' },
  { id: 58476, unicode: 'e46c', label: 'Brazilian Real Sign', name: 'fa-brazilian-real-sign' },
  { id: 63467, unicode: 'f7eb', label: 'Loaf Of Bread', name: 'fa-loaf-of-bread' },
  { id: 63468, unicode: 'f7ec', label: 'Bread Slice', name: 'fa-bread-slice' },
  { id: 58337, unicode: 'e3e1', label: 'Bread Slice Butter', name: 'fa-bread-slice-butter' },
  { id: 58568, unicode: 'e4c8', label: 'Bridge', name: 'fa-bridge' },
  { id: 58569, unicode: 'e4c9', label: 'Bridge Circle Check', name: 'fa-bridge-circle-check' },
  { id: 58570, unicode: 'e4ca', label: 'Bridge Circle Exclamation', name: 'fa-bridge-circle-exclamation' },
  { id: 58571, unicode: 'e4cb', label: 'Bridge Circle Xmark', name: 'fa-bridge-circle-xmark' },
  { id: 58572, unicode: 'e4cc', label: 'Bridge Lock', name: 'fa-bridge-lock' },
  { id: 58573, unicode: 'e4cd', label: 'Bridge Suspension', name: 'fa-bridge-suspension' },
  { id: 58574, unicode: 'e4ce', label: 'Bridge Water', name: 'fa-bridge-water' },
  { id: 61617, unicode: 'f0b1', label: 'Briefcase', name: 'fa-briefcase' },
  { id: 58098, unicode: 'e2f2', label: 'Briefcase Arrow Right', name: 'fa-briefcase-arrow-right' },
  { id: 57544, unicode: 'e0c8', label: 'Briefcase Blank', name: 'fa-briefcase-blank' },
  { id: 62569, unicode: 'f469', label: 'Medical Briefcase', name: 'fa-medical-briefcase' },
  { id: 57545, unicode: 'e0c9', label: 'Brightness', name: 'fa-brightness' },
  { id: 57546, unicode: 'e0ca', label: 'Brightness Low', name: 'fa-brightness-low' },
  { id: 63574, unicode: 'f856', label: 'Bring Forward', name: 'fa-bring-forward' },
  { id: 63575, unicode: 'f857', label: 'Bring Front', name: 'fa-bring-front' },
  { id: 58338, unicode: 'e3e2', label: 'Broccoli', name: 'fa-broccoli' },
  { id: 62746, unicode: 'f51a', label: 'Broom', name: 'fa-broom' },
  { id: 62552, unicode: 'f458', label: 'Broom And Ball', name: 'fa-broom-and-ball' },
  { id: 62334, unicode: 'f37e', label: 'Browser', name: 'fa-browser' },
  { id: 57547, unicode: 'e0cb', label: 'Browsers', name: 'fa-browsers' },
  { id: 62813, unicode: 'f55d', label: 'Brush', name: 'fa-brush' },
  { id: 61786, unicode: 'f15a', label: 'BTC', name: 'fa-btc' },
  { id: 58575, unicode: 'e4cf', label: 'Bucket', name: 'fa-bucket' },
  { id: 63543, unicode: 'f837', label: 'Buffer', name: 'fa-buffer' },
  { id: 61832, unicode: 'f188', label: 'Bug', name: 'fa-bug' },
  { id: 58512, unicode: 'e490', label: 'Bug Slash', name: 'fa-bug-slash' },
  { id: 58576, unicode: 'e4d0', label: 'Bugs', name: 'fa-bugs' },
  { id: 61869, unicode: 'f1ad', label: 'Building', name: 'fa-building' },
  { id: 58577, unicode: 'e4d1', label: 'Building Circle Arrow Right', name: 'fa-building-circle-arrow-right' },
  { id: 58578, unicode: 'e4d2', label: 'Building Circle Check', name: 'fa-building-circle-check' },
  { id: 58579, unicode: 'e4d3', label: 'Building Circle Exclamation', name: 'fa-building-circle-exclamation' },
  { id: 58580, unicode: 'e4d4', label: 'Building Circle Xmark', name: 'fa-building-circle-xmark' },
  { id: 61852, unicode: 'f19c', label: 'Building With Columns', name: 'fa-building-with-columns' },
  { id: 58581, unicode: 'e4d5', label: 'Building Flag', name: 'fa-building-flag' },
  { id: 58582, unicode: 'e4d6', label: 'Building Lock', name: 'fa-building-lock' },
  { id: 58583, unicode: 'e4d7', label: 'Building Ngo', name: 'fa-building-ngo' },
  { id: 58584, unicode: 'e4d8', label: 'Building Shield', name: 'fa-building-shield' },
  { id: 58585, unicode: 'e4d9', label: 'Building Un', name: 'fa-building-un' },
  { id: 58586, unicode: 'e4da', label: 'Building User', name: 'fa-building-user' },
  { id: 58587, unicode: 'e4db', label: 'Building Wheat', name: 'fa-building-wheat' },
  { id: 57548, unicode: 'e0cc', label: 'Buildings', name: 'fa-buildings' },
  { id: 61601, unicode: 'f0a1', label: 'Bullhorn', name: 'fa-bullhorn' },
  { id: 61760, unicode: 'f140', label: 'Bullseye', name: 'fa-bullseye' },
  { id: 63048, unicode: 'f648', label: 'Bullseye Arrow', name: 'fa-bullseye-arrow' },
  { id: 63049, unicode: 'f649', label: 'Bullseye Pointer', name: 'fa-bullseye-pointer' },
  { id: 63493, unicode: 'f805', label: 'Burger', name: 'fa-burger' },
  { id: 63473, unicode: 'f7f1', label: 'Burger Cheese', name: 'fa-burger-cheese' },
  { id: 57549, unicode: 'e0cd', label: 'Burger Fries', name: 'fa-burger-fries' },
  { id: 57550, unicode: 'e0ce', label: 'Burger Glass', name: 'fa-burger-glass' },
  { id: 58339, unicode: 'e3e3', label: 'Burger Lettuce', name: 'fa-burger-lettuce' },
  { id: 63576, unicode: 'f858', label: 'Burger And Soda', name: 'fa-burger-and-soda' },
  { id: 62335, unicode: 'f37f', label: 'Bel Experte Gmbh   Co  KG ', name: 'fa-bel-experte-gmbh---co--kg-' },
  { id: 63469, unicode: 'f7ed', label: 'Burrito', name: 'fa-burrito' },
  { id: 58588, unicode: 'e4dc', label: 'Burst', name: 'fa-burst' },
  { id: 61959, unicode: 'f207', label: 'Bus', name: 'fa-bus' },
  { id: 62941, unicode: 'f5dd', label: 'Bus School', name: 'fa-bus-school' },
  { id: 62814, unicode: 'f55e', label: 'Bus Simple', name: 'fa-bus-simple' },
  { id: 63050, unicode: 'f64a', label: 'Briefcase Clock', name: 'fa-briefcase-clock' },
  { id: 58340, unicode: 'e3e4', label: 'Butter', name: 'fa-butter' },
  { id: 63654, unicode: 'f8a6', label: 'Buy N Large', name: 'fa-buy-n-large' },
  { id: 61965, unicode: 'f20d', label: 'Buysellads', name: 'fa-buysellads' },
  { id: 67, unicode: '43', label: 'C', name: 'fa-c' },
  { id: 58477, unicode: 'e46d', label: 'Cabin', name: 'fa-cabin' },
  { id: 63051, unicode: 'f64b', label: 'Filing Cabinet', name: 'fa-filing-cabinet' },
  { id: 63450, unicode: 'f7da', label: 'Cable Car', name: 'fa-cable-car' },
  { id: 63655, unicode: 'f8a7', label: 'Cactus', name: 'fa-cactus' },
  { id: 61949, unicode: 'f1fd', label: 'Cake Candles', name: 'fa-cake-candles' },
  { id: 58341, unicode: 'e3e5', label: 'Cake Slice', name: 'fa-cake-slice' },
  { id: 61932, unicode: 'f1ec', label: 'Calculator', name: 'fa-calculator' },
  { id: 63052, unicode: 'f64c', label: 'Calculator Simple', name: 'fa-calculator-simple' },
  { id: 61747, unicode: 'f133', label: 'Calendar', name: 'fa-calendar' },
  { id: 57552, unicode: 'e0d0', label: 'Calendar Arrow Down', name: 'fa-calendar-arrow-down' },
  { id: 57553, unicode: 'e0d1', label: 'Calendar Arrow Up', name: 'fa-calendar-arrow-up' },
  { id: 62068, unicode: 'f274', label: 'Calendar Check', name: 'fa-calendar-check' },
  { id: 58478, unicode: 'e46e', label: 'Calendar Circle Exclamation', name: 'fa-calendar-circle-exclamation' },
  { id: 58479, unicode: 'e46f', label: 'Calendar Circle Minus', name: 'fa-calendar-circle-minus' },
  { id: 58480, unicode: 'e470', label: 'Calendar Circle Plus', name: 'fa-calendar-circle-plus' },
  { id: 58481, unicode: 'e471', label: 'Calendar Circle User', name: 'fa-calendar-circle-user' },
  { id: 57554, unicode: 'e0d2', label: 'Calendar Clock', name: 'fa-calendar-clock' },
  { id: 63363, unicode: 'f783', label: 'Calendar With Day Focus', name: 'fa-calendar-with-day-focus' },
  { id: 61555, unicode: 'f073', label: 'Calendar Days', name: 'fa-calendar-days' },
  { id: 62260, unicode: 'f334', label: 'Calendar Exclamation', name: 'fa-calendar-exclamation' },
  { id: 57555, unicode: 'e0d3', label: 'Calendar Heart', name: 'fa-calendar-heart' },
  { id: 57556, unicode: 'e0d4', label: 'Calendar Image', name: 'fa-calendar-image' },
  { id: 57557, unicode: 'e0d5', label: 'Calendar Lines', name: 'fa-calendar-lines' },
  { id: 58482, unicode: 'e472', label: 'Calendar Lines Pen', name: 'fa-calendar-lines-pen' },
  { id: 62066, unicode: 'f272', label: 'Calendar Minus', name: 'fa-calendar-minus' },
  { id: 62259, unicode: 'f333', label: 'Calendar Pen', name: 'fa-calendar-pen' },
  { id: 62065, unicode: 'f271', label: 'Calendar Plus', name: 'fa-calendar-plus' },
  { id: 57558, unicode: 'e0d6', label: 'Calendar Range', name: 'fa-calendar-range' },
  { id: 63286, unicode: 'f736', label: 'Calendar Star', name: 'fa-calendar-star' },
  { id: 63364, unicode: 'f784', label: 'Calendar With Week Focus', name: 'fa-calendar-with-week-focus' },
  { id: 62067, unicode: 'f273', label: 'Calendar X Mark', name: 'fa-calendar-x-mark' },
  { id: 57559, unicode: 'e0d7', label: 'Calendars', name: 'fa-calendars' },
  { id: 63656, unicode: 'f8a8', label: 'Camcorder', name: 'fa-camcorder' },
  { id: 61488, unicode: 'f030', label: 'Camera', name: 'fa-camera' },
  { id: 63660, unicode: 'f8ac', label: 'Camera Cctv', name: 'fa-camera-cctv' },
  { id: 63657, unicode: 'f8a9', label: 'Movie Camera', name: 'fa-movie-camera' },
  { id: 63658, unicode: 'f8aa', label: 'Polaroid Camera', name: 'fa-polaroid-camera' },
  { id: 61571, unicode: 'f083', label: 'Retro Camera', name: 'fa-retro-camera' },
  { id: 57560, unicode: 'e0d8', label: 'Camera Rotate', name: 'fa-camera-rotate' },
  { id: 63742, unicode: 'f8fe', label: 'Camera Security', name: 'fa-camera-security' },
  { id: 57561, unicode: 'e0d9', label: 'Camera Slash', name: 'fa-camera-slash' },
  { id: 57562, unicode: 'e0da', label: 'Camera Viewfinder', name: 'fa-camera-viewfinder' },
  { id: 63538, unicode: 'f832', label: 'Camera Web', name: 'fa-camera-web' },
  { id: 63539, unicode: 'f833', label: 'Camera Web Slash', name: 'fa-camera-web-slash' },
  { id: 63162, unicode: 'f6ba', label: 'Campfire', name: 'fa-campfire' },
  { id: 63163, unicode: 'f6bb', label: 'Campground', name: 'fa-campground' },
  { id: 58342, unicode: 'e3e6', label: 'Can Food', name: 'fa-can-food' },
  { id: 63365, unicode: 'f785', label: 'Canadian Maple Leaf', name: 'fa-canadian-maple-leaf' },
  { id: 63164, unicode: 'f6bc', label: 'Candle Holder', name: 'fa-candle-holder' },
  { id: 58343, unicode: 'e3e7', label: 'Candy', name: 'fa-candy' },
  { id: 58344, unicode: 'e3e8', label: 'Candy Bar', name: 'fa-candy-bar' },
  { id: 63366, unicode: 'f786', label: 'Candy Cane', name: 'fa-candy-cane' },
  { id: 63165, unicode: 'f6bd', label: 'Candy Corn', name: 'fa-candy-corn' },
  { id: 62815, unicode: 'f55f', label: 'Cannabis', name: 'fa-cannabis' },
  { id: 62571, unicode: 'f46b', label: 'Capsules', name: 'fa-capsules' },
  { id: 61881, unicode: 'f1b9', label: 'Car', name: 'fa-car' },
  { id: 62943, unicode: 'f5df', label: 'Car Battery', name: 'fa-car-battery' },
  { id: 58177, unicode: 'e341', label: 'Car Bolt', name: 'fa-car-bolt' },
  { id: 63577, unicode: 'f859', label: 'Car And Building', name: 'fa-car-and-building' },
  { id: 62944, unicode: 'f5e0', label: 'Car Bump', name: 'fa-car-bump' },
  { id: 62945, unicode: 'f5e1', label: 'Car Crash', name: 'fa-car-crash' },
  { id: 63578, unicode: 'f85a', label: 'Car And Bus', name: 'fa-car-and-bus' },
  { id: 58178, unicode: 'e342', label: 'Car Circle Bolt', name: 'fa-car-circle-bolt' },
  { id: 62946, unicode: 'f5e2', label: 'Car Garage', name: 'fa-car-garage' },
  { id: 58179, unicode: 'e343', label: 'Car Mirrors', name: 'fa-car-mirrors' },
  { id: 58589, unicode: 'e4dd', label: 'Car On', name: 'fa-car-on' },
  { id: 62942, unicode: 'f5de', label: 'Car Rear', name: 'fa-car-rear' },
  { id: 62948, unicode: 'f5e4', label: 'Car Side', name: 'fa-car-side' },
  { id: 58180, unicode: 'e344', label: 'Car Side Bolt', name: 'fa-car-side-bolt' },
  { id: 62949, unicode: 'f5e5', label: 'Car Tilt', name: 'fa-car-tilt' },
  { id: 58590, unicode: 'e4de', label: 'Car Tunnel', name: 'fa-car-tunnel' },
  { id: 62950, unicode: 'f5e6', label: 'Car Wash', name: 'fa-car-wash' },
  { id: 62947, unicode: 'f5e3', label: 'Car Wrench', name: 'fa-car-wrench' },
  { id: 63743, unicode: 'f8ff', label: 'Caravan', name: 'fa-caravan' },
  { id: 57344, unicode: 'e000', label: 'Caravan Simple', name: 'fa-caravan-simple' },
  { id: 58345, unicode: 'e3e9', label: 'Card Club', name: 'fa-card-club' },
  { id: 58346, unicode: 'e3ea', label: 'Card Diamond', name: 'fa-card-diamond' },
  { id: 58347, unicode: 'e3eb', label: 'Card Heart', name: 'fa-card-heart' },
  { id: 58348, unicode: 'e3ec', label: 'Card Spade', name: 'fa-card-spade' },
  { id: 58349, unicode: 'e3ed', label: 'Cards', name: 'fa-cards' },
  { id: 58591, unicode: 'e4df', label: 'Cards Blank', name: 'fa-cards-blank' },
  { id: 61655, unicode: 'f0d7', label: 'Caret Down', name: 'fa-caret-down' },
  { id: 61657, unicode: 'f0d9', label: 'Caret Left', name: 'fa-caret-left' },
  { id: 61658, unicode: 'f0da', label: 'Caret Right', name: 'fa-caret-right' },
  { id: 61656, unicode: 'f0d8', label: 'Caret Up', name: 'fa-caret-up' },
  { id: 63367, unicode: 'f787', label: 'Carrot', name: 'fa-carrot' },
  { id: 63579, unicode: 'f85b', label: 'Cars', name: 'fa-cars' },
  { id: 61976, unicode: 'f218', label: 'Shopping Cart Arrow Down', name: 'fa-shopping-cart-arrow-down' },
  { id: 58350, unicode: 'e3ee', label: 'Cart Arrow Up', name: 'fa-cart-arrow-up' },
  { id: 58351, unicode: 'e3ef', label: 'Cart Circle Arrow Down', name: 'fa-cart-circle-arrow-down' },
  { id: 58352, unicode: 'e3f0', label: 'Cart Circle Arrow Up', name: 'fa-cart-circle-arrow-up' },
  { id: 58353, unicode: 'e3f1', label: 'Cart Circle Check', name: 'fa-cart-circle-check' },
  { id: 58354, unicode: 'e3f2', label: 'Cart Circle Exclamation', name: 'fa-cart-circle-exclamation' },
  { id: 58355, unicode: 'e3f3', label: 'Cart Circle Plus', name: 'fa-cart-circle-plus' },
  { id: 58356, unicode: 'e3f4', label: 'Cart Circle Xmark', name: 'fa-cart-circle-xmark' },
  { id: 62580, unicode: 'f474', label: 'Cart Flatbed', name: 'fa-cart-flatbed' },
  { id: 62581, unicode: 'f475', label: 'Cart Flatbed Boxes', name: 'fa-cart-flatbed-boxes' },
  { id: 62582, unicode: 'f476', label: 'Cart Flatbed Empty', name: 'fa-cart-flatbed-empty' },
  { id: 62877, unicode: 'f59d', label: 'Cart Flatbed Suitcase', name: 'fa-cart-flatbed-suitcase' },
  { id: 57563, unicode: 'e0db', label: 'Cart Minus', name: 'fa-cart-minus' },
  { id: 61975, unicode: 'f217', label: 'Add To Shopping Cart', name: 'fa-add-to-shopping-cart' },
  { id: 61562, unicode: 'f07a', label: 'Cart Shopping', name: 'fa-cart-shopping' },
  { id: 57564, unicode: 'e0dc', label: 'Cart Shopping Fast', name: 'fa-cart-shopping-fast' },
  { id: 57565, unicode: 'e0dd', label: 'Cart Xmark', name: 'fa-cart-xmark' },
  { id: 63368, unicode: 'f788', label: 'Cash Register', name: 'fa-cash-register' },
  { id: 63652, unicode: 'f8a4', label: 'Betamax Cassette', name: 'fa-betamax-cassette' },
  { id: 63659, unicode: 'f8ab', label: 'Cassette Tape', name: 'fa-cassette-tape' },
  { id: 63724, unicode: 'f8ec', label: 'VHS Cassette', name: 'fa-vhs-cassette' },
  { id: 57566, unicode: 'e0de', label: 'Castle', name: 'fa-castle' },
  { id: 63166, unicode: 'f6be', label: 'Cat', name: 'fa-cat' },
  { id: 57345, unicode: 'e001', label: 'Space Cat', name: 'fa-space-cat' },
  { id: 63167, unicode: 'f6bf', label: 'Cauldron', name: 'fa-cauldron' },
  { id: 62509, unicode: 'f42d', label: 'Amazon Pay Credit Card', name: 'fa-amazon-pay-credit-card' },
  { id: 61939, unicode: 'f1f3', label: 'American Express Credit Card', name: 'fa-american-express-credit-card' },
  { id: 62486, unicode: 'f416', label: 'Apple Pay Credit Card', name: 'fa-apple-pay-credit-card' },
  { id: 62028, unicode: 'f24c', label: 'Diner S Club Credit Card', name: 'fa-diner-s-club-credit-card' },
  { id: 61938, unicode: 'f1f2', label: 'Discover Credit Card', name: 'fa-discover-credit-card' },
  { id: 62027, unicode: 'f24b', label: 'JCB Credit Card', name: 'fa-jcb-credit-card' },
  { id: 61937, unicode: 'f1f1', label: 'Mastercard Credit Card', name: 'fa-mastercard-credit-card' },
  { id: 61940, unicode: 'f1f4', label: 'Paypal Credit Card', name: 'fa-paypal-credit-card' },
  { id: 61941, unicode: 'f1f5', label: 'Stripe Credit Card', name: 'fa-stripe-credit-card' },
  { id: 61936, unicode: 'f1f0', label: 'Visa Credit Card', name: 'fa-visa-credit-card' },
  { id: 57567, unicode: 'e0df', label: 'Cedi Sign', name: 'fa-cedi-sign' },
  { id: 58357, unicode: 'e3f5', label: 'Cent Sign', name: 'fa-cent-sign' },
  { id: 62336, unicode: 'f380', label: 'Centercode', name: 'fa-centercode' },
  { id: 63369, unicode: 'f789', label: 'Centos', name: 'fa-centos' },
  { id: 61603, unicode: 'f0a3', label: 'Certificate', name: 'fa-certificate' },
  { id: 63168, unicode: 'f6c0', label: 'Chair', name: 'fa-chair' },
  { id: 63169, unicode: 'f6c1', label: 'Office Chair', name: 'fa-office-chair' },
  { id: 62747, unicode: 'f51b', label: 'Chalkboard', name: 'fa-chalkboard' },
  { id: 62748, unicode: 'f51c', label: 'Chalkboard User', name: 'fa-chalkboard-user' },
  { id: 63390, unicode: 'f79e', label: 'Champagne Glass', name: 'fa-champagne-glass' },
  { id: 63391, unicode: 'f79f', label: 'Champagne Glasses', name: 'fa-champagne-glasses' },
  { id: 62951, unicode: 'f5e7', label: 'Charging Station', name: 'fa-charging-station' },
  { id: 61950, unicode: 'f1fe', label: 'Area Chart', name: 'fa-area-chart' },
  { id: 61568, unicode: 'f080', label: 'Bar Chart', name: 'fa-bar-chart' },
  { id: 57569, unicode: 'e0e1', label: 'Chart Bullet', name: 'fa-chart-bullet' },
  { id: 57570, unicode: 'e0e2', label: 'Chart Candlestick', name: 'fa-chart-candlestick' },
  { id: 57571, unicode: 'e0e3', label: 'Chart Column', name: 'fa-chart-column' },
  { id: 57572, unicode: 'e0e4', label: 'Chart Gantt', name: 'fa-chart-gantt' },
  { id: 61953, unicode: 'f201', label: 'Line Chart', name: 'fa-line-chart' },
  { id: 63053, unicode: 'f64d', label: 'Line Chart In Down Direction', name: 'fa-line-chart-in-down-direction' },
  { id: 57573, unicode: 'e0e5', label: 'Chart Line Up', name: 'fa-chart-line-up' },
  { id: 63043, unicode: 'f643', label: 'Chart Mixed', name: 'fa-chart-mixed' },
  { id: 63370, unicode: 'f78a', label: 'Network Chart', name: 'fa-network-chart' },
  { id: 61952, unicode: 'f200', label: 'Pie Chart', name: 'fa-pie-chart' },
  { id: 63054, unicode: 'f64e', label: 'Chart Pie Simple', name: 'fa-chart-pie-simple' },
  { id: 57574, unicode: 'e0e6', label: 'Chart Pyramid', name: 'fa-chart-pyramid' },
  { id: 57575, unicode: 'e0e7', label: 'Chart Radar', name: 'fa-chart-radar' },
  { id: 63470, unicode: 'f7ee', label: 'Scatter Chart', name: 'fa-scatter-chart' },
  { id: 57576, unicode: 'e0e8', label: 'Chart Scatter 3D', name: 'fa-chart-scatter-3d' },
  { id: 57577, unicode: 'e0e9', label: 'Chart Scatter Bubble', name: 'fa-chart-scatter-bubble' },
  { id: 58483, unicode: 'e473', label: 'Chart Simple', name: 'fa-chart-simple' },
  { id: 58484, unicode: 'e474', label: 'Chart Simple Horizontal', name: 'fa-chart-simple-horizontal' },
  { id: 57578, unicode: 'e0ea', label: 'Chart Tree Map', name: 'fa-chart-tree-map' },
  { id: 63139, unicode: 'f6a3', label: 'Chart User', name: 'fa-chart-user' },
  { id: 57579, unicode: 'e0eb', label: 'Chart Waterfall', name: 'fa-chart-waterfall' },
  { id: 61452, unicode: 'f00c', label: 'Check', name: 'fa-check' },
  { id: 62816, unicode: 'f560', label: 'Double Check', name: 'fa-double-check' },
  { id: 63346, unicode: 'f772', label: 'Check To Slot', name: 'fa-check-to-slot' },
  { id: 63471, unicode: 'f7ef', label: 'Cheese', name: 'fa-cheese' },
  { id: 63472, unicode: 'f7f0', label: 'Swiss Cheese', name: 'fa-swiss-cheese' },
  { id: 57580, unicode: 'e0ec', label: 'Cherries', name: 'fa-cherries' },
  { id: 62521, unicode: 'f439', label: 'Chess', name: 'fa-chess' },
  { id: 62522, unicode: 'f43a', label: 'Chess Bishop', name: 'fa-chess-bishop' },
  { id: 62523, unicode: 'f43b', label: 'Chess Bishop Piece', name: 'fa-chess-bishop-piece' },
  { id: 62524, unicode: 'f43c', label: 'Chess Board', name: 'fa-chess-board' },
  { id: 62525, unicode: 'f43d', label: 'Chess Clock', name: 'fa-chess-clock' },
  { id: 62526, unicode: 'f43e', label: 'Chess Clock Flip', name: 'fa-chess-clock-flip' },
  { id: 62527, unicode: 'f43f', label: 'Chess King', name: 'fa-chess-king' },
  { id: 62528, unicode: 'f440', label: 'Chess King Piece', name: 'fa-chess-king-piece' },
  { id: 62529, unicode: 'f441', label: 'Chess Knight', name: 'fa-chess-knight' },
  { id: 62530, unicode: 'f442', label: 'Chess Knight Piece', name: 'fa-chess-knight-piece' },
  { id: 62531, unicode: 'f443', label: 'Chess Pawn', name: 'fa-chess-pawn' },
  { id: 62532, unicode: 'f444', label: 'Chess Pawn Piece', name: 'fa-chess-pawn-piece' },
  { id: 62533, unicode: 'f445', label: 'Chess Queen', name: 'fa-chess-queen' },
  { id: 62534, unicode: 'f446', label: 'Chess Queen Piece', name: 'fa-chess-queen-piece' },
  { id: 62535, unicode: 'f447', label: 'Chess Rook', name: 'fa-chess-rook' },
  { id: 62536, unicode: 'f448', label: 'Chess Rook Piece', name: 'fa-chess-rook-piece' },
  { id: 58358, unicode: 'e3f6', label: 'Chestnut', name: 'fa-chestnut' },
  { id: 61560, unicode: 'f078', label: 'Chevron Down', name: 'fa-chevron-down' },
  { id: 61523, unicode: 'f053', label: 'Chevron Left', name: 'fa-chevron-left' },
  { id: 61524, unicode: 'f054', label: 'Chevron Right', name: 'fa-chevron-right' },
  { id: 61559, unicode: 'f077', label: 'Chevron Up', name: 'fa-chevron-up' },
  { id: 62242, unicode: 'f322', label: 'Chevrons Down', name: 'fa-chevrons-down' },
  { id: 62243, unicode: 'f323', label: 'Chevrons Left', name: 'fa-chevrons-left' },
  { id: 62244, unicode: 'f324', label: 'Chevrons Right', name: 'fa-chevrons-right' },
  { id: 62245, unicode: 'f325', label: 'Chevrons Up', name: 'fa-chevrons-up' },
  { id: 61870, unicode: 'f1ae', label: 'Child', name: 'fa-child' },
  { id: 58780, unicode: 'e59c', label: 'Child Dress', name: 'fa-child-dress' },
  { id: 58781, unicode: 'e59d', label: 'Child Reaching', name: 'fa-child-reaching' },
  { id: 58592, unicode: 'e4e0', label: 'Child Rifle', name: 'fa-child-rifle' },
  { id: 58593, unicode: 'e4e1', label: 'Children', name: 'fa-children' },
  { id: 63371, unicode: 'f78b', label: 'Chimney', name: 'fa-chimney' },
  { id: 58359, unicode: 'e3f7', label: 'Chopsticks', name: 'fa-chopsticks' },
  { id: 62056, unicode: 'f268', label: 'Chrome', name: 'fa-chrome' },
  { id: 63544, unicode: 'f838', label: 'Chromecast', name: 'fa-chromecast' },
  { id: 62749, unicode: 'f51d', label: 'Church', name: 'fa-church' },
  { id: 61713, unicode: 'f111', label: 'Circle', name: 'fa-circle' },
  { id: 57581, unicode: 'e0ed', label: 'Circle 0', name: 'fa-circle-0' },
  { id: 57582, unicode: 'e0ee', label: 'Circle 1', name: 'fa-circle-1' },
  { id: 57583, unicode: 'e0ef', label: 'Circle 2', name: 'fa-circle-2' },
  { id: 57584, unicode: 'e0f0', label: 'Circle 3', name: 'fa-circle-3' },
  { id: 57585, unicode: 'e0f1', label: 'Circle 4', name: 'fa-circle-4' },
  { id: 57586, unicode: 'e0f2', label: 'Circle 5', name: 'fa-circle-5' },
  { id: 57587, unicode: 'e0f3', label: 'Circle 6', name: 'fa-circle-6' },
  { id: 57588, unicode: 'e0f4', label: 'Circle 7', name: 'fa-circle-7' },
  { id: 57589, unicode: 'e0f5', label: 'Circle 8', name: 'fa-circle-8' },
  { id: 57590, unicode: 'e0f6', label: 'Circle 9', name: 'fa-circle-9' },
  { id: 57591, unicode: 'e0f7', label: 'Circle A', name: 'fa-circle-a' },
  { id: 57592, unicode: 'e0f8', label: 'Circle Ampersand', name: 'fa-circle-ampersand' },
  { id: 61611, unicode: 'f0ab', label: 'Circle Arrow Down', name: 'fa-circle-arrow-down' },
  { id: 57593, unicode: 'e0f9', label: 'Circle Arrow Down Left', name: 'fa-circle-arrow-down-left' },
  { id: 57594, unicode: 'e0fa', label: 'Circle Arrow Down Right', name: 'fa-circle-arrow-down-right' },
  { id: 61608, unicode: 'f0a8', label: 'Circle Arrow Left', name: 'fa-circle-arrow-left' },
  { id: 61609, unicode: 'f0a9', label: 'Circle Arrow Right', name: 'fa-circle-arrow-right' },
  { id: 61610, unicode: 'f0aa', label: 'Circle Arrow Up', name: 'fa-circle-arrow-up' },
  { id: 57595, unicode: 'e0fb', label: 'Circle Arrow Up Left', name: 'fa-circle-arrow-up-left' },
  { id: 57596, unicode: 'e0fc', label: 'Circle Arrow Up Right', name: 'fa-circle-arrow-up-right' },
  { id: 57597, unicode: 'e0fd', label: 'Circle B', name: 'fa-circle-b' },
  { id: 57598, unicode: 'e0fe', label: 'Circle Bolt', name: 'fa-circle-bolt' },
  { id: 57599, unicode: 'e0ff', label: 'Circle Book Open', name: 'fa-circle-book-open' },
  { id: 57600, unicode: 'e100', label: 'Circle Bookmark', name: 'fa-circle-bookmark' },
  { id: 57601, unicode: 'e101', label: 'Circle C', name: 'fa-circle-c' },
  { id: 57602, unicode: 'e102', label: 'Circle Calendar', name: 'fa-circle-calendar' },
  { id: 57603, unicode: 'e103', label: 'Circle Camera', name: 'fa-circle-camera' },
  { id: 62253, unicode: 'f32d', label: 'Circle Caret Down', name: 'fa-circle-caret-down' },
  { id: 62254, unicode: 'f32e', label: 'Circle Caret Left', name: 'fa-circle-caret-left' },
  { id: 62256, unicode: 'f330', label: 'Circle Caret Right', name: 'fa-circle-caret-right' },
  { id: 62257, unicode: 'f331', label: 'Circle Caret Up', name: 'fa-circle-caret-up' },
  { id: 61528, unicode: 'f058', label: 'Circle Check', name: 'fa-circle-check' },
  { id: 61754, unicode: 'f13a', label: 'Circle Chevron Down', name: 'fa-circle-chevron-down' },
  { id: 61751, unicode: 'f137', label: 'Circle Chevron Left', name: 'fa-circle-chevron-left' },
  { id: 61752, unicode: 'f138', label: 'Circle Chevron Right', name: 'fa-circle-chevron-right' },
  { id: 61753, unicode: 'f139', label: 'Circle Chevron Up', name: 'fa-circle-chevron-up' },
  { id: 57604, unicode: 'e104', label: 'Circle D', name: 'fa-circle-d' },
  { id: 57605, unicode: 'e105', label: 'Circle Dashed', name: 'fa-circle-dashed' },
  { id: 57606, unicode: 'e106', label: 'Circle Divide', name: 'fa-circle-divide' },
  { id: 62184, unicode: 'f2e8', label: 'Circle Dollar', name: 'fa-circle-dollar' },
  { id: 62649, unicode: 'f4b9', label: 'Circle Dollar To Slot', name: 'fa-circle-dollar-to-slot' },
  { id: 61842, unicode: 'f192', label: 'Circle Dot', name: 'fa-circle-dot' },
  { id: 62296, unicode: 'f358', label: 'Circle Down', name: 'fa-circle-down' },
  { id: 57607, unicode: 'e107', label: 'Circle Down Left', name: 'fa-circle-down-left' },
  { id: 57608, unicode: 'e108', label: 'Circle Down Right', name: 'fa-circle-down-right' },
  { id: 57609, unicode: 'e109', label: 'Circle E', name: 'fa-circle-e' },
  { id: 57610, unicode: 'e10a', label: 'Circle Ellipsis', name: 'fa-circle-ellipsis' },
  { id: 57611, unicode: 'e10b', label: 'Circle Ellipsis Vertical', name: 'fa-circle-ellipsis-vertical' },
  { id: 57612, unicode: 'e10c', label: 'Circle Envelope', name: 'fa-circle-envelope' },
  { id: 61546, unicode: 'f06a', label: 'Circle Exclamation', name: 'fa-circle-exclamation' },
  { id: 57613, unicode: 'e10d', label: 'Circle Exclamation Check', name: 'fa-circle-exclamation-check' },
  { id: 57614, unicode: 'e10e', label: 'Circle F', name: 'fa-circle-f' },
  { id: 57615, unicode: 'e10f', label: 'Circle G', name: 'fa-circle-g' },
  { id: 62590, unicode: 'f47e', label: 'Circle H', name: 'fa-circle-h' },
  { id: 57616, unicode: 'e110', label: 'Circle Half', name: 'fa-circle-half' },
  { id: 61506, unicode: 'f042', label: 'Circle Half Stroke', name: 'fa-circle-half-stroke' },
  { id: 62663, unicode: 'f4c7', label: 'Circle Heart', name: 'fa-circle-heart' },
  { id: 57617, unicode: 'e111', label: 'Circle I', name: 'fa-circle-i' },
  { id: 61530, unicode: 'f05a', label: 'Circle Info', name: 'fa-circle-info' },
  { id: 57618, unicode: 'e112', label: 'Circle J', name: 'fa-circle-j' },
  { id: 57619, unicode: 'e113', label: 'Circle K', name: 'fa-circle-k' },
  { id: 57620, unicode: 'e114', label: 'Circle L', name: 'fa-circle-l' },
  { id: 62297, unicode: 'f359', label: 'Circle Left', name: 'fa-circle-left' },
  { id: 62978, unicode: 'f602', label: 'Circle Location Arrow', name: 'fa-circle-location-arrow' },
  { id: 57621, unicode: 'e115', label: 'Circle M', name: 'fa-circle-m' },
  { id: 57622, unicode: 'e116', label: 'Circle Microphone', name: 'fa-circle-microphone' },
  { id: 57623, unicode: 'e117', label: 'Circle Microphone Lines', name: 'fa-circle-microphone-lines' },
  { id: 61526, unicode: 'f056', label: 'Circle Minus', name: 'fa-circle-minus' },
  { id: 57624, unicode: 'e118', label: 'Circle N', name: 'fa-circle-n' },
  { id: 58594, unicode: 'e4e2', label: 'Circle Nodes', name: 'fa-circle-nodes' },
  { id: 61902, unicode: 'f1ce', label: 'Circle Notched', name: 'fa-circle-notched' },
  { id: 57625, unicode: 'e119', label: 'Circle O', name: 'fa-circle-o' },
  { id: 57626, unicode: 'e11a', label: 'Circle P', name: 'fa-circle-p' },
  { id: 62997, unicode: 'f615', label: 'Circle Parking', name: 'fa-circle-parking' },
  { id: 62091, unicode: 'f28b', label: 'Circle Pause', name: 'fa-circle-pause' },
  { id: 57627, unicode: 'e11b', label: 'Circle Phone', name: 'fa-circle-phone' },
  { id: 57628, unicode: 'e11c', label: 'Circle Phone Flip', name: 'fa-circle-phone-flip' },
  { id: 57629, unicode: 'e11d', label: 'Circle Phone Hangup', name: 'fa-circle-phone-hangup' },
  { id: 61764, unicode: 'f144', label: 'Circle Play', name: 'fa-circle-play' },
  { id: 61525, unicode: 'f055', label: 'Circle Plus', name: 'fa-circle-plus' },
  { id: 57630, unicode: 'e11e', label: 'Circle Q', name: 'fa-circle-q' },
  { id: 57631, unicode: 'e11f', label: 'Circle Quarter', name: 'fa-circle-quarter' },
  { id: 58360, unicode: 'e3f8', label: 'Circle Quarters', name: 'fa-circle-quarters' },
  { id: 61529, unicode: 'f059', label: 'Circle Question', name: 'fa-circle-question' },
  { id: 57632, unicode: 'e120', label: 'Circle R', name: 'fa-circle-r' },
  { id: 63418, unicode: 'f7ba', label: 'Circle Radiation', name: 'fa-circle-radiation' },
  { id: 62298, unicode: 'f35a', label: 'Circle Right', name: 'fa-circle-right' },
  { id: 57633, unicode: 'e121', label: 'Circle S', name: 'fa-circle-s' },
  { id: 57634, unicode: 'e122', label: 'Circle Small', name: 'fa-circle-small' },
  { id: 57392, unicode: 'e030', label: 'Circle Sort', name: 'fa-circle-sort' },
  { id: 57393, unicode: 'e031', label: 'Circle Sort Down', name: 'fa-circle-sort-down' },
  { id: 57394, unicode: 'e032', label: 'Circle Sort Up', name: 'fa-circle-sort-up' },
  { id: 57635, unicode: 'e123', label: 'Circle Star', name: 'fa-circle-star' },
  { id: 62093, unicode: 'f28d', label: 'Circle Stop', name: 'fa-circle-stop' },
  { id: 57636, unicode: 'e124', label: 'Circle T', name: 'fa-circle-t' },
  { id: 57637, unicode: 'e125', label: 'Circle Three Quarters', name: 'fa-circle-three-quarters' },
  { id: 57638, unicode: 'e126', label: 'Circle Trash', name: 'fa-circle-trash' },
  { id: 57639, unicode: 'e127', label: 'Circle U', name: 'fa-circle-u' },
  { id: 62299, unicode: 'f35b', label: 'Circle Up', name: 'fa-circle-up' },
  { id: 57640, unicode: 'e128', label: 'Circle Up Left', name: 'fa-circle-up-left' },
  { id: 57641, unicode: 'e129', label: 'Circle Up Right', name: 'fa-circle-up-right' },
  { id: 62141, unicode: 'f2bd', label: 'Circle User', name: 'fa-circle-user' },
  { id: 57642, unicode: 'e12a', label: 'Circle V', name: 'fa-circle-v' },
  { id: 57643, unicode: 'e12b', label: 'Circle Video', name: 'fa-circle-video' },
  { id: 57644, unicode: 'e12c', label: 'Circle W', name: 'fa-circle-w' },
  { id: 57645, unicode: 'e12d', label: 'Circle Waveform Lines', name: 'fa-circle-waveform-lines' },
  { id: 57646, unicode: 'e12e', label: 'Circle X', name: 'fa-circle-x' },
  { id: 61527, unicode: 'f057', label: 'Circle X Mark', name: 'fa-circle-x-mark' },
  { id: 57647, unicode: 'e12f', label: 'Circle Y', name: 'fa-circle-y' },
  { id: 57648, unicode: 'e130', label: 'Circle Z', name: 'fa-circle-z' },
  { id: 58100, unicode: 'e2f4', label: 'Citrus', name: 'fa-citrus' },
  { id: 58101, unicode: 'e2f5', label: 'Citrus Slice', name: 'fa-citrus-slice' },
  { id: 63055, unicode: 'f64f', label: 'City', name: 'fa-city' },
  { id: 57649, unicode: 'e131', label: 'Clapperboard', name: 'fa-clapperboard' },
  { id: 57650, unicode: 'e132', label: 'Clapperboard Play', name: 'fa-clapperboard-play' },
  { id: 63661, unicode: 'f8ad', label: 'Clarinet', name: 'fa-clarinet' },
  { id: 63170, unicode: 'f6c2', label: 'Claw Marks', name: 'fa-claw-marks' },
  { id: 62248, unicode: 'f328', label: 'Clipboard', name: 'fa-clipboard' },
  { id: 62572, unicode: 'f46c', label: 'Clipboard With Check', name: 'fa-clipboard-with-check' },
  { id: 62573, unicode: 'f46d', label: 'Clipboard List', name: 'fa-clipboard-list' },
  { id: 63287, unicode: 'f737', label: 'Clipboard List With Check', name: 'fa-clipboard-list-with-check' },
  { id: 57651, unicode: 'e133', label: 'Clipboard Medical', name: 'fa-clipboard-medical' },
  { id: 62952, unicode: 'f5e8', label: 'Clipboard Prescription', name: 'fa-clipboard-prescription' },
  { id: 58595, unicode: 'e4e3', label: 'Clipboard Question', name: 'fa-clipboard-question' },
  { id: 63475, unicode: 'f7f3', label: 'Clipboard With User', name: 'fa-clipboard-with-user' },
  { id: 61463, unicode: 'f017', label: 'Clock', name: 'fa-clock' },
  { id: 57652, unicode: 'e134', label: 'Clock Desk', name: 'fa-clock-desk' },
  { id: 58181, unicode: 'e345', label: 'Clock Eight', name: 'fa-clock-eight' },
  { id: 58182, unicode: 'e346', label: 'Clock Eight Thirty', name: 'fa-clock-eight-thirty' },
  { id: 58183, unicode: 'e347', label: 'Clock Eleven', name: 'fa-clock-eleven' },
  { id: 58184, unicode: 'e348', label: 'Clock Eleven Thirty', name: 'fa-clock-eleven-thirty' },
  { id: 58185, unicode: 'e349', label: 'Clock Five', name: 'fa-clock-five' },
  { id: 58186, unicode: 'e34a', label: 'Clock Five Thirty', name: 'fa-clock-five-thirty' },
  { id: 58187, unicode: 'e34b', label: 'Clock Four Thirty', name: 'fa-clock-four-thirty' },
  { id: 58188, unicode: 'e34c', label: 'Clock Nine', name: 'fa-clock-nine' },
  { id: 58189, unicode: 'e34d', label: 'Clock Nine Thirty', name: 'fa-clock-nine-thirty' },
  { id: 58190, unicode: 'e34e', label: 'Clock One', name: 'fa-clock-one' },
  { id: 58191, unicode: 'e34f', label: 'Clock One Thirty', name: 'fa-clock-one-thirty' },
  { id: 61914, unicode: 'f1da', label: 'Clock Rotate Left', name: 'fa-clock-rotate-left' },
  { id: 58192, unicode: 'e350', label: 'Clock Seven', name: 'fa-clock-seven' },
  { id: 58193, unicode: 'e351', label: 'Clock Seven Thirty', name: 'fa-clock-seven-thirty' },
  { id: 58194, unicode: 'e352', label: 'Clock Six', name: 'fa-clock-six' },
  { id: 58195, unicode: 'e353', label: 'Clock Six Thirty', name: 'fa-clock-six-thirty' },
  { id: 58196, unicode: 'e354', label: 'Clock Ten', name: 'fa-clock-ten' },
  { id: 58197, unicode: 'e355', label: 'Clock Ten Thirty', name: 'fa-clock-ten-thirty' },
  { id: 58198, unicode: 'e356', label: 'Clock Three', name: 'fa-clock-three' },
  { id: 58199, unicode: 'e357', label: 'Clock Three Thirty', name: 'fa-clock-three-thirty' },
  { id: 58200, unicode: 'e358', label: 'Clock Twelve', name: 'fa-clock-twelve' },
  { id: 58201, unicode: 'e359', label: 'Clock Twelve Thirty', name: 'fa-clock-twelve-thirty' },
  { id: 58202, unicode: 'e35a', label: 'Clock Two', name: 'fa-clock-two' },
  { id: 58203, unicode: 'e35b', label: 'Clock Two Thirty', name: 'fa-clock-two-thirty' },
  { id: 62029, unicode: 'f24d', label: 'Clone', name: 'fa-clone' },
  { id: 61962, unicode: 'f20a', label: 'Closed Captioning', name: 'fa-closed-captioning' },
  { id: 57653, unicode: 'e135', label: 'Closed Captioning Slash', name: 'fa-closed-captioning-slash' },
  { id: 57654, unicode: 'e136', label: 'Clothes Hanger', name: 'fa-clothes-hanger' },
  { id: 61634, unicode: 'f0c2', label: 'Cloud', name: 'fa-cloud' },
  { id: 61677, unicode: 'f0ed', label: 'Cloud Arrow Down', name: 'fa-cloud-arrow-down' },
  { id: 61678, unicode: 'f0ee', label: 'Cloud Arrow Up', name: 'fa-cloud-arrow-up' },
  { id: 63340, unicode: 'f76c', label: 'Cloud Bolt', name: 'fa-cloud-bolt' },
  { id: 63341, unicode: 'f76d', label: 'Cloud Bolt Moon', name: 'fa-cloud-bolt-moon' },
  { id: 63342, unicode: 'f76e', label: 'Cloud Bolt Sun', name: 'fa-cloud-bolt-sun' },
  { id: 58204, unicode: 'e35c', label: 'Cloud Check', name: 'fa-cloud-check' },
  { id: 63288, unicode: 'f738', label: 'Cloud With Drizzle', name: 'fa-cloud-with-drizzle' },
  { id: 58513, unicode: 'e491', label: 'Cloud Exclamation', name: 'fa-cloud-exclamation' },
  { id: 63310, unicode: 'f74e', label: 'Cloud Fog', name: 'fa-cloud-fog' },
  { id: 63289, unicode: 'f739', label: 'Cloud With Hail', name: 'fa-cloud-with-hail' },
  { id: 63290, unicode: 'f73a', label: 'Cloud With Mixed Hail', name: 'fa-cloud-with-mixed-hail' },
  { id: 63291, unicode: 'f73b', label: 'Cloud With  A Chance Of  Meatball', name: 'fa-cloud-with--a-chance-of--meatball' },
  { id: 58205, unicode: 'e35d', label: 'Cloud Minus', name: 'fa-cloud-minus' },
  { id: 63171, unicode: 'f6c3', label: 'Cloud With Moon', name: 'fa-cloud-with-moon' },
  { id: 63292, unicode: 'f73c', label: 'Cloud With Moon And Rain', name: 'fa-cloud-with-moon-and-rain' },
  { id: 63662, unicode: 'f8ae', label: 'Cloud Music', name: 'fa-cloud-music' },
  { id: 58206, unicode: 'e35e', label: 'Cloud Plus', name: 'fa-cloud-plus' },
  { id: 58514, unicode: 'e492', label: 'Cloud Question', name: 'fa-cloud-question' },
  { id: 63293, unicode: 'f73d', label: 'Cloud With Rain', name: 'fa-cloud-with-rain' },
  { id: 63294, unicode: 'f73e', label: 'Cloud With Rainbow', name: 'fa-cloud-with-rainbow' },
  { id: 63295, unicode: 'f73f', label: 'Cloud With Showers', name: 'fa-cloud-with-showers' },
  { id: 63296, unicode: 'f740', label: 'Cloud With Heavy Showers', name: 'fa-cloud-with-heavy-showers' },
  { id: 58596, unicode: 'e4e4', label: 'Cloud Showers Water', name: 'fa-cloud-showers-water' },
  { id: 57655, unicode: 'e137', label: 'Cloud Slash', name: 'fa-cloud-slash' },
  { id: 63297, unicode: 'f741', label: 'Cloud With Sleet', name: 'fa-cloud-with-sleet' },
  { id: 63298, unicode: 'f742', label: 'Cloud With Snow', name: 'fa-cloud-with-snow' },
  { id: 63172, unicode: 'f6c4', label: 'Cloud With Sun', name: 'fa-cloud-with-sun' },
  { id: 63299, unicode: 'f743', label: 'Cloud With Sun And Rain', name: 'fa-cloud-with-sun-and-rain' },
  { id: 57656, unicode: 'e138', label: 'Cloud Word', name: 'fa-cloud-word' },
  { id: 58207, unicode: 'e35f', label: 'Cloud Xmark', name: 'fa-cloud-xmark' },
  { id: 57469, unicode: 'e07d', label: 'Cloudflare', name: 'fa-cloudflare' },
  { id: 63300, unicode: 'f744', label: 'Clouds', name: 'fa-clouds' },
  { id: 63301, unicode: 'f745', label: 'Clouds With Moon', name: 'fa-clouds-with-moon' },
  { id: 63302, unicode: 'f746', label: 'Clouds With Sun', name: 'fa-clouds-with-sun' },
  { id: 62339, unicode: 'f383', label: 'Cloudscale Ch', name: 'fa-cloudscale-ch' },
  { id: 62340, unicode: 'f384', label: 'Cloudsmith', name: 'fa-cloudsmith' },
  { id: 62341, unicode: 'f385', label: 'Cloudversify', name: 'fa-cloudversify' },
  { id: 57657, unicode: 'e139', label: 'Clover', name: 'fa-clover' },
  { id: 62247, unicode: 'f327', label: 'Club', name: 'fa-club' },
  { id: 58208, unicode: 'e360', label: 'Cmplid', name: 'fa-cmplid' },
  { id: 58102, unicode: 'e2f6', label: 'Coconut', name: 'fa-coconut' },
  { id: 61729, unicode: 'f121', label: 'Code', name: 'fa-code' },
  { id: 61734, unicode: 'f126', label: 'Code Branch', name: 'fa-code-branch' },
  { id: 62342, unicode: 'f386', label: 'Code Commit', name: 'fa-code-commit' },
  { id: 57658, unicode: 'e13a', label: 'Code Compare', name: 'fa-code-compare' },
  { id: 57659, unicode: 'e13b', label: 'Code Fork', name: 'fa-code-fork' },
  { id: 62343, unicode: 'f387', label: 'Code Merge', name: 'fa-code-merge' },
  { id: 57660, unicode: 'e13c', label: 'Code Pull Request', name: 'fa-code-pull-request' },
  { id: 58361, unicode: 'e3f9', label: 'Code Pull Request Closed', name: 'fa-code-pull-request-closed' },
  { id: 58362, unicode: 'e3fa', label: 'Code Pull Request Draft', name: 'fa-code-pull-request-draft' },
  { id: 57661, unicode: 'e13d', label: 'Code Simple', name: 'fa-code-simple' },
  { id: 61899, unicode: 'f1cb', label: 'Codepen', name: 'fa-codepen' },
  { id: 62084, unicode: 'f284', label: 'Codie Pie', name: 'fa-codie-pie' },
  { id: 57662, unicode: 'e13e', label: 'Coffee Bean', name: 'fa-coffee-bean' },
  { id: 57663, unicode: 'e13f', label: 'Coffee Beans', name: 'fa-coffee-beans' },
  { id: 57346, unicode: 'e002', label: 'Coffee Pot', name: 'fa-coffee-pot' },
  { id: 63174, unicode: 'f6c6', label: 'Coffin', name: 'fa-coffin' },
  { id: 57425, unicode: 'e051', label: 'Coffin With Cross', name: 'fa-coffin-with-cross' },
  { id: 63580, unicode: 'f85c', label: 'Coin', name: 'fa-coin' },
  { id: 58363, unicode: 'e3fb', label: 'Coin Blank', name: 'fa-coin-blank' },
  { id: 58364, unicode: 'e3fc', label: 'Coin Front', name: 'fa-coin-front' },
  { id: 58365, unicode: 'e3fd', label: 'Coin Vertical', name: 'fa-coin-vertical' },
  { id: 62750, unicode: 'f51e', label: 'Coins', name: 'fa-coins' },
  { id: 58, unicode: '3a', label: 'Colon', name: 'fa-colon' },
  { id: 57664, unicode: 'e140', label: 'Colon Sign', name: 'fa-colon-sign' },
  { id: 58209, unicode: 'e361', label: 'Columns 3', name: 'fa-columns-3' },
  { id: 57347, unicode: 'e003', label: 'Comet', name: 'fa-comet' },
  { id: 44, unicode: '2c', label: 'Comma', name: 'fa-comma' },
  { id: 57666, unicode: 'e142', label: 'Command', name: 'fa-command' },
  { id: 61557, unicode: 'f075', label: 'Comment', name: 'fa-comment' },
  { id: 57667, unicode: 'e143', label: 'Comment Arrow Down', name: 'fa-comment-arrow-down' },
  { id: 57668, unicode: 'e144', label: 'Comment Arrow Up', name: 'fa-comment-arrow-up' },
  { id: 57669, unicode: 'e145', label: 'Comment Arrow Up Right', name: 'fa-comment-arrow-up-right' },
  { id: 57670, unicode: 'e146', label: 'Comment Captions', name: 'fa-comment-captions' },
  { id: 62636, unicode: 'f4ac', label: 'Comment Check', name: 'fa-comment-check' },
  { id: 57671, unicode: 'e147', label: 'Comment Code', name: 'fa-comment-code' },
  { id: 63057, unicode: 'f651', label: 'Comment Dollar', name: 'fa-comment-dollar' },
  { id: 62637, unicode: 'f4ad', label: 'Comment Dots', name: 'fa-comment-dots' },
  { id: 62639, unicode: 'f4af', label: 'Comment Exclamation', name: 'fa-comment-exclamation' },
  { id: 57672, unicode: 'e148', label: 'Comment Image', name: 'fa-comment-image' },
  { id: 62640, unicode: 'f4b0', label: 'Comment Lines', name: 'fa-comment-lines' },
  { id: 63477, unicode: 'f7f5', label: 'Alternate Medical Chat', name: 'fa-alternate-medical-chat' },
  { id: 57673, unicode: 'e149', label: 'Comment Middle', name: 'fa-comment-middle' },
  { id: 57674, unicode: 'e14a', label: 'Comment Middle Top', name: 'fa-comment-middle-top' },
  { id: 62641, unicode: 'f4b1', label: 'Comment Minus', name: 'fa-comment-minus' },
  { id: 63664, unicode: 'f8b0', label: 'Comment Music', name: 'fa-comment-music' },
  { id: 62638, unicode: 'f4ae', label: 'Comment Pen', name: 'fa-comment-pen' },
  { id: 62642, unicode: 'f4b2', label: 'Comment Plus', name: 'fa-comment-plus' },
  { id: 57675, unicode: 'e14b', label: 'Comment Question', name: 'fa-comment-question' },
  { id: 57676, unicode: 'e14c', label: 'Comment Quote', name: 'fa-comment-quote' },
  { id: 62643, unicode: 'f4b3', label: 'Comment Slash', name: 'fa-comment-slash' },
  { id: 62644, unicode: 'f4b4', label: 'Comment Smile', name: 'fa-comment-smile' },
  { id: 63437, unicode: 'f7cd', label: 'Comment Sms', name: 'fa-comment-sms' },
  { id: 57677, unicode: 'e14d', label: 'Comment Text', name: 'fa-comment-text' },
  { id: 62645, unicode: 'f4b5', label: 'Comment X Mark', name: 'fa-comment-x-mark' },
  { id: 61574, unicode: 'f086', label: 'Comments', name: 'fa-comments' },
  { id: 63059, unicode: 'f653', label: 'Comments Dollar', name: 'fa-comments-dollar' },
  { id: 57678, unicode: 'e14e', label: 'Comments Question', name: 'fa-comments-question' },
  { id: 57679, unicode: 'e14f', label: 'Comments Question Check', name: 'fa-comments-question-check' },
  { id: 62751, unicode: 'f51f', label: 'Compact Disc', name: 'fa-compact-disc' },
  { id: 61774, unicode: 'f14e', label: 'Compass', name: 'fa-compass' },
  { id: 62824, unicode: 'f568', label: 'Compass Drafting', name: 'fa-compass-drafting' },
  { id: 62953, unicode: 'f5e9', label: 'Compass Slash', name: 'fa-compass-slash' },
  { id: 61542, unicode: 'f066', label: 'Compress', name: 'fa-compress' },
  { id: 62246, unicode: 'f326', label: 'Compress Wide', name: 'fa-compress-wide' },
  { id: 58597, unicode: 'e4e5', label: 'Computer', name: 'fa-computer' },
  { id: 63665, unicode: 'f8b1', label: 'Classic Computer', name: 'fa-classic-computer' },
  { id: 63692, unicode: 'f8cc', label: 'Computer Mouse', name: 'fa-computer-mouse' },
  { id: 63693, unicode: 'f8cd', label: 'Computer Mouse Scrollwheel', name: 'fa-computer-mouse-scrollwheel' },
  { id: 63666, unicode: 'f8b2', label: 'Computer Speaker', name: 'fa-computer-speaker' },
  { id: 63373, unicode: 'f78d', label: 'Confluence', name: 'fa-confluence' },
  { id: 61966, unicode: 'f20e', label: 'Connect Develop', name: 'fa-connect-develop' },
  { id: 62647, unicode: 'f4b7', label: 'Container Storage', name: 'fa-container-storage' },
  { id: 62061, unicode: 'f26d', label: 'Contao', name: 'fa-contao' },
  { id: 62574, unicode: 'f46e', label: 'Conveyor Belt', name: 'fa-conveyor-belt' },
  { id: 62575, unicode: 'f46f', label: 'Conveyor Belt Boxes', name: 'fa-conveyor-belt-boxes' },
  { id: 57680, unicode: 'e150', label: 'Conveyor Belt Empty', name: 'fa-conveyor-belt-empty' },
  { id: 62819, unicode: 'f563', label: 'Cookie', name: 'fa-cookie' },
  { id: 62820, unicode: 'f564', label: 'Cookie Bite', name: 'fa-cookie-bite' },
  { id: 61637, unicode: 'f0c5', label: 'Copy', name: 'fa-copy' },
  { id: 61945, unicode: 'f1f9', label: 'Copyright', name: 'fa-copyright' },
  { id: 63175, unicode: 'f6c7', label: 'Corn', name: 'fa-corn' },
  { id: 58366, unicode: 'e3fe', label: 'Corner', name: 'fa-corner' },
  { id: 63646, unicode: 'f89e', label: 'Cotton Bureau', name: 'fa-cotton-bureau' },
  { id: 62648, unicode: 'f4b8', label: 'Couch', name: 'fa-couch' },
  { id: 63176, unicode: 'f6c8', label: 'Cow', name: 'fa-cow' },
  { id: 63667, unicode: 'f8b3', label: 'Cowbell', name: 'fa-cowbell' },
  { id: 63668, unicode: 'f8b4', label: 'Cowbell Circle Plus', name: 'fa-cowbell-circle-plus' },
  { id: 62344, unicode: 'f388', label: 'Cpanel', name: 'fa-cpanel' },
  { id: 58367, unicode: 'e3ff', label: 'Crab', name: 'fa-crab' },
  { id: 63153, unicode: 'f6b1', label: 'Apple Crate', name: 'fa-apple-crate' },
  { id: 57681, unicode: 'e151', label: 'Crate Empty', name: 'fa-crate-empty' },
  { id: 62046, unicode: 'f25e', label: 'Creative Commons', name: 'fa-creative-commons' },
  { id: 62695, unicode: 'f4e7', label: 'Creative Commons Attribution', name: 'fa-creative-commons-attribution' },
  { id: 62696, unicode: 'f4e8', label: 'Creative Commons Noncommercial', name: 'fa-creative-commons-noncommercial' },
  { id: 62697, unicode: 'f4e9', label: 'Creative Commons Noncommercial  Euro Sign ', name: 'fa-creative-commons-noncommercial--euro-sign-' },
  { id: 62698, unicode: 'f4ea', label: 'Creative Commons Noncommercial  Yen Sign ', name: 'fa-creative-commons-noncommercial--yen-sign-' },
  { id: 62699, unicode: 'f4eb', label: 'Creative Commons No Derivative Works', name: 'fa-creative-commons-no-derivative-works' },
  { id: 62700, unicode: 'f4ec', label: 'Creative Commons Public Domain', name: 'fa-creative-commons-public-domain' },
  { id: 62701, unicode: 'f4ed', label: 'Alternate Creative Commons Public Domain', name: 'fa-alternate-creative-commons-public-domain' },
  { id: 62702, unicode: 'f4ee', label: 'Creative Commons Remix', name: 'fa-creative-commons-remix' },
  { id: 62703, unicode: 'f4ef', label: 'Creative Commons Share Alike', name: 'fa-creative-commons-share-alike' },
  { id: 62704, unicode: 'f4f0', label: 'Creative Commons Sampling', name: 'fa-creative-commons-sampling' },
  { id: 62705, unicode: 'f4f1', label: 'Creative Commons Sampling  ', name: 'fa-creative-commons-sampling--' },
  { id: 62706, unicode: 'f4f2', label: 'Creative Commons Share', name: 'fa-creative-commons-share' },
  { id: 62707, unicode: 'f4f3', label: 'Creative Commons CC0', name: 'fa-creative-commons-cc0' },
  { id: 61597, unicode: 'f09d', label: 'Credit Card', name: 'fa-credit-card' },
  { id: 62345, unicode: 'f389', label: 'Credit Card Blank', name: 'fa-credit-card-blank' },
  { id: 62346, unicode: 'f38a', label: 'Credit Card Front', name: 'fa-credit-card-front' },
  { id: 62537, unicode: 'f449', label: 'Cricket Bat Ball', name: 'fa-cricket-bat-ball' },
  { id: 63177, unicode: 'f6c9', label: 'Critical Role', name: 'fa-critical-role' },
  { id: 63478, unicode: 'f7f6', label: 'Croissant', name: 'fa-croissant' },
  { id: 61733, unicode: 'f125', label: 'Crop', name: 'fa-crop' },
  { id: 62821, unicode: 'f565', label: 'Crop Simple', name: 'fa-crop-simple' },
  { id: 63060, unicode: 'f654', label: 'Cross', name: 'fa-cross' },
  { id: 61531, unicode: 'f05b', label: 'Crosshairs', name: 'fa-crosshairs' },
  { id: 58783, unicode: 'e59f', label: 'Crosshairs Simple', name: 'fa-crosshairs-simple' },
  { id: 62752, unicode: 'f520', label: 'Crow', name: 'fa-crow' },
  { id: 62753, unicode: 'f521', label: 'Crown', name: 'fa-crown' },
  { id: 63479, unicode: 'f7f7', label: 'Crutch', name: 'fa-crutch' },
  { id: 63480, unicode: 'f7f8', label: 'Crutches', name: 'fa-crutches' },
  { id: 57682, unicode: 'e152', label: 'Cruzeiro Sign', name: 'fa-cruzeiro-sign' },
  { id: 58210, unicode: 'e362', label: 'Crystal Ball', name: 'fa-crystal-ball' },
  { id: 61756, unicode: 'f13c', label: 'CSS 3 Logo', name: 'fa-css-3-logo' },
  { id: 62347, unicode: 'f38b', label: 'Alternate CSS3 Logo', name: 'fa-alternate-css3-logo' },
  { id: 61874, unicode: 'f1b2', label: 'Cube', name: 'fa-cube' },
  { id: 61875, unicode: 'f1b3', label: 'Cubes', name: 'fa-cubes' },
  { id: 58598, unicode: 'e4e6', label: 'Cubes Stacked', name: 'fa-cubes-stacked' },
  { id: 58369, unicode: 'e401', label: 'Cucumber', name: 'fa-cucumber' },
  { id: 58211, unicode: 'e363', label: 'Cup Straw', name: 'fa-cup-straw' },
  { id: 58212, unicode: 'e364', label: 'Cup Straw With Swoosh', name: 'fa-cup-straw-with-swoosh' },
  { id: 63173, unicode: 'f6c5', label: 'Cup Togo', name: 'fa-cup-togo' },
  { id: 58370, unicode: 'e402', label: 'Cupcake', name: 'fa-cupcake' },
  { id: 62538, unicode: 'f44a', label: 'Curling Stone', name: 'fa-curling-stone' },
  { id: 58371, unicode: 'e403', label: 'Custard', name: 'fa-custard' },
  { id: 62348, unicode: 'f38c', label: 'Cuttlefish', name: 'fa-cuttlefish' },
  { id: 68, unicode: '44', label: 'D', name: 'fa-d' },
  { id: 62349, unicode: 'f38d', label: 'Dungeons   Dragons', name: 'fa-dungeons---dragons' },
  { id: 63178, unicode: 'f6ca', label: 'D D Beyond', name: 'fa-d-d-beyond' },
  { id: 63179, unicode: 'f6cb', label: 'Dagger', name: 'fa-dagger' },
  { id: 57426, unicode: 'e052', label: 'Dailymotion', name: 'fa-dailymotion' },
  { id: 58372, unicode: 'e404', label: 'Dash', name: 'fa-dash' },
  { id: 61968, unicode: 'f210', label: 'Dashcube', name: 'fa-dashcube' },
  { id: 61888, unicode: 'f1c0', label: 'Database', name: 'fa-database' },
  { id: 63374, unicode: 'f78e', label: 'Deer', name: 'fa-deer' },
  { id: 63375, unicode: 'f78f', label: 'Deer Rudolph', name: 'fa-deer-rudolph' },
  { id: 57463, unicode: 'e077', label: 'Deezer', name: 'fa-deezer' },
  { id: 62810, unicode: 'f55a', label: 'Delete Left', name: 'fa-delete-left' },
  { id: 57684, unicode: 'e154', label: 'Delete Right', name: 'fa-delete-right' },
  { id: 61861, unicode: 'f1a5', label: 'Delicious', name: 'fa-delicious' },
  { id: 63303, unicode: 'f747', label: 'Democrat', name: 'fa-democrat' },
  { id: 62350, unicode: 'f38e', label: 'Deploy Dog', name: 'fa-deploy-dog' },
  { id: 62351, unicode: 'f38f', label: 'Deskpro', name: 'fa-deskpro' },
  { id: 62352, unicode: 'f390', label: 'Desktop', name: 'fa-desktop' },
  { id: 57685, unicode: 'e155', label: 'Desktop Arrow Down', name: 'fa-desktop-arrow-down' },
  { id: 63180, unicode: 'f6cc', label: 'DEV', name: 'fa-dev' },
  { id: 61885, unicode: 'f1bd', label: 'Deviantart', name: 'fa-deviantart' },
  { id: 63061, unicode: 'f655', label: 'Dharmachakra', name: 'fa-dharmachakra' },
  { id: 63376, unicode: 'f790', label: 'DHL', name: 'fa-dhl' },
  { id: 58485, unicode: 'e475', label: 'Diagram Cells', name: 'fa-diagram-cells' },
  { id: 57686, unicode: 'e156', label: 'Diagram Lean Canvas', name: 'fa-diagram-lean-canvas' },
  { id: 57687, unicode: 'e157', label: 'Diagram Nested', name: 'fa-diagram-nested' },
  { id: 58486, unicode: 'e476', label: 'Diagram Next', name: 'fa-diagram-next' },
  { id: 58487, unicode: 'e477', label: 'Diagram Predecessor', name: 'fa-diagram-predecessor' },
  { id: 58488, unicode: 'e478', label: 'Diagram Previous', name: 'fa-diagram-previous' },
  { id: 62786, unicode: 'f542', label: 'Project Diagram', name: 'fa-project-diagram' },
  { id: 57688, unicode: 'e158', label: 'Diagram Sankey', name: 'fa-diagram-sankey' },
  { id: 58489, unicode: 'e479', label: 'Diagram Subtask', name: 'fa-diagram-subtask' },
  { id: 58490, unicode: 'e47a', label: 'Diagram Successor', name: 'fa-diagram-successor' },
  { id: 57690, unicode: 'e15a', label: 'Venn Diagram', name: 'fa-venn-diagram' },
  { id: 57691, unicode: 'e15b', label: 'Dial', name: 'fa-dial' },
  { id: 57692, unicode: 'e15c', label: 'Dial High', name: 'fa-dial-high' },
  { id: 57693, unicode: 'e15d', label: 'Dial Low', name: 'fa-dial-low' },
  { id: 57694, unicode: 'e15e', label: 'Dial Max', name: 'fa-dial-max' },
  { id: 57695, unicode: 'e15f', label: 'Dial Med', name: 'fa-dial-med' },
  { id: 57696, unicode: 'e160', label: 'Dial Med Low', name: 'fa-dial-med-low' },
  { id: 57697, unicode: 'e161', label: 'Dial Min', name: 'fa-dial-min' },
  { id: 57698, unicode: 'e162', label: 'Dial Off', name: 'fa-dial-off' },
  { id: 61977, unicode: 'f219', label: 'Diamond', name: 'fa-diamond' },
  { id: 58373, unicode: 'e405', label: 'Diamond Exclamation', name: 'fa-diamond-exclamation' },
  { id: 62955, unicode: 'f5eb', label: 'Diamond Turn Right', name: 'fa-diamond-turn-right' },
  { id: 63377, unicode: 'f791', label: 'Diaspora', name: 'fa-diaspora' },
  { id: 62754, unicode: 'f522', label: 'Dice', name: 'fa-dice' },
  { id: 63181, unicode: 'f6cd', label: 'Dice D10', name: 'fa-dice-d10' },
  { id: 63182, unicode: 'f6ce', label: 'Dice D12', name: 'fa-dice-d12' },
  { id: 63183, unicode: 'f6cf', label: 'Dice D20', name: 'fa-dice-d20' },
  { id: 63184, unicode: 'f6d0', label: 'Dice D4', name: 'fa-dice-d4' },
  { id: 63185, unicode: 'f6d1', label: 'Dice D6', name: 'fa-dice-d6' },
  { id: 63186, unicode: 'f6d2', label: 'Dice D8', name: 'fa-dice-d8' },
  { id: 62755, unicode: 'f523', label: 'Dice Five', name: 'fa-dice-five' },
  { id: 62756, unicode: 'f524', label: 'Dice Four', name: 'fa-dice-four' },
  { id: 62757, unicode: 'f525', label: 'Dice One', name: 'fa-dice-one' },
  { id: 62758, unicode: 'f526', label: 'Dice Six', name: 'fa-dice-six' },
  { id: 62759, unicode: 'f527', label: 'Dice Three', name: 'fa-dice-three' },
  { id: 62760, unicode: 'f528', label: 'Dice Two', name: 'fa-dice-two' },
  { id: 61862, unicode: 'f1a6', label: 'Digg Logo', name: 'fa-digg-logo' },
  { id: 62353, unicode: 'f391', label: 'Digital Ocean', name: 'fa-digital-ocean' },
  { id: 62954, unicode: 'f5ea', label: 'Diploma', name: 'fa-diploma' },
  { id: 63669, unicode: 'f8b5', label: 'Disc Drive', name: 'fa-disc-drive' },
  { id: 62354, unicode: 'f392', label: 'Discord', name: 'fa-discord' },
  { id: 62355, unicode: 'f393', label: 'Discourse', name: 'fa-discourse' },
  { id: 63482, unicode: 'f7fa', label: 'Disease', name: 'fa-disease' },
  { id: 57699, unicode: 'e163', label: 'Display', name: 'fa-display' },
  { id: 57700, unicode: 'e164', label: 'Display Arrow Down', name: 'fa-display-arrow-down' },
  { id: 57701, unicode: 'e165', label: 'Display Code', name: 'fa-display-code' },
  { id: 57702, unicode: 'e166', label: 'Display Medical', name: 'fa-display-medical' },
  { id: 58106, unicode: 'e2fa', label: 'Display Slash', name: 'fa-display-slash' },
  { id: 58213, unicode: 'e365', label: 'Distribute Spacing Horizontal', name: 'fa-distribute-spacing-horizontal' },
  { id: 58214, unicode: 'e366', label: 'Distribute Spacing Vertical', name: 'fa-distribute-spacing-vertical' },
  { id: 34, unicode: '22', label: 'Ditto', name: 'fa-ditto' },
  { id: 62761, unicode: 'f529', label: 'Divide', name: 'fa-divide' },
  { id: 62577, unicode: 'f471', label: 'DNA', name: 'fa-dna' },
  { id: 62956, unicode: 'f5ec', label: 'Do Not Enter', name: 'fa-do-not-enter' },
  { id: 62356, unicode: 'f394', label: 'Dochub', name: 'fa-dochub' },
  { id: 62357, unicode: 'f395', label: 'Docker', name: 'fa-docker' },
  { id: 63187, unicode: 'f6d3', label: 'Dog', name: 'fa-dog' },
  { id: 63188, unicode: 'f6d4', label: 'Leashed Dog', name: 'fa-leashed-dog' },
  { id: 36, unicode: '24', label: 'Dollar Sign', name: 'fa-dollar-sign' },
  { id: 62578, unicode: 'f472', label: 'Dolly', name: 'fa-dolly' },
  { id: 62579, unicode: 'f473', label: 'Dolly Empty', name: 'fa-dolly-empty' },
  { id: 57704, unicode: 'e168', label: 'Dolphin', name: 'fa-dolphin' },
  { id: 57705, unicode: 'e169', label: 'Dong Sign', name: 'fa-dong-sign' },
  { id: 58374, unicode: 'e406', label: 'Donut', name: 'fa-donut' },
  { id: 62762, unicode: 'f52a', label: 'Door Closed', name: 'fa-door-closed' },
  { id: 62763, unicode: 'f52b', label: 'Door Open', name: 'fa-door-open' },
  { id: 62650, unicode: 'f4ba', label: 'Dove', name: 'fa-dove' },
  { id: 62292, unicode: 'f354', label: 'Down', name: 'fa-down' },
  { id: 58375, unicode: 'e407', label: 'Down From Dotted Line', name: 'fa-down-from-dotted-line' },
  { id: 62281, unicode: 'f349', label: 'Down From Line', name: 'fa-down-from-line' },
  { id: 57706, unicode: 'e16a', label: 'Down Left', name: 'fa-down-left' },
  { id: 62498, unicode: 'f422', label: 'Down Left And Up Right To Center', name: 'fa-down-left-and-up-right-to-center' },
  { id: 62217, unicode: 'f309', label: 'Down Long', name: 'fa-down-long' },
  { id: 57707, unicode: 'e16b', label: 'Down Right', name: 'fa-down-right' },
  { id: 58599, unicode: 'e4e7', label: 'Down To Bracket', name: 'fa-down-to-bracket' },
  { id: 58376, unicode: 'e408', label: 'Down To Dotted Line', name: 'fa-down-to-dotted-line' },
  { id: 62282, unicode: 'f34a', label: 'Down To Line', name: 'fa-down-to-line' },
  { id: 61465, unicode: 'f019', label: 'Download', name: 'fa-download' },
  { id: 62358, unicode: 'f396', label: 'Draft2digital', name: 'fa-draft2digital' },
  { id: 63189, unicode: 'f6d5', label: 'Dragon', name: 'fa-dragon' },
  { id: 62957, unicode: 'f5ed', label: 'Draw Circle', name: 'fa-draw-circle' },
  { id: 62958, unicode: 'f5ee', label: 'Draw Polygon', name: 'fa-draw-polygon' },
  { id: 62959, unicode: 'f5ef', label: 'Draw Square', name: 'fa-draw-square' },
  { id: 63378, unicode: 'f792', label: 'Dreidel', name: 'fa-dreidel' },
  { id: 61821, unicode: 'f17d', label: 'Dribbble', name: 'fa-dribbble' },
  { id: 63583, unicode: 'f85f', label: 'Drone', name: 'fa-drone' },
  { id: 63584, unicode: 'f860', label: 'Drone Front', name: 'fa-drone-front' },
  { id: 61803, unicode: 'f16b', label: 'Dropbox', name: 'fa-dropbox' },
  { id: 61507, unicode: 'f043', label: 'Droplet', name: 'fa-droplet' },
  { id: 63304, unicode: 'f748', label: 'Droplet Degree', name: 'fa-droplet-degree' },
  { id: 63312, unicode: 'f750', label: 'Droplet Percent', name: 'fa-droplet-percent' },
  { id: 62919, unicode: 'f5c7', label: 'Droplet Slash', name: 'fa-droplet-slash' },
  { id: 62825, unicode: 'f569', label: 'Drum', name: 'fa-drum' },
  { id: 62826, unicode: 'f56a', label: 'Drum Steelpan', name: 'fa-drum-steelpan' },
  { id: 63190, unicode: 'f6d6', label: 'Drumstick', name: 'fa-drumstick' },
  { id: 63191, unicode: 'f6d7', label: 'Drumstick With Bite Taken Out', name: 'fa-drumstick-with-bite-taken-out' },
  { id: 61865, unicode: 'f1a9', label: 'Drupal Logo', name: 'fa-drupal-logo' },
  { id: 63585, unicode: 'f861', label: 'Dryer', name: 'fa-dryer' },
  { id: 63586, unicode: 'f862', label: 'Dryer Heat', name: 'fa-dryer-heat' },
  { id: 63192, unicode: 'f6d8', label: 'Duck', name: 'fa-duck' },
  { id: 62539, unicode: 'f44b', label: 'Dumbbell', name: 'fa-dumbbell' },
  { id: 63379, unicode: 'f793', label: 'Dumpster', name: 'fa-dumpster' },
  { id: 63380, unicode: 'f794', label: 'Dumpster Fire', name: 'fa-dumpster-fire' },
  { id: 63193, unicode: 'f6d9', label: 'Dungeon', name: 'fa-dungeon' },
  { id: 62361, unicode: 'f399', label: 'Dyalog', name: 'fa-dyalog' },
  { id: 69, unicode: '45', label: 'E', name: 'fa-e' },
  { id: 62960, unicode: 'f5f0', label: 'Ear', name: 'fa-ear' },
  { id: 62116, unicode: 'f2a4', label: 'Ear Deaf', name: 'fa-ear-deaf' },
  { id: 62114, unicode: 'f2a2', label: 'Ear Listen', name: 'fa-ear-listen' },
  { id: 63381, unicode: 'f795', label: 'Ear Muffs', name: 'fa-ear-muffs' },
  { id: 62362, unicode: 'f39a', label: 'Earlybirds', name: 'fa-earlybirds' },
  { id: 62844, unicode: 'f57c', label: 'Earth Africa', name: 'fa-earth-africa' },
  { id: 62845, unicode: 'f57d', label: 'Earth Americas', name: 'fa-earth-americas' },
  { id: 62846, unicode: 'f57e', label: 'Earth Asia', name: 'fa-earth-asia' },
  { id: 63394, unicode: 'f7a2', label: 'Earth Europe', name: 'fa-earth-europe' },
  { id: 58491, unicode: 'e47b', label: 'Earth Oceania', name: 'fa-earth-oceania' },
  { id: 62708, unicode: 'f4f4', label: 'Ebay', name: 'fa-ebay' },
  { id: 63305, unicode: 'f749', label: 'Eclipse', name: 'fa-eclipse' },
  { id: 62082, unicode: 'f282', label: 'Edge Browser', name: 'fa-edge-browser' },
  { id: 57464, unicode: 'e078', label: 'Edge Legacy Browser', name: 'fa-edge-legacy-browser' },
  { id: 63483, unicode: 'f7fb', label: 'Egg', name: 'fa-egg' },
  { id: 63484, unicode: 'f7fc', label: 'Fried Egg', name: 'fa-fried-egg' },
  { id: 57708, unicode: 'e16c', label: 'Eggplant', name: 'fa-eggplant' },
  { id: 61522, unicode: 'f052', label: 'Eject', name: 'fa-eject' },
  { id: 62512, unicode: 'f430', label: 'Elementor', name: 'fa-elementor' },
  { id: 63194, unicode: 'f6da', label: 'Elephant', name: 'fa-elephant' },
  { id: 57709, unicode: 'e16d', label: 'Elevator', name: 'fa-elevator' },
  { id: 61761, unicode: 'f141', label: 'Ellipsis', name: 'fa-ellipsis' },
  { id: 62363, unicode: 'f39b', label: 'Ellipsis Stroke', name: 'fa-ellipsis-stroke' },
  { id: 62364, unicode: 'f39c', label: 'Ellipsis Stroke Vertical', name: 'fa-ellipsis-stroke-vertical' },
  { id: 61762, unicode: 'f142', label: 'Ellipsis Vertical', name: 'fa-ellipsis-vertical' },
  { id: 62961, unicode: 'f5f1', label: 'Ello', name: 'fa-ello' },
  { id: 62499, unicode: 'f423', label: 'Ember', name: 'fa-ember' },
  { id: 61905, unicode: 'f1d1', label: 'Galactic Empire', name: 'fa-galactic-empire' },
  { id: 63062, unicode: 'f656', label: 'Empty Set', name: 'fa-empty-set' },
  { id: 57710, unicode: 'e16e', label: 'Engine', name: 'fa-engine' },
  { id: 62962, unicode: 'f5f2', label: 'Engine Warning', name: 'fa-engine-warning' },
  { id: 61664, unicode: 'f0e0', label: 'Envelope', name: 'fa-envelope' },
  { id: 58600, unicode: 'e4e8', label: 'Envelope Circle Check', name: 'fa-envelope-circle-check' },
  { id: 57711, unicode: 'e16f', label: 'Envelope Dot', name: 'fa-envelope-dot' },
  { id: 62134, unicode: 'f2b6', label: 'Envelope Open', name: 'fa-envelope-open' },
  { id: 63063, unicode: 'f657', label: 'Envelope Open Dollar', name: 'fa-envelope-open-dollar' },
  { id: 63064, unicode: 'f658', label: 'Envelope Open Text', name: 'fa-envelope-open-text' },
  { id: 57712, unicode: 'e170', label: 'Envelopes', name: 'fa-envelopes' },
  { id: 63092, unicode: 'f674', label: 'Envelopes Bulk', name: 'fa-envelopes-bulk' },
  { id: 62105, unicode: 'f299', label: 'Envira Gallery', name: 'fa-envira-gallery' },
  { id: 61, unicode: '3d', label: 'Equals', name: 'fa-equals' },
  { id: 61741, unicode: 'f12d', label: 'Eraser', name: 'fa-eraser' },
  { id: 62365, unicode: 'f39d', label: 'Erlang', name: 'fa-erlang' },
  { id: 57713, unicode: 'e171', label: 'Escalator', name: 'fa-escalator' },
  { id: 62510, unicode: 'f42e', label: 'Ethereum', name: 'fa-ethereum' },
  { id: 63382, unicode: 'f796', label: 'Ethernet', name: 'fa-ethernet' },
  { id: 62167, unicode: 'f2d7', label: 'Etsy', name: 'fa-etsy' },
  { id: 61779, unicode: 'f153', label: 'Euro Sign', name: 'fa-euro-sign' },
  { id: 63545, unicode: 'f839', label: 'Evernote', name: 'fa-evernote' },
  { id: 33, unicode: '21', label: 'Exclamation', name: 'fa-exclamation' },
  { id: 61541, unicode: 'f065', label: 'Expand', name: 'fa-expand' },
  { id: 62240, unicode: 'f320', label: 'Expand Wide', name: 'fa-expand-wide' },
  { id: 62014, unicode: 'f23e', label: 'Expeditedssl', name: 'fa-expeditedssl' },
  { id: 58601, unicode: 'e4e9', label: 'Explosion', name: 'fa-explosion' },
  { id: 61550, unicode: 'f06e', label: 'Eye', name: 'fa-eye' },
  { id: 61947, unicode: 'f1fb', label: 'Eye Dropper', name: 'fa-eye-dropper' },
  { id: 57714, unicode: 'e172', label: 'Eye Dropper Full', name: 'fa-eye-dropper-full' },
  { id: 57715, unicode: 'e173', label: 'Eye Dropper Half', name: 'fa-eye-dropper-half' },
  { id: 63195, unicode: 'f6db', label: 'Evil Eye', name: 'fa-evil-eye' },
  { id: 62120, unicode: 'f2a8', label: 'Eye Low Vision', name: 'fa-eye-low-vision' },
  { id: 61552, unicode: 'f070', label: 'Eye Slash', name: 'fa-eye-slash' },
  { id: 58215, unicode: 'e367', label: 'Eyes', name: 'fa-eyes' },
  { id: 70, unicode: '46', label: 'F', name: 'fa-f' },
  { id: 62806, unicode: 'f556', label: 'Face Angry', name: 'fa-face-angry' },
  { id: 58216, unicode: 'e368', label: 'Face Angry Horns', name: 'fa-face-angry-horns' },
  { id: 58217, unicode: 'e369', label: 'Face Anguished', name: 'fa-face-anguished' },
  { id: 58218, unicode: 'e36a', label: 'Face Anxious Sweat', name: 'fa-face-anxious-sweat' },
  { id: 58219, unicode: 'e36b', label: 'Face Astonished', name: 'fa-face-astonished' },
  { id: 58377, unicode: 'e409', label: 'Face Awesome', name: 'fa-face-awesome' },
  { id: 58492, unicode: 'e47c', label: 'Face Beam Hand Over Mouth', name: 'fa-face-beam-hand-over-mouth' },
  { id: 58493, unicode: 'e47d', label: 'Face In Clouds', name: 'fa-face-in-clouds' },
  { id: 58220, unicode: 'e36c', label: 'Face Confounded', name: 'fa-face-confounded' },
  { id: 58221, unicode: 'e36d', label: 'Face Confused', name: 'fa-face-confused' },
  { id: 58222, unicode: 'e36e', label: 'Face Cowboy Hat', name: 'fa-face-cowboy-hat' },
  { id: 58494, unicode: 'e47e', label: 'Face Diagonal Mouth', name: 'fa-face-diagonal-mouth' },
  { id: 58223, unicode: 'e36f', label: 'Face Disappointed', name: 'fa-face-disappointed' },
  { id: 58224, unicode: 'e370', label: 'Face Disguise', name: 'fa-face-disguise' },
  { id: 62823, unicode: 'f567', label: 'Face Dizzy', name: 'fa-face-dizzy' },
  { id: 58495, unicode: 'e47f', label: 'Face Dotted', name: 'fa-face-dotted' },
  { id: 58225, unicode: 'e371', label: 'Face Downcast Sweat', name: 'fa-face-downcast-sweat' },
  { id: 58226, unicode: 'e372', label: 'Face Drooling', name: 'fa-face-drooling' },
  { id: 58496, unicode: 'e480', label: 'Face Exhaling', name: 'fa-face-exhaling' },
  { id: 58110, unicode: 'e2fe', label: 'Face Explode', name: 'fa-face-explode' },
  { id: 58227, unicode: 'e373', label: 'Face Expressionless', name: 'fa-face-expressionless' },
  { id: 58228, unicode: 'e374', label: 'Face Eyes Xmarks', name: 'fa-face-eyes-xmarks' },
  { id: 58229, unicode: 'e375', label: 'Face Fearful', name: 'fa-face-fearful' },
  { id: 62841, unicode: 'f579', label: 'Face Flushed', name: 'fa-face-flushed' },
  { id: 61721, unicode: 'f119', label: 'Face Frown', name: 'fa-face-frown' },
  { id: 62842, unicode: 'f57a', label: 'Face Frown Open', name: 'fa-face-frown-open' },
  { id: 58230, unicode: 'e376', label: 'Face Frown Slight', name: 'fa-face-frown-slight' },
  { id: 58231, unicode: 'e377', label: 'Face Glasses And Smile', name: 'fa-face-glasses-and-smile' },
  { id: 62847, unicode: 'f57f', label: 'Face Grimace', name: 'fa-face-grimace' },
  { id: 62848, unicode: 'f580', label: 'Face Grin', name: 'fa-face-grin' },
  { id: 62850, unicode: 'f582', label: 'Face Grin Beam', name: 'fa-face-grin-beam' },
  { id: 62851, unicode: 'f583', label: 'Face Grin Beam Sweat', name: 'fa-face-grin-beam-sweat' },
  { id: 62852, unicode: 'f584', label: 'Face Grin Hearts', name: 'fa-face-grin-hearts' },
  { id: 62853, unicode: 'f585', label: 'Face Grin Squint', name: 'fa-face-grin-squint' },
  { id: 62854, unicode: 'f586', label: 'Face Grin Squint Tears', name: 'fa-face-grin-squint-tears' },
  { id: 62855, unicode: 'f587', label: 'Face Grin Stars', name: 'fa-face-grin-stars' },
  { id: 62856, unicode: 'f588', label: 'Face Grin Tears', name: 'fa-face-grin-tears' },
  { id: 62857, unicode: 'f589', label: 'Face Grin Tongue', name: 'fa-face-grin-tongue' },
  { id: 62858, unicode: 'f58a', label: 'Face Grin Tongue Squint', name: 'fa-face-grin-tongue-squint' },
  { id: 62859, unicode: 'f58b', label: 'Face Grin Tongue Wink', name: 'fa-face-grin-tongue-wink' },
  { id: 62849, unicode: 'f581', label: 'Face Grin Wide', name: 'fa-face-grin-wide' },
  { id: 62860, unicode: 'f58c', label: 'Face Grin Wink', name: 'fa-face-grin-wink' },
  { id: 58232, unicode: 'e378', label: 'Face With Hand Over Mouth', name: 'fa-face-with-hand-over-mouth' },
  { id: 58497, unicode: 'e481', label: 'Face Hand Peeking', name: 'fa-face-hand-peeking' },
  { id: 58233, unicode: 'e379', label: 'Face Hand Yawn', name: 'fa-face-hand-yawn' },
  { id: 58234, unicode: 'e37a', label: 'Face Head Bandage', name: 'fa-face-head-bandage' },
  { id: 58498, unicode: 'e482', label: 'Face Holding Back Tears', name: 'fa-face-holding-back-tears' },
  { id: 58235, unicode: 'e37b', label: 'Face Hushed', name: 'fa-face-hushed' },
  { id: 58236, unicode: 'e37c', label: 'Face Icicles', name: 'fa-face-icicles' },
  { id: 62870, unicode: 'f596', label: 'Face Kiss', name: 'fa-face-kiss' },
  { id: 62871, unicode: 'f597', label: 'Face Kiss Beam', name: 'fa-face-kiss-beam' },
  { id: 58237, unicode: 'e37d', label: 'Face Kiss Closed Eyes', name: 'fa-face-kiss-closed-eyes' },
  { id: 62872, unicode: 'f598', label: 'Face Kiss Wink Heart', name: 'fa-face-kiss-wink-heart' },
  { id: 62873, unicode: 'f599', label: 'Face Laugh', name: 'fa-face-laugh' },
  { id: 62874, unicode: 'f59a', label: 'Face Laugh Beam', name: 'fa-face-laugh-beam' },
  { id: 62875, unicode: 'f59b', label: 'Face Laugh Squint', name: 'fa-face-laugh-squint' },
  { id: 62876, unicode: 'f59c', label: 'Face Laugh Wink', name: 'fa-face-laugh-wink' },
  { id: 58238, unicode: 'e37e', label: 'Face Lying', name: 'fa-face-lying' },
  { id: 58239, unicode: 'e37f', label: 'Face Mask', name: 'fa-face-mask' },
  { id: 61722, unicode: 'f11a', label: 'Face Meh', name: 'fa-face-meh' },
  { id: 62884, unicode: 'f5a4', label: 'Face Meh Blank', name: 'fa-face-meh-blank' },
  { id: 58499, unicode: 'e483', label: 'Face Melting', name: 'fa-face-melting' },
  { id: 58240, unicode: 'e380', label: 'Face Monocle', name: 'fa-face-monocle' },
  { id: 58241, unicode: 'e381', label: 'Face Nauseated', name: 'fa-face-nauseated' },
  { id: 58242, unicode: 'e382', label: 'Face Nose Steam', name: 'fa-face-nose-steam' },
  { id: 58243, unicode: 'e383', label: 'Face Party', name: 'fa-face-party' },
  { id: 58244, unicode: 'e384', label: 'Face Pensive', name: 'fa-face-pensive' },
  { id: 58245, unicode: 'e385', label: 'Face Persevering', name: 'fa-face-persevering' },
  { id: 58246, unicode: 'e386', label: 'Face Pleading', name: 'fa-face-pleading' },
  { id: 58247, unicode: 'e387', label: 'Face Pouting', name: 'fa-face-pouting' },
  { id: 58248, unicode: 'e388', label: 'Face Raised Eyebrow', name: 'fa-face-raised-eyebrow' },
  { id: 58249, unicode: 'e389', label: 'Face Relieved', name: 'fa-face-relieved' },
  { id: 62885, unicode: 'f5a5', label: 'Face Rolling Eyes', name: 'fa-face-rolling-eyes' },
  { id: 62899, unicode: 'f5b3', label: 'Face Sad Cry', name: 'fa-face-sad-cry' },
  { id: 58250, unicode: 'e38a', label: 'Face Sad Sweat', name: 'fa-face-sad-sweat' },
  { id: 62900, unicode: 'f5b4', label: 'Face Sad Tear', name: 'fa-face-sad-tear' },
  { id: 58500, unicode: 'e484', label: 'Face Saluting', name: 'fa-face-saluting' },
  { id: 58251, unicode: 'e38b', label: 'Face Scream', name: 'fa-face-scream' },
  { id: 58252, unicode: 'e38c', label: 'Face Shush', name: 'fa-face-shush' },
  { id: 58253, unicode: 'e38d', label: 'Face Sleeping', name: 'fa-face-sleeping' },
  { id: 58254, unicode: 'e38e', label: 'Face Sleepy', name: 'fa-face-sleepy' },
  { id: 61720, unicode: 'f118', label: 'Face Smile', name: 'fa-face-smile' },
  { id: 62904, unicode: 'f5b8', label: 'Face Smile Beam', name: 'fa-face-smile-beam' },
  { id: 58255, unicode: 'e38f', label: 'Face Smile Halo', name: 'fa-face-smile-halo' },
  { id: 58256, unicode: 'e390', label: 'Face Smile Hearts', name: 'fa-face-smile-hearts' },
  { id: 58257, unicode: 'e391', label: 'Face Smile Horns', name: 'fa-face-smile-horns' },
  { id: 62905, unicode: 'f5b9', label: 'Face Smile Plus', name: 'fa-face-smile-plus' },
  { id: 58258, unicode: 'e392', label: 'Face Smile Relaxed', name: 'fa-face-smile-relaxed' },
  { id: 58259, unicode: 'e393', label: 'Face Smile Tear', name: 'fa-face-smile-tear' },
  { id: 58260, unicode: 'e394', label: 'Face Smile Tongue', name: 'fa-face-smile-tongue' },
  { id: 58261, unicode: 'e395', label: 'Face Smile Upside Down', name: 'fa-face-smile-upside-down' },
  { id: 62682, unicode: 'f4da', label: 'Face Smile Wink', name: 'fa-face-smile-wink' },
  { id: 58262, unicode: 'e396', label: 'Face Smiling Hands', name: 'fa-face-smiling-hands' },
  { id: 58263, unicode: 'e397', label: 'Face Smirking', name: 'fa-face-smirking' },
  { id: 58501, unicode: 'e485', label: 'Face Spiral Eyes', name: 'fa-face-spiral-eyes' },
  { id: 58264, unicode: 'e398', label: 'Face Sunglasses', name: 'fa-face-sunglasses' },
  { id: 62914, unicode: 'f5c2', label: 'Face Surprise', name: 'fa-face-surprise' },
  { id: 58265, unicode: 'e399', label: 'Face Swear', name: 'fa-face-swear' },
  { id: 58266, unicode: 'e39a', label: 'Face Thermometer', name: 'fa-face-thermometer' },
  { id: 58267, unicode: 'e39b', label: 'Face Thinking', name: 'fa-face-thinking' },
  { id: 62920, unicode: 'f5c8', label: 'Face Tired', name: 'fa-face-tired' },
  { id: 58268, unicode: 'e39c', label: 'Face Tissue', name: 'fa-face-tissue' },
  { id: 58269, unicode: 'e39d', label: 'Face Tongue Money', name: 'fa-face-tongue-money' },
  { id: 58270, unicode: 'e39e', label: 'Face Tongue Sweat', name: 'fa-face-tongue-sweat' },
  { id: 58271, unicode: 'e39f', label: 'Face Unamused', name: 'fa-face-unamused' },
  { id: 58111, unicode: 'e2ff', label: 'Face Viewfinder', name: 'fa-face-viewfinder' },
  { id: 58272, unicode: 'e3a0', label: 'Face Vomit', name: 'fa-face-vomit' },
  { id: 58273, unicode: 'e3a1', label: 'Face Weary', name: 'fa-face-weary' },
  { id: 58274, unicode: 'e3a2', label: 'Face Woozy', name: 'fa-face-woozy' },
  { id: 58275, unicode: 'e3a3', label: 'Face Worried', name: 'fa-face-worried' },
  { id: 58276, unicode: 'e3a4', label: 'Face Zany', name: 'fa-face-zany' },
  { id: 58277, unicode: 'e3a5', label: 'Face Zipper', name: 'fa-face-zipper' },
  { id: 61594, unicode: 'f09a', label: 'Facebook', name: 'fa-facebook' },
  { id: 62366, unicode: 'f39e', label: 'Facebook F', name: 'fa-facebook-f' },
  { id: 62367, unicode: 'f39f', label: 'Facebook Messenger', name: 'fa-facebook-messenger' },
  { id: 58378, unicode: 'e40a', label: 'Falafel', name: 'fa-falafel' },
  { id: 58112, unicode: 'e300', label: 'Family', name: 'fa-family' },
  { id: 58113, unicode: 'e301', label: 'Family Dress', name: 'fa-family-dress' },
  { id: 58114, unicode: 'e302', label: 'Family Pants', name: 'fa-family-pants' },
  { id: 63587, unicode: 'f863', label: 'Fan', name: 'fa-fan' },
  { id: 57348, unicode: 'e004', label: 'Fan Table', name: 'fa-fan-table' },
  { id: 63196, unicode: 'f6dc', label: 'Fantasy Flight Games', name: 'fa-fantasy-flight-games' },
  { id: 63588, unicode: 'f864', label: 'Farm', name: 'fa-farm' },
  { id: 57349, unicode: 'e005', label: 'Faucet', name: 'fa-faucet' },
  { id: 57350, unicode: 'e006', label: 'Faucet Drip', name: 'fa-faucet-drip' },
  { id: 61868, unicode: 'f1ac', label: 'Fax', name: 'fa-fax' },
  { id: 62765, unicode: 'f52d', label: 'Feather', name: 'fa-feather' },
  { id: 62827, unicode: 'f56b', label: 'Feather Pointed', name: 'fa-feather-pointed' },
  { id: 63383, unicode: 'f797', label: 'Fedex', name: 'fa-fedex' },
  { id: 63384, unicode: 'f798', label: 'Fedora', name: 'fa-fedora' },
  { id: 58115, unicode: 'e303', label: 'Fence', name: 'fa-fence' },
  { id: 57716, unicode: 'e174', label: 'Ferris Wheel', name: 'fa-ferris-wheel' },
  { id: 58602, unicode: 'e4ea', label: 'Ferry', name: 'fa-ferry' },
  { id: 62540, unicode: 'f44c', label: 'Field Hockey Stick Ball', name: 'fa-field-hockey-stick-ball' },
  { id: 63385, unicode: 'f799', label: 'Figma', name: 'fa-figma' },
  { id: 61787, unicode: 'f15b', label: 'File', name: 'fa-file' },
  { id: 62829, unicode: 'f56d', label: 'File Arrow Down', name: 'fa-file-arrow-down' },
  { id: 62836, unicode: 'f574', label: 'File Arrow Up', name: 'fa-file-arrow-up' },
  { id: 61895, unicode: 'f1c7', label: 'Audio File', name: 'fa-audio-file' },
  { id: 57717, unicode: 'e175', label: 'File Binary', name: 'fa-file-binary' },
  { id: 62963, unicode: 'f5f3', label: 'File Certificate', name: 'fa-file-certificate' },
  { id: 63065, unicode: 'f659', label: 'File Chart Column', name: 'fa-file-chart-column' },
  { id: 63066, unicode: 'f65a', label: 'Pie Chart File', name: 'fa-pie-chart-file' },
  { id: 62230, unicode: 'f316', label: 'Check File', name: 'fa-check-file' },
  { id: 58784, unicode: 'e5a0', label: 'File Circle Check', name: 'fa-file-circle-check' },
  { id: 58603, unicode: 'e4eb', label: 'File Circle Exclamation', name: 'fa-file-circle-exclamation' },
  { id: 58515, unicode: 'e493', label: 'File Circle Info', name: 'fa-file-circle-info' },
  { id: 58605, unicode: 'e4ed', label: 'File Circle Minus', name: 'fa-file-circle-minus' },
  { id: 58516, unicode: 'e494', label: 'File Circle Plus', name: 'fa-file-circle-plus' },
  { id: 58607, unicode: 'e4ef', label: 'File Circle Question', name: 'fa-file-circle-question' },
  { id: 58785, unicode: 'e5a1', label: 'File Circle Xmark', name: 'fa-file-circle-xmark' },
  { id: 61897, unicode: 'f1c9', label: 'Code File', name: 'fa-code-file' },
  { id: 62828, unicode: 'f56c', label: 'File Contract', name: 'fa-file-contract' },
  { id: 63197, unicode: 'f6dd', label: 'File CSV', name: 'fa-file-csv' },
  { id: 63607, unicode: 'f877', label: 'File Dashed Line', name: 'fa-file-dashed-line' },
  { id: 61891, unicode: 'f1c3', label: 'Excel File', name: 'fa-excel-file' },
  { id: 62234, unicode: 'f31a', label: 'Exclamation File', name: 'fa-exclamation-file' },
  { id: 62830, unicode: 'f56e', label: 'File Export', name: 'fa-file-export' },
  { id: 57718, unicode: 'e176', label: 'File Heart', name: 'fa-file-heart' },
  { id: 61893, unicode: 'f1c5', label: 'Image File', name: 'fa-image-file' },
  { id: 62831, unicode: 'f56f', label: 'File Import', name: 'fa-file-import' },
  { id: 62832, unicode: 'f570', label: 'File Invoice', name: 'fa-file-invoice' },
  { id: 62833, unicode: 'f571', label: 'File Invoice With US Dollar', name: 'fa-file-invoice-with-us-dollar' },
  { id: 61788, unicode: 'f15c', label: 'File Lines', name: 'fa-file-lines' },
  { id: 58278, unicode: 'e3a6', label: 'File Lock', name: 'fa-file-lock' },
  { id: 63589, unicode: 'f865', label: 'File Magnifying Glass', name: 'fa-file-magnifying-glass' },
  { id: 62583, unicode: 'f477', label: 'Medical File', name: 'fa-medical-file' },
  { id: 62232, unicode: 'f318', label: 'Minus File', name: 'fa-minus-file' },
  { id: 63670, unicode: 'f8b6', label: 'File Music', name: 'fa-file-music' },
  { id: 61889, unicode: 'f1c1', label: 'PDF File', name: 'fa-pdf-file' },
  { id: 62236, unicode: 'f31c', label: 'File Pen', name: 'fa-file-pen' },
  { id: 62233, unicode: 'f319', label: 'Plus File', name: 'fa-plus-file' },
  { id: 57719, unicode: 'e177', label: 'File Plus Minus', name: 'fa-file-plus-minus' },
  { id: 61892, unicode: 'f1c4', label: 'Powerpoint File', name: 'fa-powerpoint-file' },
  { id: 62834, unicode: 'f572', label: 'File Prescription', name: 'fa-file-prescription' },
  { id: 58608, unicode: 'e4f0', label: 'File Shield', name: 'fa-file-shield' },
  { id: 62835, unicode: 'f573', label: 'File Signature', name: 'fa-file-signature' },
  { id: 58279, unicode: 'e3a7', label: 'File Slash', name: 'fa-file-slash' },
  { id: 63067, unicode: 'f65b', label: 'Spreadsheet File', name: 'fa-spreadsheet-file' },
  { id: 63068, unicode: 'f65c', label: 'User File', name: 'fa-user-file' },
  { id: 61896, unicode: 'f1c8', label: 'Video File', name: 'fa-video-file' },
  { id: 62584, unicode: 'f478', label: 'File Waveform', name: 'fa-file-waveform' },
  { id: 61890, unicode: 'f1c2', label: 'Word File', name: 'fa-word-file' },
  { id: 62231, unicode: 'f317', label: 'File X Mark', name: 'fa-file-x-mark' },
  { id: 61894, unicode: 'f1c6', label: 'File Zipper', name: 'fa-file-zipper' },
  { id: 57720, unicode: 'e178', label: 'Files', name: 'fa-files' },
  { id: 63485, unicode: 'f7fd', label: 'Medical Files', name: 'fa-medical-files' },
  { id: 62837, unicode: 'f575', label: 'Fill', name: 'fa-fill' },
  { id: 62838, unicode: 'f576', label: 'Fill Drip', name: 'fa-fill-drip' },
  { id: 61448, unicode: 'f008', label: 'Film', name: 'fa-film' },
  { id: 63671, unicode: 'f8b7', label: 'Film Canister', name: 'fa-film-canister' },
  { id: 62368, unicode: 'f3a0', label: 'Film Simple', name: 'fa-film-simple' },
  { id: 57721, unicode: 'e179', label: 'Film Slash', name: 'fa-film-slash' },
  { id: 57722, unicode: 'e17a', label: 'Films', name: 'fa-films' },
  { id: 61616, unicode: 'f0b0', label: 'Filter', name: 'fa-filter' },
  { id: 63074, unicode: 'f662', label: 'Filter Circle Dollar', name: 'fa-filter-circle-dollar' },
  { id: 57723, unicode: 'e17b', label: 'Filter Circle X Mark', name: 'fa-filter-circle-x-mark' },
  { id: 57724, unicode: 'e17c', label: 'Filter List', name: 'fa-filter-list' },
  { id: 57725, unicode: 'e17d', label: 'Filter Slash', name: 'fa-filter-slash' },
  { id: 57726, unicode: 'e17e', label: 'Filters', name: 'fa-filters' },
  { id: 62839, unicode: 'f577', label: 'Fingerprint', name: 'fa-fingerprint' },
  { id: 61549, unicode: 'f06d', label: 'Fire', name: 'fa-fire' },
  { id: 58609, unicode: 'e4f1', label: 'Fire Burner', name: 'fa-fire-burner' },
  { id: 61748, unicode: 'f134', label: 'Fire Extinguisher', name: 'fa-fire-extinguisher' },
  { id: 63199, unicode: 'f6df', label: 'Fire Flame', name: 'fa-fire-flame' },
  { id: 63460, unicode: 'f7e4', label: 'Fire Flame Curved', name: 'fa-fire-flame-curved' },
  { id: 62570, unicode: 'f46a', label: 'Fire Flame Simple', name: 'fa-fire-flame-simple' },
  { id: 57727, unicode: 'e17f', label: 'Fire Hydrant', name: 'fa-fire-hydrant' },
  { id: 63307, unicode: 'f74b', label: 'Fire And Smoke', name: 'fa-fire-and-smoke' },
  { id: 62057, unicode: 'f269', label: 'Firefox', name: 'fa-firefox' },
  { id: 57351, unicode: 'e007', label: 'Firefox Browser', name: 'fa-firefox-browser' },
  { id: 63386, unicode: 'f79a', label: 'Fireplace', name: 'fa-fireplace' },
  { id: 62128, unicode: 'f2b0', label: 'First Order', name: 'fa-first-order' },
  { id: 62730, unicode: 'f50a', label: 'Alternate First Order', name: 'fa-alternate-first-order' },
  { id: 62369, unicode: 'f3a1', label: 'Firstdraft', name: 'fa-firstdraft' },
  { id: 62840, unicode: 'f578', label: 'Fish', name: 'fa-fish' },
  { id: 58116, unicode: 'e304', label: 'Fish Bones', name: 'fa-fish-bones' },
  { id: 63486, unicode: 'f7fe', label: 'Cooked Fish', name: 'fa-cooked-fish' },
  { id: 58610, unicode: 'e4f2', label: 'Fish Fins', name: 'fa-fish-fins' },
  { id: 58280, unicode: 'e3a8', label: 'Fishing Rod', name: 'fa-fishing-rod' },
  { id: 61476, unicode: 'f024', label: 'Flag', name: 'fa-flag' },
  { id: 61726, unicode: 'f11e', label: 'Flag Checkered', name: 'fa-flag-checkered' },
  { id: 62550, unicode: 'f456', label: 'Flag Pennant', name: 'fa-flag-pennant' },
  { id: 63308, unicode: 'f74c', label: 'Flag Swallowtail', name: 'fa-flag-swallowtail' },
  { id: 63309, unicode: 'f74d', label: 'United States Of America Flag', name: 'fa-united-states-of-america-flag' },
  { id: 63672, unicode: 'f8b8', label: 'Flashlight', name: 'fa-flashlight' },
  { id: 61635, unicode: 'f0c3', label: 'Flask', name: 'fa-flask' },
  { id: 63200, unicode: 'f6e0', label: 'Flask Round Poison', name: 'fa-flask-round-poison' },
  { id: 63201, unicode: 'f6e1', label: 'Flask Round Potion', name: 'fa-flask-round-potion' },
  { id: 58611, unicode: 'e4f3', label: 'Flask And Vial', name: 'fa-flask-and-vial' },
  { id: 58379, unicode: 'e40b', label: 'Flatbread', name: 'fa-flatbread' },
  { id: 58380, unicode: 'e40c', label: 'Flatbread Stuffed', name: 'fa-flatbread-stuffed' },
  { id: 61806, unicode: 'f16e', label: 'Flickr', name: 'fa-flickr' },
  { id: 62541, unicode: 'f44d', label: 'Flipboard', name: 'fa-flipboard' },
  { id: 61639, unicode: 'f0c7', label: 'Floppy Disk', name: 'fa-floppy-disk' },
  { id: 57728, unicode: 'e180', label: 'Floppy Disk Arrow Right', name: 'fa-floppy-disk-arrow-right' },
  { id: 57729, unicode: 'e181', label: 'Floppy Disk X Mark', name: 'fa-floppy-disk-x-mark' },
  { id: 57730, unicode: 'e182', label: 'Floppy Disk Pen', name: 'fa-floppy-disk-pen' },
  { id: 57731, unicode: 'e183', label: 'Floppy Disks', name: 'fa-floppy-disks' },
  { id: 57732, unicode: 'e184', label: 'Florin Sign', name: 'fa-florin-sign' },
  { id: 63487, unicode: 'f7ff', label: 'Flower', name: 'fa-flower' },
  { id: 63488, unicode: 'f800', label: 'Flower Daffodil', name: 'fa-flower-daffodil' },
  { id: 63489, unicode: 'f801', label: 'Flower Tulip', name: 'fa-flower-tulip' },
  { id: 63673, unicode: 'f8b9', label: 'Flute', name: 'fa-flute' },
  { id: 63674, unicode: 'f8ba', label: 'Flux Capacitor', name: 'fa-flux-capacitor' },
  { id: 62487, unicode: 'f417', label: 'Fly', name: 'fa-fly' },
  { id: 58281, unicode: 'e3a9', label: 'Flying Disc', name: 'fa-flying-disc' },
  { id: 61563, unicode: 'f07b', label: 'Folder', name: 'fa-folder' },
  { id: 57427, unicode: 'e053', label: 'Folder Arrow Down', name: 'fa-folder-arrow-down' },
  { id: 57428, unicode: 'e054', label: 'Folder Arrow Up', name: 'fa-folder-arrow-up' },
  { id: 57734, unicode: 'e186', label: 'Folder Bookmark', name: 'fa-folder-bookmark' },
  { id: 57733, unicode: 'e185', label: 'Folder Closed', name: 'fa-folder-closed' },
  { id: 57735, unicode: 'e187', label: 'Folder Gear', name: 'fa-folder-gear' },
  { id: 57736, unicode: 'e188', label: 'Folder Grid', name: 'fa-folder-grid' },
  { id: 57737, unicode: 'e189', label: 'Folder Heart', name: 'fa-folder-heart' },
  { id: 57738, unicode: 'e18a', label: 'Folder Image', name: 'fa-folder-image' },
  { id: 57739, unicode: 'e18b', label: 'Folder Magnifying Glass', name: 'fa-folder-magnifying-glass' },
  { id: 57740, unicode: 'e18c', label: 'Folder Medical', name: 'fa-folder-medical' },
  { id: 63069, unicode: 'f65d', label: 'Folder Minus', name: 'fa-folder-minus' },
  { id: 57741, unicode: 'e18d', label: 'Folder Music', name: 'fa-folder-music' },
  { id: 61564, unicode: 'f07c', label: 'Folder Open', name: 'fa-folder-open' },
  { id: 63070, unicode: 'f65e', label: 'Folder Plus', name: 'fa-folder-plus' },
  { id: 63490, unicode: 'f802', label: 'Folder Tree', name: 'fa-folder-tree' },
  { id: 57742, unicode: 'e18e', label: 'Folder User', name: 'fa-folder-user' },
  { id: 63071, unicode: 'f65f', label: 'Folder X Mark', name: 'fa-folder-x-mark' },
  { id: 63072, unicode: 'f660', label: 'Folders', name: 'fa-folders' },
  { id: 58381, unicode: 'e40d', label: 'Fondue Pot', name: 'fa-fondue-pot' },
  { id: 61489, unicode: 'f031', label: 'Font', name: 'fa-font' },
  { id: 62132, unicode: 'f2b4', label: 'Font Awesome', name: 'fa-font-awesome' },
  { id: 63590, unicode: 'f866', label: 'Font Case', name: 'fa-font-case' },
  { id: 62080, unicode: 'f280', label: 'Fonticons', name: 'fa-fonticons' },
  { id: 62370, unicode: 'f3a2', label: 'Fonticons Fi', name: 'fa-fonticons-fi' },
  { id: 62542, unicode: 'f44e', label: 'Football Ball', name: 'fa-football-ball' },
  { id: 62543, unicode: 'f44f', label: 'Football Helmet', name: 'fa-football-helmet' },
  { id: 62179, unicode: 'f2e3', label: 'Fork', name: 'fa-fork' },
  { id: 62182, unicode: 'f2e6', label: 'Fork Knife', name: 'fa-fork-knife' },
  { id: 62586, unicode: 'f47a', label: 'Forklift', name: 'fa-forklift' },
  { id: 58502, unicode: 'e486', label: 'Fort', name: 'fa-fort' },
  { id: 62086, unicode: 'f286', label: 'Fort Awesome', name: 'fa-fort-awesome' },
  { id: 62371, unicode: 'f3a3', label: 'Alternate Fort Awesome', name: 'fa-alternate-fort-awesome' },
  { id: 61969, unicode: 'f211', label: 'Forumbee', name: 'fa-forumbee' },
  { id: 61518, unicode: 'f04e', label: 'Forward', name: 'fa-forward' },
  { id: 61520, unicode: 'f050', label: 'Forward Fast', name: 'fa-forward-fast' },
  { id: 61521, unicode: 'f051', label: 'Forward Step', name: 'fa-forward-step' },
  { id: 61824, unicode: 'f180', label: 'Foursquare', name: 'fa-foursquare' },
  { id: 58517, unicode: 'e495', label: 'Frame', name: 'fa-frame' },
  { id: 57743, unicode: 'e18f', label: 'Franc Sign', name: 'fa-franc-sign' },
  { id: 62149, unicode: 'f2c5', label: 'Freecodecamp', name: 'fa-freecodecamp' },
  { id: 62372, unicode: 'f3a4', label: 'Freebsd', name: 'fa-freebsd' },
  { id: 63491, unicode: 'f803', label: 'French Fries', name: 'fa-french-fries' },
  { id: 62766, unicode: 'f52e', label: 'Frog', name: 'fa-frog' },
  { id: 62731, unicode: 'f50b', label: 'Fulcrum', name: 'fa-fulcrum' },
  { id: 63073, unicode: 'f661', label: 'Function', name: 'fa-function' },
  { id: 61923, unicode: 'f1e3', label: 'Futbol Ball', name: 'fa-futbol-ball' },
  { id: 71, unicode: '47', label: 'G', name: 'fa-g' },
  { id: 62732, unicode: 'f50c', label: 'Galactic Republic', name: 'fa-galactic-republic' },
  { id: 62733, unicode: 'f50d', label: 'Galactic Senate', name: 'fa-galactic-senate' },
  { id: 57352, unicode: 'e008', label: 'Galaxy', name: 'fa-galaxy' },
  { id: 58282, unicode: 'e3aa', label: 'Gallery Thumbnails', name: 'fa-gallery-thumbnails' },
  { id: 63591, unicode: 'f867', label: 'Game Board', name: 'fa-game-board' },
  { id: 63592, unicode: 'f868', label: 'Game Board Simple', name: 'fa-game-board-simple' },
  { id: 63675, unicode: 'f8bb', label: 'Handheld Game Console', name: 'fa-handheld-game-console' },
  { id: 61723, unicode: 'f11b', label: 'Gamepad', name: 'fa-gamepad' },
  { id: 58786, unicode: 'e5a2', label: 'Gamepad Modern', name: 'fa-gamepad-modern' },
  { id: 57353, unicode: 'e009', label: 'Garage', name: 'fa-garage' },
  { id: 57354, unicode: 'e00a', label: 'Garage Car', name: 'fa-garage-car' },
  { id: 57355, unicode: 'e00b', label: 'Garage Open', name: 'fa-garage-open' },
  { id: 58382, unicode: 'e40e', label: 'Garlic', name: 'fa-garlic' },
  { id: 62767, unicode: 'f52f', label: 'Gas Pump', name: 'fa-gas-pump' },
  { id: 62964, unicode: 'f5f4', label: 'Gas Pump Slash', name: 'fa-gas-pump-slash' },
  { id: 63012, unicode: 'f624', label: 'Gauge Med', name: 'fa-gauge-med' },
  { id: 58518, unicode: 'e496', label: 'Gauge Circle Bolt', name: 'fa-gauge-circle-bolt' },
  { id: 58519, unicode: 'e497', label: 'Gauge Circle Minus', name: 'fa-gauge-circle-minus' },
  { id: 58520, unicode: 'e498', label: 'Gauge Circle Plus', name: 'fa-gauge-circle-plus' },
  { id: 63013, unicode: 'f625', label: 'Gauge', name: 'fa-gauge' },
  { id: 63015, unicode: 'f627', label: 'Gauge Low', name: 'fa-gauge-low' },
  { id: 63014, unicode: 'f626', label: 'Gauge Max', name: 'fa-gauge-max' },
  { id: 63016, unicode: 'f628', label: 'Gauge Min', name: 'fa-gauge-min' },
  { id: 63017, unicode: 'f629', label: 'Gauge Simple Med', name: 'fa-gauge-simple-med' },
  { id: 63018, unicode: 'f62a', label: 'Gauge Simple', name: 'fa-gauge-simple' },
  { id: 63020, unicode: 'f62c', label: 'Gauge Simple Low', name: 'fa-gauge-simple-low' },
  { id: 63019, unicode: 'f62b', label: 'Gauge Simple Max', name: 'fa-gauge-simple-max' },
  { id: 63021, unicode: 'f62d', label: 'Gauge Simple Min', name: 'fa-gauge-simple-min' },
  { id: 61667, unicode: 'f0e3', label: 'Gavel', name: 'fa-gavel' },
  { id: 61459, unicode: 'f013', label: 'Gear', name: 'fa-gear' },
  { id: 61573, unicode: 'f085', label: 'Gears', name: 'fa-gears' },
  { id: 62373, unicode: 'f3a5', label: 'Gem', name: 'fa-gem' },
  { id: 61997, unicode: 'f22d', label: 'Genderless', name: 'fa-genderless' },
  { id: 62053, unicode: 'f265', label: 'Get Pocket', name: 'fa-get-pocket' },
  { id: 62048, unicode: 'f260', label: 'GG Currency', name: 'fa-gg-currency' },
  { id: 62049, unicode: 'f261', label: 'GG Currency Circle', name: 'fa-gg-currency-circle' },
  { id: 63202, unicode: 'f6e2', label: 'Ghost', name: 'fa-ghost' },
  { id: 57744, unicode: 'e190', label: 'Gif', name: 'fa-gif' },
  { id: 61547, unicode: 'f06b', label: 'Gift', name: 'fa-gift' },
  { id: 63075, unicode: 'f663', label: 'Gift Card', name: 'fa-gift-card' },
  { id: 63388, unicode: 'f79c', label: 'Gifts', name: 'fa-gifts' },
  { id: 63389, unicode: 'f79d', label: 'Gingerbread Man', name: 'fa-gingerbread-man' },
  { id: 61907, unicode: 'f1d3', label: 'Git', name: 'fa-git' },
  { id: 63553, unicode: 'f841', label: 'Git Alt', name: 'fa-git-alt' },
  { id: 61595, unicode: 'f09b', label: 'Github', name: 'fa-github' },
  { id: 61715, unicode: 'f113', label: 'Alternate Github', name: 'fa-alternate-github' },
  { id: 62374, unicode: 'f3a6', label: 'Gitkraken', name: 'fa-gitkraken' },
  { id: 62102, unicode: 'f296', label: 'Gitlab', name: 'fa-gitlab' },
  { id: 62502, unicode: 'f426', label: 'Gitter', name: 'fa-gitter' },
  { id: 63492, unicode: 'f804', label: 'Glass', name: 'fa-glass' },
  { id: 63593, unicode: 'f869', label: 'Glass Citrus', name: 'fa-glass-citrus' },
  { id: 57745, unicode: 'e191', label: 'Glass Empty', name: 'fa-glass-empty' },
  { id: 57746, unicode: 'e192', label: 'Glass Half', name: 'fa-glass-half' },
  { id: 58612, unicode: 'e4f4', label: 'Glass Water', name: 'fa-glass-water' },
  { id: 58613, unicode: 'e4f5', label: 'Glass Water Droplet', name: 'fa-glass-water-droplet' },
  { id: 62768, unicode: 'f530', label: 'Glasses', name: 'fa-glasses' },
  { id: 62965, unicode: 'f5f5', label: 'Glasses Round', name: 'fa-glasses-round' },
  { id: 62117, unicode: 'f2a5', label: 'Glide', name: 'fa-glide' },
  { id: 62118, unicode: 'f2a6', label: 'Glide G', name: 'fa-glide-g' },
  { id: 61612, unicode: 'f0ac', label: 'Globe', name: 'fa-globe' },
  { id: 63395, unicode: 'f7a3', label: 'Globe Snow', name: 'fa-globe-snow' },
  { id: 62966, unicode: 'f5f6', label: 'Globe Stand', name: 'fa-globe-stand' },
  { id: 58283, unicode: 'e3ab', label: 'Goal Net', name: 'fa-goal-net' },
  { id: 62375, unicode: 'f3a7', label: 'Gofore', name: 'fa-gofore' },
  { id: 58383, unicode: 'e40f', label: 'Go', name: 'fa-go' },
  { id: 62544, unicode: 'f450', label: 'Golf Ball Tee', name: 'fa-golf-ball-tee' },
  { id: 62545, unicode: 'f451', label: 'Golf Club', name: 'fa-golf-club' },
  { id: 58284, unicode: 'e3ac', label: 'Golf Flag   Hole', name: 'fa-golf-flag---hole' },
  { id: 62376, unicode: 'f3a8', label: 'Goodreads', name: 'fa-goodreads' },
  { id: 62377, unicode: 'f3a9', label: 'Goodreads G', name: 'fa-goodreads-g' },
  { id: 61856, unicode: 'f1a0', label: 'Google Logo', name: 'fa-google-logo' },
  { id: 62378, unicode: 'f3aa', label: 'Google Drive', name: 'fa-google-drive' },
  { id: 57465, unicode: 'e079', label: 'Google Pay', name: 'fa-google-pay' },
  { id: 62379, unicode: 'f3ab', label: 'Google Play', name: 'fa-google-play' },
  { id: 62131, unicode: 'f2b3', label: 'Google Plus', name: 'fa-google-plus' },
  { id: 61653, unicode: 'f0d5', label: 'Google Plus G', name: 'fa-google-plus-g' },
  { id: 61934, unicode: 'f1ee', label: 'Google Wallet', name: 'fa-google-wallet' },
  { id: 63076, unicode: 'f664', label: 'Gopuram', name: 'fa-gopuram' },
  { id: 61853, unicode: 'f19d', label: 'Graduation Cap', name: 'fa-graduation-cap' },
  { id: 63677, unicode: 'f8bd', label: 'Gramophone', name: 'fa-gramophone' },
  { id: 58118, unicode: 'e306', label: 'Grapes', name: 'fa-grapes' },
  { id: 57747, unicode: 'e193', label: 'Grate', name: 'fa-grate' },
  { id: 57748, unicode: 'e194', label: 'Grate Droplet', name: 'fa-grate-droplet' },
  { id: 61828, unicode: 'f184', label: 'Gratipay  Gittip ', name: 'fa-gratipay--gittip-' },
  { id: 62166, unicode: 'f2d6', label: 'Grav', name: 'fa-grav' },
  { id: 62, unicode: '3e', label: 'Greater Than', name: 'fa-greater-than' },
  { id: 62770, unicode: 'f532', label: 'Greater Than Equal To', name: 'fa-greater-than-equal-to' },
  { id: 57749, unicode: 'e195', label: 'Grid', name: 'fa-grid' },
  { id: 57750, unicode: 'e196', label: 'Grid 2', name: 'fa-grid-2' },
  { id: 57751, unicode: 'e197', label: 'Grid 2 Plus', name: 'fa-grid-2-plus' },
  { id: 57752, unicode: 'e198', label: 'Grid 4', name: 'fa-grid-4' },
  { id: 57753, unicode: 'e199', label: 'Grid 5', name: 'fa-grid-5' },
  { id: 58285, unicode: 'e3ad', label: 'Grid Dividers', name: 'fa-grid-dividers' },
  { id: 58119, unicode: 'e307', label: 'Grid Horizontal', name: 'fa-grid-horizontal' },
  { id: 58787, unicode: 'e5a3', label: 'Grill', name: 'fa-grill' },
  { id: 58788, unicode: 'e5a4', label: 'Grill Fire', name: 'fa-grill-fire' },
  { id: 58789, unicode: 'e5a5', label: 'Grill Hot', name: 'fa-grill-hot' },
  { id: 62861, unicode: 'f58d', label: 'Grip', name: 'fa-grip' },
  { id: 58384, unicode: 'e410', label: 'Grip Dots', name: 'fa-grip-dots' },
  { id: 58385, unicode: 'e411', label: 'Grip Dots Vertical', name: 'fa-grip-dots-vertical' },
  { id: 63396, unicode: 'f7a4', label: 'Grip Lines', name: 'fa-grip-lines' },
  { id: 63397, unicode: 'f7a5', label: 'Grip Lines Vertical', name: 'fa-grip-lines-vertical' },
  { id: 62862, unicode: 'f58e', label: 'Grip Vertical', name: 'fa-grip-vertical' },
  { id: 62380, unicode: 'f3ac', label: 'Gripfire  Inc ', name: 'fa-gripfire--inc-' },
  { id: 58614, unicode: 'e4f6', label: 'Group Arrows Rotate', name: 'fa-group-arrows-rotate' },
  { id: 62381, unicode: 'f3ad', label: 'Grunt', name: 'fa-grunt' },
  { id: 57754, unicode: 'e19a', label: 'Guarani Sign', name: 'fa-guarani-sign' },
  { id: 57470, unicode: 'e07e', label: 'Guilded', name: 'fa-guilded' },
  { id: 63398, unicode: 'f7a6', label: 'Guitar', name: 'fa-guitar' },
  { id: 63678, unicode: 'f8be', label: 'Guitar Electric', name: 'fa-guitar-electric' },
  { id: 63679, unicode: 'f8bf', label: 'Guitars', name: 'fa-guitars' },
  { id: 62382, unicode: 'f3ae', label: 'Gulp', name: 'fa-gulp' },
  { id: 57755, unicode: 'e19b', label: 'Gun', name: 'fa-gun' },
  { id: 57756, unicode: 'e19c', label: 'Gun Slash', name: 'fa-gun-slash' },
  { id: 57757, unicode: 'e19d', label: 'Squirt Gun', name: 'fa-squirt-gun' },
  { id: 72, unicode: '48', label: 'H', name: 'fa-h' },
  { id: 62227, unicode: 'f313', label: 'H1 Heading', name: 'fa-h1-heading' },
  { id: 62228, unicode: 'f314', label: 'H2 Heading', name: 'fa-h2-heading' },
  { id: 62229, unicode: 'f315', label: 'H3 Heading', name: 'fa-h3-heading' },
  { id: 63594, unicode: 'f86a', label: 'H4', name: 'fa-h4' },
  { id: 58386, unicode: 'e412', label: 'H5', name: 'fa-h5' },
  { id: 58387, unicode: 'e413', label: 'H6', name: 'fa-h6' },
  { id: 61908, unicode: 'f1d4', label: 'Hacker News', name: 'fa-hacker-news' },
  { id: 62967, unicode: 'f5f7', label: 'Hackerrank', name: 'fa-hackerrank' },
  { id: 63203, unicode: 'f6e3', label: 'Hammer', name: 'fa-hammer' },
  { id: 58388, unicode: 'e414', label: 'Hammer Crash', name: 'fa-hammer-crash' },
  { id: 63204, unicode: 'f6e4', label: 'Hammer War', name: 'fa-hammer-war' },
  { id: 63077, unicode: 'f665', label: 'Hamsa', name: 'fa-hamsa' },
  { id: 62038, unicode: 'f256', label: 'Paper  Hand ', name: 'fa-paper--hand-' },
  { id: 62037, unicode: 'f255', label: 'Rock  Hand ', name: 'fa-rock--hand-' },
  { id: 57758, unicode: 'e19e', label: 'Hand Back Point Down', name: 'fa-hand-back-point-down' },
  { id: 57759, unicode: 'e19f', label: 'Hand Back Point Left', name: 'fa-hand-back-point-left' },
  { id: 57760, unicode: 'e1a0', label: 'Hand Back Point Ribbon', name: 'fa-hand-back-point-ribbon' },
  { id: 57761, unicode: 'e1a1', label: 'Hand Back Point Right', name: 'fa-hand-back-point-right' },
  { id: 57762, unicode: 'e1a2', label: 'Hand Back Point Up', name: 'fa-hand-back-point-up' },
  { id: 62561, unicode: 'f461', label: 'Hand Dots', name: 'fa-hand-dots' },
  { id: 57763, unicode: 'e1a3', label: 'Hand Fingers Crossed', name: 'fa-hand-fingers-crossed' },
  { id: 63198, unicode: 'f6de', label: 'Raised Fist', name: 'fa-raised-fist' },
  { id: 62652, unicode: 'f4bc', label: 'Hand With Heart', name: 'fa-hand-with-heart' },
  { id: 62653, unicode: 'f4bd', label: 'Hand Holding', name: 'fa-hand-holding' },
  { id: 62587, unicode: 'f47b', label: 'Hand Holding Box', name: 'fa-hand-holding-box' },
  { id: 62656, unicode: 'f4c0', label: 'Hand Holding Dollar', name: 'fa-hand-holding-dollar' },
  { id: 62657, unicode: 'f4c1', label: 'Hand Holding Droplet', name: 'fa-hand-holding-droplet' },
  { id: 58615, unicode: 'e4f7', label: 'Hand Holding Hand', name: 'fa-hand-holding-hand' },
  { id: 62654, unicode: 'f4be', label: 'Hand Holding Heart', name: 'fa-hand-holding-heart' },
  { id: 63205, unicode: 'f6e5', label: 'Hand Holding Magic', name: 'fa-hand-holding-magic' },
  { id: 57436, unicode: 'e05c', label: 'Hand Holding Medical Cross', name: 'fa-hand-holding-medical-cross' },
  { id: 62655, unicode: 'f4bf', label: 'Hand Holding Seedling', name: 'fa-hand-holding-seedling' },
  { id: 57764, unicode: 'e1a4', label: 'Hand Holding A Skull', name: 'fa-hand-holding-a-skull' },
  { id: 57769, unicode: 'e1a9', label: 'Hands Horns', name: 'fa-hands-horns' },
  { id: 62040, unicode: 'f258', label: 'Lizard  Hand ', name: 'fa-lizard--hand-' },
  { id: 57765, unicode: 'e1a5', label: 'Hand Love', name: 'fa-hand-love' },
  { id: 63494, unicode: 'f806', label: 'Hand With Middle Finger Raised', name: 'fa-hand-with-middle-finger-raised' },
  { id: 62043, unicode: 'f25b', label: 'Peace  Hand ', name: 'fa-peace--hand-' },
  { id: 61607, unicode: 'f0a7', label: 'Hand Pointing Down', name: 'fa-hand-pointing-down' },
  { id: 61605, unicode: 'f0a5', label: 'Hand Pointing Left', name: 'fa-hand-pointing-left' },
  { id: 57766, unicode: 'e1a6', label: 'Hand Point Ribbon', name: 'fa-hand-point-ribbon' },
  { id: 61604, unicode: 'f0a4', label: 'Hand Pointing Right', name: 'fa-hand-pointing-right' },
  { id: 61606, unicode: 'f0a6', label: 'Hand Pointing Up', name: 'fa-hand-pointing-up' },
  { id: 62042, unicode: 'f25a', label: 'Pointer  Hand ', name: 'fa-pointer--hand-' },
  { id: 62039, unicode: 'f257', label: 'Scissors  Hand ', name: 'fa-scissors--hand-' },
  { id: 57437, unicode: 'e05d', label: 'Hand Sparkles', name: 'fa-hand-sparkles' },
  { id: 62041, unicode: 'f259', label: 'Spock  Hand ', name: 'fa-spock--hand-' },
  { id: 57767, unicode: 'e1a7', label: 'Hand Wave', name: 'fa-hand-wave' },
  { id: 58616, unicode: 'e4f8', label: 'Handcuffs', name: 'fa-handcuffs' },
  { id: 62119, unicode: 'f2a7', label: 'Hands', name: 'fa-hands' },
  { id: 62115, unicode: 'f2a3', label: 'Hands American Sign Language Interpreting', name: 'fa-hands-american-sign-language-interpreting' },
  { id: 58617, unicode: 'e4f9', label: 'Hands Bound', name: 'fa-hands-bound' },
  { id: 57438, unicode: 'e05e', label: 'Hands Bubbles', name: 'fa-hands-bubbles' },
  { id: 57768, unicode: 'e1a8', label: 'Hands Clapping', name: 'fa-hands-clapping' },
  { id: 62658, unicode: 'f4c2', label: 'Hands Holding', name: 'fa-hands-holding' },
  { id: 58618, unicode: 'e4fa', label: 'Hands Holding Child', name: 'fa-hands-holding-child' },
  { id: 58619, unicode: 'e4fb', label: 'Hands Holding Circle', name: 'fa-hands-holding-circle' },
  { id: 62588, unicode: 'f47c', label: 'Hands Holding Diamond', name: 'fa-hands-holding-diamond' },
  { id: 62661, unicode: 'f4c5', label: 'Hands Holding Dollar', name: 'fa-hands-holding-dollar' },
  { id: 62659, unicode: 'f4c3', label: 'Hands Holding Heart', name: 'fa-hands-holding-heart' },
  { id: 63108, unicode: 'f684', label: 'Hands Praying', name: 'fa-hands-praying' },
  { id: 62133, unicode: 'f2b5', label: 'Handshake', name: 'fa-handshake' },
  { id: 62660, unicode: 'f4c4', label: 'Handshake Angle', name: 'fa-handshake-angle' },
  { id: 62662, unicode: 'f4c6', label: 'Handshake Simple', name: 'fa-handshake-simple' },
  { id: 57439, unicode: 'e05f', label: 'Handshake Simple Slash', name: 'fa-handshake-simple-slash' },
  { id: 57440, unicode: 'e060', label: 'Handshake Slash', name: 'fa-handshake-slash' },
  { id: 63206, unicode: 'f6e6', label: 'Hanukiah', name: 'fa-hanukiah' },
  { id: 61600, unicode: 'f0a0', label: 'Hard Drive', name: 'fa-hard-drive' },
  { id: 58521, unicode: 'e499', label: 'Hashnode', name: 'fa-hashnode' },
  { id: 35, unicode: '23', label: 'Hashtag', name: 'fa-hashtag' },
  { id: 58389, unicode: 'e415', label: 'Hashtag Lock', name: 'fa-hashtag-lock' },
  { id: 63595, unicode: 'f86b', label: 'Chef Hat', name: 'fa-chef-hat' },
  { id: 63680, unicode: 'f8c0', label: 'Cowboy Hat', name: 'fa-cowboy-hat' },
  { id: 63681, unicode: 'f8c1', label: 'Cowboy Hat Side', name: 'fa-cowboy-hat-side' },
  { id: 63399, unicode: 'f7a7', label: 'Santa S Hat', name: 'fa-santa-s-hat' },
  { id: 63400, unicode: 'f7a8', label: 'Hat Winter', name: 'fa-hat-winter' },
  { id: 63207, unicode: 'f6e7', label: 'Witch S Hat', name: 'fa-witch-s-hat' },
  { id: 63208, unicode: 'f6e8', label: 'Wizard S Hat', name: 'fa-wizard-s-hat' },
  { id: 63209, unicode: 'f6e9', label: 'Head Side', name: 'fa-head-side' },
  { id: 63496, unicode: 'f808', label: 'Head Side With Brain', name: 'fa-head-side-with-brain' },
  { id: 57441, unicode: 'e061', label: 'Head Side Cough', name: 'fa-head-side-cough' },
  { id: 57442, unicode: 'e062', label: 'Head Side Cough Slash', name: 'fa-head-side-cough-slash' },
  { id: 63210, unicode: 'f6ea', label: 'Head Side Goggles', name: 'fa-head-side-goggles' },
  { id: 63682, unicode: 'f8c2', label: 'Head Side With Headphones', name: 'fa-head-side-with-headphones' },
  { id: 57770, unicode: 'e1aa', label: 'Head Side Heart', name: 'fa-head-side-heart' },
  { id: 57443, unicode: 'e063', label: 'Head Side Mask', name: 'fa-head-side-mask' },
  { id: 63497, unicode: 'f809', label: 'Head Side With Medical Symbol', name: 'fa-head-side-with-medical-symbol' },
  { id: 57444, unicode: 'e064', label: 'Head Side Virus', name: 'fa-head-side-virus' },
  { id: 61916, unicode: 'f1dc', label: 'Heading', name: 'fa-heading' },
  { id: 61477, unicode: 'f025', label: 'Headphones', name: 'fa-headphones' },
  { id: 62863, unicode: 'f58f', label: 'Headphones Simple', name: 'fa-headphones-simple' },
  { id: 62864, unicode: 'f590', label: 'Headset', name: 'fa-headset' },
  { id: 61444, unicode: 'f004', label: 'Heart', name: 'fa-heart' },
  { id: 58620, unicode: 'e4fc', label: 'Heart Circle Bolt', name: 'fa-heart-circle-bolt' },
  { id: 58621, unicode: 'e4fd', label: 'Heart Circle Check', name: 'fa-heart-circle-check' },
  { id: 58622, unicode: 'e4fe', label: 'Heart Circle Exclamation', name: 'fa-heart-circle-exclamation' },
  { id: 58623, unicode: 'e4ff', label: 'Heart Circle Minus', name: 'fa-heart-circle-minus' },
  { id: 58624, unicode: 'e500', label: 'Heart Circle Plus', name: 'fa-heart-circle-plus' },
  { id: 58625, unicode: 'e501', label: 'Heart Circle Xmark', name: 'fa-heart-circle-xmark' },
  { id: 63401, unicode: 'f7a9', label: 'Heart Crack', name: 'fa-heart-crack' },
  { id: 57771, unicode: 'e1ab', label: 'Heart Half', name: 'fa-heart-half' },
  { id: 57772, unicode: 'e1ac', label: 'Heart Half Stroke', name: 'fa-heart-half-stroke' },
  { id: 61982, unicode: 'f21e', label: 'Heart Pulse', name: 'fa-heart-pulse' },
  { id: 57356, unicode: 'e00c', label: 'Heat', name: 'fa-heat' },
  { id: 62771, unicode: 'f533', label: 'Helicopter', name: 'fa-helicopter' },
  { id: 58626, unicode: 'e502', label: 'Helicopter Symbol', name: 'fa-helicopter-symbol' },
  { id: 63211, unicode: 'f6eb', label: 'Battle Helmet', name: 'fa-battle-helmet' },
  { id: 63495, unicode: 'f807', label: 'Helmet Safety', name: 'fa-helmet-safety' },
  { id: 58627, unicode: 'e503', label: 'Helmet Un', name: 'fa-helmet-un' },
  { id: 62226, unicode: 'f312', label: 'Hexagon', name: 'fa-hexagon' },
  { id: 58390, unicode: 'e416', label: 'Hexagon Check', name: 'fa-hexagon-check' },
  { id: 57773, unicode: 'e1ad', label: 'Hexagon Divide', name: 'fa-hexagon-divide' },
  { id: 58391, unicode: 'e417', label: 'Hexagon Exclamation', name: 'fa-hexagon-exclamation' },
  { id: 58628, unicode: 'e504', label: 'Hexagon Image', name: 'fa-hexagon-image' },
  { id: 62215, unicode: 'f307', label: 'Hexagon Minus', name: 'fa-hexagon-minus' },
  { id: 62208, unicode: 'f300', label: 'Hexagon Plus', name: 'fa-hexagon-plus' },
  { id: 58629, unicode: 'e505', label: 'Hexagon NFT', name: 'fa-hexagon-nft' },
  { id: 58630, unicode: 'e506', label: 'Hexagon NFT Slanted', name: 'fa-hexagon-nft-slanted' },
  { id: 62190, unicode: 'f2ee', label: 'Hexagon X Mark', name: 'fa-hexagon-x-mark' },
  { id: 57774, unicode: 'e1ae', label: 'High Definition', name: 'fa-high-definition' },
  { id: 62865, unicode: 'f591', label: 'Highlighter', name: 'fa-highlighter' },
  { id: 57775, unicode: 'e1af', label: 'Highlighter Line', name: 'fa-highlighter-line' },
  { id: 58631, unicode: 'e507', label: 'Hill Avalanche', name: 'fa-hill-avalanche' },
  { id: 58632, unicode: 'e508', label: 'Hill Rockslide', name: 'fa-hill-rockslide' },
  { id: 63213, unicode: 'f6ed', label: 'Hippo', name: 'fa-hippo' },
  { id: 62546, unicode: 'f452', label: 'Hips', name: 'fa-hips' },
  { id: 62384, unicode: 'f3b0', label: 'Hireahelper', name: 'fa-hireahelper' },
  { id: 57471, unicode: 'e07f', label: 'Hive Blockchain Network', name: 'fa-hive-blockchain-network' },
  { id: 63214, unicode: 'f6ee', label: 'Hockey Mask', name: 'fa-hockey-mask' },
  { id: 62547, unicode: 'f453', label: 'Hockey Puck', name: 'fa-hockey-puck' },
  { id: 58286, unicode: 'e3ae', label: 'Hockey Stick   Puck', name: 'fa-hockey-stick---puck' },
  { id: 62548, unicode: 'f454', label: 'Hockey Sticks', name: 'fa-hockey-sticks' },
  { id: 63402, unicode: 'f7aa', label: 'Holly Berry', name: 'fa-holly-berry' },
  { id: 58392, unicode: 'e418', label: 'Honey Pot', name: 'fa-honey-pot' },
  { id: 63215, unicode: 'f6ef', label: 'Hood Cloak', name: 'fa-hood-cloak' },
  { id: 62503, unicode: 'f427', label: 'Hooli', name: 'fa-hooli' },
  { id: 63596, unicode: 'f86c', label: 'Horizontal Rule', name: 'fa-horizontal-rule' },
  { id: 62866, unicode: 'f592', label: 'Hornbill', name: 'fa-hornbill' },
  { id: 63216, unicode: 'f6f0', label: 'Horse', name: 'fa-horse' },
  { id: 63403, unicode: 'f7ab', label: 'Horse Head', name: 'fa-horse-head' },
  { id: 63683, unicode: 'f8c3', label: 'Horse Saddle', name: 'fa-horse-saddle' },
  { id: 58393, unicode: 'e419', label: 'Hose', name: 'fa-hose' },
  { id: 58394, unicode: 'e41a', label: 'Hose Reel', name: 'fa-hose-reel' },
  { id: 61688, unicode: 'f0f8', label: 'Hospital', name: 'fa-hospital' },
  { id: 63501, unicode: 'f80d', label: 'Hospital With User', name: 'fa-hospital-with-user' },
  { id: 63502, unicode: 'f80e', label: 'Hospitals', name: 'fa-hospitals' },
  { id: 62867, unicode: 'f593', label: 'Hot Tub Person', name: 'fa-hot-tub-person' },
  { id: 63503, unicode: 'f80f', label: 'Hot Dog', name: 'fa-hot-dog' },
  { id: 62868, unicode: 'f594', label: 'Hotel', name: 'fa-hotel' },
  { id: 62385, unicode: 'f3b1', label: 'Hotjar', name: 'fa-hotjar' },
  { id: 62036, unicode: 'f254', label: 'Hourglass', name: 'fa-hourglass' },
  { id: 58395, unicode: 'e41b', label: 'Hourglass Clock', name: 'fa-hourglass-clock' },
  { id: 62035, unicode: 'f253', label: 'Hourglass End', name: 'fa-hourglass-end' },
  { id: 62034, unicode: 'f252', label: 'Hourglass Half', name: 'fa-hourglass-half' },
  { id: 62033, unicode: 'f251', label: 'Hourglass Start', name: 'fa-hourglass-start' },
  { id: 61461, unicode: 'f015', label: 'House', name: 'fa-house' },
  { id: 58503, unicode: 'e487', label: 'House Simple Blank', name: 'fa-house-simple-blank' },
  { id: 57777, unicode: 'e1b1', label: 'House Building', name: 'fa-house-building' },
  { id: 58287, unicode: 'e3af', label: 'House Chimney', name: 'fa-house-chimney' },
  { id: 58288, unicode: 'e3b0', label: 'House Blank', name: 'fa-house-blank' },
  { id: 63217, unicode: 'f6f1', label: 'House Crack', name: 'fa-house-crack' },
  { id: 57778, unicode: 'e1b2', label: 'House Heart', name: 'fa-house-heart' },
  { id: 63474, unicode: 'f7f2', label: 'House Medical', name: 'fa-house-medical' },
  { id: 57445, unicode: 'e065', label: 'House User', name: 'fa-house-user' },
  { id: 57357, unicode: 'e00d', label: 'House With Window   Chimney', name: 'fa-house-with-window---chimney' },
  { id: 58633, unicode: 'e509', label: 'House Circle Check', name: 'fa-house-circle-check' },
  { id: 58634, unicode: 'e50a', label: 'House Circle Exclamation', name: 'fa-house-circle-exclamation' },
  { id: 58635, unicode: 'e50b', label: 'House Circle Xmark', name: 'fa-house-circle-xmark' },
  { id: 58289, unicode: 'e3b1', label: 'House Simple Crack', name: 'fa-house-simple-crack' },
  { id: 57358, unicode: 'e00e', label: 'House Day', name: 'fa-house-day' },
  { id: 58636, unicode: 'e50c', label: 'House Fire', name: 'fa-house-fire' },
  { id: 58637, unicode: 'e50d', label: 'House Flag', name: 'fa-house-flag' },
  { id: 58638, unicode: 'e50e', label: 'House Flood', name: 'fa-house-flood' },
  { id: 58639, unicode: 'e50f', label: 'House Flood Circle Arrow Right', name: 'fa-house-flood-circle-arrow-right' },
  { id: 62665, unicode: 'f4c9', label: 'House Simple Heart', name: 'fa-house-simple-heart' },
  { id: 57446, unicode: 'e066', label: 'House Laptop', name: 'fa-house-laptop' },
  { id: 58640, unicode: 'e510', label: 'House Lock', name: 'fa-house-lock' },
  { id: 58290, unicode: 'e3b2', label: 'House Simple Medical', name: 'fa-house-simple-medical' },
  { id: 58641, unicode: 'e511', label: 'House Medical Circle Check', name: 'fa-house-medical-circle-check' },
  { id: 58642, unicode: 'e512', label: 'House Medical Circle Exclamation', name: 'fa-house-medical-circle-exclamation' },
  { id: 58643, unicode: 'e513', label: 'House Medical Circle Xmark', name: 'fa-house-medical-circle-xmark' },
  { id: 58644, unicode: 'e514', label: 'House Medical Flag', name: 'fa-house-medical-flag' },
  { id: 57360, unicode: 'e010', label: 'House Night', name: 'fa-house-night' },
  { id: 57359, unicode: 'e00f', label: 'House Person Leave', name: 'fa-house-person-leave' },
  { id: 57361, unicode: 'e011', label: 'House Person Return', name: 'fa-house-person-return' },
  { id: 57362, unicode: 'e012', label: 'House Signal', name: 'fa-house-signal' },
  { id: 57779, unicode: 'e1b3', label: 'House Tree', name: 'fa-house-tree' },
  { id: 58645, unicode: 'e515', label: 'House Tsunami', name: 'fa-house-tsunami' },
  { id: 57780, unicode: 'e1b4', label: 'House Turret', name: 'fa-house-turret' },
  { id: 57776, unicode: 'e1b0', label: 'Home User', name: 'fa-home-user' },
  { id: 63311, unicode: 'f74f', label: 'House With Water', name: 'fa-house-with-water' },
  { id: 58291, unicode: 'e3b3', label: 'House Window', name: 'fa-house-window' },
  { id: 62076, unicode: 'f27c', label: 'Houzz', name: 'fa-houzz' },
  { id: 63218, unicode: 'f6f2', label: 'Hryvnia Sign', name: 'fa-hryvnia-sign' },
  { id: 61755, unicode: 'f13b', label: 'HTML 5 Logo', name: 'fa-html-5-logo' },
  { id: 62386, unicode: 'f3b2', label: 'Hubspot', name: 'fa-hubspot' },
  { id: 58396, unicode: 'e41c', label: 'Hundred Points', name: 'fa-hundred-points' },
  { id: 63313, unicode: 'f751', label: 'Hurricane', name: 'fa-hurricane' },
  { id: 45, unicode: '2d', label: 'Hyphen', name: 'fa-hyphen' },
  { id: 73, unicode: '49', label: 'I', name: 'fa-i' },
  { id: 62022, unicode: 'f246', label: 'I Beam Cursor', name: 'fa-i-beam-cursor' },
  { id: 63504, unicode: 'f810', label: 'Ice Cream', name: 'fa-ice-cream' },
  { id: 63404, unicode: 'f7ac', label: 'Ice Skate', name: 'fa-ice-skate' },
  { id: 63405, unicode: 'f7ad', label: 'Icicles', name: 'fa-icicles' },
  { id: 63597, unicode: 'f86d', label: 'Icons', name: 'fa-icons' },
  { id: 62145, unicode: 'f2c1', label: 'Identification Badge', name: 'fa-identification-badge' },
  { id: 62146, unicode: 'f2c2', label: 'Identification Card', name: 'fa-identification-card' },
  { id: 62591, unicode: 'f47f', label: 'Id Card Clip', name: 'fa-id-card-clip' },
  { id: 57363, unicode: 'e013', label: 'Ideal', name: 'fa-ideal' },
  { id: 63406, unicode: 'f7ae', label: 'Igloo', name: 'fa-igloo' },
  { id: 61502, unicode: 'f03e', label: 'Image', name: 'fa-image' },
  { id: 57781, unicode: 'e1b5', label: 'Image Landscape', name: 'fa-image-landscape' },
  { id: 63684, unicode: 'f8c4', label: 'Polaroid Image', name: 'fa-polaroid-image' },
  { id: 57782, unicode: 'e1b6', label: 'Image Polaroid User', name: 'fa-image-polaroid-user' },
  { id: 62432, unicode: 'f3e0', label: 'Image Portrait', name: 'fa-image-portrait' },
  { id: 57783, unicode: 'e1b7', label: 'Image Slash', name: 'fa-image-slash' },
  { id: 57784, unicode: 'e1b8', label: 'Image User', name: 'fa-image-user' },
  { id: 62210, unicode: 'f302', label: 'Images', name: 'fa-images' },
  { id: 57785, unicode: 'e1b9', label: 'Images User', name: 'fa-images-user' },
  { id: 62168, unicode: 'f2d8', label: 'IMDB', name: 'fa-imdb' },
  { id: 61468, unicode: 'f01c', label: 'Inbox', name: 'fa-inbox' },
  { id: 57786, unicode: 'e1ba', label: 'Inbox Full', name: 'fa-inbox-full' },
  { id: 62224, unicode: 'f310', label: 'Inbox In', name: 'fa-inbox-in' },
  { id: 62225, unicode: 'f311', label: 'Inbox Out', name: 'fa-inbox-out' },
  { id: 57787, unicode: 'e1bb', label: 'Inboxes', name: 'fa-inboxes' },
  { id: 61500, unicode: 'f03c', label: 'Indent', name: 'fa-indent' },
  { id: 57788, unicode: 'e1bc', label: 'Indian Rupee Sign', name: 'fa-indian-rupee-sign' },
  { id: 62069, unicode: 'f275', label: 'Industry', name: 'fa-industry' },
  { id: 62387, unicode: 'f3b3', label: 'Industry Windows', name: 'fa-industry-windows' },
  { id: 62772, unicode: 'f534', label: 'Infinity', name: 'fa-infinity' },
  { id: 61737, unicode: 'f129', label: 'Info', name: 'fa-info' },
  { id: 62969, unicode: 'f5f9', label: 'Inhaler', name: 'fa-inhaler' },
  { id: 57789, unicode: 'e1bd', label: 'Input Numeric', name: 'fa-input-numeric' },
  { id: 57790, unicode: 'e1be', label: 'Input Pipe', name: 'fa-input-pipe' },
  { id: 57791, unicode: 'e1bf', label: 'Input Text', name: 'fa-input-text' },
  { id: 61805, unicode: 'f16d', label: 'Instagram', name: 'fa-instagram' },
  { id: 57473, unicode: 'e081', label: 'Instalod', name: 'fa-instalod' },
  { id: 63079, unicode: 'f667', label: 'Integral', name: 'fa-integral' },
  { id: 63407, unicode: 'f7af', label: 'Intercom', name: 'fa-intercom' },
  { id: 62059, unicode: 'f26b', label: 'Internet Explorer', name: 'fa-internet-explorer' },
  { id: 63080, unicode: 'f668', label: 'Intersection', name: 'fa-intersection' },
  { id: 63408, unicode: 'f7b0', label: 'Invision', name: 'fa-invision' },
  { id: 61960, unicode: 'f208', label: 'Ioxhost', name: 'fa-ioxhost' },
  { id: 63505, unicode: 'f811', label: 'Tropical Island', name: 'fa-tropical-island' },
  { id: 61491, unicode: 'f033', label: 'Italic', name: 'fa-italic' },
  { id: 63546, unicode: 'f83a', label: 'Itch Io', name: 'fa-itch-io' },
  { id: 62388, unicode: 'f3b4', label: 'Itunes', name: 'fa-itunes' },
  { id: 62389, unicode: 'f3b5', label: 'Itunes Note', name: 'fa-itunes-note' },
  { id: 74, unicode: '4a', label: 'J', name: 'fa-j' },
  { id: 62222, unicode: 'f30e', label: 'Jack O  Lantern', name: 'fa-jack-o--lantern' },
  { id: 58646, unicode: 'e516', label: 'Jar', name: 'fa-jar' },
  { id: 58647, unicode: 'e517', label: 'Jar Wheat', name: 'fa-jar-wheat' },
  { id: 62692, unicode: 'f4e4', label: 'Java', name: 'fa-java' },
  { id: 63081, unicode: 'f669', label: 'Jedi', name: 'fa-jedi' },
  { id: 62734, unicode: 'f50e', label: 'Jedi Order', name: 'fa-jedi-order' },
  { id: 62390, unicode: 'f3b6', label: 'Jenkis', name: 'fa-jenkis' },
  { id: 61691, unicode: 'f0fb', label: 'Jet Fighter', name: 'fa-jet-fighter' },
  { id: 58648, unicode: 'e518', label: 'Jet Fighter Up', name: 'fa-jet-fighter-up' },
  { id: 63409, unicode: 'f7b1', label: 'Jira', name: 'fa-jira' },
  { id: 62391, unicode: 'f3b7', label: 'Joget', name: 'fa-joget' },
  { id: 62869, unicode: 'f595', label: 'Joint', name: 'fa-joint' },
  { id: 61866, unicode: 'f1aa', label: 'Joomla Logo', name: 'fa-joomla-logo' },
  { id: 63685, unicode: 'f8c5', label: 'Joystick', name: 'fa-joystick' },
  { id: 62392, unicode: 'f3b8', label: 'Javascript  JS ', name: 'fa-javascript--js-' },
  { id: 61900, unicode: 'f1cc', label: 'Jsfiddle', name: 'fa-jsfiddle' },
  { id: 63686, unicode: 'f8c6', label: 'Jug', name: 'fa-jug' },
  { id: 58649, unicode: 'e519', label: 'Jug Detergent', name: 'fa-jug-detergent' },
  { id: 75, unicode: '4b', label: 'K', name: 'fa-k' },
  { id: 63083, unicode: 'f66b', label: 'Kaaba', name: 'fa-kaaba' },
  { id: 62970, unicode: 'f5fa', label: 'Kaggle', name: 'fa-kaggle' },
  { id: 63687, unicode: 'f8c7', label: 'Kazoo', name: 'fa-kazoo' },
  { id: 63599, unicode: 'f86f', label: 'Kerning', name: 'fa-kerning' },
  { id: 61572, unicode: 'f084', label: 'Key', name: 'fa-key' },
  { id: 63219, unicode: 'f6f3', label: 'Key Skeleton', name: 'fa-key-skeleton' },
  { id: 58292, unicode: 'e3b4', label: 'Key Skeleton Left Right', name: 'fa-key-skeleton-left-right' },
  { id: 62709, unicode: 'f4f5', label: 'Keybase', name: 'fa-keybase' },
  { id: 61724, unicode: 'f11c', label: 'Keyboard', name: 'fa-keyboard' },
  { id: 57792, unicode: 'e1c0', label: 'Keyboard Brightness', name: 'fa-keyboard-brightness' },
  { id: 57793, unicode: 'e1c1', label: 'Keyboard Brightness Low', name: 'fa-keyboard-brightness-low' },
  { id: 57794, unicode: 'e1c2', label: 'Keyboard Down', name: 'fa-keyboard-down' },
  { id: 57795, unicode: 'e1c3', label: 'Keyboard Left', name: 'fa-keyboard-left' },
  { id: 62394, unicode: 'f3ba', label: 'Keycdn', name: 'fa-keycdn' },
  { id: 63084, unicode: 'f66c', label: 'Keynote', name: 'fa-keynote' },
  { id: 63085, unicode: 'f66d', label: 'Khanda', name: 'fa-khanda' },
  { id: 62395, unicode: 'f3bb', label: 'Kickstarter', name: 'fa-kickstarter' },
  { id: 62396, unicode: 'f3bc', label: 'Kickstarter K', name: 'fa-kickstarter-k' },
  { id: 62971, unicode: 'f5fb', label: 'Kidneys', name: 'fa-kidneys' },
  { id: 57796, unicode: 'e1c4', label: 'Kip Sign', name: 'fa-kip-sign' },
  { id: 62585, unicode: 'f479', label: 'Kit Medical', name: 'fa-kit-medical' },
  { id: 58650, unicode: 'e51a', label: 'Kitchen Set', name: 'fa-kitchen-set' },
  { id: 63220, unicode: 'f6f4', label: 'Kite', name: 'fa-kite' },
  { id: 62773, unicode: 'f535', label: 'Kiwi Bird', name: 'fa-kiwi-bird' },
  { id: 58124, unicode: 'e30c', label: 'Kiwi Fruit', name: 'fa-kiwi-fruit' },
  { id: 62180, unicode: 'f2e4', label: 'Knife', name: 'fa-knife' },
  { id: 63221, unicode: 'f6f5', label: 'Knife Kitchen', name: 'fa-knife-kitchen' },
  { id: 62511, unicode: 'f42f', label: 'KORVUE', name: 'fa-korvue' },
  { id: 76, unicode: '4c', label: 'L', name: 'fa-l' },
  { id: 58293, unicode: 'e3b5', label: 'Lacrosse Stick', name: 'fa-lacrosse-stick' },
  { id: 58294, unicode: 'e3b6', label: 'Lacrosse Stick   Ball', name: 'fa-lacrosse-stick---ball' },
  { id: 63086, unicode: 'f66e', label: 'Lambda', name: 'fa-lambda' },
  { id: 62666, unicode: 'f4ca', label: 'Lamp', name: 'fa-lamp' },
  { id: 57364, unicode: 'e014', label: 'Lamp Desk', name: 'fa-lamp-desk' },
  { id: 57365, unicode: 'e015', label: 'Lamp Floor', name: 'fa-lamp-floor' },
  { id: 57797, unicode: 'e1c5', label: 'Lamp Street', name: 'fa-lamp-street' },
  { id: 58651, unicode: 'e51b', label: 'Land Mine On', name: 'fa-land-mine-on' },
  { id: 63087, unicode: 'f66f', label: 'Landmark', name: 'fa-landmark' },
  { id: 63314, unicode: 'f752', label: 'Landmark Dome', name: 'fa-landmark-dome' },
  { id: 58652, unicode: 'e51c', label: 'Landmark Flag', name: 'fa-landmark-flag' },
  { id: 61867, unicode: 'f1ab', label: 'Language', name: 'fa-language' },
  { id: 61705, unicode: 'f109', label: 'Laptop', name: 'fa-laptop' },
  { id: 57798, unicode: 'e1c6', label: 'Laptop Arrow Down', name: 'fa-laptop-arrow-down' },
  { id: 62972, unicode: 'f5fc', label: 'Laptop Code', name: 'fa-laptop-code' },
  { id: 58653, unicode: 'e51d', label: 'Laptop File', name: 'fa-laptop-file' },
  { id: 63506, unicode: 'f812', label: 'Laptop Medical', name: 'fa-laptop-medical' },
  { id: 63610, unicode: 'f87a', label: 'Laptop Mobile', name: 'fa-laptop-mobile' },
  { id: 57799, unicode: 'e1c7', label: 'Laptop Slash', name: 'fa-laptop-slash' },
  { id: 62397, unicode: 'f3bd', label: 'Laravel', name: 'fa-laravel' },
  { id: 57800, unicode: 'e1c8', label: 'Lari Sign', name: 'fa-lari-sign' },
  { id: 63688, unicode: 'f8c8', label: 'Lasso', name: 'fa-lasso' },
  { id: 57801, unicode: 'e1c9', label: 'Lasso Sparkles', name: 'fa-lasso-sparkles' },
  { id: 61954, unicode: 'f202', label: 'Last Fm', name: 'fa-last-fm' },
  { id: 62973, unicode: 'f5fd', label: 'Layer Group', name: 'fa-layer-group' },
  { id: 62974, unicode: 'f5fe', label: 'Layer Minus', name: 'fa-layer-minus' },
  { id: 62975, unicode: 'f5ff', label: 'Layer Plus', name: 'fa-layer-plus' },
  { id: 61548, unicode: 'f06c', label: 'Leaf', name: 'fa-leaf' },
  { id: 62667, unicode: 'f4cb', label: 'Leaf With A Heart', name: 'fa-leaf-with-a-heart' },
  { id: 63222, unicode: 'f6f6', label: 'Maple Leaf', name: 'fa-maple-leaf' },
  { id: 63223, unicode: 'f6f7', label: 'Oak Leaf', name: 'fa-oak-leaf' },
  { id: 58397, unicode: 'e41d', label: 'Leafy Green', name: 'fa-leafy-green' },
  { id: 61970, unicode: 'f212', label: 'Leanpub', name: 'fa-leanpub' },
  { id: 62293, unicode: 'f355', label: 'Left', name: 'fa-left' },
  { id: 62280, unicode: 'f348', label: 'Left From Line', name: 'fa-left-from-line' },
  { id: 62218, unicode: 'f30a', label: 'Left Long', name: 'fa-left-long' },
  { id: 58398, unicode: 'e41e', label: 'Left Long To Line', name: 'fa-left-long-to-line' },
  { id: 62263, unicode: 'f337', label: 'Left Right', name: 'fa-left-right' },
  { id: 62283, unicode: 'f34b', label: 'Left To Line', name: 'fa-left-to-line' },
  { id: 61588, unicode: 'f094', label: 'Lemon', name: 'fa-lemon' },
  { id: 62493, unicode: 'f41d', label: 'Less', name: 'fa-less' },
  { id: 60, unicode: '3c', label: 'Less Than', name: 'fa-less-than' },
  { id: 62775, unicode: 'f537', label: 'Less Than Equal To', name: 'fa-less-than-equal-to' },
  { id: 61901, unicode: 'f1cd', label: 'Life Ring', name: 'fa-life-ring' },
  { id: 57366, unicode: 'e016', label: 'Light Ceiling', name: 'fa-light-ceiling' },
  { id: 58399, unicode: 'e41f', label: 'Light Emergency', name: 'fa-light-emergency' },
  { id: 58400, unicode: 'e420', label: 'Light Emergency On', name: 'fa-light-emergency-on' },
  { id: 57367, unicode: 'e017', label: 'Light Switch', name: 'fa-light-switch' },
  { id: 57368, unicode: 'e018', label: 'Light Switch Off', name: 'fa-light-switch-off' },
  { id: 57369, unicode: 'e019', label: 'Light Switch On', name: 'fa-light-switch-on' },
  { id: 61675, unicode: 'f0eb', label: 'Lightbulb', name: 'fa-lightbulb' },
  { id: 58790, unicode: 'e5a6', label: 'Lightbulb CFL', name: 'fa-lightbulb-cfl' },
  { id: 58791, unicode: 'e5a7', label: 'Lightbulb CFL On', name: 'fa-lightbulb-cfl-on' },
  { id: 63088, unicode: 'f670', label: 'Lightbulb Dollar', name: 'fa-lightbulb-dollar' },
  { id: 63089, unicode: 'f671', label: 'Lightbulb Exclamation', name: 'fa-lightbulb-exclamation' },
  { id: 57802, unicode: 'e1ca', label: 'Lightbulb Exclamation On', name: 'fa-lightbulb-exclamation-on' },
  { id: 63090, unicode: 'f672', label: 'Lightbulb On', name: 'fa-lightbulb-on' },
  { id: 63091, unicode: 'f673', label: 'Lightbulb Slash', name: 'fa-lightbulb-slash' },
  { id: 63410, unicode: 'f7b2', label: 'Holiday Lights', name: 'fa-holiday-lights' },
  { id: 62400, unicode: 'f3c0', label: 'Line', name: 'fa-line' },
  { id: 63600, unicode: 'f870', label: 'Line Columns', name: 'fa-line-columns' },
  { id: 63601, unicode: 'f871', label: 'Line Height', name: 'fa-line-height' },
  { id: 58654, unicode: 'e51e', label: 'Lines Leaning', name: 'fa-lines-leaning' },
  { id: 61633, unicode: 'f0c1', label: 'Link', name: 'fa-link' },
  { id: 57803, unicode: 'e1cb', label: 'Link Horizontal', name: 'fa-link-horizontal' },
  { id: 57804, unicode: 'e1cc', label: 'Link Horizontal Slash', name: 'fa-link-horizontal-slash' },
  { id: 57805, unicode: 'e1cd', label: 'Link Simple', name: 'fa-link-simple' },
  { id: 57806, unicode: 'e1ce', label: 'Link Simple Slash', name: 'fa-link-simple-slash' },
  { id: 61735, unicode: 'f127', label: 'Link Slash', name: 'fa-link-slash' },
  { id: 61580, unicode: 'f08c', label: 'Linkedin', name: 'fa-linkedin' },
  { id: 61665, unicode: 'f0e1', label: 'Linkedin In', name: 'fa-linkedin-in' },
  { id: 62136, unicode: 'f2b8', label: 'Linode', name: 'fa-linode' },
  { id: 61820, unicode: 'f17c', label: 'Linux', name: 'fa-linux' },
  { id: 62976, unicode: 'f600', label: 'Lips', name: 'fa-lips' },
  { id: 61845, unicode: 'f195', label: 'Turkish Lira Sign', name: 'fa-turkish-lira-sign' },
  { id: 61498, unicode: 'f03a', label: 'List', name: 'fa-list' },
  { id: 61614, unicode: 'f0ae', label: 'List Check', name: 'fa-list-check' },
  { id: 57807, unicode: 'e1cf', label: 'List Dropdown', name: 'fa-list-dropdown' },
  { id: 63689, unicode: 'f8c9', label: 'List Music', name: 'fa-list-music' },
  { id: 61643, unicode: 'f0cb', label: 'List Ol', name: 'fa-list-ol' },
  { id: 57808, unicode: 'e1d0', label: 'List Radio', name: 'fa-list-radio' },
  { id: 57809, unicode: 'e1d1', label: 'List Timeline', name: 'fa-list-timeline' },
  { id: 57810, unicode: 'e1d2', label: 'List Tree', name: 'fa-list-tree' },
  { id: 61642, unicode: 'f0ca', label: 'List Ul', name: 'fa-list-ul' },
  { id: 57811, unicode: 'e1d3', label: 'Litecoin Sign', name: 'fa-litecoin-sign' },
  { id: 57812, unicode: 'e1d4', label: 'Loader', name: 'fa-loader' },
  { id: 58401, unicode: 'e421', label: 'Lobster', name: 'fa-lobster' },
  { id: 61732, unicode: 'f124', label: 'Location Arrow', name: 'fa-location-arrow' },
  { id: 62982, unicode: 'f606', label: 'Location Check', name: 'fa-location-check' },
  { id: 62977, unicode: 'f601', label: 'Location Crosshairs', name: 'fa-location-crosshairs' },
  { id: 62979, unicode: 'f603', label: 'Location Crosshairs Slash', name: 'fa-location-crosshairs-slash' },
  { id: 62405, unicode: 'f3c5', label: 'Location Dot', name: 'fa-location-dot' },
  { id: 62981, unicode: 'f605', label: 'Location Dot Slash', name: 'fa-location-dot-slash' },
  { id: 62984, unicode: 'f608', label: 'Location Exclamation', name: 'fa-location-exclamation' },
  { id: 62985, unicode: 'f609', label: 'Location Minus', name: 'fa-location-minus' },
  { id: 62983, unicode: 'f607', label: 'Location Pen', name: 'fa-location-pen' },
  { id: 61505, unicode: 'f041', label: 'Location', name: 'fa-location' },
  { id: 58655, unicode: 'e51f', label: 'Location Pin Lock', name: 'fa-location-pin-lock' },
  { id: 62988, unicode: 'f60c', label: 'Location Slash', name: 'fa-location-slash' },
  { id: 62986, unicode: 'f60a', label: 'Location Plus', name: 'fa-location-plus' },
  { id: 62987, unicode: 'f60b', label: 'Location Question', name: 'fa-location-question' },
  { id: 62989, unicode: 'f60d', label: 'Location Smile', name: 'fa-location-smile' },
  { id: 62990, unicode: 'f60e', label: 'Location X Mark', name: 'fa-location-x-mark' },
  { id: 61475, unicode: 'f023', label: 'Lock', name: 'fa-lock' },
  { id: 58402, unicode: 'e422', label: 'Lock A', name: 'fa-lock-a' },
  { id: 58403, unicode: 'e423', label: 'Lock Hashtag', name: 'fa-lock-hashtag' },
  { id: 62221, unicode: 'f30d', label: 'Lock Keyhole', name: 'fa-lock-keyhole' },
  { id: 62402, unicode: 'f3c2', label: 'Lock Keyhole Open', name: 'fa-lock-keyhole-open' },
  { id: 62401, unicode: 'f3c1', label: 'Lock Open', name: 'fa-lock-open' },
  { id: 58656, unicode: 'e520', label: 'Locust', name: 'fa-locust' },
  { id: 58404, unicode: 'e424', label: 'Lollipop', name: 'fa-lollipop' },
  { id: 62668, unicode: 'f4cc', label: 'Loveseat', name: 'fa-loveseat' },
  { id: 62549, unicode: 'f455', label: 'Luchador Mask', name: 'fa-luchador-mask' },
  { id: 62980, unicode: 'f604', label: 'Lungs', name: 'fa-lungs' },
  { id: 57447, unicode: 'e067', label: 'Lungs Virus', name: 'fa-lungs-virus' },
  { id: 62403, unicode: 'f3c3', label: 'Lyft', name: 'fa-lyft' },
  { id: 77, unicode: '4d', label: 'M', name: 'fa-m' },
  { id: 63224, unicode: 'f6f8', label: 'Mace', name: 'fa-mace' },
  { id: 62404, unicode: 'f3c4', label: 'Magento', name: 'fa-magento' },
  { id: 61558, unicode: 'f076', label: 'Magnet', name: 'fa-magnet' },
  { id: 61442, unicode: 'f002', label: 'Magnifying Glass', name: 'fa-magnifying-glass' },
  { id: 58657, unicode: 'e521', label: 'Magnifying Glass Arrow Right', name: 'fa-magnifying-glass-arrow-right' },
  { id: 58658, unicode: 'e522', label: 'Magnifying Glass Chart', name: 'fa-magnifying-glass-chart' },
  { id: 63112, unicode: 'f688', label: 'Magnifying Glass Dollar', name: 'fa-magnifying-glass-dollar' },
  { id: 63113, unicode: 'f689', label: 'Magnifying Glass Location', name: 'fa-magnifying-glass-location' },
  { id: 61456, unicode: 'f010', label: 'Magnifying Glass Minus', name: 'fa-magnifying-glass-minus' },
  { id: 61454, unicode: 'f00e', label: 'Magnifying Glass Plus', name: 'fa-magnifying-glass-plus' },
  { id: 63507, unicode: 'f813', label: 'Mailbox', name: 'fa-mailbox' },
  { id: 62878, unicode: 'f59e', label: 'Mailchimp', name: 'fa-mailchimp' },
  { id: 57813, unicode: 'e1d5', label: 'Manat Sign', name: 'fa-manat-sign' },
  { id: 62735, unicode: 'f50f', label: 'Mandalorian', name: 'fa-mandalorian' },
  { id: 63225, unicode: 'f6f9', label: 'Mandolin', name: 'fa-mandolin' },
  { id: 58127, unicode: 'e30f', label: 'Mango', name: 'fa-mango' },
  { id: 57814, unicode: 'e1d6', label: 'Manhole', name: 'fa-manhole' },
  { id: 62073, unicode: 'f279', label: 'Map', name: 'fa-map' },
  { id: 62879, unicode: 'f59f', label: 'Map Location', name: 'fa-map-location' },
  { id: 62880, unicode: 'f5a0', label: 'Map Location Dot', name: 'fa-map-location-dot' },
  { id: 62070, unicode: 'f276', label: 'Map Pin', name: 'fa-map-pin' },
  { id: 62991, unicode: 'f60f', label: 'Markdown', name: 'fa-markdown' },
  { id: 62881, unicode: 'f5a1', label: 'Marker', name: 'fa-marker' },
  { id: 61986, unicode: 'f222', label: 'Mars', name: 'fa-mars' },
  { id: 61988, unicode: 'f224', label: 'Mars And Venus', name: 'fa-mars-and-venus' },
  { id: 58659, unicode: 'e523', label: 'Mars And Venus Burst', name: 'fa-mars-and-venus-burst' },
  { id: 61991, unicode: 'f227', label: 'Mars Double', name: 'fa-mars-double' },
  { id: 61993, unicode: 'f229', label: 'Mars Stroke', name: 'fa-mars-stroke' },
  { id: 61995, unicode: 'f22b', label: 'Mars Stroke Right', name: 'fa-mars-stroke-right' },
  { id: 61994, unicode: 'f22a', label: 'Mars Stroke Up', name: 'fa-mars-stroke-up' },
  { id: 62843, unicode: 'f57b', label: 'Martini Glass', name: 'fa-martini-glass' },
  { id: 62817, unicode: 'f561', label: 'Martini Glass Citrus', name: 'fa-martini-glass-citrus' },
  { id: 61440, unicode: 'f000', label: 'Martini Glass Empty', name: 'fa-martini-glass-empty' },
  { id: 63226, unicode: 'f6fa', label: 'Mask', name: 'fa-mask' },
  { id: 57815, unicode: 'e1d7', label: 'Face Mask', name: 'fa-face-mask' },
  { id: 58295, unicode: 'e3b7', label: 'Mask Snorkel', name: 'fa-mask-snorkel' },
  { id: 58660, unicode: 'e524', label: 'Mask Ventilator', name: 'fa-mask-ventilator' },
  { id: 63024, unicode: 'f630', label: 'Masks Theater', name: 'fa-masks-theater' },
  { id: 62710, unicode: 'f4f6', label: 'Mastodon', name: 'fa-mastodon' },
  { id: 58661, unicode: 'e525', label: 'Mattress Pillow', name: 'fa-mattress-pillow' },
  { id: 61750, unicode: 'f136', label: 'Maxcdn', name: 'fa-maxcdn' },
  { id: 62238, unicode: 'f31e', label: 'Maximize', name: 'fa-maximize' },
  { id: 63690, unicode: 'f8ca', label: 'Material Design For Bootstrap', name: 'fa-material-design-for-bootstrap' },
  { id: 63508, unicode: 'f814', label: 'Meat', name: 'fa-meat' },
  { id: 62882, unicode: 'f5a2', label: 'Medal', name: 'fa-medal' },
  { id: 62406, unicode: 'f3c6', label: 'Medapps', name: 'fa-medapps' },
  { id: 62010, unicode: 'f23a', label: 'Medium', name: 'fa-medium' },
  { id: 62408, unicode: 'f3c8', label: 'MRT', name: 'fa-mrt' },
  { id: 62176, unicode: 'f2e0', label: 'Meetup', name: 'fa-meetup' },
  { id: 63093, unicode: 'f675', label: 'Megaphone', name: 'fa-megaphone' },
  { id: 62883, unicode: 'f5a3', label: 'Megaport', name: 'fa-megaport' },
  { id: 58128, unicode: 'e310', label: 'Melon', name: 'fa-melon' },
  { id: 58129, unicode: 'e311', label: 'Melon Slice', name: 'fa-melon-slice' },
  { id: 57816, unicode: 'e1d8', label: 'Memo', name: 'fa-memo' },
  { id: 57817, unicode: 'e1d9', label: 'Memo Circle Check', name: 'fa-memo-circle-check' },
  { id: 58522, unicode: 'e49a', label: 'Memo Circle Info', name: 'fa-memo-circle-info' },
  { id: 57818, unicode: 'e1da', label: 'Memo Pad', name: 'fa-memo-pad' },
  { id: 62776, unicode: 'f538', label: 'Memory', name: 'fa-memory' },
  { id: 63411, unicode: 'f7b3', label: 'Mendeley', name: 'fa-mendeley' },
  { id: 63094, unicode: 'f676', label: 'Menorah', name: 'fa-menorah' },
  { id: 61987, unicode: 'f223', label: 'Mercury', name: 'fa-mercury' },
  { id: 58662, unicode: 'e526', label: 'Merge', name: 'fa-merge' },
  { id: 62074, unicode: 'f27a', label: 'Message', name: 'fa-message' },
  { id: 57819, unicode: 'e1db', label: 'Message Arrow Down', name: 'fa-message-arrow-down' },
  { id: 57820, unicode: 'e1dc', label: 'Message Arrow Up', name: 'fa-message-arrow-up' },
  { id: 57821, unicode: 'e1dd', label: 'Message Arrow Up Right', name: 'fa-message-arrow-up-right' },
  { id: 58296, unicode: 'e3b8', label: 'Message Bot', name: 'fa-message-bot' },
  { id: 57822, unicode: 'e1de', label: 'Message Captions', name: 'fa-message-captions' },
  { id: 62626, unicode: 'f4a2', label: 'Message Check', name: 'fa-message-check' },
  { id: 57823, unicode: 'e1df', label: 'Message Code', name: 'fa-message-code' },
  { id: 63056, unicode: 'f650', label: 'Message Dollar', name: 'fa-message-dollar' },
  { id: 62627, unicode: 'f4a3', label: 'Message Dots', name: 'fa-message-dots' },
  { id: 62629, unicode: 'f4a5', label: 'Message Exclamation', name: 'fa-message-exclamation' },
  { id: 57824, unicode: 'e1e0', label: 'Message Image', name: 'fa-message-image' },
  { id: 62630, unicode: 'f4a6', label: 'Message Lines', name: 'fa-message-lines' },
  { id: 63476, unicode: 'f7f4', label: 'Message Medical', name: 'fa-message-medical' },
  { id: 57825, unicode: 'e1e1', label: 'Message Middle', name: 'fa-message-middle' },
  { id: 57826, unicode: 'e1e2', label: 'Message Middle Top', name: 'fa-message-middle-top' },
  { id: 62631, unicode: 'f4a7', label: 'Message Minus', name: 'fa-message-minus' },
  { id: 63663, unicode: 'f8af', label: 'Message Music', name: 'fa-message-music' },
  { id: 62628, unicode: 'f4a4', label: 'Message Pen', name: 'fa-message-pen' },
  { id: 62632, unicode: 'f4a8', label: 'Message Plus', name: 'fa-message-plus' },
  { id: 57827, unicode: 'e1e3', label: 'Message Question', name: 'fa-message-question' },
  { id: 57828, unicode: 'e1e4', label: 'Message Quote', name: 'fa-message-quote' },
  { id: 62633, unicode: 'f4a9', label: 'Message Slash', name: 'fa-message-slash' },
  { id: 62634, unicode: 'f4aa', label: 'Message Smile', name: 'fa-message-smile' },
  { id: 57829, unicode: 'e1e5', label: 'Message Sms', name: 'fa-message-sms' },
  { id: 57830, unicode: 'e1e6', label: 'Message Text', name: 'fa-message-text' },
  { id: 62635, unicode: 'f4ab', label: 'Message X Mark', name: 'fa-message-x-mark' },
  { id: 62646, unicode: 'f4b6', label: 'Messages', name: 'fa-messages' },
  { id: 63058, unicode: 'f652', label: 'Messages Dollar', name: 'fa-messages-dollar' },
  { id: 57831, unicode: 'e1e7', label: 'Messages Question', name: 'fa-messages-question' },
  { id: 58523, unicode: 'e49b', label: 'Meta', name: 'fa-meta' },
  { id: 63315, unicode: 'f753', label: 'Meteor', name: 'fa-meteor' },
  { id: 57832, unicode: 'e1e8', label: 'Meter', name: 'fa-meter' },
  { id: 57833, unicode: 'e1e9', label: 'Meter Bolt', name: 'fa-meter-bolt' },
  { id: 57834, unicode: 'e1ea', label: 'Meter Droplet', name: 'fa-meter-droplet' },
  { id: 57835, unicode: 'e1eb', label: 'Meter Fire', name: 'fa-meter-fire' },
  { id: 57370, unicode: 'e01a', label: 'Micro Blog', name: 'fa-micro-blog' },
  { id: 62171, unicode: 'f2db', label: 'Microchip', name: 'fa-microchip' },
  { id: 57836, unicode: 'e1ec', label: 'Microchip Ai', name: 'fa-microchip-ai' },
  { id: 61744, unicode: 'f130', label: 'Microphone', name: 'fa-microphone' },
  { id: 62409, unicode: 'f3c9', label: 'Microphone Lines', name: 'fa-microphone-lines' },
  { id: 62777, unicode: 'f539', label: 'Microphone Lines Slash', name: 'fa-microphone-lines-slash' },
  { id: 61745, unicode: 'f131', label: 'Microphone Slash', name: 'fa-microphone-slash' },
  { id: 63691, unicode: 'f8cb', label: 'Microphone Stand', name: 'fa-microphone-stand' },
  { id: 62992, unicode: 'f610', label: 'Microscope', name: 'fa-microscope' },
  { id: 62410, unicode: 'f3ca', label: 'Microsoft', name: 'fa-microsoft' },
  { id: 57371, unicode: 'e01b', label: 'Microwave', name: 'fa-microwave' },
  { id: 57837, unicode: 'e1ed', label: 'Mill Sign', name: 'fa-mill-sign' },
  { id: 63372, unicode: 'f78c', label: 'Minimize', name: 'fa-minimize' },
  { id: 61544, unicode: 'f068', label: 'Minus', name: 'fa-minus' },
  { id: 63412, unicode: 'f7b4', label: 'Mistletoe', name: 'fa-mistletoe' },
  { id: 63413, unicode: 'f7b5', label: 'Mitten', name: 'fa-mitten' },
  { id: 62411, unicode: 'f3cb', label: 'Mix', name: 'fa-mix' },
  { id: 62089, unicode: 'f289', label: 'Mixcloud', name: 'fa-mixcloud' },
  { id: 57430, unicode: 'e056', label: 'Mixer', name: 'fa-mixer' },
  { id: 62412, unicode: 'f3cc', label: 'Mizuni', name: 'fa-mizuni' },
  { id: 62414, unicode: 'f3ce', label: 'Mobile', name: 'fa-mobile' },
  { id: 61707, unicode: 'f10b', label: 'Mobile Button', name: 'fa-mobile-button' },
  { id: 57838, unicode: 'e1ee', label: 'Mobile Notch', name: 'fa-mobile-notch' },
  { id: 58663, unicode: 'e527', label: 'Mobile Retro', name: 'fa-mobile-retro' },
  { id: 62415, unicode: 'f3cf', label: 'Mobile Screen', name: 'fa-mobile-screen' },
  { id: 62413, unicode: 'f3cd', label: 'Mobile Screen Button', name: 'fa-mobile-screen-button' },
  { id: 57839, unicode: 'e1ef', label: 'Mobile Signal', name: 'fa-mobile-signal' },
  { id: 57840, unicode: 'e1f0', label: 'Mobile Signal Out', name: 'fa-mobile-signal-out' },
  { id: 62085, unicode: 'f285', label: 'MODX', name: 'fa-modx' },
  { id: 62416, unicode: 'f3d0', label: 'Monero', name: 'fa-monero' },
  { id: 61654, unicode: 'f0d6', label: 'Money Bill', name: 'fa-money-bill' },
  { id: 62417, unicode: 'f3d1', label: 'Money Bill 1', name: 'fa-money-bill-1' },
  { id: 62779, unicode: 'f53b', label: 'Money Bill 1 Wave', name: 'fa-money-bill-1-wave' },
  { id: 57841, unicode: 'e1f1', label: 'Money Bill Simple', name: 'fa-money-bill-simple' },
  { id: 57842, unicode: 'e1f2', label: 'Money Bill Simple Wave', name: 'fa-money-bill-simple-wave' },
  { id: 58664, unicode: 'e528', label: 'Money Bill Transfer', name: 'fa-money-bill-transfer' },
  { id: 58665, unicode: 'e529', label: 'Money Bill Trend Up', name: 'fa-money-bill-trend-up' },
  { id: 62778, unicode: 'f53a', label: 'Wavy Money Bill', name: 'fa-wavy-money-bill' },
  { id: 58666, unicode: 'e52a', label: 'Money Bill Wheat', name: 'fa-money-bill-wheat' },
  { id: 57843, unicode: 'e1f3', label: 'Money Bills', name: 'fa-money-bills' },
  { id: 57844, unicode: 'e1f4', label: 'Money Bills Simple', name: 'fa-money-bills-simple' },
  { id: 62780, unicode: 'f53c', label: 'Money Check', name: 'fa-money-check' },
  { id: 62781, unicode: 'f53d', label: 'Money Check Dollar', name: 'fa-money-check-dollar' },
  { id: 63603, unicode: 'f873', label: 'Money Check Dollar Pen', name: 'fa-money-check-dollar-pen' },
  { id: 63602, unicode: 'f872', label: 'Money Check Pen', name: 'fa-money-check-pen' },
  { id: 58130, unicode: 'e312', label: 'Money From Bracket', name: 'fa-money-from-bracket' },
  { id: 58131, unicode: 'e313', label: 'Money Simple From Bracket', name: 'fa-money-simple-from-bracket' },
  { id: 62993, unicode: 'f611', label: 'Monitor Waveform', name: 'fa-monitor-waveform' },
  { id: 63227, unicode: 'f6fb', label: 'Monkey', name: 'fa-monkey' },
  { id: 62886, unicode: 'f5a6', label: 'Monument', name: 'fa-monument' },
  { id: 61830, unicode: 'f186', label: 'Moon', name: 'fa-moon' },
  { id: 63316, unicode: 'f754', label: 'Moon With Cloud', name: 'fa-moon-with-cloud' },
  { id: 63306, unicode: 'f74a', label: 'Moon Over Sun', name: 'fa-moon-over-sun' },
  { id: 63317, unicode: 'f755', label: 'Moon With Stars', name: 'fa-moon-with-stars' },
  { id: 58297, unicode: 'e3b9', label: 'Moped', name: 'fa-moped' },
  { id: 62887, unicode: 'f5a7', label: 'Mortar Pestle', name: 'fa-mortar-pestle' },
  { id: 63096, unicode: 'f678', label: 'Mosque', name: 'fa-mosque' },
  { id: 58667, unicode: 'e52b', label: 'Mosquito', name: 'fa-mosquito' },
  { id: 58668, unicode: 'e52c', label: 'Mosquito Net', name: 'fa-mosquito-net' },
  { id: 61980, unicode: 'f21c', label: 'Motorcycle', name: 'fa-motorcycle' },
  { id: 58669, unicode: 'e52d', label: 'Mound', name: 'fa-mound' },
  { id: 63228, unicode: 'f6fc', label: 'Mountain', name: 'fa-mountain' },
  { id: 58670, unicode: 'e52e', label: 'Mountain City', name: 'fa-mountain-city' },
  { id: 58671, unicode: 'e52f', label: 'Mountain Sun', name: 'fa-mountain-sun' },
  { id: 63229, unicode: 'f6fd', label: 'Mountains', name: 'fa-mountains' },
  { id: 58792, unicode: 'e5a8', label: 'Mouse', name: 'fa-mouse' },
  { id: 63694, unicode: 'f8ce', label: 'MP3 Player', name: 'fa-mp3-player' },
  { id: 63604, unicode: 'f874', label: 'Mug', name: 'fa-mug' },
  { id: 63414, unicode: 'f7b6', label: 'Mug Hot', name: 'fa-mug-hot' },
  { id: 63415, unicode: 'f7b7', label: 'Mug With Marshmallows', name: 'fa-mug-with-marshmallows' },
  { id: 61684, unicode: 'f0f4', label: 'Mug Saucer', name: 'fa-mug-saucer' },
  { id: 63605, unicode: 'f875', label: 'Mug Tea', name: 'fa-mug-tea' },
  { id: 57845, unicode: 'e1f5', label: 'Mug Tea Saucer', name: 'fa-mug-tea-saucer' },
  { id: 58405, unicode: 'e425', label: 'Mushroom', name: 'fa-mushroom' },
  { id: 61441, unicode: 'f001', label: 'Music', name: 'fa-music' },
  { id: 63695, unicode: 'f8cf', label: 'Music Note', name: 'fa-music-note' },
  { id: 63696, unicode: 'f8d0', label: 'Music Note Slash', name: 'fa-music-note-slash' },
  { id: 63697, unicode: 'f8d1', label: 'Music Slash', name: 'fa-music-slash' },
  { id: 78, unicode: '4e', label: 'N', name: 'fa-n' },
  { id: 57846, unicode: 'e1f6', label: 'Naira Sign', name: 'fa-naira-sign' },
  { id: 62418, unicode: 'f3d2', label: 'Napster', name: 'fa-napster' },
  { id: 63230, unicode: 'f6fe', label: 'Narwhal', name: 'fa-narwhal' },
  { id: 62994, unicode: 'f612', label: 'Neos', name: 'fa-neos' },
  { id: 58298, unicode: 'e3ba', label: 'Nesting Dolls', name: 'fa-nesting-dolls' },
  { id: 63231, unicode: 'f6ff', label: 'Wired Network', name: 'fa-wired-network' },
  { id: 61996, unicode: 'f22c', label: 'Neuter', name: 'fa-neuter' },
  { id: 61930, unicode: 'f1ea', label: 'Newspaper', name: 'fa-newspaper' },
  { id: 57847, unicode: 'e1f7', label: 'NFC', name: 'fa-nfc' },
  { id: 58672, unicode: 'e530', label: 'NFC Directional', name: 'fa-nfc-directional' },
  { id: 57848, unicode: 'e1f8', label: 'NFC Lock', name: 'fa-nfc-lock' },
  { id: 57849, unicode: 'e1f9', label: 'NFC Magnifying Glass', name: 'fa-nfc-magnifying-glass' },
  { id: 57850, unicode: 'e1fa', label: 'NFC Pen', name: 'fa-nfc-pen' },
  { id: 57851, unicode: 'e1fb', label: 'NFC Signal', name: 'fa-nfc-signal' },
  { id: 57852, unicode: 'e1fc', label: 'NFC Slash', name: 'fa-nfc-slash' },
  { id: 58673, unicode: 'e531', label: 'NFC Simplified', name: 'fa-nfc-simplified' },
  { id: 57853, unicode: 'e1fd', label: 'NFC Trash', name: 'fa-nfc-trash' },
  { id: 62888, unicode: 'f5a8', label: 'Nimblr', name: 'fa-nimblr' },
  { id: 62489, unicode: 'f419', label: 'Node Js', name: 'fa-node-js' },
  { id: 62419, unicode: 'f3d3', label: 'Node Js JS', name: 'fa-node-js-js' },
  { id: 62782, unicode: 'f53e', label: 'Not Equal', name: 'fa-not-equal' },
  { id: 57854, unicode: 'e1fe', label: 'Notdef', name: 'fa-notdef' },
  { id: 57855, unicode: 'e1ff', label: 'Note', name: 'fa-note' },
  { id: 57856, unicode: 'e200', label: 'Note Medical', name: 'fa-note-medical' },
  { id: 62025, unicode: 'f249', label: 'Note Sticky', name: 'fa-note-sticky' },
  { id: 57857, unicode: 'e201', label: 'Notebook', name: 'fa-notebook' },
  { id: 57858, unicode: 'e202', label: 'Notes', name: 'fa-notes' },
  { id: 62593, unicode: 'f481', label: 'Medical Notes', name: 'fa-medical-notes' },
  { id: 62420, unicode: 'f3d4', label: 'Npm', name: 'fa-npm' },
  { id: 62421, unicode: 'f3d5', label: 'NS8', name: 'fa-ns8' },
  { id: 62422, unicode: 'f3d6', label: 'Nutritionix', name: 'fa-nutritionix' },
  { id: 79, unicode: '4f', label: 'O', name: 'fa-o' },
  { id: 58524, unicode: 'e49c', label: 'Object Exclude', name: 'fa-object-exclude' },
  { id: 62023, unicode: 'f247', label: 'Object Group', name: 'fa-object-group' },
  { id: 58525, unicode: 'e49d', label: 'Object Intersect', name: 'fa-object-intersect' },
  { id: 58526, unicode: 'e49e', label: 'Object Subtract', name: 'fa-object-subtract' },
  { id: 62024, unicode: 'f248', label: 'Object Ungroup', name: 'fa-object-ungroup' },
  { id: 58527, unicode: 'e49f', label: 'Object Union', name: 'fa-object-union' },
  { id: 58299, unicode: 'e3bb', label: 'Objects Align Bottom', name: 'fa-objects-align-bottom' },
  { id: 58300, unicode: 'e3bc', label: 'Objects Align Center Horizontal', name: 'fa-objects-align-center-horizontal' },
  { id: 58301, unicode: 'e3bd', label: 'Objects Align Center Vertical', name: 'fa-objects-align-center-vertical' },
  { id: 58302, unicode: 'e3be', label: 'Objects Align Left', name: 'fa-objects-align-left' },
  { id: 58303, unicode: 'e3bf', label: 'Objects Align Right', name: 'fa-objects-align-right' },
  { id: 58304, unicode: 'e3c0', label: 'Objects Align Top', name: 'fa-objects-align-top' },
  { id: 58305, unicode: 'e3c1', label: 'Objects Column', name: 'fa-objects-column' },
  { id: 62214, unicode: 'f306', label: 'Octagon', name: 'fa-octagon' },
  { id: 58406, unicode: 'e426', label: 'Octagon Check', name: 'fa-octagon-check' },
  { id: 57859, unicode: 'e203', label: 'Octagon Divide', name: 'fa-octagon-divide' },
  { id: 57860, unicode: 'e204', label: 'Octagon Exclamation', name: 'fa-octagon-exclamation' },
  { id: 62216, unicode: 'f308', label: 'Octagon Minus', name: 'fa-octagon-minus' },
  { id: 62209, unicode: 'f301', label: 'Octagon Plus', name: 'fa-octagon-plus' },
  { id: 62192, unicode: 'f2f0', label: 'Octagon X Mark', name: 'fa-octagon-x-mark' },
  { id: 57474, unicode: 'e082', label: 'Octopus Deploy', name: 'fa-octopus-deploy' },
  { id: 62051, unicode: 'f263', label: 'Odnoklassniki', name: 'fa-odnoklassniki' },
  { id: 62995, unicode: 'f613', label: 'Oil Can', name: 'fa-oil-can' },
  { id: 57861, unicode: 'e205', label: 'Oil Can Drip', name: 'fa-oil-can-drip' },
  { id: 62996, unicode: 'f614', label: 'Oil Temperature', name: 'fa-oil-temperature' },
  { id: 58674, unicode: 'e532', label: 'Oil Well', name: 'fa-oil-well' },
  { id: 62736, unicode: 'f510', label: 'Old Republic', name: 'fa-old-republic' },
  { id: 58134, unicode: 'e316', label: 'Olive', name: 'fa-olive' },
  { id: 58135, unicode: 'e317', label: 'Olive Branch', name: 'fa-olive-branch' },
  { id: 63097, unicode: 'f679', label: 'Om', name: 'fa-om' },
  { id: 63098, unicode: 'f67a', label: 'Omega', name: 'fa-omega' },
  { id: 58407, unicode: 'e427', label: 'Onion', name: 'fa-onion' },
  { id: 62013, unicode: 'f23d', label: 'Opencart', name: 'fa-opencart' },
  { id: 61851, unicode: 'f19b', label: 'Openid', name: 'fa-openid' },
  { id: 62058, unicode: 'f26a', label: 'Opera', name: 'fa-opera' },
  { id: 62012, unicode: 'f23c', label: 'Optin Monster', name: 'fa-optin-monster' },
  { id: 58136, unicode: 'e318', label: 'Option', name: 'fa-option' },
  { id: 63698, unicode: 'f8d2', label: 'ORCID', name: 'fa-orcid' },
  { id: 63416, unicode: 'f7b8', label: 'Ornament', name: 'fa-ornament' },
  { id: 62490, unicode: 'f41a', label: 'Open Source Initiative', name: 'fa-open-source-initiative' },
  { id: 63232, unicode: 'f700', label: 'Otter', name: 'fa-otter' },
  { id: 61499, unicode: 'f03b', label: 'Outdent', name: 'fa-outdent' },
  { id: 57372, unicode: 'e01c', label: 'Outlet', name: 'fa-outlet' },
  { id: 57373, unicode: 'e01d', label: 'Oven', name: 'fa-oven' },
  { id: 63606, unicode: 'f876', label: 'Overline', name: 'fa-overline' },
  { id: 80, unicode: '50', label: 'P', name: 'fa-p' },
  { id: 58528, unicode: 'e4a0', label: 'Padlet', name: 'fa-padlet' },
  { id: 58408, unicode: 'e428', label: 'Page', name: 'fa-page' },
  { id: 58409, unicode: 'e429', label: 'Page Caret Down', name: 'fa-page-caret-down' },
  { id: 58410, unicode: 'e42a', label: 'Page Caret Up', name: 'fa-page-caret-up' },
  { id: 62423, unicode: 'f3d7', label: 'Page4 Corporation', name: 'fa-page4-corporation' },
  { id: 61836, unicode: 'f18c', label: 'Pagelines', name: 'fa-pagelines' },
  { id: 63509, unicode: 'f815', label: 'Pager', name: 'fa-pager' },
  { id: 62890, unicode: 'f5aa', label: 'Paint Roller', name: 'fa-paint-roller' },
  { id: 61948, unicode: 'f1fc', label: 'Paint Brush', name: 'fa-paint-brush' },
  { id: 62889, unicode: 'f5a9', label: 'Paint Brush Fine', name: 'fa-paint-brush-fine' },
  { id: 57862, unicode: 'e206', label: 'Paintbrush Pencil', name: 'fa-paintbrush-pencil' },
  { id: 62783, unicode: 'f53f', label: 'Palette', name: 'fa-palette' },
  { id: 62424, unicode: 'f3d8', label: 'Palfed', name: 'fa-palfed' },
  { id: 62594, unicode: 'f482', label: 'Pallet', name: 'fa-pallet' },
  { id: 57864, unicode: 'e208', label: 'Pallet Box', name: 'fa-pallet-box' },
  { id: 62595, unicode: 'f483', label: 'Pallet With Boxes', name: 'fa-pallet-with-boxes' },
  { id: 58411, unicode: 'e42b', label: 'Pan Food', name: 'fa-pan-food' },
  { id: 58412, unicode: 'e42c', label: 'Pan Frying', name: 'fa-pan-frying' },
  { id: 58413, unicode: 'e42d', label: 'Pancakes', name: 'fa-pancakes' },
  { id: 58414, unicode: 'e42e', label: 'EWS Panel', name: 'fa-ews-panel' },
  { id: 58415, unicode: 'e42f', label: 'Panel Fire', name: 'fa-panel-fire' },
  { id: 57865, unicode: 'e209', label: 'Panorama', name: 'fa-panorama' },
  { id: 61912, unicode: 'f1d8', label: 'Paper Plane', name: 'fa-paper-plane' },
  { id: 57866, unicode: 'e20a', label: 'Paper Plane Top', name: 'fa-paper-plane-top' },
  { id: 61638, unicode: 'f0c6', label: 'Paperclip', name: 'fa-paperclip' },
  { id: 58306, unicode: 'e3c2', label: 'Paperclip Vertical', name: 'fa-paperclip-vertical' },
  { id: 62669, unicode: 'f4cd', label: 'Parachute Box', name: 'fa-parachute-box' },
  { id: 61917, unicode: 'f1dd', label: 'Paragraph', name: 'fa-paragraph' },
  { id: 63608, unicode: 'f878', label: 'Paragraph Left', name: 'fa-paragraph-left' },
  { id: 58138, unicode: 'e31a', label: 'Party Bell', name: 'fa-party-bell' },
  { id: 58139, unicode: 'e31b', label: 'Party Horn', name: 'fa-party-horn' },
  { id: 62891, unicode: 'f5ab', label: 'Passport', name: 'fa-passport' },
  { id: 61674, unicode: 'f0ea', label: 'Paste', name: 'fa-paste' },
  { id: 62425, unicode: 'f3d9', label: 'Patreon', name: 'fa-patreon' },
  { id: 61516, unicode: 'f04c', label: 'Pause', name: 'fa-pause' },
  { id: 61872, unicode: 'f1b0', label: 'Paw', name: 'fa-paw' },
  { id: 63234, unicode: 'f702', label: 'Paw Claws', name: 'fa-paw-claws' },
  { id: 63233, unicode: 'f701', label: 'Paw Simple', name: 'fa-paw-simple' },
  { id: 61933, unicode: 'f1ed', label: 'Paypal', name: 'fa-paypal' },
  { id: 63100, unicode: 'f67c', label: 'Peace', name: 'fa-peace' },
  { id: 57867, unicode: 'e20b', label: 'Peach', name: 'fa-peach' },
  { id: 58416, unicode: 'e430', label: 'Peanut', name: 'fa-peanut' },
  { id: 58417, unicode: 'e431', label: 'Peanuts', name: 'fa-peanuts' },
  { id: 58140, unicode: 'e31c', label: 'Peapod', name: 'fa-peapod' },
  { id: 57868, unicode: 'e20c', label: 'Pear', name: 'fa-pear' },
  { id: 57869, unicode: 'e20d', label: 'Pedestal', name: 'fa-pedestal' },
  { id: 63235, unicode: 'f703', label: 'Pegasus', name: 'fa-pegasus' },
  { id: 62212, unicode: 'f304', label: 'Pen', name: 'fa-pen' },
  { id: 57870, unicode: 'e20e', label: 'Pen Circle', name: 'fa-pen-circle' },
  { id: 62213, unicode: 'f305', label: 'Pen Clip', name: 'fa-pen-clip' },
  { id: 57871, unicode: 'e20f', label: 'Pen Clip Slash', name: 'fa-pen-clip-slash' },
  { id: 62892, unicode: 'f5ac', label: 'Pen Fancy', name: 'fa-pen-fancy' },
  { id: 57872, unicode: 'e210', label: 'Pen Fancy Slash', name: 'fa-pen-fancy-slash' },
  { id: 57873, unicode: 'e211', label: 'Pen Field', name: 'fa-pen-field' },
  { id: 57874, unicode: 'e212', label: 'Pen Line', name: 'fa-pen-line' },
  { id: 62893, unicode: 'f5ad', label: 'Pen Nib', name: 'fa-pen-nib' },
  { id: 58529, unicode: 'e4a1', label: 'Pen Nib Slash', name: 'fa-pen-nib-slash' },
  { id: 63000, unicode: 'f618', label: 'Pen Paintbrush', name: 'fa-pen-paintbrush' },
  { id: 62894, unicode: 'f5ae', label: 'Pen Ruler', name: 'fa-pen-ruler' },
  { id: 57875, unicode: 'e213', label: 'Pen Slash', name: 'fa-pen-slash' },
  { id: 57876, unicode: 'e214', label: 'Pen Swirl', name: 'fa-pen-swirl' },
  { id: 61508, unicode: 'f044', label: 'Pen To Square', name: 'fa-pen-to-square' },
  { id: 62211, unicode: 'f303', label: 'Pencil', name: 'fa-pencil' },
  { id: 57877, unicode: 'e215', label: 'Pencil Slash', name: 'fa-pencil-slash' },
  { id: 57878, unicode: 'e216', label: 'People', name: 'fa-people' },
  { id: 57448, unicode: 'e068', label: 'People Arrows Left Right', name: 'fa-people-arrows-left-right' },
  { id: 62670, unicode: 'f4ce', label: 'People Carry Box', name: 'fa-people-carry-box' },
  { id: 57879, unicode: 'e217', label: 'People Dress', name: 'fa-people-dress' },
  { id: 57880, unicode: 'e218', label: 'People Dress Simple', name: 'fa-people-dress-simple' },
  { id: 58675, unicode: 'e533', label: 'People Group', name: 'fa-people-group' },
  { id: 58676, unicode: 'e534', label: 'People Line', name: 'fa-people-line' },
  { id: 57881, unicode: 'e219', label: 'People Pants', name: 'fa-people-pants' },
  { id: 57882, unicode: 'e21a', label: 'People Pants Simple', name: 'fa-people-pants-simple' },
  { id: 58677, unicode: 'e535', label: 'People Pulling', name: 'fa-people-pulling' },
  { id: 58678, unicode: 'e536', label: 'People Robbery', name: 'fa-people-robbery' },
  { id: 58679, unicode: 'e537', label: 'People Roof', name: 'fa-people-roof' },
  { id: 57883, unicode: 'e21b', label: 'People Simple', name: 'fa-people-simple' },
  { id: 58418, unicode: 'e432', label: 'Pepper', name: 'fa-pepper' },
  { id: 63510, unicode: 'f816', label: 'Hot Pepper', name: 'fa-hot-pepper' },
  { id: 57475, unicode: 'e083', label: 'Perbyte', name: 'fa-perbyte' },
  { id: 37, unicode: '25', label: 'Percent', name: 'fa-percent' },
  { id: 46, unicode: '2e', label: 'Period', name: 'fa-period' },
  { id: 62426, unicode: 'f3da', label: 'Periscope', name: 'fa-periscope' },
  { id: 61827, unicode: 'f183', label: 'Person', name: 'fa-person' },
  { id: 58680, unicode: 'e538', label: 'Person Arrow Down To Line', name: 'fa-person-arrow-down-to-line' },
  { id: 58681, unicode: 'e539', label: 'Person Arrow Up From Line', name: 'fa-person-arrow-up-from-line' },
  { id: 63562, unicode: 'f84a', label: 'Person Biking', name: 'fa-person-biking' },
  { id: 63563, unicode: 'f84b', label: 'Person Biking Mountain', name: 'fa-person-biking-mountain' },
  { id: 63318, unicode: 'f756', label: 'Person Entering Booth', name: 'fa-person-entering-booth' },
  { id: 58682, unicode: 'e53a', label: 'Person Breastfeeding', name: 'fa-person-breastfeeding' },
  { id: 58683, unicode: 'e53b', label: 'Person Burst', name: 'fa-person-burst' },
  { id: 58684, unicode: 'e53c', label: 'Person Cane', name: 'fa-person-cane' },
  { id: 62671, unicode: 'f4cf', label: 'Person Carry Box', name: 'fa-person-carry-box' },
  { id: 58685, unicode: 'e53d', label: 'Person Chalkboard', name: 'fa-person-chalkboard' },
  { id: 58686, unicode: 'e53e', label: 'Person Circle Check', name: 'fa-person-circle-check' },
  { id: 58687, unicode: 'e53f', label: 'Person Circle Exclamation', name: 'fa-person-circle-exclamation' },
  { id: 58688, unicode: 'e540', label: 'Person Circle Minus', name: 'fa-person-circle-minus' },
  { id: 58689, unicode: 'e541', label: 'Person Circle Plus', name: 'fa-person-circle-plus' },
  { id: 58690, unicode: 'e542', label: 'Person Circle Question', name: 'fa-person-circle-question' },
  { id: 58691, unicode: 'e543', label: 'Person Circle Xmark', name: 'fa-person-circle-xmark' },
  { id: 63582, unicode: 'f85e', label: 'Person Digging', name: 'fa-person-digging' },
  { id: 62672, unicode: 'f4d0', label: 'Person And Dolly', name: 'fa-person-and-dolly' },
  { id: 62673, unicode: 'f4d1', label: 'Person And Empty Dolly', name: 'fa-person-and-empty-dolly' },
  { id: 62576, unicode: 'f470', label: 'Person Dots From Line', name: 'fa-person-dots-from-line' },
  { id: 61826, unicode: 'f182', label: 'Person Dress', name: 'fa-person-dress' },
  { id: 58692, unicode: 'e544', label: 'Person Dress Burst', name: 'fa-person-dress-burst' },
  { id: 57884, unicode: 'e21c', label: 'Person Dress Simple', name: 'fa-person-dress-simple' },
  { id: 58693, unicode: 'e545', label: 'Person Drowning', name: 'fa-person-drowning' },
  { id: 58694, unicode: 'e546', label: 'Person Falling', name: 'fa-person-falling' },
  { id: 58695, unicode: 'e547', label: 'Person Falling Burst', name: 'fa-person-falling-burst' },
  { id: 57379, unicode: 'e023', label: 'Person From Portal', name: 'fa-person-from-portal' },
  { id: 58696, unicode: 'e548', label: 'Person Half Dress', name: 'fa-person-half-dress' },
  { id: 58697, unicode: 'e549', label: 'Person Harassing', name: 'fa-person-harassing' },
  { id: 63212, unicode: 'f6ec', label: 'Person Hiking', name: 'fa-person-hiking' },
  { id: 58698, unicode: 'e54a', label: 'Person Military Pointing', name: 'fa-person-military-pointing' },
  { id: 58699, unicode: 'e54b', label: 'Person Military Rifle', name: 'fa-person-military-rifle' },
  { id: 58700, unicode: 'e54c', label: 'Person Military To Person', name: 'fa-person-military-to-person' },
  { id: 57885, unicode: 'e21d', label: 'Person Pinball', name: 'fa-person-pinball' },
  { id: 63107, unicode: 'f683', label: 'Person Praying', name: 'fa-person-praying' },
  { id: 58142, unicode: 'e31e', label: 'Person Pregnant', name: 'fa-person-pregnant' },
  { id: 58701, unicode: 'e54d', label: 'Person Rays', name: 'fa-person-rays' },
  { id: 58702, unicode: 'e54e', label: 'Person Rifle', name: 'fa-person-rifle' },
  { id: 63244, unicode: 'f70c', label: 'Person Running', name: 'fa-person-running' },
  { id: 57886, unicode: 'e21e', label: 'Person In A Seat', name: 'fa-person-in-a-seat' },
  { id: 57887, unicode: 'e21f', label: 'Person Seat Reclined', name: 'fa-person-seat-reclined' },
  { id: 58703, unicode: 'e54f', label: 'Person Shelter', name: 'fa-person-shelter' },
  { id: 63319, unicode: 'f757', label: 'Person Holding Sign', name: 'fa-person-holding-sign' },
  { id: 57888, unicode: 'e220', label: 'Person Simple', name: 'fa-person-simple' },
  { id: 63429, unicode: 'f7c5', label: 'Person Skating', name: 'fa-person-skating' },
  { id: 63431, unicode: 'f7c7', label: 'Person Ski Jumping', name: 'fa-person-ski-jumping' },
  { id: 63432, unicode: 'f7c8', label: 'Person Ski Lift', name: 'fa-person-ski-lift' },
  { id: 63433, unicode: 'f7c9', label: 'Person Skiing', name: 'fa-person-skiing' },
  { id: 63434, unicode: 'f7ca', label: 'Person Skiing Nordic', name: 'fa-person-skiing-nordic' },
  { id: 63435, unicode: 'f7cb', label: 'Person Sledding', name: 'fa-person-sledding' },
  { id: 63438, unicode: 'f7ce', label: 'Person Snowboarding', name: 'fa-person-snowboarding' },
  { id: 63441, unicode: 'f7d1', label: 'Person Snowmobiling', name: 'fa-person-snowmobiling' },
  { id: 62916, unicode: 'f5c4', label: 'Person Swimming', name: 'fa-person-swimming' },
  { id: 58793, unicode: 'e5a9', label: 'Person Through Window', name: 'fa-person-through-window' },
  { id: 58419, unicode: 'e433', label: 'Person To Door', name: 'fa-person-to-door' },
  { id: 57378, unicode: 'e022', label: 'Person To Portal', name: 'fa-person-to-portal' },
  { id: 62804, unicode: 'f554', label: 'Person Walking', name: 'fa-person-walking' },
  { id: 58705, unicode: 'e551', label: 'Person Walking Arrow Loop Left', name: 'fa-person-walking-arrow-loop-left' },
  { id: 58706, unicode: 'e552', label: 'Person Walking Arrow Right', name: 'fa-person-walking-arrow-right' },
  { id: 58707, unicode: 'e553', label: 'Person Walking Dashed Line Arrow Right', name: 'fa-person-walking-dashed-line-arrow-right' },
  { id: 58708, unicode: 'e554', label: 'Person Walking Luggage', name: 'fa-person-walking-luggage' },
  { id: 62109, unicode: 'f29d', label: 'Person Walking With Cane', name: 'fa-person-walking-with-cane' },
  { id: 57889, unicode: 'e221', label: 'Peseta Sign', name: 'fa-peseta-sign' },
  { id: 57890, unicode: 'e222', label: 'Peso Sign', name: 'fa-peso-sign' },
  { id: 62427, unicode: 'f3db', label: 'Phabricator', name: 'fa-phabricator' },
  { id: 62428, unicode: 'f3dc', label: 'Phoenix Framework', name: 'fa-phoenix-framework' },
  { id: 62737, unicode: 'f511', label: 'Phoenix Squadron', name: 'fa-phoenix-squadron' },
  { id: 61589, unicode: 'f095', label: 'Phone', name: 'fa-phone' },
  { id: 57891, unicode: 'e223', label: 'Phone Arrow Down Left', name: 'fa-phone-arrow-down-left' },
  { id: 57892, unicode: 'e224', label: 'Phone Arrow Up Right', name: 'fa-phone-arrow-up-right' },
  { id: 63609, unicode: 'f879', label: 'Phone Flip', name: 'fa-phone-flip' },
  { id: 57893, unicode: 'e225', label: 'Phone Hangup', name: 'fa-phone-hangup' },
  { id: 58420, unicode: 'e434', label: 'Phone Intercom', name: 'fa-phone-intercom' },
  { id: 57894, unicode: 'e226', label: 'Phone Missed', name: 'fa-phone-missed' },
  { id: 63101, unicode: 'f67d', label: 'Office Phone', name: 'fa-office-phone' },
  { id: 62674, unicode: 'f4d2', label: 'Phone Plus', name: 'fa-phone-plus' },
  { id: 63699, unicode: 'f8d3', label: 'Rotary Phone', name: 'fa-rotary-phone' },
  { id: 62429, unicode: 'f3dd', label: 'Phone Slash', name: 'fa-phone-slash' },
  { id: 62112, unicode: 'f2a0', label: 'Phone Volume', name: 'fa-phone-volume' },
  { id: 57895, unicode: 'e227', label: 'Phone Xmark', name: 'fa-phone-xmark' },
  { id: 63612, unicode: 'f87c', label: 'Photo Film', name: 'fa-photo-film' },
  { id: 57896, unicode: 'e228', label: 'Photo Film Music', name: 'fa-photo-film-music' },
  { id: 62551, unicode: 'f457', label: 'PHP', name: 'fa-php' },
  { id: 63102, unicode: 'f67e', label: 'Pi', name: 'fa-pi' },
  { id: 63700, unicode: 'f8d4', label: 'Piano', name: 'fa-piano' },
  { id: 63701, unicode: 'f8d5', label: 'Piano Keyboard', name: 'fa-piano-keyboard' },
  { id: 58421, unicode: 'e435', label: 'Pickleball', name: 'fa-pickleball' },
  { id: 63237, unicode: 'f705', label: 'Pie', name: 'fa-pie' },
  { id: 62126, unicode: 'f2ae', label: 'Pied Piper Logo', name: 'fa-pied-piper-logo' },
  { id: 61864, unicode: 'f1a8', label: 'Alternate Pied Piper Logo  Old ', name: 'fa-alternate-pied-piper-logo--old-' },
  { id: 62693, unicode: 'f4e5', label: 'Pied Piper Hat  Old ', name: 'fa-pied-piper-hat--old-' },
  { id: 61863, unicode: 'f1a7', label: 'Pied Piper PP Logo  Old ', name: 'fa-pied-piper-pp-logo--old-' },
  { id: 63238, unicode: 'f706', label: 'Pig', name: 'fa-pig' },
  { id: 62675, unicode: 'f4d3', label: 'Piggy Bank', name: 'fa-piggy-bank' },
  { id: 62596, unicode: 'f484', label: 'Pills', name: 'fa-pills' },
  { id: 58307, unicode: 'e3c3', label: 'Pinata', name: 'fa-pinata' },
  { id: 57897, unicode: 'e229', label: 'Pinball', name: 'fa-pinball' },
  { id: 58143, unicode: 'e31f', label: 'Pineapple', name: 'fa-pineapple' },
  { id: 61650, unicode: 'f0d2', label: 'Pinterest', name: 'fa-pinterest' },
  { id: 62001, unicode: 'f231', label: 'Pinterest P', name: 'fa-pinterest-p' },
  { id: 124, unicode: '7c', label: 'Pipe', name: 'fa-pipe' },
  { id: 58422, unicode: 'e436', label: 'Pipe Circle Check', name: 'fa-pipe-circle-check' },
  { id: 58423, unicode: 'e437', label: 'Pipe Collar', name: 'fa-pipe-collar' },
  { id: 58424, unicode: 'e438', label: 'Pipe Section', name: 'fa-pipe-section' },
  { id: 58308, unicode: 'e3c4', label: 'Pipe Smoking', name: 'fa-pipe-smoking' },
  { id: 58425, unicode: 'e439', label: 'Pipe Valve', name: 'fa-pipe-valve' },
  { id: 58426, unicode: 'e43a', label: 'Pix', name: 'fa-pix' },
  { id: 63511, unicode: 'f817', label: 'Pizza', name: 'fa-pizza' },
  { id: 63512, unicode: 'f818', label: 'Pizza Slice', name: 'fa-pizza-slice' },
  { id: 63103, unicode: 'f67f', label: 'Place Of Worship', name: 'fa-place-of-worship' },
  { id: 61554, unicode: 'f072', label: 'Plane', name: 'fa-plane' },
  { id: 62895, unicode: 'f5af', label: 'Plane Arrival', name: 'fa-plane-arrival' },
  { id: 58709, unicode: 'e555', label: 'Plane Circle Check', name: 'fa-plane-circle-check' },
  { id: 58710, unicode: 'e556', label: 'Plane Circle Exclamation', name: 'fa-plane-circle-exclamation' },
  { id: 58711, unicode: 'e557', label: 'Plane Circle Xmark', name: 'fa-plane-circle-xmark' },
  { id: 62896, unicode: 'f5b0', label: 'Plane Departure', name: 'fa-plane-departure' },
  { id: 62430, unicode: 'f3de', label: 'Plane Engines', name: 'fa-plane-engines' },
  { id: 58712, unicode: 'e558', label: 'Plane Lock', name: 'fa-plane-lock' },
  { id: 57899, unicode: 'e22b', label: 'Plane Prop', name: 'fa-plane-prop' },
  { id: 57449, unicode: 'e069', label: 'Plane Slash', name: 'fa-plane-slash' },
  { id: 57900, unicode: 'e22c', label: 'Plane Tail', name: 'fa-plane-tail' },
  { id: 57901, unicode: 'e22d', label: 'Plane Up', name: 'fa-plane-up' },
  { id: 57902, unicode: 'e22e', label: 'Plane Up Slash', name: 'fa-plane-up-slash' },
  { id: 57375, unicode: 'e01f', label: 'Planet With Moon', name: 'fa-planet-with-moon' },
  { id: 57376, unicode: 'e020', label: 'Ringed Planet', name: 'fa-ringed-planet' },
  { id: 58794, unicode: 'e5aa', label: 'Plant Wilt', name: 'fa-plant-wilt' },
  { id: 58427, unicode: 'e43b', label: 'Plate Utensils', name: 'fa-plate-utensils' },
  { id: 58714, unicode: 'e55a', label: 'Plate Wheat', name: 'fa-plate-wheat' },
  { id: 61515, unicode: 'f04b', label: 'Play', name: 'fa-play' },
  { id: 57903, unicode: 'e22f', label: 'Play Pause', name: 'fa-play-pause' },
  { id: 62431, unicode: 'f3df', label: 'Playstation', name: 'fa-playstation' },
  { id: 61926, unicode: 'f1e6', label: 'Plug', name: 'fa-plug' },
  { id: 58715, unicode: 'e55b', label: 'Plug Circle Bolt', name: 'fa-plug-circle-bolt' },
  { id: 58716, unicode: 'e55c', label: 'Plug Circle Check', name: 'fa-plug-circle-check' },
  { id: 58717, unicode: 'e55d', label: 'Plug Circle Exclamation', name: 'fa-plug-circle-exclamation' },
  { id: 58718, unicode: 'e55e', label: 'Plug Circle Minus', name: 'fa-plug-circle-minus' },
  { id: 58719, unicode: 'e55f', label: 'Plug Circle Plus', name: 'fa-plug-circle-plus' },
  { id: 58720, unicode: 'e560', label: 'Plug Circle Xmark', name: 'fa-plug-circle-xmark' },
  { id: 43, unicode: '2b', label: 'Plus', name: 'fa-plus' },
  { id: 58782, unicode: 'e59e', label: 'Plus Large', name: 'fa-plus-large' },
  { id: 58428, unicode: 'e43c', label: 'Plus Minus', name: 'fa-plus-minus' },
  { id: 62158, unicode: 'f2ce', label: 'Podcast', name: 'fa-podcast' },
  { id: 63104, unicode: 'f680', label: 'Podium', name: 'fa-podium' },
  { id: 63320, unicode: 'f758', label: 'Podium With Star', name: 'fa-podium-with-star' },
  { id: 57377, unicode: 'e021', label: 'Police Box', name: 'fa-police-box' },
  { id: 63321, unicode: 'f759', label: 'Poll People', name: 'fa-poll-people' },
  { id: 58429, unicode: 'e43d', label: 'Pompebled', name: 'fa-pompebled' },
  { id: 62206, unicode: 'f2fe', label: 'Poo', name: 'fa-poo' },
  { id: 63322, unicode: 'f75a', label: 'Poo Bolt', name: 'fa-poo-bolt' },
  { id: 58309, unicode: 'e3c5', label: 'Pool 8 Ball', name: 'fa-pool-8-ball' },
  { id: 63001, unicode: 'f619', label: 'Poop', name: 'fa-poop' },
  { id: 63513, unicode: 'f819', label: 'Popcorn', name: 'fa-popcorn' },
  { id: 58430, unicode: 'e43e', label: 'Popsicle', name: 'fa-popsicle' },
  { id: 58431, unicode: 'e43f', label: 'Pot Food', name: 'fa-pot-food' },
  { id: 58432, unicode: 'e440', label: 'Potato', name: 'fa-potato' },
  { id: 61457, unicode: 'f011', label: 'Power Off', name: 'fa-power-off' },
  { id: 62897, unicode: 'f5b1', label: 'Prescription', name: 'fa-prescription' },
  { id: 62597, unicode: 'f485', label: 'Prescription Bottle', name: 'fa-prescription-bottle' },
  { id: 62598, unicode: 'f486', label: 'Prescription Bottle Medical', name: 'fa-prescription-bottle-medical' },
  { id: 63109, unicode: 'f685', label: 'Presentation Screen', name: 'fa-presentation-screen' },
  { id: 58433, unicode: 'e441', label: 'Pretzel', name: 'fa-pretzel' },
  { id: 61487, unicode: 'f02f', label: 'Print', name: 'fa-print' },
  { id: 63514, unicode: 'f81a', label: 'Print Magnifying Glass', name: 'fa-print-magnifying-glass' },
  { id: 63110, unicode: 'f686', label: 'Print Slash', name: 'fa-print-slash' },
  { id: 62088, unicode: 'f288', label: 'Product Hunt', name: 'fa-product-hunt' },
  { id: 63702, unicode: 'f8d6', label: 'Projector', name: 'fa-projector' },
  { id: 58434, unicode: 'e442', label: 'Pump', name: 'fa-pump' },
  { id: 57450, unicode: 'e06a', label: 'Pump Medical', name: 'fa-pump-medical' },
  { id: 57451, unicode: 'e06b', label: 'Pump Soap', name: 'fa-pump-soap' },
  { id: 63239, unicode: 'f707', label: 'Pumpkin', name: 'fa-pumpkin' },
  { id: 62433, unicode: 'f3e1', label: 'Pushed', name: 'fa-pushed' },
  { id: 58435, unicode: 'e443', label: 'Puzzle', name: 'fa-puzzle' },
  { id: 61742, unicode: 'f12e', label: 'Puzzle Piece', name: 'fa-puzzle-piece' },
  { id: 57905, unicode: 'e231', label: 'Puzzle Piece Simple', name: 'fa-puzzle-piece-simple' },
  { id: 62434, unicode: 'f3e2', label: 'Python', name: 'fa-python' },
  { id: 81, unicode: '51', label: 'Q', name: 'fa-q' },
  { id: 61910, unicode: 'f1d6', label: 'QQ', name: 'fa-qq' },
  { id: 61481, unicode: 'f029', label: 'Qrcode', name: 'fa-qrcode' },
  { id: 63, unicode: '3f', label: 'Question', name: 'fa-question' },
  { id: 62553, unicode: 'f459', label: 'Quinscape', name: 'fa-quinscape' },
  { id: 62148, unicode: 'f2c4', label: 'Quora', name: 'fa-quora' },
  { id: 61709, unicode: 'f10d', label: 'Quote Left', name: 'fa-quote-left' },
  { id: 61710, unicode: 'f10e', label: 'Quote Right', name: 'fa-quote-right' },
  { id: 57908, unicode: 'e234', label: 'Quotes', name: 'fa-quotes' },
  { id: 82, unicode: '52', label: 'R', name: 'fa-r' },
  { id: 62711, unicode: 'f4f7', label: 'R Project', name: 'fa-r-project' },
  { id: 63240, unicode: 'f708', label: 'Rabbit', name: 'fa-rabbit' },
  { id: 63241, unicode: 'f709', label: 'Rabbit Running', name: 'fa-rabbit-running' },
  { id: 62554, unicode: 'f45a', label: 'Racquet', name: 'fa-racquet' },
  { id: 57380, unicode: 'e024', label: 'Radar', name: 'fa-radar' },
  { id: 63417, unicode: 'f7b9', label: 'Radiation', name: 'fa-radiation' },
  { id: 63703, unicode: 'f8d7', label: 'Radio', name: 'fa-radio' },
  { id: 63704, unicode: 'f8d8', label: 'Radio Tuner', name: 'fa-radio-tuner' },
  { id: 63323, unicode: 'f75b', label: 'Rainbow', name: 'fa-rainbow' },
  { id: 63324, unicode: 'f75c', label: 'Raindrops', name: 'fa-raindrops' },
  { id: 63242, unicode: 'f70a', label: 'Ram', name: 'fa-ram' },
  { id: 62676, unicode: 'f4d4', label: 'Ramp Loading', name: 'fa-ramp-loading' },
  { id: 58721, unicode: 'e561', label: 'Ranking Star', name: 'fa-ranking-star' },
  { id: 63419, unicode: 'f7bb', label: 'Raspberry Pi', name: 'fa-raspberry-pi' },
  { id: 62169, unicode: 'f2d9', label: 'Ravelry', name: 'fa-ravelry' },
  { id: 57381, unicode: 'e025', label: 'Raygun', name: 'fa-raygun' },
  { id: 62491, unicode: 'f41b', label: 'React', name: 'fa-react' },
  { id: 63325, unicode: 'f75d', label: 'Reacteurope', name: 'fa-reacteurope' },
  { id: 62677, unicode: 'f4d5', label: 'Readme', name: 'fa-readme' },
  { id: 61904, unicode: 'f1d0', label: 'Rebel Alliance', name: 'fa-rebel-alliance' },
  { id: 62787, unicode: 'f543', label: 'Receipt', name: 'fa-receipt' },
  { id: 63705, unicode: 'f8d9', label: 'Record Vinyl', name: 'fa-record-vinyl' },
  { id: 62202, unicode: 'f2fa', label: 'Rectangle', name: 'fa-rectangle' },
  { id: 63041, unicode: 'f641', label: 'Rectangle Ad', name: 'fa-rectangle-ad' },
  { id: 62563, unicode: 'f463', label: 'Rectangle Barcode', name: 'fa-rectangle-barcode' },
  { id: 58146, unicode: 'e322', label: 'Rectangle Code', name: 'fa-rectangle-code' },
  { id: 58530, unicode: 'e4a2', label: 'Rectangle History', name: 'fa-rectangle-history' },
  { id: 58531, unicode: 'e4a3', label: 'Rectangle History Circle Plus', name: 'fa-rectangle-history-circle-plus' },
  { id: 58532, unicode: 'e4a4', label: 'Rectangle History Circle User', name: 'fa-rectangle-history-circle-user' },
  { id: 61474, unicode: 'f022', label: 'Rectangle List', name: 'fa-rectangle-list' },
  { id: 57909, unicode: 'e235', label: 'Rectangle Pro', name: 'fa-rectangle-pro' },
  { id: 57910, unicode: 'e236', label: 'Rectangle Terminal', name: 'fa-rectangle-terminal' },
  { id: 62203, unicode: 'f2fb', label: 'Rectangle Vertical', name: 'fa-rectangle-vertical' },
  { id: 57911, unicode: 'e237', label: 'Rectangle Vertical History', name: 'fa-rectangle-vertical-history' },
  { id: 62204, unicode: 'f2fc', label: 'Wide Rectangle', name: 'fa-wide-rectangle' },
  { id: 62480, unicode: 'f410', label: 'Rectangle X Mark', name: 'fa-rectangle-x-mark' },
  { id: 58147, unicode: 'e323', label: 'Rectangles Mixed', name: 'fa-rectangles-mixed' },
  { id: 61880, unicode: 'f1b8', label: 'Recycle', name: 'fa-recycle' },
  { id: 62435, unicode: 'f3e3', label: 'Red River', name: 'fa-red-river' },
  { id: 61857, unicode: 'f1a1', label: 'Reddit Logo', name: 'fa-reddit-logo' },
  { id: 62081, unicode: 'f281', label: 'Reddit Alien', name: 'fa-reddit-alien' },
  { id: 63420, unicode: 'f7bc', label: 'Redhat', name: 'fa-redhat' },
  { id: 57912, unicode: 'e238', label: 'Reel', name: 'fa-reel' },
  { id: 57382, unicode: 'e026', label: 'Refrigerator', name: 'fa-refrigerator' },
  { id: 62045, unicode: 'f25d', label: 'Registered Trademark', name: 'fa-registered-trademark' },
  { id: 61835, unicode: 'f18b', label: 'Renren', name: 'fa-renren' },
  { id: 62307, unicode: 'f363', label: 'Repeat', name: 'fa-repeat' },
  { id: 62309, unicode: 'f365', label: 'Repeat 1', name: 'fa-repeat-1' },
  { id: 62437, unicode: 'f3e5', label: 'Reply', name: 'fa-reply' },
  { id: 61730, unicode: 'f122', label: 'Reply All', name: 'fa-reply-all' },
  { id: 57913, unicode: 'e239', label: 'Reply Clock', name: 'fa-reply-clock' },
  { id: 62438, unicode: 'f3e6', label: 'Replyd', name: 'fa-replyd' },
  { id: 63326, unicode: 'f75e', label: 'Republican', name: 'fa-republican' },
  { id: 62712, unicode: 'f4f8', label: 'Researchgate', name: 'fa-researchgate' },
  { id: 62439, unicode: 'f3e7', label: 'Resolving', name: 'fa-resolving' },
  { id: 63421, unicode: 'f7bd', label: 'Restroom', name: 'fa-restroom' },
  { id: 57914, unicode: 'e23a', label: 'Restroom Simple', name: 'fa-restroom-simple' },
  { id: 61561, unicode: 'f079', label: 'Retweet', name: 'fa-retweet' },
  { id: 62898, unicode: 'f5b2', label: 'Rev Io', name: 'fa-rev-io' },
  { id: 57915, unicode: 'e23b', label: 'Rhombus', name: 'fa-rhombus' },
  { id: 62678, unicode: 'f4d6', label: 'Ribbon', name: 'fa-ribbon' },
  { id: 62294, unicode: 'f356', label: 'Right', name: 'fa-right' },
  { id: 62197, unicode: 'f2f5', label: 'Right From Bracket', name: 'fa-right-from-bracket' },
  { id: 62279, unicode: 'f347', label: 'Right From Line', name: 'fa-right-from-line' },
  { id: 62306, unicode: 'f362', label: 'Right Left', name: 'fa-right-left' },
  { id: 62219, unicode: 'f30b', label: 'Right Long', name: 'fa-right-long' },
  { id: 58436, unicode: 'e444', label: 'Right Long To Line', name: 'fa-right-long-to-line' },
  { id: 62198, unicode: 'f2f6', label: 'Right To Bracket', name: 'fa-right-to-bracket' },
  { id: 62284, unicode: 'f34c', label: 'Right To Line', name: 'fa-right-to-line' },
  { id: 63243, unicode: 'f70b', label: 'Ring', name: 'fa-ring' },
  { id: 58795, unicode: 'e5ab', label: 'Ring Diamond', name: 'fa-ring-diamond' },
  { id: 63515, unicode: 'f81b', label: 'Rings Wedding', name: 'fa-rings-wedding' },
  { id: 61464, unicode: 'f018', label: 'Road', name: 'fa-road' },
  { id: 58722, unicode: 'e562', label: 'Road Barrier', name: 'fa-road-barrier' },
  { id: 58723, unicode: 'e563', label: 'Road Bridge', name: 'fa-road-bridge' },
  { id: 58724, unicode: 'e564', label: 'Road Circle Check', name: 'fa-road-circle-check' },
  { id: 58725, unicode: 'e565', label: 'Road Circle Exclamation', name: 'fa-road-circle-exclamation' },
  { id: 58726, unicode: 'e566', label: 'Road Circle Xmark', name: 'fa-road-circle-xmark' },
  { id: 58727, unicode: 'e567', label: 'Road Lock', name: 'fa-road-lock' },
  { id: 58728, unicode: 'e568', label: 'Road Spikes', name: 'fa-road-spikes' },
  { id: 62788, unicode: 'f544', label: 'Robot', name: 'fa-robot' },
  { id: 58066, unicode: 'e2d2', label: 'Robot Astromech', name: 'fa-robot-astromech' },
  { id: 61749, unicode: 'f135', label: 'Rocket', name: 'fa-rocket' },
  { id: 57383, unicode: 'e027', label: 'Rocket Launch', name: 'fa-rocket-launch' },
  { id: 62440, unicode: 'f3e8', label: 'Rocket Chat', name: 'fa-rocket-chat' },
  { id: 62441, unicode: 'f3e9', label: 'Rockrms', name: 'fa-rockrms' },
  { id: 58148, unicode: 'e324', label: 'Roller Coaster', name: 'fa-roller-coaster' },
  { id: 62193, unicode: 'f2f1', label: 'Rotate', name: 'fa-rotate' },
  { id: 57916, unicode: 'e23c', label: 'Rotate Exclamation', name: 'fa-rotate-exclamation' },
  { id: 62186, unicode: 'f2ea', label: 'Rotate Left', name: 'fa-rotate-left' },
  { id: 62201, unicode: 'f2f9', label: 'Rotate Right', name: 'fa-rotate-right' },
  { id: 62679, unicode: 'f4d7', label: 'Route', name: 'fa-route' },
  { id: 63002, unicode: 'f61a', label: 'Route Highway', name: 'fa-route-highway' },
  { id: 63003, unicode: 'f61b', label: 'Route Interstate', name: 'fa-route-interstate' },
  { id: 63706, unicode: 'f8da', label: 'Router', name: 'fa-router' },
  { id: 61598, unicode: 'f09e', label: 'Rss', name: 'fa-rss' },
  { id: 61784, unicode: 'f158', label: 'Ruble Sign', name: 'fa-ruble-sign' },
  { id: 58729, unicode: 'e569', label: 'Rug', name: 'fa-rug' },
  { id: 58310, unicode: 'e3c6', label: 'Rugby Ball', name: 'fa-rugby-ball' },
  { id: 62789, unicode: 'f545', label: 'Ruler', name: 'fa-ruler' },
  { id: 62790, unicode: 'f546', label: 'Ruler Combined', name: 'fa-ruler-combined' },
  { id: 62791, unicode: 'f547', label: 'Ruler Horizontal', name: 'fa-ruler-horizontal' },
  { id: 63004, unicode: 'f61c', label: 'Ruler Triangle', name: 'fa-ruler-triangle' },
  { id: 62792, unicode: 'f548', label: 'Ruler Vertical', name: 'fa-ruler-vertical' },
  { id: 61782, unicode: 'f156', label: 'Indian Rupee Sign', name: 'fa-indian-rupee-sign' },
  { id: 57917, unicode: 'e23d', label: 'Rupiah Sign', name: 'fa-rupiah-sign' },
  { id: 57466, unicode: 'e07a', label: 'Rust', name: 'fa-rust' },
  { id: 63422, unicode: 'f7be', label: 'R V ', name: 'fa-r-v-' },
  { id: 83, unicode: '53', label: 'S', name: 'fa-s' },
  { id: 63516, unicode: 'f81c', label: 'Sack', name: 'fa-sack' },
  { id: 63517, unicode: 'f81d', label: 'Sack Of Money', name: 'fa-sack-of-money' },
  { id: 58730, unicode: 'e56a', label: 'Sack Xmark', name: 'fa-sack-xmark' },
  { id: 62055, unicode: 'f267', label: 'Safari', name: 'fa-safari' },
  { id: 58437, unicode: 'e445', label: 'Sailboat', name: 'fa-sailboat' },
  { id: 63518, unicode: 'f81e', label: 'Salad', name: 'fa-salad' },
  { id: 63547, unicode: 'f83b', label: 'Salesforce', name: 'fa-salesforce' },
  { id: 58438, unicode: 'e446', label: 'Salt Shaker', name: 'fa-salt-shaker' },
  { id: 63519, unicode: 'f81f', label: 'Sandwich', name: 'fa-sandwich' },
  { id: 62494, unicode: 'f41e', label: 'Sass', name: 'fa-sass' },
  { id: 63423, unicode: 'f7bf', label: 'Satellite', name: 'fa-satellite' },
  { id: 63424, unicode: 'f7c0', label: 'Satellite Dish', name: 'fa-satellite-dish' },
  { id: 63520, unicode: 'f820', label: 'Sausage', name: 'fa-sausage' },
  { id: 63708, unicode: 'f8dc', label: 'Saxophone', name: 'fa-saxophone' },
  { id: 63707, unicode: 'f8db', label: 'Saxophone Fire', name: 'fa-saxophone-fire' },
  { id: 62030, unicode: 'f24e', label: 'Scale Balanced', name: 'fa-scale-balanced' },
  { id: 62741, unicode: 'f515', label: 'Scale Unbalanced', name: 'fa-scale-unbalanced' },
  { id: 62742, unicode: 'f516', label: 'Scale Unbalanced Flip', name: 'fa-scale-unbalanced-flip' },
  { id: 63005, unicode: 'f61d', label: 'Scalpel', name: 'fa-scalpel' },
  { id: 63006, unicode: 'f61e', label: 'Scalpel Line Dashed', name: 'fa-scalpel-line-dashed' },
  { id: 62600, unicode: 'f488', label: 'Scanner Gun', name: 'fa-scanner-gun' },
  { id: 63731, unicode: 'f8f3', label: 'Image Scanner', name: 'fa-image-scanner' },
  { id: 62601, unicode: 'f489', label: 'Scanner Keyboard', name: 'fa-scanner-keyboard' },
  { id: 62602, unicode: 'f48a', label: 'Scanner Touchscreen', name: 'fa-scanner-touchscreen' },
  { id: 63245, unicode: 'f70d', label: 'Scarecrow', name: 'fa-scarecrow' },
  { id: 63425, unicode: 'f7c1', label: 'Scarf', name: 'fa-scarf' },
  { id: 62442, unicode: 'f3ea', label: 'SCHLIX', name: 'fa-schlix' },
  { id: 62793, unicode: 'f549', label: 'School', name: 'fa-school' },
  { id: 58731, unicode: 'e56b', label: 'School Circle Check', name: 'fa-school-circle-check' },
  { id: 58732, unicode: 'e56c', label: 'School Circle Exclamation', name: 'fa-school-circle-exclamation' },
  { id: 58733, unicode: 'e56d', label: 'School Circle Xmark', name: 'fa-school-circle-xmark' },
  { id: 58734, unicode: 'e56e', label: 'School Flag', name: 'fa-school-flag' },
  { id: 58735, unicode: 'e56f', label: 'School Lock', name: 'fa-school-lock' },
  { id: 61636, unicode: 'f0c4', label: 'Scissors', name: 'fa-scissors' },
  { id: 63037, unicode: 'f63d', label: 'Screen Users', name: 'fa-screen-users' },
  { id: 57918, unicode: 'e23e', label: 'Screencast', name: 'fa-screencast' },
  { id: 58736, unicode: 'e570', label: 'Screenpal', name: 'fa-screenpal' },
  { id: 62794, unicode: 'f54a', label: 'Screwdriver', name: 'fa-screwdriver' },
  { id: 63449, unicode: 'f7d9', label: 'Screwdriver Wrench', name: 'fa-screwdriver-wrench' },
  { id: 57919, unicode: 'e23f', label: 'Scribble', name: 'fa-scribble' },
  { id: 62090, unicode: 'f28a', label: 'Scribd', name: 'fa-scribd' },
  { id: 63246, unicode: 'f70e', label: 'Scroll', name: 'fa-scroll' },
  { id: 63247, unicode: 'f70f', label: 'Scroll Old', name: 'fa-scroll-old' },
  { id: 63136, unicode: 'f6a0', label: 'Scroll Torah', name: 'fa-scroll-torah' },
  { id: 62200, unicode: 'f2f8', label: 'Scrubber', name: 'fa-scrubber' },
  { id: 63248, unicode: 'f710', label: 'Scythe', name: 'fa-scythe' },
  { id: 63426, unicode: 'f7c2', label: 'Sd Card', name: 'fa-sd-card' },
  { id: 57920, unicode: 'e240', label: 'Sd Cards', name: 'fa-sd-cards' },
  { id: 57921, unicode: 'e241', label: 'Seal', name: 'fa-seal' },
  { id: 57922, unicode: 'e242', label: 'Seal Exclamation', name: 'fa-seal-exclamation' },
  { id: 57923, unicode: 'e243', label: 'Seal Question', name: 'fa-seal-question' },
  { id: 62443, unicode: 'f3eb', label: 'Searchengin', name: 'fa-searchengin' },
  { id: 57924, unicode: 'e244', label: 'Seat Airline', name: 'fa-seat-airline' },
  { id: 58439, unicode: 'e447', label: 'Section', name: 'fa-section' },
  { id: 62680, unicode: 'f4d8', label: 'Seedling', name: 'fa-seedling' },
  { id: 62170, unicode: 'f2da', label: 'Sellcast', name: 'fa-sellcast' },
  { id: 61971, unicode: 'f213', label: 'Sellsy', name: 'fa-sellsy' },
  { id: 59, unicode: '3b', label: 'Semicolon', name: 'fa-semicolon' },
  { id: 63614, unicode: 'f87e', label: 'Send Back', name: 'fa-send-back' },
  { id: 63615, unicode: 'f87f', label: 'Send Backward', name: 'fa-send-backward' },
  { id: 57384, unicode: 'e028', label: 'Sensor', name: 'fa-sensor' },
  { id: 57388, unicode: 'e02c', label: 'Sensor Cloud', name: 'fa-sensor-cloud' },
  { id: 57386, unicode: 'e02a', label: 'Sensor Fire', name: 'fa-sensor-fire' },
  { id: 57387, unicode: 'e02b', label: 'Sensor On', name: 'fa-sensor-on' },
  { id: 57385, unicode: 'e029', label: 'Sensor Triangle Exclamation', name: 'fa-sensor-triangle-exclamation' },
  { id: 62003, unicode: 'f233', label: 'Server', name: 'fa-server' },
  { id: 62444, unicode: 'f3ec', label: 'Servicestack', name: 'fa-servicestack' },
  { id: 63007, unicode: 'f61f', label: 'Shapes', name: 'fa-shapes' },
  { id: 61540, unicode: 'f064', label: 'Share', name: 'fa-share' },
  { id: 62311, unicode: 'f367', label: 'Share All', name: 'fa-share-all' },
  { id: 61773, unicode: 'f14d', label: 'Share From Square', name: 'fa-share-from-square' },
  { id: 61920, unicode: 'f1e0', label: 'Share Nodes', name: 'fa-share-nodes' },
  { id: 63249, unicode: 'f711', label: 'Sheep', name: 'fa-sheep' },
  { id: 58737, unicode: 'e571', label: 'Sheet Plastic', name: 'fa-sheet-plastic' },
  { id: 61963, unicode: 'f20b', label: 'Shekel Sign', name: 'fa-shekel-sign' },
  { id: 62592, unicode: 'f480', label: 'Shelves', name: 'fa-shelves' },
  { id: 57926, unicode: 'e246', label: 'Shelves Empty', name: 'fa-shelves-empty' },
  { id: 61746, unicode: 'f132', label: 'Shield', name: 'fa-shield' },
  { id: 58738, unicode: 'e572', label: 'Shield Cat', name: 'fa-shield-cat' },
  { id: 62199, unicode: 'f2f7', label: 'Shield', name: 'fa-shield' },
  { id: 63250, unicode: 'f712', label: 'Shield Cross', name: 'fa-shield-cross' },
  { id: 58739, unicode: 'e573', label: 'Shield Dog', name: 'fa-shield-dog' },
  { id: 57927, unicode: 'e247', label: 'Shield Exclamation', name: 'fa-shield-exclamation' },
  { id: 62445, unicode: 'f3ed', label: 'Shield Halved', name: 'fa-shield-halved' },
  { id: 58740, unicode: 'e574', label: 'Shield Heart', name: 'fa-shield-heart' },
  { id: 57928, unicode: 'e248', label: 'Shield Keyhole', name: 'fa-shield-keyhole' },
  { id: 57929, unicode: 'e249', label: 'Shield Minus', name: 'fa-shield-minus' },
  { id: 57930, unicode: 'e24a', label: 'Shield Plus', name: 'fa-shield-plus' },
  { id: 58741, unicode: 'e575', label: 'Shield Quartered', name: 'fa-shield-quartered' },
  { id: 57931, unicode: 'e24b', label: 'Shield Slash', name: 'fa-shield-slash' },
  { id: 57452, unicode: 'e06c', label: 'Shield Virus', name: 'fa-shield-virus' },
  { id: 57932, unicode: 'e24c', label: 'Shield X Mark', name: 'fa-shield-x-mark' },
  { id: 61978, unicode: 'f21a', label: 'Ship', name: 'fa-ship' },
  { id: 62803, unicode: 'f553', label: 'T Shirt', name: 'fa-t-shirt' },
  { id: 58311, unicode: 'e3c7', label: 'Long Sleeve Shirt', name: 'fa-long-sleeve-shirt' },
  { id: 58312, unicode: 'e3c8', label: 'Running Shirt', name: 'fa-running-shirt' },
  { id: 58313, unicode: 'e3c9', label: 'Tank Top Shirt', name: 'fa-tank-top-shirt' },
  { id: 61972, unicode: 'f214', label: 'Shirts In Bulk', name: 'fa-shirts-in-bulk' },
  { id: 63521, unicode: 'f821', label: 'Shish Kebab', name: 'fa-shish-kebab' },
  { id: 62795, unicode: 'f54b', label: 'Shoe Prints', name: 'fa-shoe-prints' },
  { id: 62799, unicode: 'f54f', label: 'Shop', name: 'fa-shop' },
  { id: 58533, unicode: 'e4a5', label: 'Shop Lock', name: 'fa-shop-lock' },
  { id: 57456, unicode: 'e070', label: 'Shop Slash', name: 'fa-shop-slash' },
  { id: 57431, unicode: 'e057', label: 'Shopify', name: 'fa-shopify' },
  { id: 62901, unicode: 'f5b5', label: 'Shopware', name: 'fa-shopware' },
  { id: 63251, unicode: 'f713', label: 'Shovel', name: 'fa-shovel' },
  { id: 63427, unicode: 'f7c3', label: 'Shovel Snow', name: 'fa-shovel-snow' },
  { id: 62156, unicode: 'f2cc', label: 'Shower', name: 'fa-shower' },
  { id: 57933, unicode: 'e24d', label: 'Shower Down', name: 'fa-shower-down' },
  { id: 63114, unicode: 'f68a', label: 'Shredder', name: 'fa-shredder' },
  { id: 58440, unicode: 'e448', label: 'Shrimp', name: 'fa-shrimp' },
  { id: 61556, unicode: 'f074', label: 'Shuffle', name: 'fa-shuffle' },
  { id: 58441, unicode: 'e449', label: 'Shutters', name: 'fa-shutters' },
  { id: 61847, unicode: 'f197', label: 'Shuttle Space', name: 'fa-shuttle-space' },
  { id: 62555, unicode: 'f45b', label: 'Shuttlecock', name: 'fa-shuttlecock' },
  { id: 63522, unicode: 'f822', label: 'Sickle', name: 'fa-sickle' },
  { id: 57934, unicode: 'e24e', label: 'Sidebar', name: 'fa-sidebar' },
  { id: 57935, unicode: 'e24f', label: 'Sidebar Flip', name: 'fa-sidebar-flip' },
  { id: 63115, unicode: 'f68b', label: 'Sigma  Summation ', name: 'fa-sigma--summation-' },
  { id: 62681, unicode: 'f4d9', label: 'Sign Hanging', name: 'fa-sign-hanging' },
  { id: 61458, unicode: 'f012', label: 'Signal', name: 'fa-signal' },
  { id: 63120, unicode: 'f690', label: 'Signal Bars', name: 'fa-signal-bars' },
  { id: 63122, unicode: 'f692', label: 'Signal Bars Fair', name: 'fa-signal-bars-fair' },
  { id: 63123, unicode: 'f693', label: 'Signal Bars Good', name: 'fa-signal-bars-good' },
  { id: 63124, unicode: 'f694', label: 'Signal Bars Slash', name: 'fa-signal-bars-slash' },
  { id: 63121, unicode: 'f691', label: 'Signal Bars Weak', name: 'fa-signal-bars-weak' },
  { id: 63117, unicode: 'f68d', label: 'Signal Fair', name: 'fa-signal-fair' },
  { id: 63118, unicode: 'f68e', label: 'Signal Good', name: 'fa-signal-good' },
  { id: 63125, unicode: 'f695', label: 'Signal Slash', name: 'fa-signal-slash' },
  { id: 63709, unicode: 'f8dd', label: 'Signal Stream', name: 'fa-signal-stream' },
  { id: 57936, unicode: 'e250', label: 'Signal Stream Slash', name: 'fa-signal-stream-slash' },
  { id: 63119, unicode: 'f68f', label: 'Signal Strong', name: 'fa-signal-strong' },
  { id: 63116, unicode: 'f68c', label: 'Signal Weak', name: 'fa-signal-weak' },
  { id: 62903, unicode: 'f5b7', label: 'Signature', name: 'fa-signature' },
  { id: 58314, unicode: 'e3ca', label: 'Signature Lock', name: 'fa-signature-lock' },
  { id: 58315, unicode: 'e3cb', label: 'Signature Slash', name: 'fa-signature-slash' },
  { id: 62071, unicode: 'f277', label: 'Signs Post', name: 'fa-signs-post' },
  { id: 63428, unicode: 'f7c4', label: 'SIM Card', name: 'fa-sim-card' },
  { id: 57937, unicode: 'e251', label: 'Sim Cards', name: 'fa-sim-cards' },
  { id: 61973, unicode: 'f215', label: 'Simplybuilt', name: 'fa-simplybuilt' },
  { id: 57453, unicode: 'e06d', label: 'Sink', name: 'fa-sink' },
  { id: 57389, unicode: 'e02d', label: 'Siren', name: 'fa-siren' },
  { id: 57390, unicode: 'e02e', label: 'Siren On', name: 'fa-siren-on' },
  { id: 62446, unicode: 'f3ee', label: 'SISTRIX', name: 'fa-sistrix' },
  { id: 61672, unicode: 'f0e8', label: 'Sitemap', name: 'fa-sitemap' },
  { id: 62738, unicode: 'f512', label: 'Sith', name: 'fa-sith' },
  { id: 58442, unicode: 'e44a', label: 'Sitrox', name: 'fa-sitrox' },
  { id: 63008, unicode: 'f620', label: 'Skeleton', name: 'fa-skeleton' },
  { id: 63430, unicode: 'f7c6', label: 'Sketch', name: 'fa-sketch' },
  { id: 58316, unicode: 'e3cc', label: 'Ski Boot', name: 'fa-ski-boot' },
  { id: 58317, unicode: 'e3cd', label: 'Ski Boot On Ski', name: 'fa-ski-boot-on-ski' },
  { id: 62796, unicode: 'f54c', label: 'Skull', name: 'fa-skull' },
  { id: 63710, unicode: 'f8de', label: 'Cow Skull', name: 'fa-cow-skull' },
  { id: 63252, unicode: 'f714', label: 'Skull   Crossbones', name: 'fa-skull---crossbones' },
  { id: 61974, unicode: 'f216', label: 'Skyatlas', name: 'fa-skyatlas' },
  { id: 61822, unicode: 'f17e', label: 'Skype', name: 'fa-skype' },
  { id: 61848, unicode: 'f198', label: 'Slack Logo', name: 'fa-slack-logo' },
  { id: 63253, unicode: 'f715', label: 'Slash', name: 'fa-slash' },
  { id: 92, unicode: '5c', label: 'Slash Back', name: 'fa-slash-back' },
  { id: 47, unicode: '2f', label: 'Slash Forward', name: 'fa-slash-forward' },
  { id: 63436, unicode: 'f7cc', label: 'Sleigh', name: 'fa-sleigh' },
  { id: 57938, unicode: 'e252', label: 'Slider', name: 'fa-slider' },
  { id: 61918, unicode: 'f1de', label: 'Sliders', name: 'fa-sliders' },
  { id: 57939, unicode: 'e253', label: 'Sliders Simple', name: 'fa-sliders-simple' },
  { id: 62449, unicode: 'f3f1', label: 'Sliders Up', name: 'fa-sliders-up' },
  { id: 61927, unicode: 'f1e7', label: 'Slideshare', name: 'fa-slideshare' },
  { id: 58318, unicode: 'e3ce', label: 'Slot Machine', name: 'fa-slot-machine' },
  { id: 63327, unicode: 'f75f', label: 'Smog', name: 'fa-smog' },
  { id: 63328, unicode: 'f760', label: 'Smoke', name: 'fa-smoke' },
  { id: 62605, unicode: 'f48d', label: 'Smoking', name: 'fa-smoking' },
  { id: 63254, unicode: 'f716', label: 'Snake', name: 'fa-snake' },
  { id: 62123, unicode: 'f2ab', label: 'Snapchat', name: 'fa-snapchat' },
  { id: 63616, unicode: 'f880', label: 'Snooze', name: 'fa-snooze' },
  { id: 63329, unicode: 'f761', label: 'Snow Blowing', name: 'fa-snow-blowing' },
  { id: 62172, unicode: 'f2dc', label: 'Snowflake', name: 'fa-snowflake' },
  { id: 63439, unicode: 'f7cf', label: 'Snowflakes', name: 'fa-snowflakes' },
  { id: 63440, unicode: 'f7d0', label: 'Snowman', name: 'fa-snowman' },
  { id: 63387, unicode: 'f79b', label: 'Snowman Head', name: 'fa-snowman-head' },
  { id: 63442, unicode: 'f7d2', label: 'Snowplow', name: 'fa-snowplow' },
  { id: 57454, unicode: 'e06e', label: 'Soap', name: 'fa-soap' },
  { id: 63126, unicode: 'f696', label: 'Socks', name: 'fa-socks' },
  { id: 58368, unicode: 'e400', label: 'Soft Serve', name: 'fa-soft-serve' },
  { id: 62906, unicode: 'f5ba', label: 'Solar Panel', name: 'fa-solar-panel' },
  { id: 57391, unicode: 'e02f', label: 'Solar System', name: 'fa-solar-system' },
  { id: 61660, unicode: 'f0dc', label: 'Sort', name: 'fa-sort' },
  { id: 61661, unicode: 'f0dd', label: 'Sort Down  Descending ', name: 'fa-sort-down--descending-' },
  { id: 61662, unicode: 'f0de', label: 'Sort Up  Ascending ', name: 'fa-sort-up--ascending-' },
  { id: 61886, unicode: 'f1be', label: 'Soundcloud', name: 'fa-soundcloud' },
  { id: 63443, unicode: 'f7d3', label: 'Sourcetree', name: 'fa-sourcetree' },
  { id: 62907, unicode: 'f5bb', label: 'Spa', name: 'fa-spa' },
  { id: 58796, unicode: 'e5ac', label: 'Space Awesome', name: 'fa-space-awesome' },
  { id: 57395, unicode: 'e033', label: 'Moon Space Station', name: 'fa-moon-space-station' },
  { id: 57396, unicode: 'e034', label: 'Space Station Moon Construction', name: 'fa-space-station-moon-construction' },
  { id: 62196, unicode: 'f2f4', label: 'Spade', name: 'fa-spade' },
  { id: 63099, unicode: 'f67b', label: 'Spaghetti Monster Flying', name: 'fa-spaghetti-monster-flying' },
  { id: 63632, unicode: 'f890', label: 'Sparkles', name: 'fa-sparkles' },
  { id: 62451, unicode: 'f3f3', label: 'Speakap', name: 'fa-speakap' },
  { id: 63711, unicode: 'f8df', label: 'Speaker', name: 'fa-speaker' },
  { id: 63548, unicode: 'f83c', label: 'Speaker Deck', name: 'fa-speaker-deck' },
  { id: 63712, unicode: 'f8e0', label: 'Speakers', name: 'fa-speakers' },
  { id: 63633, unicode: 'f891', label: 'Spell Check', name: 'fa-spell-check' },
  { id: 63255, unicode: 'f717', label: 'Spider', name: 'fa-spider' },
  { id: 63256, unicode: 'f718', label: 'Black Widow Spider', name: 'fa-black-widow-spider' },
  { id: 63257, unicode: 'f719', label: 'Spider Web', name: 'fa-spider-web' },
  { id: 61712, unicode: 'f110', label: 'Spinner', name: 'fa-spinner' },
  { id: 62452, unicode: 'f3f4', label: 'Spinner Third', name: 'fa-spinner-third' },
  { id: 57940, unicode: 'e254', label: 'Split', name: 'fa-split' },
  { id: 62908, unicode: 'f5bc', label: 'Splotch', name: 'fa-splotch' },
  { id: 62181, unicode: 'f2e5', label: 'Spoon', name: 'fa-spoon' },
  { id: 58443, unicode: 'e44b', label: 'Sportsball', name: 'fa-sportsball' },
  { id: 61884, unicode: 'f1bc', label: 'Spotify', name: 'fa-spotify' },
  { id: 62909, unicode: 'f5bd', label: 'Spray Can', name: 'fa-spray-can' },
  { id: 62928, unicode: 'f5d0', label: 'Spray Can Sparkles', name: 'fa-spray-can-sparkles' },
  { id: 57397, unicode: 'e035', label: 'Sprinkler', name: 'fa-sprinkler' },
  { id: 58444, unicode: 'e44c', label: 'Sprinkler Ceiling', name: 'fa-sprinkler-ceiling' },
  { id: 61640, unicode: 'f0c8', label: 'Square', name: 'fa-square' },
  { id: 57941, unicode: 'e255', label: 'Square 0', name: 'fa-square-0' },
  { id: 57942, unicode: 'e256', label: 'Square 1', name: 'fa-square-1' },
  { id: 57943, unicode: 'e257', label: 'Square 2', name: 'fa-square-2' },
  { id: 57944, unicode: 'e258', label: 'Square 3', name: 'fa-square-3' },
  { id: 57945, unicode: 'e259', label: 'Square 4', name: 'fa-square-4' },
  { id: 57946, unicode: 'e25a', label: 'Square 5', name: 'fa-square-5' },
  { id: 57947, unicode: 'e25b', label: 'Square 6', name: 'fa-square-6' },
  { id: 57948, unicode: 'e25c', label: 'Square 7', name: 'fa-square-7' },
  { id: 57949, unicode: 'e25d', label: 'Square 8', name: 'fa-square-8' },
  { id: 57950, unicode: 'e25e', label: 'Square 9', name: 'fa-square-9' },
  { id: 57951, unicode: 'e25f', label: 'Square A', name: 'fa-square-a' },
  { id: 58445, unicode: 'e44d', label: 'Square A Lock', name: 'fa-square-a-lock' },
  { id: 57952, unicode: 'e260', label: 'Square Ampersand', name: 'fa-square-ampersand' },
  { id: 62265, unicode: 'f339', label: 'Square Arrow Down', name: 'fa-square-arrow-down' },
  { id: 57953, unicode: 'e261', label: 'Square Arrow Down Left', name: 'fa-square-arrow-down-left' },
  { id: 57954, unicode: 'e262', label: 'Square Arrow Down Right', name: 'fa-square-arrow-down-right' },
  { id: 62266, unicode: 'f33a', label: 'Square Arrow Left', name: 'fa-square-arrow-left' },
  { id: 62267, unicode: 'f33b', label: 'Square Arrow Right', name: 'fa-square-arrow-right' },
  { id: 62268, unicode: 'f33c', label: 'Square Arrow Up', name: 'fa-square-arrow-up' },
  { id: 57955, unicode: 'e263', label: 'Square Arrow Up Left', name: 'fa-square-arrow-up-left' },
  { id: 61772, unicode: 'f14c', label: 'Square Arrow Up Right', name: 'fa-square-arrow-up-right' },
  { id: 57956, unicode: 'e264', label: 'Square B', name: 'fa-square-b' },
  { id: 61877, unicode: 'f1b5', label: 'Behance Square', name: 'fa-behance-square' },
  { id: 57957, unicode: 'e265', label: 'Square Bolt', name: 'fa-square-bolt' },
  { id: 57958, unicode: 'e266', label: 'Square C', name: 'fa-square-c' },
  { id: 61776, unicode: 'f150', label: 'Square Caret Down', name: 'fa-square-caret-down' },
  { id: 61841, unicode: 'f191', label: 'Square Caret Left', name: 'fa-square-caret-left' },
  { id: 61778, unicode: 'f152', label: 'Square Caret Right', name: 'fa-square-caret-right' },
  { id: 61777, unicode: 'f151', label: 'Square Caret Up', name: 'fa-square-caret-up' },
  { id: 61770, unicode: 'f14a', label: 'Square Check', name: 'fa-square-check' },
  { id: 62249, unicode: 'f329', label: 'Square Chevron Down', name: 'fa-square-chevron-down' },
  { id: 62250, unicode: 'f32a', label: 'Square Chevron Left', name: 'fa-square-chevron-left' },
  { id: 62251, unicode: 'f32b', label: 'Square Chevron Right', name: 'fa-square-chevron-right' },
  { id: 62252, unicode: 'f32c', label: 'Square Chevron Up', name: 'fa-square-chevron-up' },
  { id: 57959, unicode: 'e267', label: 'Square Code', name: 'fa-square-code' },
  { id: 57960, unicode: 'e268', label: 'Square D', name: 'fa-square-d' },
  { id: 57961, unicode: 'e269', label: 'Square Dashed', name: 'fa-square-dashed' },
  { id: 57962, unicode: 'e26a', label: 'Square Divide', name: 'fa-square-divide' },
  { id: 62185, unicode: 'f2e9', label: 'Square Dollar', name: 'fa-square-dollar' },
  { id: 62288, unicode: 'f350', label: 'Square Down', name: 'fa-square-down' },
  { id: 57963, unicode: 'e26b', label: 'Square Down Left', name: 'fa-square-down-left' },
  { id: 57964, unicode: 'e26c', label: 'Square Down Right', name: 'fa-square-down-right' },
  { id: 62359, unicode: 'f397', label: 'Dribbble Square', name: 'fa-dribbble-square' },
  { id: 57965, unicode: 'e26d', label: 'Square E', name: 'fa-square-e' },
  { id: 57966, unicode: 'e26e', label: 'Square Ellipsis', name: 'fa-square-ellipsis' },
  { id: 57967, unicode: 'e26f', label: 'Square Ellipsis Vertical', name: 'fa-square-ellipsis-vertical' },
  { id: 61849, unicode: 'f199', label: 'Square Envelope', name: 'fa-square-envelope' },
  { id: 62241, unicode: 'f321', label: 'Square Exclamation', name: 'fa-square-exclamation' },
  { id: 57968, unicode: 'e270', label: 'Square F', name: 'fa-square-f' },
  { id: 61570, unicode: 'f082', label: 'Facebook Square', name: 'fa-facebook-square' },
  { id: 58797, unicode: 'e5ad', label: 'Font Awesome In Square', name: 'fa-font-awesome-in-square' },
  { id: 62300, unicode: 'f35c', label: 'Font Awesome In Square With Stroke Outline', name: 'fa-font-awesome-in-square-with-stroke-outline' },
  { id: 62619, unicode: 'f49b', label: 'Square Fragile', name: 'fa-square-fragile' },
  { id: 62556, unicode: 'f45c', label: 'Square Full', name: 'fa-square-full' },
  { id: 57969, unicode: 'e271', label: 'Square G', name: 'fa-square-g' },
  { id: 61906, unicode: 'f1d2', label: 'Git Square', name: 'fa-git-square' },
  { id: 61586, unicode: 'f092', label: 'Github Square', name: 'fa-github-square' },
  { id: 58798, unicode: 'e5ae', label: 'Square Gitlab', name: 'fa-square-gitlab' },
  { id: 61652, unicode: 'f0d4', label: 'Google Plus Square', name: 'fa-google-plus-square' },
  { id: 61693, unicode: 'f0fd', label: 'Square H', name: 'fa-square-h' },
  { id: 62383, unicode: 'f3af', label: 'Hacker News Square', name: 'fa-hacker-news-square' },
  { id: 62664, unicode: 'f4c8', label: 'Square Heart', name: 'fa-square-heart' },
  { id: 57970, unicode: 'e272', label: 'Square I', name: 'fa-square-i' },
  { id: 62223, unicode: 'f30f', label: 'Square Info', name: 'fa-square-info' },
  { id: 57429, unicode: 'e055', label: 'Instagram Square', name: 'fa-instagram-square' },
  { id: 57971, unicode: 'e273', label: 'Square J', name: 'fa-square-j' },
  { id: 62393, unicode: 'f3b9', label: 'Javascript  JS  Square', name: 'fa-javascript--js--square' },
  { id: 57972, unicode: 'e274', label: 'Square K', name: 'fa-square-k' },
  { id: 58504, unicode: 'e488', label: 'Square Kanban', name: 'fa-square-kanban' },
  { id: 57973, unicode: 'e275', label: 'Square L', name: 'fa-square-l' },
  { id: 61955, unicode: 'f203', label: 'Last Fm Square', name: 'fa-last-fm-square' },
  { id: 62289, unicode: 'f351', label: 'Square Left', name: 'fa-square-left' },
  { id: 58505, unicode: 'e489', label: 'Square List', name: 'fa-square-list' },
  { id: 57974, unicode: 'e276', label: 'Square M', name: 'fa-square-m' },
  { id: 61766, unicode: 'f146', label: 'Square Minus', name: 'fa-square-minus' },
  { id: 57975, unicode: 'e277', label: 'Square N', name: 'fa-square-n' },
  { id: 58742, unicode: 'e576', label: 'Square Nfi', name: 'fa-square-nfi' },
  { id: 57976, unicode: 'e278', label: 'Square O', name: 'fa-square-o' },
  { id: 62052, unicode: 'f264', label: 'Odnoklassniki Square', name: 'fa-odnoklassniki-square' },
  { id: 57977, unicode: 'e279', label: 'Square P', name: 'fa-square-p' },
  { id: 62784, unicode: 'f540', label: 'Square Parking', name: 'fa-square-parking' },
  { id: 62999, unicode: 'f617', label: 'Square Parking Slash', name: 'fa-square-parking-slash' },
  { id: 61771, unicode: 'f14b', label: 'Square Pen', name: 'fa-square-pen' },
  { id: 58743, unicode: 'e577', label: 'Square Person Confined', name: 'fa-square-person-confined' },
  { id: 61592, unicode: 'f098', label: 'Square Phone', name: 'fa-square-phone' },
  { id: 63611, unicode: 'f87b', label: 'Square Phone Flip', name: 'fa-square-phone-flip' },
  { id: 57978, unicode: 'e27a', label: 'Square Phone Hangup', name: 'fa-square-phone-hangup' },
  { id: 57374, unicode: 'e01e', label: 'Pied Piper Square Logo  Old ', name: 'fa-pied-piper-square-logo--old-' },
  { id: 61651, unicode: 'f0d3', label: 'Pinterest Square', name: 'fa-pinterest-square' },
  { id: 61694, unicode: 'f0fe', label: 'Square Plus', name: 'fa-square-plus' },
  { id: 63106, unicode: 'f682', label: 'Square Poll Horizontal', name: 'fa-square-poll-horizontal' },
  { id: 63105, unicode: 'f681', label: 'Square Poll Vertical', name: 'fa-square-poll-vertical' },
  { id: 57979, unicode: 'e27b', label: 'Square Q', name: 'fa-square-q' },
  { id: 58446, unicode: 'e44e', label: 'Square Quarters', name: 'fa-square-quarters' },
  { id: 62205, unicode: 'f2fd', label: 'Square Question', name: 'fa-square-question' },
  { id: 58153, unicode: 'e329', label: 'Square Quote', name: 'fa-square-quote' },
  { id: 57980, unicode: 'e27c', label: 'Square R', name: 'fa-square-r' },
  { id: 61858, unicode: 'f1a2', label: 'Reddit Square', name: 'fa-reddit-square' },
  { id: 62290, unicode: 'f352', label: 'Square Right', name: 'fa-square-right' },
  { id: 58447, unicode: 'e44f', label: 'Square Ring', name: 'fa-square-ring' },
  { id: 63127, unicode: 'f697', label: 'Square Root', name: 'fa-square-root' },
  { id: 63128, unicode: 'f698', label: 'Square Root Variable', name: 'fa-square-root-variable' },
  { id: 61763, unicode: 'f143', label: 'Square Rss', name: 'fa-square-rss' },
  { id: 57981, unicode: 'e27d', label: 'Square S', name: 'fa-square-s' },
  { id: 61921, unicode: 'f1e1', label: 'Square Share Nodes', name: 'fa-square-share-nodes' },
  { id: 62448, unicode: 'f3f0', label: 'Square Sliders', name: 'fa-square-sliders' },
  { id: 62450, unicode: 'f3f2', label: 'Square Sliders Vertical', name: 'fa-square-sliders-vertical' },
  { id: 57982, unicode: 'e27e', label: 'Square Small', name: 'fa-square-small' },
  { id: 62125, unicode: 'f2ad', label: 'Snapchat Square', name: 'fa-snapchat-square' },
  { id: 57983, unicode: 'e27f', label: 'Square Star', name: 'fa-square-star' },
  { id: 61879, unicode: 'f1b7', label: 'Steam Square', name: 'fa-steam-square' },
  { id: 57984, unicode: 'e280', label: 'Square T', name: 'fa-square-t' },
  { id: 58154, unicode: 'e32a', label: 'Square Terminal', name: 'fa-square-terminal' },
  { id: 62623, unicode: 'f49f', label: 'Square This Way Up', name: 'fa-square-this-way-up' },
  { id: 61812, unicode: 'f174', label: 'Tumblr Square', name: 'fa-tumblr-square' },
  { id: 61569, unicode: 'f081', label: 'Twitter Square', name: 'fa-twitter-square' },
  { id: 57985, unicode: 'e281', label: 'Square U', name: 'fa-square-u' },
  { id: 62291, unicode: 'f353', label: 'Square Up', name: 'fa-square-up' },
  { id: 57986, unicode: 'e282', label: 'Square Up Left', name: 'fa-square-up-left' },
  { id: 62304, unicode: 'f360', label: 'Square Up Right', name: 'fa-square-up-right' },
  { id: 57987, unicode: 'e283', label: 'Square User', name: 'fa-square-user' },
  { id: 57988, unicode: 'e284', label: 'Square V', name: 'fa-square-v' },
  { id: 62122, unicode: 'f2aa', label: 'Viadeo Square', name: 'fa-viadeo-square' },
  { id: 61844, unicode: 'f194', label: 'Vimeo Square', name: 'fa-vimeo-square' },
  { id: 58744, unicode: 'e578', label: 'Square Virus', name: 'fa-square-virus' },
  { id: 57989, unicode: 'e285', label: 'Square W', name: 'fa-square-w' },
  { id: 62476, unicode: 'f40c', label: 'What S App Square', name: 'fa-what-s-app-square' },
  { id: 57990, unicode: 'e286', label: 'Square X', name: 'fa-square-x' },
  { id: 61801, unicode: 'f169', label: 'Xing Square', name: 'fa-xing-square' },
  { id: 62163, unicode: 'f2d3', label: 'Square X Mark', name: 'fa-square-x-mark' },
  { id: 57991, unicode: 'e287', label: 'Square Y', name: 'fa-square-y' },
  { id: 62513, unicode: 'f431', label: 'Youtube Square', name: 'fa-youtube-square' },
  { id: 57992, unicode: 'e288', label: 'Square Z', name: 'fa-square-z' },
  { id: 62910, unicode: 'f5be', label: 'Squarespace', name: 'fa-squarespace' },
  { id: 58448, unicode: 'e450', label: 'Squid', name: 'fa-squid' },
  { id: 63258, unicode: 'f71a', label: 'Squirrel', name: 'fa-squirrel' },
  { id: 61837, unicode: 'f18d', label: 'Stack Exchange', name: 'fa-stack-exchange' },
  { id: 61804, unicode: 'f16c', label: 'Stack Overflow', name: 'fa-stack-overflow' },
  { id: 63554, unicode: 'f842', label: 'Stackpath', name: 'fa-stackpath' },
  { id: 63259, unicode: 'f71b', label: 'Staff', name: 'fa-staff' },
  { id: 58745, unicode: 'e579', label: 'Staff Aesculapius', name: 'fa-staff-aesculapius' },
  { id: 57993, unicode: 'e289', label: 'Stairs', name: 'fa-stairs' },
  { id: 62911, unicode: 'f5bf', label: 'Stamp', name: 'fa-stamp' },
  { id: 57994, unicode: 'e28a', label: 'Standard Definition', name: 'fa-standard-definition' },
  { id: 58799, unicode: 'e5af', label: 'Stapler', name: 'fa-stapler' },
  { id: 61445, unicode: 'f005', label: 'Star', name: 'fa-star' },
  { id: 63129, unicode: 'f699', label: 'Star And Crescent', name: 'fa-star-and-crescent' },
  { id: 63444, unicode: 'f7d4', label: 'Christmas Star', name: 'fa-christmas-star' },
  { id: 62195, unicode: 'f2f3', label: 'Exclamation Star', name: 'fa-exclamation-star' },
  { id: 61577, unicode: 'f089', label: 'Star Half', name: 'fa-star-half' },
  { id: 62912, unicode: 'f5c0', label: 'Star Half Stroke', name: 'fa-star-half-stroke' },
  { id: 63130, unicode: 'f69a', label: 'Star Of David', name: 'fa-star-of-david' },
  { id: 63009, unicode: 'f621', label: 'Star Of Life', name: 'fa-star-of-life' },
  { id: 57995, unicode: 'e28b', label: 'Star Sharp', name: 'fa-star-sharp' },
  { id: 57996, unicode: 'e28c', label: 'Star Sharp Half', name: 'fa-star-sharp-half' },
  { id: 57997, unicode: 'e28d', label: 'Star Sharp Half Stroke', name: 'fa-star-sharp-half-stroke' },
  { id: 57398, unicode: 'e036', label: 'Star Shooting', name: 'fa-star-shooting' },
  { id: 57399, unicode: 'e037', label: 'Starfighter', name: 'fa-starfighter' },
  { id: 57400, unicode: 'e038', label: 'Starfighter Twin Ion Engine', name: 'fa-starfighter-twin-ion-engine' },
  { id: 57998, unicode: 'e28e', label: 'Starfighter Twin Ion Engine Advanced', name: 'fa-starfighter-twin-ion-engine-advanced' },
  { id: 63330, unicode: 'f762', label: 'Stars', name: 'fa-stars' },
  { id: 57401, unicode: 'e039', label: 'Starship', name: 'fa-starship' },
  { id: 57402, unicode: 'e03a', label: 'Starship Freighter', name: 'fa-starship-freighter' },
  { id: 62453, unicode: 'f3f5', label: 'Staylinked', name: 'fa-staylinked' },
  { id: 63524, unicode: 'f824', label: 'Steak', name: 'fa-steak' },
  { id: 61878, unicode: 'f1b6', label: 'Steam', name: 'fa-steam' },
  { id: 62454, unicode: 'f3f6', label: 'Steam Symbol', name: 'fa-steam-symbol' },
  { id: 63010, unicode: 'f622', label: 'Wheel Steering', name: 'fa-wheel-steering' },
  { id: 61780, unicode: 'f154', label: 'Sterling Sign', name: 'fa-sterling-sign' },
  { id: 61681, unicode: 'f0f1', label: 'Stethoscope', name: 'fa-stethoscope' },
  { id: 62455, unicode: 'f3f7', label: 'Sticker Mule', name: 'fa-sticker-mule' },
  { id: 63445, unicode: 'f7d5', label: 'Stocking', name: 'fa-stocking' },
  { id: 63011, unicode: 'f623', label: 'Stomach', name: 'fa-stomach' },
  { id: 61517, unicode: 'f04d', label: 'Stop', name: 'fa-stop' },
  { id: 62194, unicode: 'f2f2', label: 'Stopwatch', name: 'fa-stopwatch' },
  { id: 57455, unicode: 'e06f', label: 'Stopwatch 20', name: 'fa-stopwatch-20' },
  { id: 62798, unicode: 'f54e', label: 'Store', name: 'fa-store' },
  { id: 58534, unicode: 'e4a6', label: 'Store Lock', name: 'fa-store-lock' },
  { id: 57457, unicode: 'e071', label: 'Store Slash', name: 'fa-store-slash' },
  { id: 62504, unicode: 'f428', label: 'Strava', name: 'fa-strava' },
  { id: 58155, unicode: 'e32b', label: 'Strawberry', name: 'fa-strawberry' },
  { id: 61981, unicode: 'f21d', label: 'Street View', name: 'fa-street-view' },
  { id: 63525, unicode: 'f825', label: 'Stretcher', name: 'fa-stretcher' },
  { id: 61644, unicode: 'f0cc', label: 'Strikethrough', name: 'fa-strikethrough' },
  { id: 62505, unicode: 'f429', label: 'Stripe', name: 'fa-stripe' },
  { id: 62506, unicode: 'f42a', label: 'Stripe S', name: 'fa-stripe-s' },
  { id: 62801, unicode: 'f551', label: 'Stroopwafel', name: 'fa-stroopwafel' },
  { id: 62456, unicode: 'f3f8', label: 'Studio Vinari', name: 'fa-studio-vinari' },
  { id: 61860, unicode: 'f1a4', label: 'Stumbleupon Logo', name: 'fa-stumbleupon-logo' },
  { id: 61859, unicode: 'f1a3', label: 'Stumbleupon Circle', name: 'fa-stumbleupon-circle' },
  { id: 61740, unicode: 'f12c', label: 'Subscript', name: 'fa-subscript' },
  { id: 61682, unicode: 'f0f2', label: 'Suitcase', name: 'fa-suitcase' },
  { id: 61690, unicode: 'f0fa', label: 'Suitcase Medical', name: 'fa-suitcase-medical' },
  { id: 62913, unicode: 'f5c1', label: 'Suitcase Rolling', name: 'fa-suitcase-rolling' },
  { id: 61829, unicode: 'f185', label: 'Sun', name: 'fa-sun' },
  { id: 57999, unicode: 'e28f', label: 'Sun Bright', name: 'fa-sun-bright' },
  { id: 63331, unicode: 'f763', label: 'Sun With Cloud', name: 'fa-sun-with-cloud' },
  { id: 63332, unicode: 'f764', label: 'Sun Dust', name: 'fa-sun-dust' },
  { id: 63333, unicode: 'f765', label: 'Sun Haze', name: 'fa-sun-haze' },
  { id: 58746, unicode: 'e57a', label: 'Sun Plant Wilt', name: 'fa-sun-plant-wilt' },
  { id: 63634, unicode: 'f892', label: 'Sunglasses', name: 'fa-sunglasses' },
  { id: 63334, unicode: 'f766', label: 'Sunrise', name: 'fa-sunrise' },
  { id: 63335, unicode: 'f767', label: 'Sunset', name: 'fa-sunset' },
  { id: 62173, unicode: 'f2dd', label: 'Superpowers', name: 'fa-superpowers' },
  { id: 61739, unicode: 'f12b', label: 'Superscript', name: 'fa-superscript' },
  { id: 62457, unicode: 'f3f9', label: 'Supple', name: 'fa-supple' },
  { id: 63446, unicode: 'f7d6', label: 'Suse', name: 'fa-suse' },
  { id: 58506, unicode: 'e48a', label: 'Sushi', name: 'fa-sushi' },
  { id: 58507, unicode: 'e48b', label: 'Sushi Roll', name: 'fa-sushi-roll' },
  { id: 62915, unicode: 'f5c3', label: 'Swatchbook', name: 'fa-swatchbook' },
  { id: 63713, unicode: 'f8e1', label: 'Swift', name: 'fa-swift' },
  { id: 63260, unicode: 'f71c', label: 'Sword', name: 'fa-sword' },
  { id: 57403, unicode: 'e03b', label: 'Sword Laser', name: 'fa-sword-laser' },
  { id: 57404, unicode: 'e03c', label: 'Sword Laser Alternate', name: 'fa-sword-laser-alternate' },
  { id: 63261, unicode: 'f71d', label: 'Swords', name: 'fa-swords' },
  { id: 57405, unicode: 'e03d', label: 'Swords Laser', name: 'fa-swords-laser' },
  { id: 63598, unicode: 'f86e', label: 'Symbols', name: 'fa-symbols' },
  { id: 63549, unicode: 'f83d', label: 'Symfony', name: 'fa-symfony' },
  { id: 63131, unicode: 'f69b', label: 'Synagogue', name: 'fa-synagogue' },
  { id: 62606, unicode: 'f48e', label: 'Syringe', name: 'fa-syringe' },
  { id: 84, unicode: '54', label: 'T', name: 'fa-t' },
  { id: 61646, unicode: 'f0ce', label: 'Table', name: 'fa-table' },
  { id: 61450, unicode: 'f00a', label: 'Table Cells', name: 'fa-table-cells' },
  { id: 61449, unicode: 'f009', label: 'Table Cells Large', name: 'fa-table-cells-large' },
  { id: 61659, unicode: 'f0db', label: 'Table Columns', name: 'fa-table-columns' },
  { id: 58000, unicode: 'e290', label: 'Table Layout', name: 'fa-table-layout' },
  { id: 61451, unicode: 'f00b', label: 'Table List', name: 'fa-table-list' },
  { id: 58157, unicode: 'e32d', label: 'Table Picnic', name: 'fa-table-picnic' },
  { id: 58001, unicode: 'e291', label: 'Table Pivot', name: 'fa-table-pivot' },
  { id: 58002, unicode: 'e292', label: 'Table Rows', name: 'fa-table-rows' },
  { id: 62557, unicode: 'f45d', label: 'Table Tennis Paddle Ball', name: 'fa-table-tennis-paddle-ball' },
  { id: 58003, unicode: 'e293', label: 'Table Tree', name: 'fa-table-tree' },
  { id: 62459, unicode: 'f3fb', label: 'Tablet', name: 'fa-tablet' },
  { id: 61706, unicode: 'f10a', label: 'Tablet Button', name: 'fa-tablet-button' },
  { id: 62607, unicode: 'f48f', label: 'Rugged Tablet', name: 'fa-rugged-tablet' },
  { id: 62460, unicode: 'f3fc', label: 'Tablet Screen', name: 'fa-tablet-screen' },
  { id: 62458, unicode: 'f3fa', label: 'Tablet Screen Button', name: 'fa-tablet-screen-button' },
  { id: 62608, unicode: 'f490', label: 'Tablets', name: 'fa-tablets' },
  { id: 62822, unicode: 'f566', label: 'Tachograph Digital', name: 'fa-tachograph-digital' },
  { id: 63526, unicode: 'f826', label: 'Taco', name: 'fa-taco' },
  { id: 61483, unicode: 'f02b', label: 'Tag', name: 'fa-tag' },
  { id: 61484, unicode: 'f02c', label: 'Tags', name: 'fa-tags' },
  { id: 63132, unicode: 'f69c', label: 'Tally', name: 'fa-tally' },
  { id: 58004, unicode: 'e294', label: 'Tally 1', name: 'fa-tally-1' },
  { id: 58005, unicode: 'e295', label: 'Tally 2', name: 'fa-tally-2' },
  { id: 58006, unicode: 'e296', label: 'Tally 3', name: 'fa-tally-3' },
  { id: 58007, unicode: 'e297', label: 'Tally 4', name: 'fa-tally-4' },
  { id: 58449, unicode: 'e451', label: 'Tamale', name: 'fa-tamale' },
  { id: 58450, unicode: 'e452', label: 'Tank Water', name: 'fa-tank-water' },
  { id: 62683, unicode: 'f4db', label: 'Tape', name: 'fa-tape' },
  { id: 58747, unicode: 'e57b', label: 'Tarp', name: 'fa-tarp' },
  { id: 58748, unicode: 'e57c', label: 'Tarp Droplet', name: 'fa-tarp-droplet' },
  { id: 61882, unicode: 'f1ba', label: 'Taxi', name: 'fa-taxi' },
  { id: 58008, unicode: 'e298', label: 'Taxi Bus', name: 'fa-taxi-bus' },
  { id: 62713, unicode: 'f4f9', label: 'Teamspeak', name: 'fa-teamspeak' },
  { id: 58319, unicode: 'e3cf', label: 'Teddy Bear', name: 'fa-teddy-bear' },
  { id: 63022, unicode: 'f62e', label: 'Teeth', name: 'fa-teeth' },
  { id: 63023, unicode: 'f62f', label: 'Teeth Open', name: 'fa-teeth-open' },
  { id: 62150, unicode: 'f2c6', label: 'Telegram', name: 'fa-telegram' },
  { id: 57406, unicode: 'e03e', label: 'Telescope', name: 'fa-telescope' },
  { id: 57407, unicode: 'e03f', label: 'Temperature Arrow Down', name: 'fa-temperature-arrow-down' },
  { id: 57408, unicode: 'e040', label: 'Temperature Arrow Up', name: 'fa-temperature-arrow-up' },
  { id: 62155, unicode: 'f2cb', label: 'Temperature Empty', name: 'fa-temperature-empty' },
  { id: 62151, unicode: 'f2c7', label: 'Temperature Full', name: 'fa-temperature-full' },
  { id: 62153, unicode: 'f2c9', label: 'Temperature Half', name: 'fa-temperature-half' },
  { id: 63337, unicode: 'f769', label: 'High Temperature', name: 'fa-high-temperature' },
  { id: 58009, unicode: 'e299', label: 'Temperature List', name: 'fa-temperature-list' },
  { id: 63339, unicode: 'f76b', label: 'Low Temperature', name: 'fa-low-temperature' },
  { id: 62154, unicode: 'f2ca', label: 'Temperature Quarter', name: 'fa-temperature-quarter' },
  { id: 63336, unicode: 'f768', label: 'Temperature Snow', name: 'fa-temperature-snow' },
  { id: 63338, unicode: 'f76a', label: 'Temperature Sun', name: 'fa-temperature-sun' },
  { id: 62152, unicode: 'f2c8', label: 'Temperature Three Quarters', name: 'fa-temperature-three-quarters' },
  { id: 61909, unicode: 'f1d5', label: 'Tencent Weibo', name: 'fa-tencent-weibo' },
  { id: 63447, unicode: 'f7d7', label: 'Tenge Sign', name: 'fa-tenge-sign' },
  { id: 62558, unicode: 'f45e', label: 'Tennis Ball', name: 'fa-tennis-ball' },
  { id: 58749, unicode: 'e57d', label: 'Tent', name: 'fa-tent' },
  { id: 58750, unicode: 'e57e', label: 'Tent Arrow Down To Line', name: 'fa-tent-arrow-down-to-line' },
  { id: 58751, unicode: 'e57f', label: 'Tent Arrow Left Right', name: 'fa-tent-arrow-left-right' },
  { id: 58752, unicode: 'e580', label: 'Tent Arrow Turn Left', name: 'fa-tent-arrow-turn-left' },
  { id: 58753, unicode: 'e581', label: 'Tent Arrows Down', name: 'fa-tent-arrows-down' },
  { id: 58754, unicode: 'e582', label: 'Tents', name: 'fa-tents' },
  { id: 61728, unicode: 'f120', label: 'Terminal', name: 'fa-terminal' },
  { id: 63635, unicode: 'f893', label: 'Text', name: 'fa-text' },
  { id: 61492, unicode: 'f034', label: 'Text Height', name: 'fa-text-height' },
  { id: 63636, unicode: 'f894', label: 'Text Size', name: 'fa-text-size' },
  { id: 63613, unicode: 'f87d', label: 'Text Slash', name: 'fa-text-slash' },
  { id: 61493, unicode: 'f035', label: 'Text Width', name: 'fa-text-width' },
  { id: 63133, unicode: 'f69d', label: 'The Red Yeti', name: 'fa-the-red-yeti' },
  { id: 62918, unicode: 'f5c6', label: 'Themeco', name: 'fa-themeco' },
  { id: 62130, unicode: 'f2b2', label: 'Themeisle', name: 'fa-themeisle' },
  { id: 62609, unicode: 'f491', label: 'Thermometer', name: 'fa-thermometer' },
  { id: 63134, unicode: 'f69e', label: 'Theta', name: 'fa-theta' },
  { id: 63281, unicode: 'f731', label: 'Think Peaks', name: 'fa-think-peaks' },
  { id: 58158, unicode: 'e32e', label: 'Thought Bubble', name: 'fa-thought-bubble' },
  { id: 61797, unicode: 'f165', label: 'Thumbs Down', name: 'fa-thumbs-down' },
  { id: 61796, unicode: 'f164', label: 'Thumbs Up', name: 'fa-thumbs-up' },
  { id: 61581, unicode: 'f08d', label: 'Thumbtack', name: 'fa-thumbtack' },
  { id: 58159, unicode: 'e32f', label: 'Tick', name: 'fa-tick' },
  { id: 61765, unicode: 'f145', label: 'Ticket', name: 'fa-ticket' },
  { id: 58010, unicode: 'e29a', label: 'Ticket Airline', name: 'fa-ticket-airline' },
  { id: 62463, unicode: 'f3ff', label: 'Ticket Simple', name: 'fa-ticket-simple' },
  { id: 58011, unicode: 'e29b', label: 'Tickets Airline', name: 'fa-tickets-airline' },
  { id: 57467, unicode: 'e07b', label: 'Tiktok', name: 'fa-tiktok' },
  { id: 126, unicode: '7e', label: 'Tilde', name: 'fa-tilde' },
  { id: 58012, unicode: 'e29c', label: 'Timeline', name: 'fa-timeline' },
  { id: 58013, unicode: 'e29d', label: 'Timeline Arrow', name: 'fa-timeline-arrow' },
  { id: 58014, unicode: 'e29e', label: 'Timer', name: 'fa-timer' },
  { id: 63025, unicode: 'f631', label: 'Tire', name: 'fa-tire' },
  { id: 63026, unicode: 'f632', label: 'Tire Flat', name: 'fa-tire-flat' },
  { id: 63027, unicode: 'f633', label: 'Tire Pressure Warning', name: 'fa-tire-pressure-warning' },
  { id: 63028, unicode: 'f634', label: 'Tire Rugged', name: 'fa-tire-rugged' },
  { id: 58800, unicode: 'e5b0', label: 'Toggle Large Off', name: 'fa-toggle-large-off' },
  { id: 58801, unicode: 'e5b1', label: 'Toggle Large On', name: 'fa-toggle-large-on' },
  { id: 61956, unicode: 'f204', label: 'Toggle Off', name: 'fa-toggle-off' },
  { id: 61957, unicode: 'f205', label: 'Toggle On', name: 'fa-toggle-on' },
  { id: 63448, unicode: 'f7d8', label: 'Toilet', name: 'fa-toilet' },
  { id: 63262, unicode: 'f71e', label: 'Toilet Paper', name: 'fa-toilet-paper' },
  { id: 63263, unicode: 'f71f', label: 'Toilet Paper Blank', name: 'fa-toilet-paper-blank' },
  { id: 58015, unicode: 'e29f', label: 'Toilet Paper Blank Under', name: 'fa-toilet-paper-blank-under' },
  { id: 58802, unicode: 'e5b2', label: 'Toilet Paper Check', name: 'fa-toilet-paper-check' },
  { id: 57458, unicode: 'e072', label: 'Toilet Paper Slash', name: 'fa-toilet-paper-slash' },
  { id: 58016, unicode: 'e2a0', label: 'Toilet Paper Under', name: 'fa-toilet-paper-under' },
  { id: 58017, unicode: 'e2a1', label: 'Toilet Paper Under Slash', name: 'fa-toilet-paper-under-slash' },
  { id: 58803, unicode: 'e5b3', label: 'Toilet Paper Xmark', name: 'fa-toilet-paper-xmark' },
  { id: 58755, unicode: 'e583', label: 'Toilet Portable', name: 'fa-toilet-portable' },
  { id: 58756, unicode: 'e584', label: 'Toilets Portable', name: 'fa-toilets-portable' },
  { id: 58160, unicode: 'e330', label: 'Tomato', name: 'fa-tomato' },
  { id: 63264, unicode: 'f720', label: 'Tombstone', name: 'fa-tombstone' },
  { id: 63265, unicode: 'f721', label: 'Tombstone Blank', name: 'fa-tombstone-blank' },
  { id: 62802, unicode: 'f552', label: 'Toolbox', name: 'fa-toolbox' },
  { id: 62921, unicode: 'f5c9', label: 'Tooth', name: 'fa-tooth' },
  { id: 63029, unicode: 'f635', label: 'Toothbrush', name: 'fa-toothbrush' },
  { id: 63137, unicode: 'f6a1', label: 'Torii Gate', name: 'fa-torii-gate' },
  { id: 63343, unicode: 'f76f', label: 'Tornado', name: 'fa-tornado' },
  { id: 62745, unicode: 'f519', label: 'Tower Broadcast', name: 'fa-tower-broadcast' },
  { id: 58757, unicode: 'e585', label: 'Tower Cell', name: 'fa-tower-cell' },
  { id: 58018, unicode: 'e2a2', label: 'Tower Control', name: 'fa-tower-control' },
  { id: 58758, unicode: 'e586', label: 'Tower Observation', name: 'fa-tower-observation' },
  { id: 63266, unicode: 'f722', label: 'Tractor', name: 'fa-tractor' },
  { id: 62739, unicode: 'f513', label: 'Trade Federation', name: 'fa-trade-federation' },
  { id: 62044, unicode: 'f25c', label: 'Trademark', name: 'fa-trademark' },
  { id: 63030, unicode: 'f636', label: 'Traffic Cone', name: 'fa-traffic-cone' },
  { id: 63031, unicode: 'f637', label: 'Traffic Light', name: 'fa-traffic-light' },
  { id: 63032, unicode: 'f638', label: 'Traffic Light Go', name: 'fa-traffic-light-go' },
  { id: 63033, unicode: 'f639', label: 'Traffic Light Slow', name: 'fa-traffic-light-slow' },
  { id: 63034, unicode: 'f63a', label: 'Traffic Light Stop', name: 'fa-traffic-light-stop' },
  { id: 57409, unicode: 'e041', label: 'Trailer', name: 'fa-trailer' },
  { id: 62008, unicode: 'f238', label: 'Train', name: 'fa-train' },
  { id: 62009, unicode: 'f239', label: 'Train Subway', name: 'fa-train-subway' },
  { id: 58019, unicode: 'e2a3', label: 'Train Subway Tunnel', name: 'fa-train-subway-tunnel' },
  { id: 58451, unicode: 'e453', label: 'Train Track', name: 'fa-train-track' },
  { id: 58804, unicode: 'e5b4', label: 'Train Tram', name: 'fa-train-tram' },
  { id: 58452, unicode: 'e454', label: 'Train Tunnel', name: 'fa-train-tunnel' },
  { id: 58020, unicode: 'e2a4', label: 'Transformer Bolt', name: 'fa-transformer-bolt' },
  { id: 61989, unicode: 'f225', label: 'Transgender', name: 'fa-transgender' },
  { id: 57410, unicode: 'e042', label: 'Transporter', name: 'fa-transporter' },
  { id: 57411, unicode: 'e043', label: 'Transporter 1', name: 'fa-transporter-1' },
  { id: 57412, unicode: 'e044', label: 'Transporter 2', name: 'fa-transporter-2' },
  { id: 57413, unicode: 'e045', label: 'Transporter 3', name: 'fa-transporter-3' },
  { id: 58021, unicode: 'e2a5', label: 'Transporter 4', name: 'fa-transporter-4' },
  { id: 58022, unicode: 'e2a6', label: 'Transporter 5', name: 'fa-transporter-5' },
  { id: 58023, unicode: 'e2a7', label: 'Transporter 6', name: 'fa-transporter-6' },
  { id: 58024, unicode: 'e2a8', label: 'Transporter 7', name: 'fa-transporter-7' },
  { id: 57414, unicode: 'e046', label: 'Transporter Empty', name: 'fa-transporter-empty' },
  { id: 61944, unicode: 'f1f8', label: 'Trash', name: 'fa-trash' },
  { id: 63529, unicode: 'f829', label: 'Trash Arrow Up', name: 'fa-trash-arrow-up' },
  { id: 62189, unicode: 'f2ed', label: 'Trash Can', name: 'fa-trash-can' },
  { id: 63530, unicode: 'f82a', label: 'Trash Can Arrow Up', name: 'fa-trash-can-arrow-up' },
  { id: 58025, unicode: 'e2a9', label: 'Trash Can Check', name: 'fa-trash-can-check' },
  { id: 58026, unicode: 'e2aa', label: 'Trash Can Clock', name: 'fa-trash-can-clock' },
  { id: 58027, unicode: 'e2ab', label: 'Trash Can List', name: 'fa-trash-can-list' },
  { id: 58028, unicode: 'e2ac', label: 'Trash Can Plus', name: 'fa-trash-can-plus' },
  { id: 58029, unicode: 'e2ad', label: 'Trash Can Slash', name: 'fa-trash-can-slash' },
  { id: 63638, unicode: 'f896', label: 'Trash Can Undo', name: 'fa-trash-can-undo' },
  { id: 58030, unicode: 'e2ae', label: 'Trash Can Xmark', name: 'fa-trash-can-xmark' },
  { id: 58031, unicode: 'e2af', label: 'Trash Check', name: 'fa-trash-check' },
  { id: 58032, unicode: 'e2b0', label: 'Trash Clock', name: 'fa-trash-clock' },
  { id: 58033, unicode: 'e2b1', label: 'Trash List', name: 'fa-trash-list' },
  { id: 58034, unicode: 'e2b2', label: 'Trash Plus', name: 'fa-trash-plus' },
  { id: 58035, unicode: 'e2b3', label: 'Trash Slash', name: 'fa-trash-slash' },
  { id: 63637, unicode: 'f895', label: 'Trash Undo', name: 'fa-trash-undo' },
  { id: 58036, unicode: 'e2b4', label: 'Trash Xmark', name: 'fa-trash-xmark' },
  { id: 63267, unicode: 'f723', label: 'Treasure Chest', name: 'fa-treasure-chest' },
  { id: 61883, unicode: 'f1bb', label: 'Tree', name: 'fa-tree' },
  { id: 63451, unicode: 'f7db', label: 'Christmas Tree', name: 'fa-christmas-tree' },
  { id: 58759, unicode: 'e587', label: 'Tree City', name: 'fa-tree-city' },
  { id: 62464, unicode: 'f400', label: 'Tree Deciduous', name: 'fa-tree-deciduous' },
  { id: 63452, unicode: 'f7dc', label: 'Tree Decorated', name: 'fa-tree-decorated' },
  { id: 63453, unicode: 'f7dd', label: 'Tree Large', name: 'fa-tree-large' },
  { id: 63531, unicode: 'f82b', label: 'Palm Tree', name: 'fa-palm-tree' },
  { id: 63268, unicode: 'f724', label: 'Trees', name: 'fa-trees' },
  { id: 61825, unicode: 'f181', label: 'Trello', name: 'fa-trello' },
  { id: 62188, unicode: 'f2ec', label: 'Triangle', name: 'fa-triangle' },
  { id: 61553, unicode: 'f071', label: 'Triangle Exclamation', name: 'fa-triangle-exclamation' },
  { id: 63714, unicode: 'f8e2', label: 'Triangle Instrument', name: 'fa-triangle-instrument' },
  { id: 63581, unicode: 'f85d', label: 'Triangle Person Digging', name: 'fa-triangle-person-digging' },
  { id: 58760, unicode: 'e588', label: 'Trillium', name: 'fa-trillium' },
  { id: 61585, unicode: 'f091', label: 'Trophy', name: 'fa-trophy' },
  { id: 62187, unicode: 'f2eb', label: 'Trophy Star', name: 'fa-trophy-star' },
  { id: 58761, unicode: 'e589', label: 'Trowel', name: 'fa-trowel' },
  { id: 58762, unicode: 'e58a', label: 'Trowel Bricks', name: 'fa-trowel-bricks' },
  { id: 61649, unicode: 'f0d1', label: 'Truck', name: 'fa-truck' },
  { id: 58763, unicode: 'e58b', label: 'Truck Arrow Right', name: 'fa-truck-arrow-right' },
  { id: 58320, unicode: 'e3d0', label: 'Truck Bolt', name: 'fa-truck-bolt' },
  { id: 62604, unicode: 'f48c', label: 'Truck Clock', name: 'fa-truck-clock' },
  { id: 62684, unicode: 'f4dc', label: 'Truck Container', name: 'fa-truck-container' },
  { id: 58037, unicode: 'e2b5', label: 'Truck Container Empty', name: 'fa-truck-container-empty' },
  { id: 58764, unicode: 'e58c', label: 'Truck Droplet', name: 'fa-truck-droplet' },
  { id: 62603, unicode: 'f48b', label: 'Truck Fast', name: 'fa-truck-fast' },
  { id: 58765, unicode: 'e58d', label: 'Truck Field', name: 'fa-truck-field' },
  { id: 58766, unicode: 'e58e', label: 'Truck Field Un', name: 'fa-truck-field-un' },
  { id: 58038, unicode: 'e2b6', label: 'Truck Flatbed', name: 'fa-truck-flatbed' },
  { id: 58039, unicode: 'e2b7', label: 'Truck Front', name: 'fa-truck-front' },
  { id: 61689, unicode: 'f0f9', label: 'Truck Medical', name: 'fa-truck-medical' },
  { id: 63035, unicode: 'f63b', label: 'Truck Monster', name: 'fa-truck-monster' },
  { id: 62687, unicode: 'f4df', label: 'Truck Moving', name: 'fa-truck-moving' },
  { id: 63036, unicode: 'f63c', label: 'Truck Side', name: 'fa-truck-side' },
  { id: 58767, unicode: 'e58f', label: 'Truck Plane', name: 'fa-truck-plane' },
  { id: 63454, unicode: 'f7de', label: 'Truck Plow', name: 'fa-truck-plow' },
  { id: 62688, unicode: 'f4e0', label: 'Truck Ramp', name: 'fa-truck-ramp' },
  { id: 62686, unicode: 'f4de', label: 'Truck Ramp Box', name: 'fa-truck-ramp-box' },
  { id: 62685, unicode: 'f4dd', label: 'Truck Ramp Couch', name: 'fa-truck-ramp-couch' },
  { id: 58040, unicode: 'e2b8', label: 'Truck Tow', name: 'fa-truck-tow' },
  { id: 63715, unicode: 'f8e3', label: 'Trumpet', name: 'fa-trumpet' },
  { id: 61924, unicode: 'f1e4', label: 'TTY', name: 'fa-tty' },
  { id: 58041, unicode: 'e2b9', label: 'Tty Answer', name: 'fa-tty-answer' },
  { id: 58042, unicode: 'e2ba', label: 'Tugrik Sign', name: 'fa-tugrik-sign' },
  { id: 61811, unicode: 'f173', label: 'Tumblr', name: 'fa-tumblr' },
  { id: 63269, unicode: 'f725', label: 'Turkey', name: 'fa-turkey' },
  { id: 58043, unicode: 'e2bb', label: 'Turkish Lira Sign', name: 'fa-turkish-lira-sign' },
  { id: 62398, unicode: 'f3be', label: 'Turn Down', name: 'fa-turn-down' },
  { id: 58161, unicode: 'e331', label: 'Turn Down Left', name: 'fa-turn-down-left' },
  { id: 58453, unicode: 'e455', label: 'Turn Down Right', name: 'fa-turn-down-right' },
  { id: 62399, unicode: 'f3bf', label: 'Turn Up', name: 'fa-turn-up' },
  { id: 63716, unicode: 'f8e4', label: 'Turntable', name: 'fa-turntable' },
  { id: 63270, unicode: 'f726', label: 'Turtle', name: 'fa-turtle' },
  { id: 62060, unicode: 'f26c', label: 'Television', name: 'fa-television' },
  { id: 63718, unicode: 'f8e6', label: 'TV Music', name: 'fa-tv-music' },
  { id: 62465, unicode: 'f401', label: 'Retro Televison', name: 'fa-retro-televison' },
  { id: 61928, unicode: 'f1e8', label: 'Twitch', name: 'fa-twitch' },
  { id: 61593, unicode: 'f099', label: 'Twitter', name: 'fa-twitter' },
  { id: 63719, unicode: 'f8e7', label: 'Typewriter', name: 'fa-typewriter' },
  { id: 62507, unicode: 'f42b', label: 'Typo3', name: 'fa-typo3' },
  { id: 85, unicode: '55', label: 'U', name: 'fa-u' },
  { id: 62466, unicode: 'f402', label: 'Uber', name: 'fa-uber' },
  { id: 63455, unicode: 'f7df', label: 'Ubuntu', name: 'fa-ubuntu' },
  { id: 57415, unicode: 'e047', label: 'U F O ', name: 'fa-u-f-o-' },
  { id: 57416, unicode: 'e048', label: 'U F O  With Beam', name: 'fa-u-f-o--with-beam' },
  { id: 62467, unicode: 'f403', label: 'Uikit', name: 'fa-uikit' },
  { id: 63720, unicode: 'f8e8', label: 'Umbraco', name: 'fa-umbraco' },
  { id: 61673, unicode: 'f0e9', label: 'Umbrella', name: 'fa-umbrella' },
  { id: 62922, unicode: 'f5ca', label: 'Umbrella Beach', name: 'fa-umbrella-beach' },
  { id: 58044, unicode: 'e2bc', label: 'Umbrella Simple', name: 'fa-umbrella-simple' },
  { id: 57476, unicode: 'e084', label: 'Uncharted Software', name: 'fa-uncharted-software' },
  { id: 61645, unicode: 'f0cd', label: 'Underline', name: 'fa-underline' },
  { id: 63271, unicode: 'f727', label: 'Unicorn', name: 'fa-unicorn' },
  { id: 58321, unicode: 'e3d1', label: 'Martial Arts Uniform', name: 'fa-martial-arts-uniform' },
  { id: 63138, unicode: 'f6a2', label: 'Union', name: 'fa-union' },
  { id: 62468, unicode: 'f404', label: 'Uniregistry', name: 'fa-uniregistry' },
  { id: 57417, unicode: 'e049', label: 'Unity 3D', name: 'fa-unity-3d' },
  { id: 62106, unicode: 'f29a', label: 'Universal Access', name: 'fa-universal-access' },
  { id: 61596, unicode: 'f09c', label: 'Unlock', name: 'fa-unlock' },
  { id: 61758, unicode: 'f13e', label: 'Unlock Keyhole', name: 'fa-unlock-keyhole' },
  { id: 57468, unicode: 'e07c', label: 'Unsplash', name: 'fa-unsplash' },
  { id: 62469, unicode: 'f405', label: 'Untappd', name: 'fa-untappd' },
  { id: 62295, unicode: 'f357', label: 'Up', name: 'fa-up' },
  { id: 62264, unicode: 'f338', label: 'Up Down', name: 'fa-up-down' },
  { id: 61618, unicode: 'f0b2', label: 'Up Down Left Right', name: 'fa-up-down-left-right' },
  { id: 58768, unicode: 'e590', label: 'Up From Bracket', name: 'fa-up-from-bracket' },
  { id: 58454, unicode: 'e456', label: 'Up From Dotted Line', name: 'fa-up-from-dotted-line' },
  { id: 62278, unicode: 'f346', label: 'Up From Line', name: 'fa-up-from-line' },
  { id: 58045, unicode: 'e2bd', label: 'Up Left', name: 'fa-up-left' },
  { id: 62220, unicode: 'f30c', label: 'Up Long', name: 'fa-up-long' },
  { id: 58046, unicode: 'e2be', label: 'Up Right', name: 'fa-up-right' },
  { id: 62500, unicode: 'f424', label: 'Up Right And Down Left From Center', name: 'fa-up-right-and-down-left-from-center' },
  { id: 62301, unicode: 'f35d', label: 'Up Right From Square', name: 'fa-up-right-from-square' },
  { id: 58455, unicode: 'e457', label: 'Up To Dotted Line', name: 'fa-up-to-dotted-line' },
  { id: 62285, unicode: 'f34d', label: 'Up To Line', name: 'fa-up-to-line' },
  { id: 61587, unicode: 'f093', label: 'Upload', name: 'fa-upload' },
  { id: 63456, unicode: 'f7e0', label: 'UPS', name: 'fa-ups' },
  { id: 62087, unicode: 'f287', label: 'USB', name: 'fa-usb' },
  { id: 63721, unicode: 'f8e9', label: 'USB Drive', name: 'fa-usb-drive' },
  { id: 61447, unicode: 'f007', label: 'User', name: 'fa-user' },
  { id: 57418, unicode: 'e04a', label: 'User Alien', name: 'fa-user-alien' },
  { id: 62715, unicode: 'f4fb', label: 'User Astronaut', name: 'fa-user-astronaut' },
  { id: 58047, unicode: 'e2bf', label: 'User Bounty Hunter', name: 'fa-user-bounty-hunter' },
  { id: 62716, unicode: 'f4fc', label: 'User Check', name: 'fa-user-check' },
  { id: 58322, unicode: 'e3d2', label: 'User Chef', name: 'fa-user-chef' },
  { id: 62717, unicode: 'f4fd', label: 'User Clock', name: 'fa-user-clock' },
  { id: 63722, unicode: 'f8ea', label: 'User Cowboy', name: 'fa-user-cowboy' },
  { id: 63140, unicode: 'f6a4', label: 'User Crown', name: 'fa-user-crown' },
  { id: 61680, unicode: 'f0f0', label: 'User Doctor', name: 'fa-user-doctor' },
  { id: 58456, unicode: 'e458', label: 'User Doctor Hair', name: 'fa-user-doctor-hair' },
  { id: 58457, unicode: 'e459', label: 'User Doctor Hair Long', name: 'fa-user-doctor-hair-long' },
  { id: 63534, unicode: 'f82e', label: 'User Doctor Message', name: 'fa-user-doctor-message' },
  { id: 62718, unicode: 'f4fe', label: 'User Gear', name: 'fa-user-gear' },
  { id: 62721, unicode: 'f501', label: 'User Graduate', name: 'fa-user-graduate' },
  { id: 62720, unicode: 'f500', label: 'User Group', name: 'fa-user-group' },
  { id: 63141, unicode: 'f6a5', label: 'User Group Crown', name: 'fa-user-group-crown' },
  { id: 58458, unicode: 'e45a', label: 'User Hair', name: 'fa-user-hair' },
  { id: 58323, unicode: 'e3d3', label: 'User Hair In Buns', name: 'fa-user-hair-in-buns' },
  { id: 58459, unicode: 'e45b', label: 'User Hair Long', name: 'fa-user-hair-long' },
  { id: 58460, unicode: 'e45c', label: 'User Hair Mullet', name: 'fa-user-hair-mullet' },
  { id: 63533, unicode: 'f82d', label: 'User Headset', name: 'fa-user-headset' },
  { id: 63532, unicode: 'f82c', label: 'User Helmet Safety', name: 'fa-user-helmet-safety' },
  { id: 63272, unicode: 'f728', label: 'User Injured', name: 'fa-user-injured' },
  { id: 62470, unicode: 'f406', label: 'User Large', name: 'fa-user-large' },
  { id: 62714, unicode: 'f4fa', label: 'User Large Slash', name: 'fa-user-large-slash' },
  { id: 62722, unicode: 'f502', label: 'User Lock', name: 'fa-user-lock' },
  { id: 62723, unicode: 'f503', label: 'User Minus', name: 'fa-user-minus' },
  { id: 63723, unicode: 'f8eb', label: 'User Music', name: 'fa-user-music' },
  { id: 62724, unicode: 'f504', label: 'User Ninja', name: 'fa-user-ninja' },
  { id: 63535, unicode: 'f82f', label: 'Nurse', name: 'fa-nurse' },
  { id: 58461, unicode: 'e45d', label: 'User Nurse Hair', name: 'fa-user-nurse-hair' },
  { id: 58462, unicode: 'e45e', label: 'User Nurse Hair Long', name: 'fa-user-nurse-hair-long' },
  { id: 62719, unicode: 'f4ff', label: 'User Pen', name: 'fa-user-pen' },
  { id: 58048, unicode: 'e2c0', label: 'User Pilot', name: 'fa-user-pilot' },
  { id: 58049, unicode: 'e2c1', label: 'User Pilot Tie', name: 'fa-user-pilot-tie' },
  { id: 62004, unicode: 'f234', label: 'User Plus', name: 'fa-user-plus' },
  { id: 58163, unicode: 'e333', label: 'User Police', name: 'fa-user-police' },
  { id: 58164, unicode: 'e334', label: 'User Police Tie', name: 'fa-user-police-tie' },
  { id: 57419, unicode: 'e04b', label: 'User Robot', name: 'fa-user-robot' },
  { id: 58535, unicode: 'e4a7', label: 'User Robot Xmarks', name: 'fa-user-robot-xmarks' },
  { id: 61979, unicode: 'f21b', label: 'User Secret', name: 'fa-user-secret' },
  { id: 58050, unicode: 'e2c2', label: 'User Shakespeare', name: 'fa-user-shakespeare' },
  { id: 62725, unicode: 'f505', label: 'User Shield', name: 'fa-user-shield' },
  { id: 62726, unicode: 'f506', label: 'User Slash', name: 'fa-user-slash' },
  { id: 62727, unicode: 'f507', label: 'User Tag', name: 'fa-user-tag' },
  { id: 62728, unicode: 'f508', label: 'User Tie', name: 'fa-user-tie' },
  { id: 58463, unicode: 'e45f', label: 'User Tie Hair', name: 'fa-user-tie-hair' },
  { id: 58464, unicode: 'e460', label: 'User Tie Hair Long', name: 'fa-user-tie-hair-long' },
  { id: 57432, unicode: 'e058', label: 'User Unlock', name: 'fa-user-unlock' },
  { id: 57420, unicode: 'e04c', label: 'User Visor', name: 'fa-user-visor' },
  { id: 58465, unicode: 'e461', label: 'User Vneck', name: 'fa-user-vneck' },
  { id: 58466, unicode: 'e462', label: 'User Vneck Hair', name: 'fa-user-vneck-hair' },
  { id: 58467, unicode: 'e463', label: 'User Vneck Hair Long', name: 'fa-user-vneck-hair-long' },
  { id: 62005, unicode: 'f235', label: 'User X Mark', name: 'fa-user-x-mark' },
  { id: 61632, unicode: 'f0c0', label: 'Users', name: 'fa-users' },
  { id: 58769, unicode: 'e591', label: 'Users Between Lines', name: 'fa-users-between-lines' },
  { id: 62729, unicode: 'f509', label: 'Users Gear', name: 'fa-users-gear' },
  { id: 58770, unicode: 'e592', label: 'Users Line', name: 'fa-users-line' },
  { id: 63536, unicode: 'f830', label: 'Users With Medical Symbol', name: 'fa-users-with-medical-symbol' },
  { id: 58771, unicode: 'e593', label: 'Users Rays', name: 'fa-users-rays' },
  { id: 58772, unicode: 'e594', label: 'Users Rectangle', name: 'fa-users-rectangle' },
  { id: 57459, unicode: 'e073', label: 'Users Slash', name: 'fa-users-slash' },
  { id: 58773, unicode: 'e595', label: 'Users Viewfinder', name: 'fa-users-viewfinder' },
  { id: 63457, unicode: 'f7e1', label: 'United States Postal Service', name: 'fa-united-states-postal-service' },
  { id: 62471, unicode: 'f407', label: 'Us Sunnah Foundation', name: 'fa-us-sunnah-foundation' },
  { id: 62183, unicode: 'f2e7', label: 'Utensils', name: 'fa-utensils' },
  { id: 58468, unicode: 'e464', label: 'Utensils Slash', name: 'fa-utensils-slash' },
  { id: 58051, unicode: 'e2c3', label: 'Utility Pole', name: 'fa-utility-pole' },
  { id: 58052, unicode: 'e2c4', label: 'Utility Pole Double', name: 'fa-utility-pole-double' },
  { id: 86, unicode: '56', label: 'V', name: 'fa-v' },
  { id: 62472, unicode: 'f408', label: 'Vaadin', name: 'fa-vaadin' },
  { id: 57421, unicode: 'e04d', label: 'Vacuum', name: 'fa-vacuum' },
  { id: 57422, unicode: 'e04e', label: 'Robot Vacuum', name: 'fa-robot-vacuum' },
  { id: 63142, unicode: 'f6a6', label: 'Value Absolute', name: 'fa-value-absolute' },
  { id: 62902, unicode: 'f5b6', label: 'Van Shuttle', name: 'fa-van-shuttle' },
  { id: 58053, unicode: 'e2c5', label: 'Vault', name: 'fa-vault' },
  { id: 58054, unicode: 'e2c6', label: 'Vector Circle', name: 'fa-vector-circle' },
  { id: 58055, unicode: 'e2c7', label: 'Vector Polygon', name: 'fa-vector-polygon' },
  { id: 62923, unicode: 'f5cb', label: 'Vector Square', name: 'fa-vector-square' },
  { id: 58469, unicode: 'e465', label: 'Vent Damper', name: 'fa-vent-damper' },
  { id: 61985, unicode: 'f221', label: 'Venus', name: 'fa-venus' },
  { id: 61990, unicode: 'f226', label: 'Venus Double', name: 'fa-venus-double' },
  { id: 61992, unicode: 'f228', label: 'Venus Mars', name: 'fa-venus-mars' },
  { id: 57477, unicode: 'e085', label: 'Vest', name: 'fa-vest' },
  { id: 57478, unicode: 'e086', label: 'Vest Patches', name: 'fa-vest-patches' },
  { id: 62007, unicode: 'f237', label: 'Viacoin', name: 'fa-viacoin' },
  { id: 62121, unicode: 'f2a9', label: 'Viadeo', name: 'fa-viadeo' },
  { id: 62610, unicode: 'f492', label: 'Vial', name: 'fa-vial' },
  { id: 58774, unicode: 'e596', label: 'Vial Circle Check', name: 'fa-vial-circle-check' },
  { id: 58775, unicode: 'e597', label: 'Vial Virus', name: 'fa-vial-virus' },
  { id: 62611, unicode: 'f493', label: 'Vials', name: 'fa-vials' },
  { id: 62473, unicode: 'f409', label: 'Viber', name: 'fa-viber' },
  { id: 61501, unicode: 'f03d', label: 'Video', name: 'fa-video' },
  { id: 58056, unicode: 'e2c8', label: 'Video Arrow Down Left', name: 'fa-video-arrow-down-left' },
  { id: 58057, unicode: 'e2c9', label: 'Video Arrow Up Right', name: 'fa-video-arrow-up-right' },
  { id: 62689, unicode: 'f4e1', label: 'Video Plus', name: 'fa-video-plus' },
  { id: 62690, unicode: 'f4e2', label: 'Video Slash', name: 'fa-video-slash' },
  { id: 63143, unicode: 'f6a7', label: 'Vihara', name: 'fa-vihara' },
  { id: 62474, unicode: 'f40a', label: 'Vimeo', name: 'fa-vimeo' },
  { id: 62077, unicode: 'f27d', label: 'Vimeo', name: 'fa-vimeo' },
  { id: 61898, unicode: 'f1ca', label: 'Vine', name: 'fa-vine' },
  { id: 63725, unicode: 'f8ed', label: 'Violin', name: 'fa-violin' },
  { id: 57460, unicode: 'e074', label: 'Virus', name: 'fa-virus' },
  { id: 58536, unicode: 'e4a8', label: 'Virus Covid', name: 'fa-virus-covid' },
  { id: 58537, unicode: 'e4a9', label: 'Virus Covid Slash', name: 'fa-virus-covid-slash' },
  { id: 57461, unicode: 'e075', label: 'Virus Slash', name: 'fa-virus-slash' },
  { id: 57462, unicode: 'e076', label: 'Viruses', name: 'fa-viruses' },
  { id: 61833, unicode: 'f189', label: 'VK', name: 'fa-vk' },
  { id: 62475, unicode: 'f40b', label: 'VNV', name: 'fa-vnv' },
  { id: 63639, unicode: 'f897', label: 'Voicemail', name: 'fa-voicemail' },
  { id: 63344, unicode: 'f770', label: 'Volcano', name: 'fa-volcano' },
  { id: 62559, unicode: 'f45f', label: 'Volleyball Ball', name: 'fa-volleyball-ball' },
  { id: 63144, unicode: 'f6a8', label: 'Volume', name: 'fa-volume' },
  { id: 61480, unicode: 'f028', label: 'Volume High', name: 'fa-volume-high' },
  { id: 61479, unicode: 'f027', label: 'Volume Low', name: 'fa-volume-low' },
  { id: 61478, unicode: 'f026', label: 'Volume Off', name: 'fa-volume-off' },
  { id: 62178, unicode: 'f2e2', label: 'Volume Slash', name: 'fa-volume-slash' },
  { id: 63145, unicode: 'f6a9', label: 'Volume X Mark', name: 'fa-volume-x-mark' },
  { id: 63273, unicode: 'f729', label: 'Cardboard VR', name: 'fa-cardboard-vr' },
  { id: 62495, unicode: 'f41f', label: 'Vue Js', name: 'fa-vue-js' },
  { id: 87, unicode: '57', label: 'W', name: 'fa-w' },
  { id: 58470, unicode: 'e466', label: 'Waffle', name: 'fa-waffle' },
  { id: 63726, unicode: 'f8ee', label: 'Covered Wagon', name: 'fa-covered-wagon' },
  { id: 63537, unicode: 'f831', label: 'Walker', name: 'fa-walker' },
  { id: 63727, unicode: 'f8ef', label: 'Walkie Talkie', name: 'fa-walkie-talkie' },
  { id: 62805, unicode: 'f555', label: 'Wallet', name: 'fa-wallet' },
  { id: 63274, unicode: 'f72a', label: 'Wand', name: 'fa-wand' },
  { id: 61648, unicode: 'f0d0', label: 'Wand Magic', name: 'fa-wand-magic' },
  { id: 58058, unicode: 'e2ca', label: 'Wand Magic Sparkles', name: 'fa-wand-magic-sparkles' },
  { id: 63275, unicode: 'f72b', label: 'Wand Sparkles', name: 'fa-wand-sparkles' },
  { id: 62612, unicode: 'f494', label: 'Warehouse', name: 'fa-warehouse' },
  { id: 62613, unicode: 'f495', label: 'Warehouse Full', name: 'fa-warehouse-full' },
  { id: 63640, unicode: 'f898', label: 'Washing Machine', name: 'fa-washing-machine' },
  { id: 62177, unicode: 'f2e1', label: 'Watch', name: 'fa-watch' },
  { id: 58059, unicode: 'e2cb', label: 'Watch Apple', name: 'fa-watch-apple' },
  { id: 63728, unicode: 'f8f0', label: 'Calculator Watch', name: 'fa-calculator-watch' },
  { id: 63038, unicode: 'f63e', label: 'Watch Fitness', name: 'fa-watch-fitness' },
  { id: 58060, unicode: 'e2cc', label: 'Watch Smart', name: 'fa-watch-smart' },
  { id: 57479, unicode: 'e087', label: 'Watchman Monitoring', name: 'fa-watchman-monitoring' },
  { id: 63347, unicode: 'f773', label: 'Water', name: 'fa-water' },
  { id: 63348, unicode: 'f774', label: 'Water Arrow Down', name: 'fa-water-arrow-down' },
  { id: 63349, unicode: 'f775', label: 'Water Arrow Up', name: 'fa-water-arrow-up' },
  { id: 62917, unicode: 'f5c5', label: 'Water Ladder', name: 'fa-water-ladder' },
  { id: 58167, unicode: 'e337', label: 'Watermelon Slice', name: 'fa-watermelon-slice' },
  { id: 62968, unicode: 'f5f8', label: 'Wave Pulse', name: 'fa-wave-pulse' },
  { id: 63641, unicode: 'f899', label: 'Sine Wave', name: 'fa-sine-wave' },
  { id: 63550, unicode: 'f83e', label: 'Square Wave', name: 'fa-square-wave' },
  { id: 63642, unicode: 'f89a', label: 'Triangle Wave', name: 'fa-triangle-wave' },
  { id: 63729, unicode: 'f8f1', label: 'Waveform', name: 'fa-waveform' },
  { id: 63730, unicode: 'f8f2', label: 'Waveform Lines', name: 'fa-waveform-lines' },
  { id: 63551, unicode: 'f83f', label: 'Waze', name: 'fa-waze' },
  { id: 62924, unicode: 'f5cc', label: 'Weebly', name: 'fa-weebly' },
  { id: 61834, unicode: 'f18a', label: 'Weibo', name: 'fa-weibo' },
  { id: 62925, unicode: 'f5cd', label: 'Hanging Weight', name: 'fa-hanging-weight' },
  { id: 62614, unicode: 'f496', label: 'Weight Scale', name: 'fa-weight-scale' },
  { id: 61911, unicode: 'f1d7', label: 'Weixin  Wechat ', name: 'fa-weixin--wechat-' },
  { id: 63276, unicode: 'f72c', label: 'Whale', name: 'fa-whale' },
  { id: 62002, unicode: 'f232', label: 'What S App', name: 'fa-what-s-app' },
  { id: 63277, unicode: 'f72d', label: 'Wheat', name: 'fa-wheat' },
  { id: 58061, unicode: 'e2cd', label: 'Wheat Awn', name: 'fa-wheat-awn' },
  { id: 58776, unicode: 'e598', label: 'Wheat Awn Circle Exclamation', name: 'fa-wheat-awn-circle-exclamation' },
  { id: 58168, unicode: 'e338', label: 'Wheat Awn Slash', name: 'fa-wheat-awn-slash' },
  { id: 58169, unicode: 'e339', label: 'Wheat Slash', name: 'fa-wheat-slash' },
  { id: 61843, unicode: 'f193', label: 'Wheelchair', name: 'fa-wheelchair' },
  { id: 58062, unicode: 'e2ce', label: 'Wheelchair Move', name: 'fa-wheelchair-move' },
  { id: 63392, unicode: 'f7a0', label: 'Whiskey Glass', name: 'fa-whiskey-glass' },
  { id: 63393, unicode: 'f7a1', label: 'Whiskey Glass Ice', name: 'fa-whiskey-glass-ice' },
  { id: 62560, unicode: 'f460', label: 'Whistle', name: 'fa-whistle' },
  { id: 62477, unicode: 'f40d', label: 'WHMCS', name: 'fa-whmcs' },
  { id: 61931, unicode: 'f1eb', label: 'Wifi', name: 'fa-wifi' },
  { id: 58063, unicode: 'e2cf', label: 'Wifi Exclamation', name: 'fa-wifi-exclamation' },
  { id: 63147, unicode: 'f6ab', label: 'Wifi Fair', name: 'fa-wifi-fair' },
  { id: 63148, unicode: 'f6ac', label: 'Wifi Slash', name: 'fa-wifi-slash' },
  { id: 63146, unicode: 'f6aa', label: 'Wifi Weak', name: 'fa-wifi-weak' },
  { id: 62054, unicode: 'f266', label: 'Wikipedia W', name: 'fa-wikipedia-w' },
  { id: 63278, unicode: 'f72e', label: 'Wind', name: 'fa-wind' },
  { id: 63643, unicode: 'f89b', label: 'Wind Turbine', name: 'fa-wind-turbine' },
  { id: 63350, unicode: 'f776', label: 'Wind Warning', name: 'fa-wind-warning' },
  { id: 62478, unicode: 'f40e', label: 'Window', name: 'fa-window' },
  { id: 62479, unicode: 'f40f', label: 'Window Flip', name: 'fa-window-flip' },
  { id: 57423, unicode: 'e04f', label: 'House Window', name: 'fa-house-window' },
  { id: 57424, unicode: 'e050', label: 'House Window Open', name: 'fa-house-window-open' },
  { id: 62160, unicode: 'f2d0', label: 'Window Maximize', name: 'fa-window-maximize' },
  { id: 62161, unicode: 'f2d1', label: 'Window Minimize', name: 'fa-window-minimize' },
  { id: 62162, unicode: 'f2d2', label: 'Window Restore', name: 'fa-window-restore' },
  { id: 61818, unicode: 'f17a', label: 'Windows', name: 'fa-windows' },
  { id: 63351, unicode: 'f777', label: 'Windsock', name: 'fa-windsock' },
  { id: 63279, unicode: 'f72f', label: 'Wine Bottle', name: 'fa-wine-bottle' },
  { id: 62691, unicode: 'f4e3', label: 'Wine Glass', name: 'fa-wine-glass' },
  { id: 62651, unicode: 'f4bb', label: 'Wine Glass Crack', name: 'fa-wine-glass-crack' },
  { id: 62926, unicode: 'f5ce', label: 'Wine Glass Empty', name: 'fa-wine-glass-empty' },
  { id: 58064, unicode: 'e2d0', label: 'Wirsindhandwerk', name: 'fa-wirsindhandwerk' },
  { id: 62927, unicode: 'f5cf', label: 'Wix', name: 'fa-wix' },
  { id: 63280, unicode: 'f730', label: 'Wizards Of The Coast', name: 'fa-wizards-of-the-coast' },
  { id: 57480, unicode: 'e088', label: 'Wodu', name: 'fa-wodu' },
  { id: 62740, unicode: 'f514', label: 'Wolf Pack Battalion', name: 'fa-wolf-pack-battalion' },
  { id: 61785, unicode: 'f159', label: 'Won Sign', name: 'fa-won-sign' },
  { id: 61850, unicode: 'f19a', label: 'Wordpress Logo', name: 'fa-wordpress-logo' },
  { id: 62481, unicode: 'f411', label: 'Wordpress Simple', name: 'fa-wordpress-simple' },
  { id: 58777, unicode: 'e599', label: 'Worm', name: 'fa-worm' },
  { id: 62103, unicode: 'f297', label: 'Wpbeginner', name: 'fa-wpbeginner' },
  { id: 62174, unicode: 'f2de', label: 'Wpexplorer', name: 'fa-wpexplorer' },
  { id: 62104, unicode: 'f298', label: 'Wpforms', name: 'fa-wpforms' },
  { id: 62436, unicode: 'f3e4', label: 'Wpressr', name: 'fa-wpressr' },
  { id: 63458, unicode: 'f7e2', label: 'Wreath', name: 'fa-wreath' },
  { id: 61613, unicode: 'f0ad', label: 'Wrench', name: 'fa-wrench' },
  { id: 58065, unicode: 'e2d1', label: 'Wrench Simple', name: 'fa-wrench-simple' },
  { id: 88, unicode: '58', label: 'X', name: 'fa-x' },
  { id: 62615, unicode: 'f497', label: 'X Ray', name: 'fa-x-ray' },
  { id: 62482, unicode: 'f412', label: 'Xbox', name: 'fa-xbox' },
  { id: 61800, unicode: 'f168', label: 'Xing', name: 'fa-xing' },
  { id: 61453, unicode: 'f00d', label: 'X Mark', name: 'fa-x-mark' },
  { id: 58779, unicode: 'e59b', label: 'Xmark Large', name: 'fa-xmark-large' },
  { id: 63345, unicode: 'f771', label: 'X Mark To Slot', name: 'fa-x-mark-to-slot' },
  { id: 58778, unicode: 'e59a', label: 'Xmarks Lines', name: 'fa-xmarks-lines' },
  { id: 89, unicode: '59', label: 'Y', name: 'fa-y' },
  { id: 62011, unicode: 'f23b', label: 'Y Combinator', name: 'fa-y-combinator' },
  { id: 61854, unicode: 'f19e', label: 'Yahoo Logo', name: 'fa-yahoo-logo' },
  { id: 63552, unicode: 'f840', label: 'Yammer', name: 'fa-yammer' },
  { id: 62483, unicode: 'f413', label: 'Yandex', name: 'fa-yandex' },
  { id: 62484, unicode: 'f414', label: 'Yandex International', name: 'fa-yandex-international' },
  { id: 63459, unicode: 'f7e3', label: 'Yarn', name: 'fa-yarn' },
  { id: 61929, unicode: 'f1e9', label: 'Yelp', name: 'fa-yelp' },
  { id: 61783, unicode: 'f157', label: 'Yen Sign', name: 'fa-yen-sign' },
  { id: 63149, unicode: 'f6ad', label: 'Yin Yang', name: 'fa-yin-yang' },
  { id: 62129, unicode: 'f2b1', label: 'Yoast', name: 'fa-yoast' },
  { id: 61799, unicode: 'f167', label: 'Youtube', name: 'fa-youtube' },
  { id: 90, unicode: '5a', label: 'Z', name: 'fa-z' },
  { id: 63039, unicode: 'f63f', label: 'Zhihu', name: 'fa-zhihu' },
];
