import { forwardRef, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import NavBar from './NavBar';
import { useAppDispatch, useAppSelector } from '@hooks';
import { closeSnackbar, setNavigationActions, setNavigationContext, setTeamNavigationContext } from '@slices';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Helmet } from 'react-helmet-async';
import { AppBreadcrumbs, UserTeamsInvitationDialog } from '@components';
import { matchPath, useLocation, useParams } from 'react-router';
import { RoutePath } from '@constants';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  children: JSX.Element | JSX.Element[];
};

const LexacomLayout = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const { display, message, severity, position, duration } = useAppSelector((state) => state.snackbar);
  const { helmet } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();

  const close = (): void => {
    dispatch(closeSnackbar());
  };
  useEffect(() => {
    if (location.pathname.includes(RoutePath.TeamsPath) && params?.teamId) {
      dispatch(setNavigationActions(null));
    }
    if (matchPath(location.pathname, RoutePath.TeamsPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
    }
    if (matchPath(location.pathname, RoutePath.AdminCommandsPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
      dispatch(setNavigationContext({ navContext: [] }));
    }
    if (matchPath(location.pathname, RoutePath.AdminFeedbackPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
      dispatch(setNavigationContext({ navContext: [] }));
    }
    if (matchPath(location.pathname, RoutePath.UsersPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
    }
    if (matchPath(location.pathname, RoutePath.WorkflowTemplatesPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
    }
    if (matchPath(location.pathname, RoutePath.SubstitutionsPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
    }
    if (matchPath(location.pathname, RoutePath.RolesPath)) {
      dispatch(setTeamNavigationContext({ teamServices: [], teamId: '', t }));
      dispatch(setNavigationActions(null));
    }
  }, [location]);
  return (
    <>
      <Helmet>
        <title>{helmet.title}</title>
      </Helmet>
      <NavBar />
      <Box className={`w-full h-screen`} component="main">
        <div className="p-8 mt-14">
          <AppBreadcrumbs />
          {children}
          <Snackbar open={display} autoHideDuration={duration} onClose={close} anchorOrigin={position}>
            <Alert onClose={close} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          <UserTeamsInvitationDialog />
        </div>
      </Box>
    </>
  );
};

export default LexacomLayout;
