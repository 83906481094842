import { useGetPermissionsQuery, useGetRolesQuery, useGetServicesQuery } from '@apis';
import { TableIcons, Icons, AddRoleDialogForm, EditRoleDialogForm } from '@components';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { ListRolesResponseItem, UpdateRoleForm } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Chip, Grid, Typography, Paper } from '@mui/material';
import { red, green } from '@constants';
import { setHelmet, setBreadcrumbs } from '@slices';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const RoleList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const tableRef = createRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((state) => state.role);
  const { allPermissions } = useAppSelector((state) => state.permission);
  const { permissionData } = useAppSelector((state) => state.app);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [selectedEditData, setSelectedEditData] = useState<UpdateRoleForm>();
  const [selectedEditServiceId, setSelectedEditServiceId] = useState<number>();
  const [selectedEditRoleId, setSelectedEditRoleId] = useState<number>();
  const [isSysAdmin, setIsSysAdmin] = useState<boolean>(false);

  const { isLoading, isError, refetch } = useGetRolesQuery();

  const handleRefetch = (): void => {
    refetch();
  };
  useGetPermissionsQuery();
  useGetServicesQuery(undefined, { skip: !('pano-role.create' in permissionData) });

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleRoles') }));
    dispatch(setBreadcrumbs([{ text: t('roles') }]));
  }, []);

  const handleOpenEditDialog = (data: ListRolesResponseItem): void => {
    setSelectedEditData({
      ...{
        name: data.name,
        active: data.active,
        permissionIds: Object.values(data.permissions),
      },
    });
    setSelectedEditServiceId(data.serviceId);
    setIsSysAdmin(data.isSysAdmin);
    setSelectedEditRoleId(data.id);
    setIsEditDialogOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
          }}
          actions={
            'pano-role.create' in permissionData
              ? 'pano-role.edit' in permissionData
                ? [
                    {
                      icon: Icons.Add,
                      tooltip: t('addNewRole') as string,
                      isFreeAction: true,
                      onClick: () => {
                        setIsAddDialogOpen(true);
                      },
                    },
                    {
                      icon: Icons.Edit,
                      tooltip: t('editRole') as string,
                      onClick: (_event, data) => {
                        //@ts-ignore
                        handleOpenEditDialog(data);
                      },
                    },
                  ]
                : [
                    {
                      icon: Icons.Add,
                      isFreeAction: true,
                      onClick: () => {
                        setIsAddDialogOpen(true);
                      },
                    },
                  ]
              : 'pano-role.edit' in permissionData
              ? [
                  {
                    icon: Icons.Edit,
                    tooltip: t('editRole') as string,
                    onClick: (_event, data) => {
                      //@ts-ignore
                      handleOpenEditDialog(data);
                    },
                  },
                ]
              : []
          }
          columns={[
            { title: t('name'), field: 'name' },
            {
              title: t('active'),
              field: 'active',
              type: 'boolean',
              align: 'center',
              width: '10%',
              render: (rowData) =>
                rowData.active ? (
                  <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                ) : (
                  <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                ),
            },
            {
              title: t('sysAdmin'),
              field: 'isSysAdmin',
              type: 'boolean',
              align: 'center',
              width: '10%',
              render: (rowData) =>
                rowData.isSysAdmin ? (
                  <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                ) : (
                  <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                ),
            },
            { title: t('service'), field: 'serviceName' },
          ]}
          detailPanel={(rowData) => {
            return (
              <Paper className="w-full p-5 space-y-4 mb-4">
                <Typography variant="subtitle1" gutterBottom>
                  {t('permissions')}
                </Typography>
                <div className="flex w-full flex-wrap flex-row gap-3">
                  {Object.values(rowData.rowData.permissions).map((item, index) => {
                    const permission = allPermissions.find((x) => x.id === item);
                    return permission && <Chip label={permission.public ? permission.name : <i>{permission.name}</i>} key={`${index}`} />;
                  })}
                </div>
              </Paper>
            );
          }}
          data={roles}
          title={t('roles')}
        />
      </Grid>
      <AddRoleDialogForm setOpen={setIsAddDialogOpen} open={isAddDialogOpen} refetch={handleRefetch} />
      {selectedEditData && selectedEditServiceId && selectedEditRoleId && (
        <EditRoleDialogForm
          setOpen={setIsEditDialogOpen}
          open={isEditDialogOpen}
          refetch={handleRefetch}
          data={selectedEditData}
          serviceId={selectedEditServiceId}
          isSysAdmin={isSysAdmin}
          roleId={selectedEditRoleId}
        />
      )}
    </Grid>
  );
};

export default RoleList;
