import { useAuth } from 'oidc-react';
// Import and pages
import { Navigate } from 'react-router-dom';
import { RoutePath } from '../constants/routePaths';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';

/** Represents the callback page in which handles the redirect url and the login process to identity server */
const Auth = (): JSX.Element => {
  const { userData } = useAuth();

  return userData ? (
    <Navigate to={RoutePath.Home} />
  ) : (
    <Grid container className="!mt-20 text-center">
      <Grid container item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={1} sm={2} md={3} lg={3} />
        <Grid item xs={10} sm={8} md={6} lg={6} className="flex-col space-y-4">
          <Box
            className="py-12 flex justify-center items-center"
            sx={(theme) => ({
              backgroundColor: theme.custom.appBarBackground,
            })}>
            <FontAwesomeIcon icon={faKey} className="w-20 h-20 my-0 mx-auto" color="white" />
          </Box>
          <Typography variant="h1" className="text-2xl font-bold">
            Please Wait
          </Typography>
          <LinearProgress color="info" />
          <Typography>Redirecting...</Typography>
        </Grid>
        <Grid item xs={1} sm={2} md={3} lg={3} />
      </Grid>
    </Grid>
  );
};

export default Auth;
