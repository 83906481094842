import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkflowItem, WorkflowStepItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { green, red } from '@constants';
import { TableIcons } from '../shared';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  workflow: WorkflowItem | null;
  isLoading: boolean;
};

const WorkflowTable = ({ workflow, isLoading }: Props) => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <MaterialTable
        icons={TableIcons}
        tableRef={tableRef}
        isLoading={isLoading}
        options={{
          tableLayout: 'auto',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
        }}
        columns={[
          { title: t('stepIndex'), field: 'stepIndex' },
          { title: t('name'), field: 'name' },
          {
            title: t('authorOnly'),
            field: 'authorOnly',
            type: 'boolean',
            render: (rowData) =>
              rowData.authorOnly ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
              ) : (
                <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
              ),
          },
        ]}
        data={workflow?.steps as WorkflowStepItem[]}
        title={t('worflowSteps')}
        detailPanel={(rowData) => {
          return (
            <Paper className="w-full p-5 space-y-4 mb-4">
              <Typography variant="subtitle1" gutterBottom>
                {t('media')}
              </Typography>
              <div className="flex w-full flex-wrap flex-row gap-3">
                {rowData.rowData.stepConfigurations.map((item, index) => {
                  return <Chip label={item.mediaConfiguration.description} key={`${index}`} />;
                })}
              </div>
            </Paper>
          );
        }}
        onRowClick={(_event, _rowData, togglePanel) => {
          if (togglePanel) return togglePanel.toggleDetailPanel();
        }}
      />
    </Grid>
  );
};

export default WorkflowTable;
