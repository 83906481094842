import i18n from 'i18next';
import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { openSnackbar } from '@slices';

const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    let message = '';
    switch (action.payload.status) {
      case 500:
        console.debug('Handling 500 Internal Server Error');
        message = `${i18n.t('errors500')} ${action.payload.ExceptionId}`;
        break;
      case 400:
        console.debug('Handling 400 Bad Request', action.payload);
        message = action.payload.data.message || i18n.t('errors400');
        break;
      case 401:
        console.debug('Handling 401 Unauthorized Request');
        message = action.payload.data.message || i18n.t('errors401');
        break;
      case 403:
        console.debug('Handling 403 Forbidden Request');
        message = action.payload.data.message === 'Forbidden' ? i18n.t('errors403Custom') : action.payload.data.message || i18n.t('errors403');
        break;
      case 404:
        console.debug('Handling 404 Not Found');
        message = action.payload.data.message || i18n.t('errors404');
        break;
      default:
        console.debug(action.payload);
        message = i18n.t('errorsdefault');
        break;
    }
    api.dispatch(openSnackbar({ message: message, severity: 'error', display: true }));
  }
  return next(action);
};

export default rtkQueryErrorLogger;
