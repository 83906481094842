import { useState, useEffect } from 'react';
import { AppBar, Drawer, Icon, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import NavBarMenu from './NavBarMenu';
import NavBarSideMenu from './NavBarSideMenu';
import { useAppDispatch, useAppSelector } from '@hooks';
import { updateSideMenuDrawOpenStatus } from '@slices';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

const drawerWidth = '300px';

const NavBar = (): JSX.Element => {
  const container = window.document.body;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { sideMenuDrawOpenStatus, isDarkMode } = useAppSelector((state) => state.app);
  const { userData } = useAuth();

  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery('(max-width:960px)');
  const isCenter = useMediaQuery('(max-width:768px)');

  const [disabled, setDisabled] = useState(false);

  const lexacomIconBlue = require('../../../../public/img/lexacom-logo-blue.svg');
  const lexacomIconWhite = require('../../../../public/img/lexacom-logo-white.svg');
  const logoImg = require('../../../../public/img/navbar-logo-new.png');

  const handleDrawerOpen = () => {
    dispatch(updateSideMenuDrawOpenStatus(true));
  };

  const handleDrawerClose = () => {
    dispatch(updateSideMenuDrawOpenStatus(false));
  };

  useEffect(() => {
    if (userData != null) {
      setDisabled(false);
      if (!isTabletOrMobile) {
        handleDrawerOpen();
      }
    } else {
      setDisabled(true);
      handleDrawerClose();
    }
  }, [userData, isTabletOrMobile]);

  return (
    <div className="flex shadow-lex-nav z-50">
      <AppBar
        sx={(theme) => ({
          backgroundColor: theme.custom.appBarBackground,
        })}
        position="fixed"
        className={`!z-50 ${
          sideMenuDrawOpenStatus && !isTabletOrMobile ? `!w-[calc(100%-300px)]` : isTabletOrMobile ? 'w-full' : disabled ? 'w-full' : '!w-[calc(100%-59px)]'
        } shadow-none transition-lex-nav-header`}>
        <Toolbar className={`justify-between`}>
          <div className="flex items-center">
            {disabled ? (
              <a className="pr-5" href={t('lexacomWebLink') as string}>
                <img src={logoImg} className={`${isCenter ? 'h-6' : 'h-8'}`} />
              </a>
            ) : (
              isTabletOrMobile && (
                <IconButton
                  color="inherit"
                  aria-label="sideMenuDrawOpenStatus drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  disabled={disabled}
                  className={`!mr-9 ${sideMenuDrawOpenStatus && !isTabletOrMobile ? '!hidden' : ''} !h-[44px]`}>
                  <Icon>
                    <img src={isDarkMode ? lexacomIconBlue : lexacomIconWhite} />
                  </Icon>
                </IconButton>
              )
            )}
          </div>

          <div className={`${isCenter ? '!absolute !right-5 !top-auto !bottom-auto' : '!justify-end'}`}>
            <NavBarMenu />
          </div>
        </Toolbar>
      </AppBar>
      {disabled ? (
        <div></div>
      ) : !isTabletOrMobile ? (
        <Drawer
          variant="permanent"
          className={`${sideMenuDrawOpenStatus ? `w-[300px]` : 'w-[59px] overflow-x-hidden'} flex-shrink-0 whitespace-nowrap transition-lex-nav-side !z-40`}
          classes={{ paper: `${sideMenuDrawOpenStatus ? `w-[300px] overflow-x-hidden` : 'w-[59px] overflow-x-hidden'}` }}>
          {sideMenuDrawOpenStatus && !isTabletOrMobile ? (
            <div className="flex items-center justify-end mr-1 space-x-1 space-y-0 px-2 min-h-[56px] h-16">
              <a className="pr-5" href={t('lexacomWebLink') as string}>
                <img src={logoImg} className={`${isCenter ? 'h-6' : 'h-8'}`} />
              </a>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <FontAwesomeIcon color="#fff" icon={faAngleRight} /> : <FontAwesomeIcon color="#fff" icon={faAngleLeft} />}
              </IconButton>
            </div>
          ) : (
            <div className="flex items-center justify-center space-y-0 min-h-[56px] h-16 w-full">
              <IconButton color="inherit" aria-label="sideMenuDrawOpenStatus drawer" onClick={handleDrawerOpen} disabled={disabled}>
                <Icon>
                  <img src={isDarkMode ? lexacomIconBlue : lexacomIconWhite} />
                </Icon>
              </IconButton>
            </div>
          )}
          <NavBarSideMenu />
        </Drawer>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={sideMenuDrawOpenStatus}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better sideMenuDrawOpenStatus performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}>
          <div className="!flex !items-center !justify-end mr-1 !space-x-1 !space-y-0 h-16">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <FontAwesomeIcon color="#fff" icon={faAngleRight} /> : <FontAwesomeIcon color="#fff" icon={faAngleLeft} />}
            </IconButton>
          </div>
          <div onClick={() => handleDrawerClose()}>
            <NavBarSideMenu />
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default NavBar;
