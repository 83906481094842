import pako from 'pako';
import { Buffer } from 'buffer';

export const encodeAndCompress = (decoded: string): string => {
  const uint16Array = new Uint16Array(decoded.length);
  for (let i = 0; i < decoded.length; i++) {
    uint16Array[i] = decoded.charCodeAt(i);
  }
  const uint8Array = new Uint8Array(uint16Array.buffer);

  const compressedArray = pako.gzip(uint8Array);

  const base64String = Buffer.from(compressedArray).toString('base64');

  return base64String;
};

export const decompressAndDecode = (encoded: string): string => {
  try {
    const compressedArray = Buffer.from(encoded, 'base64');

    const decompressedArray = pako.ungzip(compressedArray);

    const uint16Array = new Uint16Array(decompressedArray.buffer);
    let decodedString = '';
    for (let i = 0; i < uint16Array.length; i++) {
      decodedString += String.fromCharCode(uint16Array[i]);
    }

    return decodedString;
  } catch (error) {
    console.error('Decoding and decompression error:', error);
    throw error;
  }
};
