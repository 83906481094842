import React from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { iconMap } from '@utils';

interface Props {
  unicode: string;
  prefix?: IconPrefix;
  className?: string;
}

export const LexFontAwesomeIcon: React.FC<Props> = ({ unicode, prefix = 'far', className = '' }) => {
  const iconName = iconMap.get(unicode);

  if (!iconName) {
    return null;
  }

  return <FAIcon icon={[prefix, iconName]} className={className} />;
};
