import {
  useGetTeamQuery,
  useGetTeamServicesQuery,
  useGetTeamWorflowFlagQuery,
  useGetTeamWorkFlowStepFiltersQuery,
  useGetTeamWorkFlowsQuery,
  useGetTeamWorkerPoolsQuery,
  usePostWorkflowFiltersMutation,
  usePutWorkflowFlagMutation,
} from '@apis';
import { TeamWorkflowEdit, TeamWorkflowStepFilters } from '@components';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowStepFilterItemTableRow, WorkflowStepItem } from '@interfaces';
import { setBreadcrumbs, setTeamNavigationContext, setHelmet, openSnackbar } from '@slices';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

const TeamWorkflow = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const workflowId = parms?.workflowId ?? '';
  const { team, teamServices } = useAppSelector((x) => x.team);
  const { workFlows, workflowStepFilters, workflowFlag } = useAppSelector((x) => x.mahon);
  const [tableData, setTableData] = useState<WorkflowStepItem[]>([]);
  const [postWorflowStepFilters, { isLoading: isUpdatingFilters }] = usePostWorkflowFiltersMutation();
  const [putWorkflowFlag] = usePutWorkflowFlagMutation();
  const [tableFiltersData, setTableFiltersData] = useState<WorkflowStepFilterItemTableRow[]>([]);
  const [stepsIds, setStepsIds] = useState<number[]>([]);
  const [rowCheck, setRowCheck] = useState<boolean>(false);
  const [workflowFlagId, setWorkflowFlagId] = useState<number>(0);
  const isEditMode = location.pathname.includes('/edit');

  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const {
    isLoading: isGettingWorkFlows,
    isFetching: isFetchingWorkFlows,
    isSuccess,
    isError,
    refetch: refetchWorkflows,
  } = useGetTeamWorkFlowsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const { refetch: refetchGetWorkflowFlag } = useGetTeamWorflowFlagQuery(
    { teamId: parseInt(teamId), workflowId: workflowFlagId },
    {
      refetchOnMountOrArgChange: true,
      skip: workflowFlagId === 0,
    },
  );

  useGetTeamWorkerPoolsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const {
    refetch,
    isLoading: isGettingFilters,
    isSuccess: isGettingFiltersSuccess,
  } = useGetTeamWorkFlowStepFiltersQuery({ teamId: parseInt(teamId), stepIds: stepsIds }, { refetchOnMountOrArgChange: true, skip: stepsIds.length === 0 });

  const onSubmit = () => {
    const groupByStep = {} as any;
    tableData
      .filter((item) => !item.isService)
      .forEach((item) => {
        groupByStep[item.name] = tableFiltersData.filter((x) => x.workflowStepId === item.id);
      });
    for (const step in groupByStep) {
      if (groupByStep[step].length === 0) {
        dispatch(openSnackbar({ message: `${t('atLeastOneStepFilterRequiredForStep')} ${step}`, severity: 'error', display: true }));
        return;
      }
    }

    postWorflowStepFilters({
      data: tableFiltersData,
      teamId: parseInt(teamId),
      workflowId: workFlows.filter((x) => x.id === parseInt(workflowId))[0].workflowId,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          openSnackbar({
            message: `${t('changesStepFiltersSaved')} ${data.affectedJobCount} ${t('jobsThatMightBeAffected')}`,
            severity: 'success',
            display: true,
          }),
        );
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to update team worflow step filters', err);
        return;
      });
  };

  const onReset = (): void => {
    refetch();
  };

  const onRefetchWorkflows = (): void => {
    refetchWorkflows();
  };

  useEffect(() => {
    if (workflowStepFilters) {
      setTableFiltersData([
        ...workflowStepFilters.map((item) => {
          return {
            ...item,
            rowId: uuidv4(),
          };
        }),
      ]);
    }
  }, [workflowStepFilters]);

  useEffect(() => {
    if (!rowCheck && isGettingFiltersSuccess) setRowCheck(true);
  }, [tableFiltersData]);

  useEffect(() => {
    if (isSuccess && !isGettingWorkFlows && !isFetchingWorkFlows) {
      if (team && workFlows.length !== 0) {
        dispatch(
          setBreadcrumbs([
            { text: t('teamPageTitle'), link: '/teams' },
            { text: team.name, link: `/teams/${teamId}` },
            { text: t('workflows'), link: RoutePath.TeamFlowsPath.replace(':teamId', teamId) },
            { text: workFlows.filter((x) => x.id === parseInt(workflowId))[0].name },
          ]),
        );
      }
      if (workflowId) {
        setTableData(workFlows.filter((x) => x.id === parseInt(workflowId))[0].steps);
        setStepsIds(workFlows.filter((x) => x.id === parseInt(workflowId))[0].steps.map((x) => x.id));
        setWorkflowFlagId(workFlows.filter((x) => x.id === parseInt(workflowId))[0].workflowId);
      }
      dispatch(setHelmet({ title: t('htmlTitleTeamWorkflow') }));
    }
  }, [isSuccess, workFlows, workflowId, isGettingWorkFlows, isFetchingWorkFlows, team]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  const handleWorkFlowFlagChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    putWorkflowFlag({
      flagValue: checked,
      teamId: parseInt(teamId),
      workflowId: workFlows.filter((x) => x.id === parseInt(workflowId))[0].workflowId,
    })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetchGetWorkflowFlag();
      })
      .catch((err) => {
        console.debug('Failed while attempting to set workflow flag', err);
        return;
      });
  };

  return isEditMode ? (
    <TeamWorkflowEdit teamId={teamId} workflowId={workflowId} isSuccess={isSuccess} isGettingWorkFlows={isGettingWorkFlows} refetch={onRefetchWorkflows} />
  ) : (
    <TeamWorkflowStepFilters
      t={t}
      workflowFlag={workflowFlag}
      handleWorkFlowFlagChange={handleWorkFlowFlagChange}
      tableRef={tableRef}
      isGettingWorkFlows={isGettingWorkFlows}
      isGettingFilters={isGettingFilters}
      isGettingFiltersSuccess={isGettingFiltersSuccess}
      rowCheck={rowCheck}
      tableData={tableData}
      tableFiltersData={tableFiltersData}
      setTableFiltersData={setTableFiltersData}
      teamId={teamId}
      workflowId={workflowId}
      workFlows={workFlows}
      isUpdatingFilters={isUpdatingFilters}
      onReset={onReset}
      onSubmit={onSubmit}
    />
  );
};

export default TeamWorkflow;
