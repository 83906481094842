import { useDeleteSubstitutionMutation, useGetSubstitutionTypesQuery, useGetSubstitutionsQuery } from '@apis';
import { Icons, TableIcons } from '@components';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { setHelmet, setBreadcrumbs, openSnackbar } from '@slices';
import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Substitutions = () => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useGetSubstitutionTypesQuery(undefined, { refetchOnMountOrArgChange: true });
  const { isLoading, refetch } = useGetSubstitutionsQuery(undefined, { refetchOnMountOrArgChange: true });
  const tableRef = createRef();
  const { substitutions, substitutionTypes } = useAppSelector((x) => x.substitution);
  const subTypesLookup: any = {};
  const getSubTypesLookup = (): any => {
    substitutionTypes.map((item) => {
      subTypesLookup[item.id] = item.name;
    });
    return subTypesLookup;
  };
  const [deleteSubstitution] = useDeleteSubstitutionMutation();

  useEffect(() => {
    dispatch(setHelmet({ title: t('substitutionsTitle') }));
    dispatch(setBreadcrumbs([{ text: t('substitutionsTitle') }]));
  }, []);

  const navigateToSubstitution = (id: number): void => {
    navigate(RoutePath.SubstitutionPath.replace(':substitutionId', id.toString()));
  };

  const handleDelete = (id: number): void => {
    deleteSubstitution(id)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('substitutionDeletedSuccessfully'), severity: 'success', display: true }));
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to delete substitution', err);
        return;
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: Icons.Add,
              tooltip: t('addSubstitution') as string,
              isFreeAction: true,
              onClick: () => {
                navigateToSubstitution(0);
              },
            },
            {
              icon: Icons.Edit,
              tooltip: t('editSubstitution') as string,
              onClick: (_event, data) => {
                //@ts-ignore
                navigateToSubstitution(data.id);
              },
            },
            {
              icon: Icons.DeleteForeverIcon,
              tooltip: t('deleteSubstitution') as string,
              onClick: (_event, data) => {
                //@ts-ignore
                handleDelete(data.id);
              },
            },
          ]}
          columns={[
            { title: t('id'), field: 'id' },
            { title: t('name'), field: 'name' },
            { title: t('pattern'), field: 'pattern' },
            { title: t('replacement'), field: 'replacement' },
            { title: t('description'), field: 'description' },
            { title: t('type'), field: 'type' },
            { title: t('subtype'), field: 'subTypeId', lookup: getSubTypesLookup() },
          ]}
          detailPanel={(rowData) => {
            return (
              <Paper className="w-full p-5 space-y-4 mb-4">
                <Typography variant="subtitle1" gutterBottom>
                  {rowData.rowData.applications.length > 0 && rowData.rowData.applications[0].id ? t('applications') : t('applicationsGlobal')}
                </Typography>
                {rowData.rowData.applications.length > 0 && rowData.rowData.applications[0].id && (
                  <div className="flex w-full flex-wrap flex-row gap-3">
                    {rowData.rowData.applications.map((item, index) => {
                      return <Chip label={item.name} key={`${index}`} />;
                    })}
                  </div>
                )}
                <Typography variant="subtitle1" gutterBottom>
                  {rowData.rowData.teamIds.length > 0 ? t('teamsRestricted') : t('teamsGlobal')}
                </Typography>
              </Paper>
            );
          }}
          data={substitutions}
          title={t('substitutionsTitle')}
        />
      </Grid>
    </Grid>
  );
};

export default Substitutions;
