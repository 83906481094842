import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import { userManager } from '@utils';
import { IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RoutePath } from '@constants';
import { useAuth } from 'oidc-react';
import { logOut } from '@slices';

const NavBarMenu = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const { userData } = useAuth();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();
  const { isDarkMode } = useAppSelector((state) => state.app);

  const isTabletOrMobile = useMediaQuery('(max-width:768px)');

  const desktopMenu = [] as JSX.Element[];
  let loggedInDetails = '';

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(undefined);
  };

  const onLoginButtonClick = (): void => {
    userManager.signinRedirect();
  };

  const onLogoutButtonClicked = (): void => {
    if (userData) {
      dispatch(logOut());
    }
    userManager.signoutRedirect();
  };

  if (userData && !userData.expired) {
    desktopMenu.push(
      <MenuItem key={t('userText')} onClick={() => navigate(RoutePath.UserAccountPath)}>
        {t('userDashboardPageTitle')}
      </MenuItem>,
    );
    desktopMenu.push(
      <MenuItem key={t('logoutText')} onClick={onLogoutButtonClicked}>
        {t('logoutText')}
      </MenuItem>,
    );
  } else {
    desktopMenu.push(
      <MenuItem key={t('loginText')} onClick={onLoginButtonClick}>
        {t('loginText')}
      </MenuItem>,
    );
  }

  if (userData != null) {
    loggedInDetails = isTabletOrMobile ? (userData.profile.given_name as string) : (userData.profile.email as string);
  }

  return (
    <div className="flex space-x-4 items-center" style={{ color: isDarkMode ? '#fff' : '#fff' }}>
      {isTabletOrMobile ? <React.Fragment></React.Fragment> : <span>{loggedInDetails}</span>}
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={faUser} size="sm" color={isDarkMode ? '#fff' : '#fff'} />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        {desktopMenu}
      </Menu>
    </div>
  );
};

export default NavBarMenu;
