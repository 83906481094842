//@ts-nocheck
import { ChangeEvent, FormEvent, ReactNode, useState } from 'react';
import validateCurrencyCode from 'validate-currency-code';

interface Validation {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string | RegExp;
    message: string;
  };
  custom?: {
    isValid: (value: string, matchValue?: string) => boolean;
    message: string;
    matchKey?: string;
    isReverseMatch?: boolean;
  };
  special?: {
    currency: boolean;
    message: string;
  };
}

type Validations<T> = Partial<Record<keyof T, Validation>>;

export interface Options<T> {
  validations?: Validations<T>;
  initialValues: T;
  onSubmit?: () => void;
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;
interface UseForm<T> {
  formData: T;
  errors?: ErrorRecord<T>;
  setFormData: (formData: T) => void;
  handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  handleChange: (
    e:
      | ChangeEvent<HTMLInputElement & HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SyntheticEvent<Element, Event>,
    checked?: boolean | ReactNode,
    selectedValueId?: number | string,
    slectedValueKey?: string,
    mode?: 'upper' | 'lower' | 'normal',
  ) => void;
  hasFormChanged?: boolean;
  setHasFormChanged?: Dispatch<SetStateAction<boolean>>;
}

export const useForm = <T>(options: Options<T>): UseForm<T> => {
  const [data, setData] = useState<T>(options.initialValues);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  const handleChange = (
    e:
      | ChangeEvent<HTMLInputElement & HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SyntheticEvent<Element, Event>,
    checked?: boolean,
    selectedValueId?: number | string,
    slectedValueKey?: string,
    mode?: 'upper' | 'lower' | 'normal',
  ): void => {
    const { value, type, name } = e.target;
    setHasFormChanged(true);
    if (selectedValueId !== undefined) {
      data[slectedValueKey] = selectedValueId ?? '';
      setData({
        ...data,
        [slectedValueKey as keyof typeof data]: selectedValueId,
      });
    } else {
      switch (type) {
        case 'checkbox':
          data[name] = checked;
          setData({
            ...data,
            [name]: checked,
          });
          break;
        case 'number':
          if (e.target['min']) {
            data[name] =
              value.indexOf('.') != -1
                ? parseFloat(value) < e.target['min']
                  ? e.target['min']
                  : parseFloat(value)
                : parseInt(value) < e.target['min']
                ? e.target['min']
                : parseInt(value);
            setData({
              ...data,
              [name]:
                value.indexOf('.') != -1
                  ? parseFloat(value) < e.target['min']
                    ? e.target['min']
                    : parseFloat(value)
                  : parseInt(value) < e.target['min']
                  ? e.target['min']
                  : parseInt(value),
            });
          } else {
            data[name] = value.indexOf('.') != -1 ? parseFloat(value) : parseInt(value);
            setData({
              ...data,
              [name]: value.indexOf('.') != -1 ? parseFloat(value) : parseInt(value),
            });
          }
          break;
        default:
          data[name] = mode === 'upper' ? value.toUpperCase() : mode === 'lower' ? value.toLowerCase() : value;
          setData({
            ...data,
            [name]: mode === 'upper' ? value.toUpperCase() : mode === 'lower' ? value.toLowerCase() : value,
          });
      }
    }

    validateForm(slectedValueKey ? slectedValueKey : name);
  };

  const setFormData = (formData: T): void => {
    setData({ ...formData });
    setErrors({});
  };

  const validateForm = (field?: string): boolean => {
    const validations = 'validations' in options ? options.validations : null;

    if (validations) {
      let valid = true;
      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];
        if (field && field !== key) {
          continue;
        }

        const required = validation && 'required' in validation ? validation.required : null;
        if (required && required.value && !value) {
          valid = false;
          errors[key] = required.message;
        }

        const pattern = validation && 'pattern' in validation ? validation.pattern : null;
        if (pattern && pattern.value && !RegExp(pattern.value).test(String(value))) {
          valid = false;
          errors[key] = pattern.message;
        }

        const special = validation && 'special' in validation ? validation.special : null;
        if (special && special.currency && !validateCurrencyCode(String(value))) {
          valid = false;
          errors[key] = special.message;
        }

        const custom = validation && 'custom' in validation ? validation.custom : null;
        if (custom && custom.isValid) {
          if ('matchKey' in custom) {
            const matchValue = data[custom.matchKey];
            if ('isReverseMatch' in custom && custom.isReverseMatch) {
              if (custom.isValid(String(value), matchValue)) {
                delete errors[custom.matchKey];
              } else if (!custom.isValid(String(value), matchValue)) {
                valid = false;
                errors[custom.matchKey] = custom.message;
              }
            } else if (!custom.isValid(String(value), matchValue)) {
              valid = false;
              errors[key] = custom.message;
            }
          } else if (!custom.isValid(String(value))) {
            valid = false;
            errors[key] = custom.message;
          }
        }
      }
      if (!valid) {
        setErrors({ ...errors });
        return false;
      }
    }
    if (field) {
      delete errors[field];
      setErrors({ ...errors });
    } else {
      setErrors({});
    }
    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (validateForm() && 'onSubmit' in options && options.onSubmit) {
      options.onSubmit();
      setHasFormChanged(false);
    }
  };

  return {
    formData: data,
    setFormData,
    handleChange,
    handleSubmit,
    errors,
    hasFormChanged,
    setHasFormChanged,
  } as UseForm<T>;
};
