import { useGetTeamServicesQuery, useGrantServiceMutation, useRevokeServiceMutation } from '@apis';
import { LicenceDialog, ReasonDialog, ServiceDialog, TableIcons } from '@components';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { green, red } from '@constants';
import { openSnackbar } from '@slices';
import { sysConfig } from '@utils';
import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  teamId: number;
};

const TeamServices = ({ teamId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const tableRef = createRef();
  const { isLoading, refetch } = useGetTeamServicesQuery(teamId, { refetchOnMountOrArgChange: true, skip: teamId < 1 });
  const { teamServices } = useAppSelector((x) => x.team);
  const { userRights } = useAppSelector((x) => x.app);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLicenceDialogOpen, setIsLicenceDialogOpen] = useState<boolean>(false);
  const [mahonDialogOpen, setMahonDialogOpen] = useState<boolean>(false);
  const [selectedServiceId, setSelectedServiceId] = useState<number>(0);
  const echoDuration = '24855.03:14:07';
  const [grantService, { isLoading: isActivating }] = useGrantServiceMutation();
  const [revokeService, { isLoading: isDeactivating }] = useRevokeServiceMutation();

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  const closeLicenceDialog = (): void => {
    setIsLicenceDialogOpen(false);
  };

  const onActivateClick = (serviceId: number): void => {
    console.info(`Activating Service for team ${teamId} - with service id ${serviceId}`);
    setSelectedServiceId(serviceId);

    // note: if we add more custom activation handling we should come up with something a bit more graceful than this
    switch (serviceId) {
      case parseInt(sysConfig.lexacomServiceId):
        setMahonDialogOpen(true);
        break;
      case parseInt(sysConfig.echoServiceId):
        grantService({
          data: {
            ServiceId: serviceId,
            Duration: echoDuration,
          },
          teamId,
        })
          .unwrap()
          .then(() => {
            refetch();
            dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
          })
          .catch((err) => {
            console.debug('Failed while attempting to grant service', err);
            return;
          });
        break;
      default:
        grantService({
          data: {
            ServiceId: serviceId,
          },
          teamId,
        })
          .unwrap()
          .then(() => {
            refetch();
            dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
          })
          .catch((err) => {
            console.debug('Failed while attempting to grant service', err);
            return;
          });
        break;
    }
  };

  const onMahonActivateClick = (isScribe: boolean): void => {
    grantService({
      data: {
        ServiceId: parseInt(sysConfig.lexacomServiceId),
        ScribeOnly: isScribe,
      },
      teamId,
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to grant service', err);
        return;
      });
    setMahonDialogOpen(false);
  };

  const onDeactivateClick = (serviceId: number): void => {
    setIsDialogOpen(true);
    setSelectedServiceId(serviceId);
  };

  const openLicenceDialog = (serviceId: number): void => {
    setSelectedServiceId(serviceId);
    setIsLicenceDialogOpen(true);
  };

  const deactivateService = (reason: string): void => {
    let serviceIds = [] as number[];
    serviceIds.push(selectedServiceId);

    console.info(`Deactivating Service for team ${teamId} - with service ids ${serviceIds}`);
    revokeService({
      serviceIds,
      teamId,
      reason,
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to revoke service', err);
        return;
      });
  };

  const tableComponents = {
    Action: (actionProps: any) => {
      if (actionProps.action.icon === 'licenceAction' && userRights.isSysAdmin) {
        return (
          <Button variant="contained" color="info" className="!mr-2" onClick={() => openLicenceDialog(actionProps.data.id)}>
            {t('licence')}
          </Button>
        );
      } else if (actionProps.action.icon === 'serviceAction') {
        if (userRights.isTeamOwner || userRights.isTeamAdmin) {
          if (actionProps.data.isActivated) {
            return (
              <Button variant="contained" className="!mr-2" color="secondary" onClick={() => onDeactivateClick(actionProps.data.id)} disabled={isDeactivating}>
                {actionProps.data.id == selectedServiceId && isDeactivating && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                {t('deactivateButtonText')}
              </Button>
            );
          } else {
            return (
              <Button variant="contained" color="primary" onClick={() => onActivateClick(actionProps.data.id)} disabled={isActivating}>
                {actionProps.data.id == selectedServiceId && isActivating && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                {t('activateButtonText')}
              </Button>
            );
          }
        }
        return <React.Fragment />;
      } else {
        return <React.Fragment />;
      }
    },
  };

  return (
    <React.Fragment>
      <ServiceDialog
        open={mahonDialogOpen}
        serviceName={t('lexacomServiceTitle')}
        handleClose={() => setMahonDialogOpen(false)}
        handleActivate={onMahonActivateClick}
      />
      <Paper className="w-full p-5" square>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            {t('teamServicesText')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamServicesSubText')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRef}
            options={{
              tableLayout: 'auto',
              headerStyle: { height: '50px' },
              addRowPosition: 'first',
              sorting: false,
              actionsColumnIndex: -1,
              search: false,
              toolbar: false,
              pageSize: 5,
              draggable: false,
              filtering: false,
            }}
            columns={[
              {
                title: '',
                align: 'left',
                width: '100px',
                field: 'logoUri',
                render: (rowData) => <img src={rowData.logoUri} alt={rowData.logoUri} width="50" height="50" />,
              },
              { title: t('name'), align: 'left', field: 'name' },
              { title: t('echoCommandColumnDescription'), align: 'left', field: 'description' },
              {
                title: t('clientUri'),
                field: 'clientUri',
                align: 'left',
                render: (rowData) => (
                  <a href={rowData.clientUri} target="_blank" className="underline text-lex-blue-500">
                    {rowData.clientUri}
                  </a>
                ),
              },
              {
                title: t('activated'),
                align: 'center',
                field: 'isActivated',
                render: (rowData) =>
                  rowData.isActivated ? (
                    <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                  ),
              },
            ]}
            data={teamServices}
            isLoading={isLoading}
            components={tableComponents}
            actions={[
              {
                icon: 'licenceAction',
                onClick: () => {
                  console.debug('Service licence clicked');
                },
              },
              {
                icon: 'serviceAction',
                onClick: () => {
                  console.debug('Service clicked');
                },
              },
            ]}
          />
        </Grid>
      </Paper>

      {userRights.isSysAdmin && (
        <LicenceDialog isDialogOpen={isLicenceDialogOpen} closeDialog={closeLicenceDialog} serviceId={selectedServiceId} teamId={teamId} />
      )}

      <ReasonDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        action={deactivateService}
        title={t('deactivateServiceReasonDialogTitle')}
        text={t('deactivateServiceReasonDialogText')}
      />
    </React.Fragment>
  );
};

export default TeamServices;
