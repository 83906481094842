import { useGetTeamMembersQuery, useGetTeamQuery, useGetTeamServicesQuery, useGetTeamWorkerPoolsQuery, usePutWorkerPoolsMutation } from '@apis';
import { TableIcons, WorkerPoolEditor } from '@components';
import { PoolMemberItem, PoolMemberUserItem, WorkerPoolItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Fade, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { openSnackbar, setBreadcrumbs, setHelmet, setTeamNavigationContext } from '@slices';
import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red, green } from '@constants';

const TeamWorkerPools = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const [ruleSelection, setRuleSelection] = useState(0);
  const [ruleDescription, setRuleDescription] = useState('');
  const [userData, setUserData] = useState<PoolMemberUserItem[]>([]);
  const [poolMembers, setPoolMembers] = useState<PoolMemberItem[]>([]);

  const { workerPools, unlockedWorkerPools } = useAppSelector((x) => x.mahon);
  const { team, teamServices, teamMembers } = useAppSelector((x) => x.team);
  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const { isSuccess: isTeamMembersSuccess, isLoading: isGetWorkersLoading } = useGetTeamMembersQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const {
    refetch,
    isLoading: isGettingWorkerPools,
    isSuccess,
    isError,
  } = useGetTeamWorkerPoolsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const [putWorkerPools, { isLoading: isUpdatingWorkerPools }] = usePutWorkerPoolsMutation();

  const handleTeamWorkerPoolSelection = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let selectedValue = parseInt(event.target.value);
    if (selectedValue != 0) {
      setRuleSelection(selectedValue);
      setUserData(poolMembers.find((rm) => rm.ruleId === selectedValue)?.members ?? []);
      setRuleDescription(workerPools.find((rule) => rule.id === selectedValue)?.description ?? '');
    }
  };

  const updateUserSelection = (selectedUserId: number, enablingCheckBox: boolean, selectedWorkerPoolId: number): void => {
    let updatedPoolMembers = [];
    // Go through all workers in each workerpool
    for (var i = 0; i < poolMembers.length; ++i) {
      let workerPool = poolMembers[i];
      for (var j = 0; j < workerPool.members.length; ++j) {
        if (workerPool.members[j].id === selectedUserId) {
          //State for the selected workerpool is already updated, other workerpools need updating
          if (workerPool.ruleId != selectedWorkerPoolId) workerPool.members[j].tableData.disabled = enablingCheckBox ? true : false;
        }
      }
      updatedPoolMembers.push(workerPool);
    }

    setPoolMembers(updatedPoolMembers);
  };

  const handleUserSelection = (data: PoolMemberUserItem[]) => {
    //Loop over current selected worker pool.
    // for (const rowData of data) {
    //   if (rowData.tableData.disabled) continue;
    //   else updateUserSelection(rowData.id, rowData.tableData.checked, ruleSelection);
    // }

    userData.forEach((rowData, index) => {
      if (data.find((x) => x.id === rowData.id)) userData[index].tableData.checked = true;
      else if (tableRef?.current.dataManager.data.find((x: any) => x.id === rowData.id)) userData[index].tableData.checked = false;
      if (!rowData.tableData.disabled) updateUserSelection(rowData.id, rowData.tableData.checked, ruleSelection);
    });
    setUserData(userData);
  };

  let ruleSelectArea = <div></div>;
  if (isSuccess && Array.isArray(unlockedWorkerPools) && unlockedWorkerPools.length && ruleSelection > 0) {
    let ruleSelectionItems = [] as JSX.Element[];

    unlockedWorkerPools.forEach((rule) => {
      ruleSelectionItems.push(
        <MenuItem key={rule.id} value={rule.id}>
          {rule.name}
        </MenuItem>,
      );
    });

    ruleSelectArea = (
      <TextField
        fullWidth={true}
        placeholder={t('teamWorkerPoolSelection') as string}
        label={t('teamWorkerPoolSelection')}
        value={ruleSelection}
        select
        onChange={handleTeamWorkerPoolSelection}>
        {ruleSelectionItems}
      </TextField>
    );
  }
  useEffect(() => {
    if (isSuccess) {
      if (team) {
        dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('workerpools') }]));
      }
    }
  }, [isSuccess]);

  const handleRefetch = (): void => {
    refetch();
  };

  useEffect(() => {
    if (isSuccess && Array.isArray(unlockedWorkerPools) && unlockedWorkerPools.length) {
      setRuleSelection(unlockedWorkerPools[0].id);
      setRuleDescription(unlockedWorkerPools[0].description);

      if (isTeamMembersSuccess) {
        let poolMembersMap = unlockedWorkerPools.map((rule) => {
          let members = teamMembers.map((teamMember) => {
            let selected = rule.workers.some((w) => w.id === teamMember.id);
            return {
              id: teamMember.id,
              email: teamMember.email,
              givenName: teamMember.givenName,
              familyName: teamMember.familyName,
              membershipPending: teamMember.membershipPending,
              tableData: {
                checked: selected,
                disabled: selected ? false : userAlreadyExistOnAnotherWorkerPool(teamMember.id),
              },
            };
          });

          return {
            ruleId: rule.id,
            members: members,
          };
        });

        setPoolMembers(poolMembersMap);
        setUserData(poolMembersMap.find((rm) => (rm.ruleId = unlockedWorkerPools[0].id))?.members ?? []);
      }
    }
  }, [isSuccess, isTeamMembersSuccess, unlockedWorkerPools]);

  const userTableColumns = [
    { title: 'Given name', field: 'givenName' },
    { title: 'Family name', field: 'familyName' },
    { title: 'Email', field: 'email' },
    {
      title: 'Active Member',
      field: 'membershipPending',
      render: (rowData: PoolMemberUserItem) =>
        rowData.membershipPending ? (
          <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
        ) : (
          <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
        ),
    },
  ];

  const onSubmit = () => {
    let id = 0;
    let name = '';
    let description = '';
    let category = 0;
    let workers: { Id: number; WorkerType: string; TypeName: string }[] = [];

    let workflowRules: any[] = [];

    unlockedWorkerPools.map((teamWorkerPool) => {
      workers = [];

      poolMembers.map((poolMember) => {
        if (poolMember.ruleId === teamWorkerPool.id) {
          id = poolMember.ruleId;
          name = teamWorkerPool.name;
          description = teamWorkerPool.description;
          category = teamWorkerPool.category ?? 0;

          poolMember.members.map((poolMemberMembersItem) => {
            if (poolMemberMembersItem.tableData.checked) {
              workers.push({
                Id: poolMemberMembersItem.id,
                WorkerType: 'User',
                TypeName: 'WorkerIdDto',
              });
            }
          });

          workflowRules.push({
            id,
            name,
            description,
            category,
            workers,
          });
        }
      });
    });
    putWorkerPools({
      data: workflowRules,
      teamId: parseInt(teamId),
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update team worker pools', err);
        return;
      });
  };

  const onReset = (): void => {
    refetch();
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  useEffect(() => {
    if (team) {
      dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('workerpools') }]));
    }
    dispatch(setHelmet({ title: t('htmlTitleTeamWorkerPools') }));
  }, [team]);

  const userAlreadyExistOnAnotherWorkerPool = (userId: number): boolean => {
    for (const workerPool of workerPools) {
      for (const worker of workerPool.workers) {
        if (worker.id === userId) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className="pb-5">
          <Typography variant="h6" gutterBottom>
            {t('teamWorkerPoolsPageTitle')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamWorkerPoolsPageSubtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="pb-5">
          <WorkerPoolEditor refetch={handleRefetch} isLoading={isGettingWorkerPools} teamId={parseInt(teamId)} />
        </Grid>

        <Paper className="p-5 w-full space-y-6">
          <Grid container item xs={12} sm={12} md={12} lg={12} className="items-center pt-4" spacing={2}>
            {isGettingWorkerPools ? (
              <Fade in={isGettingWorkerPools} style={{ transitionDelay: isGettingWorkerPools ? '800ms' : '0ms' }} unmountOnExit>
                <CircularProgress className="circular-button-loader" id="loadingProgress" />
              </Fade>
            ) : (
              <React.Fragment>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {ruleSelectArea}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="body1">{ruleDescription}</Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MaterialTable
              tableRef={tableRef}
              icons={TableIcons}
              isLoading={isGetWorkersLoading}
              options={{
                sorting: false,
                draggable: false,
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => ({
                  disabled: rowData.tableData.disabled,
                }),
              }}
              onSelectionChange={(data) => handleUserSelection(data)}
              columns={userTableColumns}
              data={userData}
              title={t('teamWorkerPoolsUsers')}
            />
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end">
            <Button
              variant="contained"
              color="secondary"
              className="!mr-5"
              disabled={isUpdatingWorkerPools || !(Array.isArray(unlockedWorkerPools) && unlockedWorkerPools.length)}
              onClick={onReset}>
              {isUpdatingWorkerPools && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('cancelButtonText')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={isUpdatingWorkerPools || !(Array.isArray(unlockedWorkerPools) && unlockedWorkerPools.length)}
              onClick={onSubmit}>
              {isUpdatingWorkerPools && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('submitButtonText')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default TeamWorkerPools;
