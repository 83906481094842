import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { sysConfig } from '@utils';
import { SubstitutionDto, SubstitutionForm, SubstitutionTypeDto } from '@interfaces';

export const substitutionApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'substitutionApi',
  endpoints: (builder) => ({
    getSubstitutions: builder.query<SubstitutionDto[], void>({
      query: () => ({
        url: `${sysConfig.echoUri}/api/v2/substitution/`,
        method: 'GET',
      }),
    }),
    getSubstitutionTypes: builder.query<SubstitutionTypeDto[], void>({
      query: () => ({
        url: `${sysConfig.echoUri}/api/v1/substitutiontype/`,
        method: 'GET',
      }),
    }),
    getSubstitution: builder.query<SubstitutionDto, number>({
      query: (id) => ({
        url: `${sysConfig.echoUri}/api/v2/substitution/${id}`,
        method: 'GET',
      }),
    }),
    deleteSubstitution: builder.mutation<void, number>({
      query: (id) => ({
        url: `${sysConfig.echoUri}/api/v2/substitution/${id}`,
        method: 'DELETE',
      }),
    }),
    putSubstitution: builder.mutation<void, any>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v2/substitution/`,
        method: 'PUT',
        body: payload,
      }),
    }),
    postSubstitution: builder.mutation<void, any>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v2/substitution/`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetSubstitutionsQuery,
  useDeleteSubstitutionMutation,
  useGetSubstitutionQuery,
  usePostSubstitutionMutation,
  usePutSubstitutionMutation,
  useGetSubstitutionTypesQuery,
} = substitutionApi;
