import { IconName } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';

const createIconMap = () => {
  const iconMap = new Map<string, IconName>();
  Object.keys(far).forEach((key) => {
    const icon = far[key as keyof typeof far];
    if (icon && icon.icon && icon.icon[3]) {
      iconMap.set(icon.icon[3] as string, icon.iconName as IconName);
    }
  });
  return iconMap;
};

export const iconMap = createIconMap();
