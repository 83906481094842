import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import Spinner from '../Spinner';
import { useAuth } from 'oidc-react';

type Props = {
  children: JSX.Element;
  redirectTo: string;
};

const RequireAuth = ({ children, redirectTo }: Props): JSX.Element => {
  const { userData, isLoading } = useAuth();
  const { isLoggingOut } = useAppSelector((state) => state.app);

  if (isLoggingOut) {
    return <Spinner />;
  }
  if (!userData && !isLoading) {
    return <Navigate to={redirectTo} replace />;
  }
  return isLoading ? <Spinner /> : children;
};

export default RequireAuth;
