import { useGetUserQuery } from '@apis';
import { Icons, TableIcons, UserEditForm } from '@components';
import { RoutePath } from '@constants';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { UserWorkerItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { CircularProgress, Fade, Grid, Paper, Typography } from '@mui/material';
import { red, green } from '@constants';
import { setHelmet, setBreadcrumbs } from '@slices';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const User = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const parms = useParams();
  const userId = parms?.userId ?? '';
  const { isLoading: isGettingUser } = useGetUserQuery(parseInt(userId), {
    refetchOnMountOrArgChange: true,
    skip: userId === '',
  });
  const { userEditData, userTeams } = useAppSelector((x) => x.user);

  const navigateTeam = (id: number): void => {
    navigate(RoutePath.TeamPath.replace(':teamId', `${id}`).replace(':tabName', ''));
  };

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleEditUserDetails') }));
    dispatch(setBreadcrumbs([{ text: t('userPageTitle'), link: '/users' }, { text: userId }]));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" gutterBottom>
          {t('userEditPageTitle')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t('userEditPageSubtitle')}
        </Typography>
      </Grid>

      {isGettingUser ? (
        <Fade in={isGettingUser} style={{ transitionDelay: isGettingUser ? '800ms' : '0ms' }} unmountOnExit>
          <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" id="loadingProgress" />
        </Fade>
      ) : (
        <Grid container item xs={12} sm={12} md={12} lg={12} className="m-0" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className="w-full p-5 space-y-4">
              <UserEditForm editUserData={userEditData as UserWorkerItem} userId={parseInt(userId)} isMyAccountPage={false} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MaterialTable
              icons={TableIcons}
              isLoading={isGettingUser}
              options={{
                sorting: false,
                actionsColumnIndex: -1,
                search: true,
                pageSize: 5,
                draggable: false,
                minBodyHeight: 425,
              }}
              columns={[
                { title: t('id'), field: 'id' },
                { title: t('name'), field: 'name' },
                {
                  title: t('teamMembershipStatus'),
                  field: 'membershipPending',
                  type: 'boolean',
                  align: 'center',
                  render: (rowData) =>
                    rowData.membershipPending ? (
                      <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                    ) : (
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                    ),
                },
              ]}
              data={userTeams}
              title={t('teamsText')}
              actions={[
                {
                  icon: Icons.KeyboardArrowRight,
                  tooltip: t('navigateToTeam') as string,
                  onClick: (_event, rowData) => {
                    //@ts-ignore
                    navigateTeam(rowData.id);
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default User;
