import React, { SyntheticEvent, createRef, useState } from 'react';
import { Autocomplete, Paper, TextField } from '@mui/material';
import MaterialTable from '@material-table/core';
import { Application, ApplicationItem } from '@interfaces';
import { TableIcons, Icons } from '@components';
import { useTranslation } from 'react-i18next';

interface Props {
  selectAppValue: ApplicationItem[];
  handleAddApplication: (event: React.SyntheticEvent<Element, Event>, value: ApplicationItem[]) => void;
  tableData: Application[];
  handleRemoveApplication: (value: number) => void;
  isLoadingApps: boolean;
  applications: ApplicationItem[];
  isLoading: boolean;
}

const SubstitutionApplicationsEditor: React.FC<Props> = ({
  selectAppValue,
  handleAddApplication,
  tableData,
  handleRemoveApplication,
  isLoadingApps,
  applications,
  isLoading,
}) => {
  const { t } = useTranslation('pano');
  const tableAppRef = createRef();
  const [searchQuery, setSearchQuery] = useState<string>('');
  return (
    <Paper className="space-y-3 p-4">
      <Autocomplete
        multiple
        disableClearable={true}
        value={selectAppValue}
        loading={isLoadingApps}
        filterOptions={(options) => options.filter((x) => !tableData.map((item) => item.id).includes(x.id))}
        options={applications.filter((x) => x.name !== 'Defaults')}
        getOptionLabel={(option: ApplicationItem) => option.name}
        autoComplete={false}
        onChange={(event: SyntheticEvent<Element, Event>, value: ApplicationItem[]) => handleAddApplication(event, value)}
        renderTags={() => null}
        renderInput={(params) => <TextField variant="outlined" {...params} label={t('addApplication')} fullWidth />}
      />
      <MaterialTable
        icons={TableIcons}
        localization={{
          body: { emptyDataSourceMessage: searchQuery ? t('noResults') : t('global') },
        }}
        tableRef={tableAppRef}
        isLoading={isLoading}
        options={{
          tableLayout: 'auto',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
        }}
        columns={[{ title: t('name'), field: 'name' }]}
        data={tableData}
        title={t('applications')}
        onSearchChange={(searchText) => setSearchQuery(searchText ?? '')}
        actions={[
          {
            icon: Icons.DeleteForeverIcon,
            tooltip: t('removeApplication') as string,
            onClick: (_event, data) => {
              //@ts-ignore
              handleRemoveApplication(data.id);
            },
          },
        ]}
      />
    </Paper>
  );
};

export default SubstitutionApplicationsEditor;
