const links = {
  androidLink:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  iOSLink: 'https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200',
  androidSrc: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
  iOSSrc:
    'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1284940800&h=288c84cc3566737997a818b26a76c159',
  lexacomLogoSrc: 'https://www.lexacom.co.uk/wp-content/uploads/2018/10/cropped-icon-180x180.png',
};

export default links;
