import React, { useState, useEffect } from 'react';
import userManager from '../utils/userManager';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useTranslation } from 'react-i18next';
import { logIn } from '@slices';
import { useAuth } from 'oidc-react';

const HomePageContainer = (): JSX.Element => {
  const { userData, isLoading } = useAuth();
  const dispatch = useAppDispatch();
  const { isLoggingOut } = useAppSelector((state) => state.app);
  const { t } = useTranslation(['pano', 'shared']);

  const [loginError, setLoginError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onLoginClick = (): void => {
    setDisabled(true);
    setLoginError(false);

    if (!userData) {
      userManager.clearStaleState();
      userManager.signinRedirect().catch(() => {
        setDisabled(false);
        setLoginError(true);
      });
    }
  };

  useEffect(() => {
    if (userData) {
      if (isLoggingOut) dispatch(logIn());
    }
    if (!loginError && !isLoggingOut) {
      onLoginClick();
    }
  }, [isLoggingOut]);

  if (!userData && !isLoading) {
    return (
      <Grid container className="!mt-20 text-center">
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={1} sm={2} md={3} lg={3} />
          <Grid item xs={10} sm={8} md={6} lg={6} className="flex-col space-y-4">
            <Box
              className="py-12 flex justify-center items-center"
              sx={(theme) => ({
                backgroundColor: theme.custom.appBarBackground,
              })}>
              <FontAwesomeIcon icon={faKey} className="w-20 h-20 my-0 mx-auto" color="white" />
            </Box>
            <Typography variant="h1" className="text-2xl font-bold">
              {t('loginInterstitialHeader')}
            </Typography>
            <Typography>{t('loginInterstitialPara')}</Typography>

            {loginError ? <p className="error">{t('ServerError')}</p> : <div></div>}

            <Button variant="contained" color="primary" type="submit" disabled={disabled} onClick={onLoginClick}>
              {disabled && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('loginButtonText')}
            </Button>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3} />
        </Grid>
      </Grid>
    );
  }

  console.debug('HomePageContainer:LoadingUser');
  return <div>Please wait...</div>;
};

export default HomePageContainer;
