export const getKeyCodeFromVirtualKey = (keyName: any): number => {
  switch (keyName) {
    //     Left mouse button
    case 'Lbutton':
      return 0x01;

    //     Right mouse button
    case 'Rbutton':
      return 0x02;

    //     Control-break processing
    case 'Cancel':
      return 0x03;

    //     Middle mouse button (three-button mouse)
    case 'Mbutton':
      return 0x04;

    //     Windows 2000/XP: X1 mouse button
    case 'Xbutton1':
      return 0x05;

    //     Windows 2000/XP: X2 mouse button
    case 'Xbutton2':
      return 0x06;

    //     BACKSPACE key
    case 'Back':
      return 0x08;

    //     TAB key
    case 'Tab':
      return 0x09;

    //     CLEAR key
    case 'Clear':
      return 0x0c;

    //     ENTER key
    case 'Return':
      return 0x0d;

    //     SHIFT key
    case 'Shift':
      return 0x10;

    //     CTRL key
    case 'Control':
      return 0x11;

    //     ALT key
    case 'Menu':
      return 0x12;

    //     PAUSE key
    case 'Pause':
      return 0x13;

    //     CAPS LOCK key
    case 'Capital':
      return 0x14;

    //     ESC key
    case 'Escape':
      return 0x1b;

    //     IME convert
    case 'Convert':
      return 0x1c;

    //     IME nonconvert
    case 'Nonconvert':
      return 0x1d;

    //     IME accept
    case 'Accept':
      return 0x1e;

    //     IME mode change request
    case 'Modechange':
      return 0x1f;

    //     SPACEBAR
    case 'Space':
      return 0x20;

    //     PAGE UP key
    case 'Prior':
      return 0x21;

    //     PAGE DOWN key
    case 'Next':
      return 0x22;

    //     END key
    case 'End':
      return 0x23;

    //     HOME key
    case 'Home':
      return 0x24;

    //     LEFT ARROW key
    case 'Left':
      return 0x25;

    //     UP ARROW key
    case 'Up':
      return 0x26;

    //     RIGHT ARROW key
    case 'Right':
      return 0x27;

    //     DOWN ARROW key
    case 'Down':
      return 0x28;

    //     SELECT key
    case 'Select':
      return 0x29;

    //     PRINT key
    case 'Print':
      return 0x2a;

    //     EXECUTE key
    case 'Execute':
      return 0x2b;

    //     PRINT SCREEN key
    case 'Snapshot':
      return 0x2c;

    //     INS key
    case 'Insert':
      return 0x2d;

    //     DEL key
    case 'Delete':
      return 0x2e;

    //     HELP key
    case 'Help':
      return 0x2f;

    //     0 key
    case 'Key0':
      return 0x30;

    //     1 key
    case 'Key1':
      return 0x31;

    //     2 key
    case 'Key2':
      return 0x32;

    //     3 key
    case 'Key3':
      return 0x33;

    //     4 key
    case 'Key4':
      return 0x34;

    //     5 key
    case 'Key5':
      return 0x35;

    //     6 key
    case 'Key6':
      return 0x36;

    //     7 key
    case 'Key7':
      return 0x37;

    //     8 key
    case 'Key8':
      return 0x38;

    //     9 key
    case 'Key9':
      return 0x39;

    //     A key
    case 'KeyA':
      return 0x41;

    //     B key
    case 'KeyB':
      return 0x42;

    //     C key
    case 'KeyC':
      return 0x43;

    //     D key
    case 'KeyD':
      return 0x44;

    //     E key
    case 'KeyE':
      return 0x45;

    //     F key
    case 'KeyF':
      return 0x46;

    //     G key
    case 'KeyG':
      return 0x47;

    //     H key
    case 'KeyH':
      return 0x48;

    //     I key
    case 'KeyI':
      return 0x49;

    //     J key
    case 'KeyJ':
      return 0x4a;

    //     K key
    case 'KeyK':
      return 0x4b;

    //     L key
    case 'KeyL':
      return 0x4c;

    //     M key
    case 'KeyM':
      return 0x4d;

    //     N key
    case 'KeyN':
      return 0x4e;

    //     O key
    case 'KeyO':
      return 0x4f;

    //     P key
    case 'KeyP':
      return 0x50;

    //     Q key
    case 'KeyQ':
      return 0x51;

    //     R key
    case 'KeyR':
      return 0x52;

    //     S key
    case 'KeyS':
      return 0x53;

    //     T key
    case 'KeyT':
      return 0x54;

    //     U key
    case 'KeyU':
      return 0x55;

    //     V key
    case 'KeyV':
      return 0x56;

    //     W key
    case 'KeyW':
      return 0x57;

    //     X key
    case 'KeyX':
      return 0x58;

    //     Y key
    case 'KeyY':
      return 0x59;

    //     Z key
    case 'KeyZ':
      return 0x5a;

    //     Left Windows key (Microsoft Natural keyboard)
    case 'Lwin':
      return 0x5b;

    //     Right Windows key (Natural keyboard)
    case 'Rwin':
      return 0x5c;

    //     Applications key (Natural keyboard)
    case 'Apps':
      return 0x5d;

    //     Computer Sleep key
    case 'Sleep':
      return 0x5f;

    //     Numeric keypad 0 key
    case 'Numpad0':
      return 0x60;

    //     Numeric keypad 1 key
    case 'Numpad1':
      return 0x61;

    //     Numeric keypad 2 key
    case 'Numpad2':
      return 0x62;

    //     Numeric keypad 3 key
    case 'Numpad3':
      return 0x63;

    //     Numeric keypad 4 key
    case 'Numpad4':
      return 0x64;

    //     Numeric keypad 5 key
    case 'Numpad5':
      return 0x65;

    //     Numeric keypad 6 key
    case 'Numpad6':
      return 0x66;

    //     Numeric keypad 7 key
    case 'Numpad7':
      return 0x67;

    //     Numeric keypad 8 key
    case 'Numpad8':
      return 0x68;

    //     Numeric keypad 9 key
    case 'Numpad9':
      return 0x69;

    //     Multiply key
    case 'Multiply':
      return 0x6a;

    //     Add key
    case 'Add':
      return 0x6b;

    //     Separator key
    case 'Separator':
      return 0x6c;

    //     Subtract key
    case 'Subtract':
      return 0x6d;

    //     Decimal key
    case 'Decimal':
      return 0x6e;

    //     Divide key
    case 'Divide':
      return 0x6f;

    //     F1 key
    case 'F1':
      return 0x70;

    //     F2 key
    case 'F2':
      return 0x71;

    //     F3 key
    case 'F3':
      return 0x72;

    //     F4 key
    case 'F4':
      return 0x73;

    //     F5 key
    case 'F5':
      return 0x74;

    //     F6 key
    case 'F6':
      return 0x75;

    //     F7 key
    case 'F7':
      return 0x76;

    //     F8 key
    case 'F8':
      return 0x77;

    //     F9 key
    case 'F9':
      return 0x78;

    //     F10 key
    case 'F10':
      return 0x79;

    //     F11 key
    case 'F11':
      return 0x7a;

    //     F12 key
    case 'F12':
      return 0x7b;

    //     F13 key
    case 'F13':
      return 0x7c;

    //     F14 key
    case 'F14':
      return 0x7d;

    //     F15 key
    case 'F15':
      return 0x7e;

    //     F16 key
    case 'F16':
      return 0x7f;

    //     F17 key
    case 'F17':
      return 0x80;

    //     F18 key
    case 'F18':
      return 0x81;

    //     F19 key
    case 'F19':
      return 0x82;

    //     F20 key
    case 'F20':
      return 0x83;

    //     F21 key
    case 'F21':
      return 0x84;

    //     F22 key, (PPC only) Key used to lock device.
    case 'F22':
      return 0x85;

    //     F23 key
    case 'F23':
      return 0x86;

    //     F24 key
    case 'F24':
      return 0x87;

    //     NUM LOCK key
    case 'Numlock':
      return 0x90;

    //     SCROLL LOCK key
    case 'Scroll':
      return 0x91;

    //     Left SHIFT key
    case 'Lshift':
      return 0xa0;

    //     Right SHIFT key
    case 'Rshift':
      return 0xa1;

    //     Left CONTROL key
    case 'Lcontrol':
      return 0xa2;

    //     Right CONTROL key
    case 'Rcontrol':
      return 0xa3;

    //     Left MENU key
    case 'Lmenu':
      return 0xa4;

    //     Right MENU key
    case 'Rmenu':
      return 0xa5;

    //     Windows 2000/XP: Browser Back key
    case 'BrowserBack':
      return 0xa6;

    //     Windows 2000/XP: Browser Forward key
    case 'BrowserForward':
      return 0xa7;

    //     Windows 2000/XP: Browser Refresh key
    case 'BrowserRefresh':
      return 0xa8;

    //     Windows 2000/XP: Browser Stop key
    case 'BrowserStop':
      return 0xa9;

    //     Windows 2000/XP: Browser Search key
    case 'BrowserSearch':
      return 0xaa;

    //     Windows 2000/XP: Browser Favorites key
    case 'BrowserFavorites':
      return 0xab;

    //     Windows 2000/XP: Browser Start and Home key
    case 'BrowserHome':
      return 0xac;

    //     Windows 2000/XP: Volume Mute key
    case 'VolumeMute':
      return 0xad;

    //     Windows 2000/XP: Volume Down key
    case 'VolumeDown':
      return 0xae;

    //     Windows 2000/XP: Volume Up key
    case 'VolumeUp':
      return 0xaf;

    //     Windows 2000/XP: Next Track key
    case 'MediaNextTrack':
      return 0xb0;

    //     Windows 2000/XP: Previous Track key
    case 'MediaPrevTrack':
      return 0xb1;

    //     Windows 2000/XP: Stop Media key
    case 'MediaStop':
      return 0xb2;

    //     Windows 2000/XP: Play/Pause Media key
    case 'MediaPlayPause':
      return 0xb3;

    //     Windows 2000/XP: Start Mail key
    case 'LaunchMail':
      return 0xb4;

    //     Windows 2000/XP: Select Media key
    case 'LaunchMediaSelect':
      return 0xb5;

    //     Windows 2000/XP: Start Application 1 key
    case 'LaunchApp1':
      return 0xb6;

    //     Windows 2000/XP: Start Application 2 key
    case 'LaunchApp2':
      return 0xb7;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem1':
      return 0xba;

    //     Windows 2000/XP: For any country/region, the '+' key
    case 'OemPlus':
      return 0xbb;

    //     Windows 2000/XP: For any country/region, the ',' key
    case 'OemComma':
      return 0xbc;

    //     Windows 2000/XP: For any country/region, the '-' key
    case 'OemMinus':
      return 0xbd;

    //     Windows 2000/XP: For any country/region, the '.' key
    case 'OemPeriod':
      return 0xbe;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem2':
      return 0xbf;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem3':
      return 0xc0;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem4':
      return 0xdb;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem5':
      return 0xdc;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem6':
      return 0xdd;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem7':
      return 0xde;

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 'Oem8':
      return 0xdf;

    //     Windows 2000/XP: Either the angle bracket key or the backslash key on the RT 102-key keyboard
    case 'Oem102':
      return 0xe2;

    //     Windows 95/98/Me, Windows NT 4.0, Windows 2000/XP: IME PROCESS key
    case 'Processkey':
      return 0xe5;

    case 'Packet':
      return 0xe7;

    //     Attn key
    case 'Attn':
      return 0xf6;

    //     CrSel key
    case 'Crsel':
      return 0xf7;

    //     ExSel key
    case 'Exsel':
      return 0xf8;

    //     Erase EOF key
    case 'Ereof':
      return 0xf9;

    //     Play key
    case 'Play':
      return 0xfa;

    //     Zoom key
    case 'Zoom':
      return 0xfb;

    //     Reserved
    case 'Noname':
      return 0xfc;

    //     PA1 key
    case 'Pa1':
      return 0xfd;

    //     Clear key
    case 'OemClear':
      return 0xfe;

    default:
      return 0;
  }
};

export const getKeyNameFromKeyCode = (keyCode: any): string | null => {
  switch (keyCode) {
    case 0xc1:
      return 'Abnt C1';
    case 0xc2:
      return 'Abnt C2';
    case 0x6b:
      return 'Numpad +';
    case 0xf6:
      return 'Attn';
    case 0x08:
      return 'Backspace';
    case 0x03:
      return 'Break';
    case 0x0c:
      return 'Clear';
    case 0xf7:
      return 'Cr Sel';
    case 0x6e:
      return 'Numpad .';
    case 0x6f:
      return 'Numpad /';
    case 0xf9:
      return 'Er Eof';
    case 0x1b:
      return 'Escape';
    case 0x2b:
      return 'Execute';
    case 0xf8:
      return 'Ex Sel';
    case 0xe6:
      return 'IcoClr';
    case 0xe3:
      return 'IcoHlp';
    case 0x30:
      return '0';
    case 0x31:
      return '1';
    case 0x32:
      return '2';
    case 0x33:
      return '3';
    case 0x34:
      return '4';
    case 0x35:
      return '5';
    case 0x36:
      return '6';
    case 0x37:
      return '7';
    case 0x38:
      return '8';
    case 0x39:
      return '9';
    case 0x41:
      return 'A';
    case 0x42:
      return 'B';
    case 0x43:
      return 'C';
    case 0x44:
      return 'D';
    case 0x45:
      return 'E';
    case 0x46:
      return 'F';
    case 0x47:
      return 'G';
    case 0x48:
      return 'H';
    case 0x49:
      return 'I';
    case 0x4a:
      return 'J';
    case 0x4b:
      return 'K';
    case 0x4c:
      return 'L';
    case 0x4d:
      return 'M';
    case 0x4e:
      return 'N';
    case 0x4f:
      return 'O';
    case 0x50:
      return 'P';
    case 0x51:
      return 'Q';
    case 0x52:
      return 'R';
    case 0x53:
      return 'S';
    case 0x54:
      return 'T';
    case 0x55:
      return 'U';
    case 0x56:
      return 'V';
    case 0x57:
      return 'W';
    case 0x58:
      return 'X';
    case 0x59:
      return 'Y';
    case 0x5a:
      return 'Z';
    case 0x6a:
      return 'Numpad *';
    case 0xfc:
      return 'NoName';
    case 0x60:
      return 'Numpad 0';
    case 0x61:
      return 'Numpad 1';
    case 0x62:
      return 'Numpad 2';
    case 0x63:
      return 'Numpad 3';
    case 0x64:
      return 'Numpad 4';
    case 0x65:
      return 'Numpad 5';
    case 0x66:
      return 'Numpad 6';
    case 0x67:
      return 'Numpad 7';
    case 0x68:
      return 'Numpad 8';
    case 0x69:
      return 'Numpad 9';
    case 0xba:
      return ';';
    //case 0xE2: return 'OEM_102 (> <)';
    case 0xbf:
      return '/';
    case 0xc0:
      return "'";
    case 0xdb:
      return '[';
    case 0xdc:
      return '\\';
    case 0xdd:
      return ']';
    case 0xde:
      return '#';
    case 0xdf:
      return '`';
    case 0xf0:
      return 'Oem Attn';
    case 0xf3:
      return 'Auto';
    case 0xe1:
      return 'Ax';
    case 0xf5:
      return 'Back Tab';
    case 0xfe:
      return 'OemClr';
    case 0xbc:
      return ',';
    case 0xf2:
      return 'Copy';
    case 0xef:
      return 'Cu Sel';
    case 0xf4:
      return 'Enlw';
    case 0xf1:
      return 'Finish';
    case 0x95:
      return 'Loya';
    case 0x93:
      return 'Mashu';
    case 0x96:
      return 'Roya';
    case 0x94:
      return 'Touroku';
    case 0xea:
      return 'Jump';
    case 0xbd:
      return '-';
    case 0xeb:
      return 'OemPa1';
    case 0xec:
      return 'OemPa2';
    case 0xed:
      return 'OemPa3';
    case 0xbe:
      return '.';
    case 0xbb:
      return '=';
    case 0xe9:
      return 'Reset';
    case 0xee:
      return 'WsCtrl';
    case 0xfd:
      return 'Pa1';
    case 0xe7:
      return 'Packet';
    case 0xfa:
      return 'Play';
    case 0xe5:
      return 'Process';
    case 0x0d:
      return 'Enter';
    case 0x29:
      return 'Select';
    case 0x6c:
      return 'Separator';
    case 0x20:
      return 'Space';
    case 0x6d:
      return 'Numpad -';
    case 0x09:
      return 'Tab';
    case 0xfb:
      return 'Zoom';
    case 0xff:
      return 'no VK mapping';
    case 0x1e:
      return 'Accept';
    case 0x5d:
      return 'Context Menu';
    case 0xa6:
      return 'Browser Back';
    case 0xab:
      return 'Browser Favorites';
    case 0xa7:
      return 'Browser Forward';
    case 0xac:
      return 'Browser Home';
    case 0xa8:
      return 'Browser Refresh';
    case 0xaa:
      return 'Browser Search';
    case 0xa9:
      return 'Browser Stop';
    case 0x14:
      return 'Caps Lock';
    case 0x1c:
      return 'Convert';
    case 0x2e:
      return 'Delete';
    case 0x28:
      return 'Arrow Down';
    case 0x23:
      return 'End';
    case 0x70:
      return 'F1';
    case 0x79:
      return 'F10';
    case 0x7a:
      return 'F11';
    case 0x7b:
      return 'F12';
    case 0x7c:
      return 'F13';
    case 0x7d:
      return 'F14';
    case 0x7e:
      return 'F15';
    case 0x7f:
      return 'F16';
    case 0x80:
      return 'F17';
    case 0x81:
      return 'F18';
    case 0x82:
      return 'F19';
    case 0x71:
      return 'F2';
    case 0x83:
      return 'F20';
    case 0x84:
      return 'F21';
    case 0x85:
      return 'F22';
    case 0x86:
      return 'F23';
    case 0x87:
      return 'F24';
    case 0x72:
      return 'F3';
    case 0x73:
      return 'F4';
    case 0x74:
      return 'F5';
    case 0x75:
      return 'F6';
    case 0x76:
      return 'F7';
    case 0x77:
      return 'F8';
    case 0x78:
      return 'F9';
    case 0x18:
      return 'Final';
    case 0x2f:
      return 'Help';
    case 0x24:
      return 'Home';
    case 0xe4:
      return 'Ico00';
    case 0x2d:
      return 'Insert';
    case 0x17:
      return 'Junja';
    case 0x15:
      return 'Kana';
    case 0x19:
      return 'Kanji';
    case 0xb6:
      return 'App1';
    case 0xb7:
      return 'App2';
    case 0xb4:
      return 'Mail';
    case 0xb5:
      return 'Media';
    case 0x01:
      return 'Left Button';
    case 0xa2:
      return 'Left Ctrl';
    case 0x25:
      return 'Arrow Left';
    case 0xa4:
      return 'Left Alt';
    case 0xa0:
      return 'Left Shift';
    case 0x5b:
      return 'Left Win';
    case 0x04:
      return 'Middle Button';
    case 0xb0:
      return 'Next Track';
    case 0xb3:
      return 'Play / Pause';
    case 0xb1:
      return 'Previous Track';
    case 0xb2:
      return 'Stop';
    case 0x1f:
      return 'Mode Change';
    case 0x22:
      return 'Page Down';
    case 0x1d:
      return 'Non Convert';
    case 0x90:
      return 'Num Lock';
    case 0x92:
      return 'Jisho';
    case 0x13:
      return 'Pause';
    case 0x2a:
      return 'Print';
    case 0x21:
      return 'Page Up';
    case 0x02:
      return 'Right Button';
    case 0xa3:
      return 'Right Ctrl';
    case 0x27:
      return 'Arrow Right';
    case 0xa5:
      return 'Right Alt';
    case 0xa1:
      return 'Right Shift';
    case 0x5c:
      return 'Right Win';
    case 0x91:
      return 'Scroll Lock';
    case 0x5f:
      return 'Sleep';
    case 0x2c:
      return 'Print Screen';
    case 0x26:
      return 'Arrow Up';
    case 0xae:
      return 'Volume Down';
    case 0xad:
      return 'Volume Mute';
    case 0xaf:
      return 'Volume Up';
    case 0x05:
      return 'X Button 1';
    case 0x06:
      return 'X Button 2';
    case 0x11:
      return 'Ctrl';
    case 0x12:
      return 'Alt';
    case 0x10:
      return 'Shift';
    default:
      return null;
  }
};

export const getVirtualKeyFromKeyCode = (keyCode: any): string | null => {
  switch (keyCode) {
    //     Left mouse button
    case 0x01:
      return 'Lbutton';

    //     Right mouse button
    case 0x02:
      return 'Rbutton';

    //     Control-break processing
    case 0x03:
      return 'Cancel';

    //     Middle mouse button (three-button mouse)
    case 0x04:
      return 'Mbutton';

    //     Windows 2000/XP: X1 mouse button
    case 0x05:
      return 'Xbutton1';

    //     Windows 2000/XP: X2 mouse button
    case 0x06:
      return 'Xbutton2';

    //     BACKSPACE key
    case 0x08:
      return 'Back';

    //     TAB key
    case 0x09:
      return 'Tab';

    //     CLEAR key
    case 0x0c:
      return 'Clear';

    //     ENTER key
    case 0x0d:
      return 'Return';

    //     SHIFT key
    case 0x10:
      return 'Shift';

    //     CTRL key
    case 0x11:
      return 'Control';

    //     ALT key
    case 0x12:
      return 'Menu';

    //     PAUSE key
    case 0x13:
      return 'Pause';

    //     CAPS LOCK key
    case 0x14:
      return 'Capital';

    //     IME final mode
    case 0x18:
      return 'Final';

    //     ESC key
    case 0x1b:
      return 'Escape';

    //     IME convert
    case 0x1c:
      return 'Convert';

    //     IME nonconvert
    case 0x1d:
      return 'Nonconvert';

    //     IME accept
    case 0x1e:
      return 'Accept';

    //     IME mode change request
    case 0x1f:
      return 'Modechange';

    //     SPACEBAR
    case 0x20:
      return 'Space';

    //     PAGE UP key
    case 0x21:
      return 'Prior';

    //     PAGE DOWN key
    case 0x22:
      return 'Next';

    //     END key
    case 0x23:
      return 'End';

    //     HOME key
    case 0x24:
      return 'Home';

    //     LEFT ARROW key
    case 0x25:
      return 'Left';

    //     UP ARROW key
    case 0x26:
      return 'Up';

    //     RIGHT ARROW key
    case 0x27:
      return 'Right';

    //     DOWN ARROW key
    case 0x28:
      return 'Down';

    //     SELECT key
    case 0x29:
      return 'Select';

    //     PRINT key
    case 0x2a:
      return 'Print';

    //     EXECUTE key
    case 0x2b:
      return 'Execute';

    //     PRINT SCREEN key
    case 0x2c:
      return 'Snapshot';

    //     INS key
    case 0x2d:
      return 'Insert';

    //     DEL key
    case 0x2e:
      return 'Delete';

    //     HELP key
    case 0x2f:
      return 'Help';

    //     0 key
    case 0x30:
      return 'Key0';

    //     1 key
    case 0x31:
      return 'Key1';

    //     2 key
    case 0x32:
      return 'Key2';

    //     3 key
    case 0x33:
      return 'Key3';

    //     4 key
    case 0x34:
      return 'Key4';

    //     5 key
    case 0x35:
      return 'Key5';

    //     6 key
    case 0x36:
      return 'Key6';

    //     7 key
    case 0x37:
      return 'Key7';

    //     8 key
    case 0x38:
      return 'Key8';

    //     9 key
    case 0x39:
      return 'Key9';

    //     A key
    case 0x41:
      return 'KeyA';

    //     B key
    case 0x42:
      return 'KeyB';

    //     C key
    case 0x43:
      return 'KeyC';

    //     D key
    case 0x44:
      return 'KeyD';

    //     E key
    case 0x45:
      return 'KeyE';

    //     F key
    case 0x46:
      return 'KeyF';

    //     G key
    case 0x47:
      return 'KeyG';

    //     H key
    case 0x48:
      return 'KeyH';

    //     I key
    case 0x49:
      return 'KeyI';

    //     J key
    case 0x4a:
      return 'KeyJ';

    //     K key
    case 0x4b:
      return 'KeyK';

    //     L key
    case 0x4c:
      return 'KeyL';

    //     M key
    case 0x4d:
      return 'KeyM';

    //     N key
    case 0x4e:
      return 'KeyN';

    //     O key
    case 0x4f:
      return 'KeyO';

    //     P key
    case 0x50:
      return 'KeyP';

    //     Q key
    case 0x51:
      return 'KeyQ';

    //     R key
    case 0x52:
      return 'KeyR';

    //     S key
    case 0x53:
      return 'KeyS';

    //     T key
    case 0x54:
      return 'KeyT';

    //     U key
    case 0x55:
      return 'KeyU';

    //     V key
    case 0x56:
      return 'KeyV';

    //     W key
    case 0x57:
      return 'KeyW';

    //     X key
    case 0x58:
      return 'KeyX';

    //     Y key
    case 0x59:
      return 'KeyY';

    //     Z key
    case 0x5a:
      return 'KeyZ';

    //     Left Windows key (Microsoft Natural keyboard)
    case 0x5b:
      return 'Lwin';

    //     Right Windows key (Natural keyboard)
    case 0x5c:
      return 'Rwin';

    //     Applications key (Natural keyboard)
    case 0x5d:
      return 'Apps';

    //     Computer Sleep key
    case 0x5f:
      return 'Sleep';

    //     Numeric keypad 0 key
    case 0x60:
      return 'Numpad0';

    //     Numeric keypad 1 key
    case 0x61:
      return 'Numpad1';

    //     Numeric keypad 2 key
    case 0x62:
      return 'Numpad2';

    //     Numeric keypad 3 key
    case 0x63:
      return 'Numpad3';

    //     Numeric keypad 4 key
    case 0x64:
      return 'Numpad4';

    //     Numeric keypad 5 key
    case 0x65:
      return 'Numpad5';

    //     Numeric keypad 6 key
    case 0x66:
      return 'Numpad6';

    //     Numeric keypad 7 key
    case 0x67:
      return 'Numpad7';

    //     Numeric keypad 8 key
    case 0x68:
      return 'Numpad8';

    //     Numeric keypad 9 key
    case 0x69:
      return 'Numpad9';

    //     Multiply key
    case 0x6a:
      return 'Multiply';

    //     Add key
    case 0x6b:
      return 'Add';

    //     Separator key
    case 0x6c:
      return 'Separator';

    //     Subtract key
    case 0x6d:
      return 'Subtract';

    //     Decimal key
    case 0x6e:
      return 'Decimal';

    //     Divide key
    case 0x6f:
      return 'Divide';

    //     F1 key
    case 0x70:
      return 'F1';

    //     F2 key
    case 0x71:
      return 'F2';

    //     F3 key
    case 0x72:
      return 'F3';

    //     F4 key
    case 0x73:
      return 'F4';

    //     F5 key
    case 0x74:
      return 'F5';

    //     F6 key
    case 0x75:
      return 'F6';

    //     F7 key
    case 0x76:
      return 'F7';

    //     F8 key
    case 0x77:
      return 'F8';

    //     F9 key
    case 0x78:
      return 'F9';

    //     F10 key
    case 0x79:
      return 'F10';

    //     F11 key
    case 0x7a:
      return 'F11';

    //     F12 key
    case 0x7b:
      return 'F12';

    //     F13 key
    case 0x7c:
      return 'F13';

    //     F14 key
    case 0x7d:
      return 'F14';

    //     F15 key
    case 0x7e:
      return 'F15';

    //     F16 key
    case 0x7f:
      return 'F16';

    //     F17 key
    case 0x80:
      return 'F17';

    //     F18 key
    case 0x81:
      return 'F18';

    //     F19 key
    case 0x82:
      return 'F19';

    //     F20 key
    case 0x83:
      return 'F20';

    //     F21 key
    case 0x84:
      return 'F21';

    //     F22 key, (PPC only) Key used to lock device.
    case 0x85:
      return 'F22';

    //     F23 key
    case 0x86:
      return 'F23';

    //     F24 key
    case 0x87:
      return 'F24';

    //     NUM LOCK key
    case 0x90:
      return 'Numlock';

    //     SCROLL LOCK key
    case 0x91:
      return 'Scroll';

    //     Left SHIFT key
    case 0xa0:
      return 'Lshift';

    //     Right SHIFT key
    case 0xa1:
      return 'Rshift';

    //     Left CONTROL key
    case 0xa2:
      return 'Lcontrol';

    //     Right CONTROL key
    case 0xa3:
      return 'Rcontrol';

    //     Left MENU key
    case 0xa4:
      return 'Lmenu';

    //     Right MENU key
    case 0xa5:
      return 'Rmenu';

    //     Windows 2000/XP: Browser Back key
    case 0xa6:
      return 'BrowserBack';

    //     Windows 2000/XP: Browser Forward key
    case 0xa7:
      return 'BrowserForward';

    //     Windows 2000/XP: Browser Refresh key
    case 0xa8:
      return 'BrowserRefresh';

    //     Windows 2000/XP: Browser Stop key
    case 0xa9:
      return 'BrowserStop';

    //     Windows 2000/XP: Browser Search key
    case 0xaa:
      return 'BrowserSearch';

    //     Windows 2000/XP: Browser Favorites key
    case 0xab:
      return 'BrowserFavorites';

    //     Windows 2000/XP: Browser Start and Home key
    case 0xac:
      return 'BrowserHome';

    //     Windows 2000/XP: Volume Mute key
    case 0xad:
      return 'VolumeMute';

    //     Windows 2000/XP: Volume Down key
    case 0xae:
      return 'VolumeDown';

    //     Windows 2000/XP: Volume Up key
    case 0xaf:
      return 'VolumeUp';

    //     Windows 2000/XP: Next Track key
    case 0xb0:
      return 'MediaNextTrack';

    //     Windows 2000/XP: Previous Track key
    case 0xb1:
      return 'MediaPrevTrack';

    //     Windows 2000/XP: Stop Media key
    case 0xb2:
      return 'MediaStop';

    //     Windows 2000/XP: Play/Pause Media key
    case 0xb3:
      return 'MediaPlayPause';

    //     Windows 2000/XP: Start Mail key
    case 0xb4:
      return 'LaunchMail';

    //     Windows 2000/XP: Select Media key
    case 0xb5:
      return 'LaunchMediaSelect';

    //     Windows 2000/XP: Start Application 1 key
    case 0xb6:
      return 'LaunchApp1';

    //     Windows 2000/XP: Start Application 2 key
    case 0xb7:
      return 'LaunchApp2';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xba:
      return 'Oem1';

    //     Windows 2000/XP: For any country/region, the '+' key
    case 0xbb:
      return 'OemPlus';

    //     Windows 2000/XP: For any country/region, the ',' key
    case 0xbc:
      return 'OemComma';

    //     Windows 2000/XP: For any country/region, the '-' key
    case 0xbd:
      return 'OemMinus';

    //     Windows 2000/XP: For any country/region, the '.' key
    case 0xbe:
      return 'OemPeriod';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xbf:
      return 'Oem2';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xc0:
      return 'Oem3';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xdb:
      return 'Oem4';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xdc:
      return 'Oem5';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xdd:
      return 'Oem6';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xde:
      return 'Oem7';

    //     Used for miscellaneous characters; it can vary by keyboard.
    case 0xdf:
      return 'Oem8';

    //     Windows 2000/XP: Either the angle bracket key or the backslash key on the RT 102-key keyboard
    case 0xe2:
      return 'Oem102';

    //     Windows 95/98/Me, Windows NT 4.0, Windows 2000/XP: IME PROCESS key
    case 0xe5:
      return 'Processkey';

    //     Windows 2000/XP: Used to pass Unicode characters as if they were keystrokes.
    //     The VK_PACKET key is the low word of a 32-bit Virtual Key value used for non-keyboard input methods. For more
    //     information,
    //     see Remark in KEYBDINPUT, SendInput, WM_KEYDOWN, and WM_KEYUP
    case 0xe7:
      return 'Packet';

    //     Attn key
    case 0xf6:
      return 'Attn';

    //     CrSel key
    case 0xf7:
      return 'Crsel';

    //     ExSel key
    case 0xf8:
      return 'Exsel';

    //     Erase EOF key
    case 0xf9:
      return 'Ereof';

    //     Play key
    case 0xfa:
      return 'Play';

    //     Zoom key
    case 0xfb:
      return 'Zoom';

    //     Reserved
    case 0xfc:
      return 'Noname';

    //     PA1 key
    case 0xfd:
      return 'Pa1';

    //     Clear key
    case 0xfe:
      return 'OemClear';

    default:
      console.error('Not recognised', keyCode);
      return null;
  }
};
