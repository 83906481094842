import { mfaApi } from '@apis';
import { AuthenticatorKeyItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

export type MfaState = {
  authenticatorKey: AuthenticatorKeyItem | null;
  recoveryKeys: string[];
};

const initialState: MfaState = {
  authenticatorKey: null,
  recoveryKeys: [],
};

export const mfaSlice = createSlice({
  name: 'mfa',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(mfaApi.endpoints.getAuthenticatorKey.matchFulfilled, (state, { payload }) => {
      state.authenticatorKey = payload;
    });
    builder.addMatcher(mfaApi.endpoints.getRecoveryKeys.matchFulfilled, (state, { payload }) => {
      state.recoveryKeys = payload;
    });
    builder.addMatcher(mfaApi.endpoints.enableMfa.matchFulfilled, (state, { payload }) => {
      state.recoveryKeys = payload;
    });
  },
  reducers: {},
});
