import { useDeletefeedbackMutation, useGetFeedbackQuery } from '@apis';
import { AudioPlayback, TableIcons } from '@components';
import { useAppDispatch, useAppSelector } from '@hooks';
import { UserFeedbackResponseItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { openSnackbar, setBreadcrumbs, setHelmet } from '@slices';
import { zipFeedback } from '@utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const EchoFeedback = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { feedbacks } = useAppSelector((x) => x.echo);
  const [deleteFedback, { isLoading: isDeleting }] = useDeletefeedbackMutation();
  const { refetch } = useGetFeedbackQuery();
  const [selectedRows, setSelectedRows] = useState<UserFeedbackResponseItem[]>([]);

  const feedbackColumns = [
    {
      title: t('name'),
      field: 'givenName',
      render: (rowData: UserFeedbackResponseItem) =>
        rowData ? (
          <p className="table-font-size-content">
            {rowData.givenName} {rowData.familyName}
          </p>
        ) : (
          <p></p>
        ),
    },
    {
      title: t('email'),
      field: 'email',
      render: (rowData: UserFeedbackResponseItem) => (rowData ? <p className="table-font-size-content">{rowData.email}</p> : <p></p>),
    },
    {
      title: t('teamName'),
      field: 'teamName',
      render: (rowData: UserFeedbackResponseItem) => (rowData ? <p className="table-font-size-content">{rowData.teamName}</p> : <p></p>),
    },
    { title: t('echoFeedbackWhatTheSpeakerSaid'), field: 'whatTheSpeakerSaid' },
    { title: t('echoFeedbackWhatTheSystemWrote'), field: 'whatTheSystemWrote' },
    { title: t('echoFeedbackWhatWasExpected'), field: 'whatWasExpected' },
    { title: t('echoFeedbackLanguageModel'), field: 'languageModel' },
    {
      title: t('echoFeedbackAudioFeedback'),
      field: 'feedbackAudio',
      searchable: false,
      render: (rowData: UserFeedbackResponseItem) => <AudioPlayback rowId={rowData.tableData.id} data={rowData.feedbackAudio} />,
    },
  ];

  const handleDeleteFeedback = () => {
    if (!confirm(t('echoFeedbackDeleteAllDialog') as string)) return;

    deleteFedback(selectedRows.map((r) => r.id))
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to delete feedback', err);
        return;
      });
  };

  const handleZipClick = () => {
    zipFeedback(selectedRows);
  };
  const buttonsDisabled = (): boolean => {
    return selectedRows.length < 1 || isDeleting;
  };
  useEffect(() => {
    let breadcrumbs = [{ text: t('echoFeedbackButtonGlobal') }];

    dispatch(setBreadcrumbs(breadcrumbs));
    dispatch(setHelmet({ title: t('htmlTitleEchoFeedback') }));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} className="echofeedback-page wrap">
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography variant="h6" gutterBottom>
            {t('echoFeedbackPageTitle')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('echoFeedbackPageSubtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className="list-style-box">
            <MaterialTable
              title={t('echoFeedbackTableTitle')}
              columns={feedbackColumns}
              data={feedbacks}
              icons={TableIcons}
              options={{
                selection: true,
                sorting: false,
                draggable: false,
              }}
              onSelectionChange={(rows) => {
                console.debug('EchoFeedbackPage:Table:onSelectionChange', rows);
                setSelectedRows(rows);
              }}
            />
          </Paper>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-5">
          <Tooltip title={t('echoFeedbackDownloadTooltip')}>
            <span>
              <Button variant="contained" className="!mr-5" onClick={handleZipClick} color="primary" disabled={buttonsDisabled()}>
                {t('echoFeedbackDownloadButton')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('echoFeedbackDeleteTooltip')}>
            <span>
              <Button variant="contained" onClick={handleDeleteFeedback} color="secondary" disabled={buttonsDisabled()}>
                {isDeleting && <CircularProgress size={24} className="circular-button-loader" />}
                {t('echoFeedbackDeleteButton')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EchoFeedback;
