import { createApi } from '@reduxjs/toolkit/query/react';
import { IdAndNameItem, LicenceItem } from '@interfaces';
import { baseQueryHandler } from './base.api';

export const licenceApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'licenceApi',
  endpoints: (builder) => ({
    getLicences: builder.query<LicenceItem[], number>({
      query: (payload) => ({
        url: `/api/v1/team-licence`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}` },
      }),
    }),
    putLicence: builder.mutation<void, LicenceItem>({
      query: (payload) => ({
        url: `/api/v1/team-licence/${payload.id}`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload,
      }),
    }),
    postLicence: builder.mutation<void, LicenceItem>({
      query: (payload) => ({
        url: `/api/v1/team-licence`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload,
      }),
    }),
    checkRevokeUserLicence: builder.mutation<IdAndNameItem[], { teamId: number; teamLicenceId: number; userId: number }>({
      query: (payload) => ({
        url: `/api/v1/team-licence/${payload.teamLicenceId}/revoke-check/${payload.userId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    postUserLicence: builder.mutation<void, { teamId: number; teamLicenceId: number; userId: number }>({
      query: (payload) => ({
        url: `/api/v1/team-licence/${payload.teamLicenceId}/consume/${payload.userId}`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    revokeUserLicence: builder.mutation<void, { teamId: number; teamLicenceId: number; userId: number }>({
      query: (payload) => ({
        url: `/api/v1/team-licence/${payload.teamLicenceId}/revoke/${payload.userId}`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
  }),
});

export const {
  useGetLicencesQuery,
  usePostUserLicenceMutation,
  usePutLicenceMutation,
  useCheckRevokeUserLicenceMutation,
  useRevokeUserLicenceMutation,
  usePostLicenceMutation,
} = licenceApi;
