import { faAngleDown, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommandKeyCode, ReplacementKeycode } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Chip, FormGroup, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableIcons, Icons } from '../shared';
import { red } from '@constants';
import { getVirtualKeyFromKeyCode } from '@utils';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  setKeycodes: React.Dispatch<React.SetStateAction<ReplacementKeycode[]>>;
  item: ReplacementKeycode;
  keyCodes: ReplacementKeycode[];
  index: number;
  startIndexMax: number;
};

const ReplacementKeyCodeRow = ({ setKeycodes, item, index, keyCodes, startIndexMax }: Props) => {
  const { t } = useTranslation('pano');
  const [expanded, setExpanded] = useState<boolean>(false);
  let data = JSON.parse(JSON.stringify(keyCodes)) as ReplacementKeycode[];

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>, id?: string): void {
    const modifiers = [];

    const key = getVirtualKeyFromKeyCode(e.keyCode);

    if (e.getModifierState('Alt') && key !== 'Menu') modifiers.push('Menu');
    if (e.getModifierState('Shift') && key !== 'Shift') modifiers.push('Lshift');
    if (e.getModifierState('Control') && key !== 'Control') modifiers.push('Control');

    const keys = [] as CommandKeyCode[];

    modifiers.map((x) => {
      keys.push({ key: x });
    });

    if (key && key !== 'Menu' && key !== 'Shift' && key !== 'Control') {
      keys.push({ key: key });
    }

    const kIndex = data[index].keycodes.findIndex((x) => x.id === id);

    if (kIndex !== -1) {
      keys.map((x) => {
        data[index].keycodes[kIndex].commandKeyCodes.push(x);
      });
    }
    setKeycodes([...data]);
  }

  const handleDeleteChip = (_chip: CommandKeyCode, i: number, id?: string): void => {
    const kIndex = data[index].keycodes.findIndex((x) => x.id === id);

    if (kIndex !== -1) {
      data[index].keycodes[kIndex].commandKeyCodes.splice(i, 1);
      setKeycodes([...data]);
    }
  };

  const handleRemoveCommandKey = (id?: string): void => {
    const kIndex = data[index].keycodes.findIndex((x) => x.id === id);

    if (kIndex !== -1) {
      data[index].keycodes.splice(kIndex, 1);
      setKeycodes([...data]);
    }
  };

  const addCommandKey = (i: number) => {
    data[i].keycodes.push({ commandKeyCodes: [], commandPause: false, id: uuidv4() });
    setKeycodes([...data]);
  };

  const getValue = (data: ReplacementKeycode): string => {
    let result = '';
    data.keycodes.map((key) => {
      result = result + key.commandKeyCodes.map((x) => x.key).join(' ') + ' ';
      if (key.commandPause) result = result + '(pause)' + ' ';
    });
    return result;
  };

  const handleRowInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const modifiers = [];

    const key = getVirtualKeyFromKeyCode(e.keyCode);

    if (e.getModifierState('Alt') && key !== 'Menu') modifiers.push('Menu');
    if (e.getModifierState('Shift') && key !== 'Shift') modifiers.push('Lshift');
    if (e.getModifierState('Control') && key !== 'Control') modifiers.push('Control');

    const keys = [] as CommandKeyCode[];

    modifiers.map((x) => {
      keys.push({ key: x });
    });

    if (key && key !== 'Menu' && key !== 'Shift' && key !== 'Control') {
      if (key === 'Oem8' && data[index].keycodes.length - 1 >= 0) data[index].keycodes[data[index].keycodes.length - 1].commandPause = true;
      else keys.push({ key: key });
    }
    if (keys.length) data[index].keycodes.push({ commandKeyCodes: keys, commandPause: false, id: uuidv4() });
    setKeycodes([...data]);
  };

  const deleteRow = (): void => {
    data.splice(index, 1);
    setKeycodes([...data]);
  };

  const handleCommandPauseChange = (checked: boolean, id: string | undefined): void => {
    const kIndex = data[index].keycodes.findIndex((x) => x.id === id);

    if (kIndex !== -1) {
      data[index].keycodes[kIndex].commandPause = checked;
      setKeycodes([...data]);
    }
  };

  return (
    <Accordion key={`${index}`} expanded={expanded} className="w-full">
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faAngleDown} onClick={() => setExpanded(!expanded)} className="cursor-pointer" />}
        className="!cursor-default"
        aria-controls="customer-details-content"
        id="customer-details-header">
        <div className="flex w-full justify-between pr-2 items-center">
          <div className="flex w-full space-x-3">
            <TextField
              name="Replacementkeys"
              label={t('Replacement keys') as string}
              value={getValue(item)}
              variant="outlined"
              fullWidth
              onKeyDown={(e) => handleRowInputKeyDown(e)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name="pauseMs"
              label={t('pauseMs') as string}
              value={item.commandPauseInMilliseconds ? (item.commandPauseInMilliseconds < 0 ? 0 : item.commandPauseInMilliseconds) : 0}
              variant="outlined"
              className="w-32"
              type="number"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0 }}
              onChange={(e) => {
                data[index].commandPauseInMilliseconds = parseInt(e.currentTarget.value);
                setKeycodes([...data]);
              }}
            />
            <TextField
              name="startIndex"
              label={t('startIndex') as string}
              value={item.startIndex ?? 0}
              variant="outlined"
              className="w-32"
              type="number"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0, max: startIndexMax }}
              onChange={(e) => {
                data[index].startIndex = parseInt(e.currentTarget.value);
                setKeycodes([...data]);
              }}
            />
          </div>
          <IconButton className="!ml-2" onClick={deleteRow}>
            <FontAwesomeIcon color={red[500]} icon={faTrash} size="xs" />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <MaterialTable
          icons={TableIcons}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            search: false,
            toolbar: true,
            paging: false,
            actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
          }}
          columns={[
            {
              title: t('commandKeyCodes'),
              field: 'commandKeyCodes',
              render: (data) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('keys')}
                  onKeyDown={(e) => handleKeyDown(e, data.id)}
                  InputProps={{
                    readOnly: true,
                    startAdornment: data.commandKeyCodes.map((chip, index) => (
                      <Chip
                        key={index}
                        className="!m-1"
                        label={chip.key}
                        onDelete={() => handleDeleteChip(chip, index, data.id)}
                        deleteIcon={<FontAwesomeIcon icon={faTimes} />}
                      />
                    )),
                  }}
                />
              ),
            },
            {
              title: t('commandPause'),
              field: 'commandPause',
              width: '200px',
              render: (data) => (
                <FormGroup row className="w-full flex justify-start">
                  <Checkbox
                    checked={data.commandPause}
                    onChange={(e) => handleCommandPauseChange(e.target.checked, data.id)}
                    color="primary"
                    name="commandPause"
                  />
                </FormGroup>
              ),
            },
          ]}
          actions={[
            {
              icon: Icons.Add,
              tooltip: t('addCommandKey') as string,
              isFreeAction: true,
              onClick: () => {
                addCommandKey(index);
              },
            },
            {
              icon: Icons.DeleteForeverIcon,
              tooltip: t('removeCommandKey') as string,
              onClick: (_event, data) => {
                //@ts-ignore
                handleRemoveCommandKey(data.id);
              },
            },
          ]}
          data={item.keycodes}
          title={t('replacementKeyCodes')}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ReplacementKeyCodeRow;
