import { Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import MuiPhoneNumber from 'mui-phone-number';
import links from 'src/constants/links';
import { useTranslation } from 'react-i18next';

type Props = {
  qrCodeLink: string | null;
  codeInput: boolean;
  phoneInput: boolean;
  isSMS: boolean;
  handleTextPhone: () => void;
  handleConnectPhone: () => void;
  handlePhoneNumberChange: (event: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleCodeChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  phoneNumber: string;
  verificationCode: string;
};

const MfaStepTwo = ({
  qrCodeLink,
  codeInput,
  phoneInput,
  isSMS,
  handleCodeChange,
  handleConnectPhone,
  handlePhoneNumberChange,
  handleTextPhone,
  phoneNumber,
  verificationCode,
}: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  if (isSMS) {
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center" justifyItems="center">
        <Grid container item justifyContent="center" alignItems="center" justifyItems="center" xs={12} sm={12} md={6} lg={6} spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
            <Typography variant="body1" gutterBottom>
              <strong>{t('mfaStepSMStitle')}</strong>
            </Typography>

            <br />

            <Typography variant="body1" gutterBottom>
              {t('mfaStepSMSbody')}
            </Typography>

            <br />

            <div className="flex justify-center space-x-3">
              <MuiPhoneNumber defaultCountry={'gb'} value={phoneNumber} onChange={(e) => handlePhoneNumberChange(e)} variant="outlined" />
              <Button variant="contained" color="primary" onClick={handleTextPhone} disabled={!phoneInput} className="margin-10">
                {t('mfaTextCodeButton')}
              </Button>
            </div>

            <br />

            <Typography variant="body2" gutterBottom>
              {t('mfaStepSMSbody2')}
            </Typography>

            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
            <Typography variant="body1" gutterBottom>
              <strong>{t('mfaStepEnterCode')}</strong>
            </Typography>

            <br />

            <Typography variant="body1" gutterBottom>
              {t('mfaStepSMS2body')}
            </Typography>

            <br />

            <div className="flex justify-center space-x-3">
              <TextField
                id="qr-code-input"
                variant="outlined"
                label={t('mfa6DigitCode')}
                value={verificationCode}
                onChange={(e) => handleCodeChange(e)}
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 6 }}
              />
              <Button variant="contained" color="primary" onClick={handleConnectPhone} disabled={!codeInput} className="mt-4">
                {t('mfaConnectPhone')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={4} justifyContent="center" alignItems="center" justifyItems="center">
        <Grid container item justifyContent="center" alignItems="center" justifyItems="center" xs={12} sm={12} md={6} lg={6} spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
            <Typography variant="body1" gutterBottom>
              <strong>{t('mfaStepQRtitle')}</strong>
            </Typography>

            <br />

            <Typography variant="body1" gutterBottom>
              {t('mfaStepQRbody')}
            </Typography>

            <br />

            {qrCodeLink !== null ? (
              <React.Fragment>
                <QRCodeSVG className="m-auto" value={qrCodeLink} imageSettings={{ src: links.lexacomLogoSrc, width: 32, height: 32, excavate: false }} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="body1">{t('mfaGeneratingQRCode')}</Typography>
              </React.Fragment>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
            <Typography variant="body1" gutterBottom>
              <strong>{t('mfaStepEnterCode')}</strong>
            </Typography>

            <br />

            <Typography variant="body1" gutterBottom>
              {t('mfaStepQR2body')}
            </Typography>

            <br />

            <div className="flex justify-center space-x-3">
              <TextField
                id="qr-code-input"
                variant="outlined"
                label={t('mfa6DigitCode')}
                value={verificationCode}
                onChange={(e) => handleCodeChange(e)}
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 6 }}
              />
              <Button variant="contained" color="primary" onClick={handleConnectPhone} disabled={!codeInput} className="mt-4">
                {t('mfaConnectPhone')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default MfaStepTwo;
