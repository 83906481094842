import { faPlay, faStop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { blue } from '@constants';
import React, { useState } from 'react';

type Props = {
  data: any;
  rowId: any;
};

const AudioPlayback = (props: Props) => {
  const audioId = 'audio_' + props.rowId + '_' + Math.floor(Math.random() * 10000);

  const data = 'data:audio/ogg;base64,' + props.data;

  const [stopped, setStopped] = useState(true);

  const handleEnded = () => {
    setStopped(true);
  };

  const handlePlay = () => {
    setStopped(false);

    let audio = document.getElementById(audioId) as HTMLAudioElement;

    audio.load();
    audio.play();
  };

  const handleStop = () => {
    setStopped(true);

    let audio = document.getElementById(audioId) as HTMLAudioElement;

    audio.pause();
  };

  return (
    <React.Fragment>
      {stopped ? (
        <Button variant="contained" onClick={handlePlay}>
          <FontAwesomeIcon icon={faPlay} style={{ color: blue[500] }} />
        </Button>
      ) : (
        <Button variant="contained" onClick={handleStop}>
          <FontAwesomeIcon icon={faStop} />
        </Button>
      )}
      <audio id={audioId} src={data} onEnded={handleEnded} />
    </React.Fragment>
  );
};

export default AudioPlayback;
