import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { convertKeysToPascalCase, sysConfig } from '@utils';
import {
  CreateWorkflowFromTemplateDto,
  GlobalWorkflowItem,
  MediaConfigurationTeamGroup,
  PostOrPutWorkflowDto,
  PostOrPutWorkflowTemplateDto,
  PostWorflowFiltersResult,
  TeamConfigurationItem,
  ViewLayoutGetDto,
  ViewWorkflowLayoutDto,
  WorkerPoolCategoryItem,
  WorkerPoolGridColumnSettingsDto,
  WorkerPoolItem,
  WorkflowItem,
  WorkflowServiceTemplateDto,
  WorkflowStepFilterDto,
  WorkflowTemplateDto,
} from '@interfaces';

export const mahonApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'mahonApi',
  endpoints: (builder) => ({
    getTeamConfiguration: builder.query<TeamConfigurationItem, number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/configuration`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getMediaConfiguration: builder.query<MediaConfigurationTeamGroup[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/mediaconfiguration/teamgroup`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorflowServiceTemplates: builder.query<WorkflowServiceTemplateDto[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflowservice/templates`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getGlobalMediaConfiguration: builder.query<MediaConfiguration[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/mediaconfiguration/global`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkerPools: builder.query<WorkerPoolItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkFlows: builder.query<WorkflowItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/allworkflows`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getAllWorkFlows: builder.query<WorkflowItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/all`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkFlowTemplates: builder.query<WorkflowTemplateDto[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/templates`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getAllLayouts: builder.query<ViewLayoutGetDto[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/viewlayout/all`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkflowLayouts: builder.query<ViewWorkflowLayoutDto[], { teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/viewlayout/workflow/${payload.workflowId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkflowGridColumns: builder.query<WorkerPoolGridColumnSettingsDto[], { teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/pooltrackinggridcolumns/${payload.workflowId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkflowStepFilters: builder.query<WorkflowStepFilterDto[], { teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/filters/${payload.workflowId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getStepLayout: builder.query<ViewLayoutGetDto, { layoutType: string; stepId: number; stepVersion: number; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/viewlayout/layouttype?layoutType=${payload.layoutType}&stepId=${payload.stepId}&stepVersion=${payload.stepVersion}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),

    getGlobalWorkFlows: builder.query<GlobalWorkflowItem[], void>({
      query: () => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/global`,
        method: 'GET',
      }),
    }),
    getWorkflowById: builder.query<WorkflowItem, number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/${payload}`,
        method: 'GET',
      }),
    }),
    getTeamWorflowFlag: builder.query<boolean, { teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/candirect/${payload.workflowId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkFlowStepFilters: builder.query<WorkflowStepFilterDto[], { teamId: number; stepIds: number[] }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/filters/steps?${payload.stepIds
          .map((x) => `stepId=${x}&`)
          .toString()
          .replaceAll(',', '')
          .slice(0, -1)}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkerPoolCategories: builder.query<WorkerPoolCategoryItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workerpools/category`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getStepLayoutCommand: builder.mutation<ViewLayoutGetDto, { layoutType: string; stepId: number; stepVersion: number; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/viewlayout/layouttype?layoutType=${payload.layoutType}&stepId=${payload.stepId}&stepVersion=${payload.stepVersion}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putTeamConfiguration: builder.mutation<void, { data: TeamConfigurationItem; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/configuration`,
        method: 'PUT',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postWorkerPool: builder.mutation<void, { data: WorkerPoolItem; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'POST',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postTeamWorkflow: builder.mutation<{ id: number; templateId: number }, { data: CreateWorkflowFromTemplateDto; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow`,
        method: 'POST',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postWorkflowTemplate: builder.mutation<{ id: number; templateId: number }, { data: PostOrPutWorkflowTemplateDto; teamId: number }>({
      query: (payload) => {
        const clonedSteps = payload.data.steps.map((step) => {
          const clonedStepConfigurations = step.stepConfigurations.map((config) => convertKeysToPascalCase({ ...config }));
          return {
            ...step,
            stepConfigurations: clonedStepConfigurations,
          };
        });
        const newPayload = {
          ...payload.data,
          steps: clonedSteps,
        };
        return {
          url: `${sysConfig.mahonUri}/api/v1/workflow/template`,
          method: 'POST',
          body: convertKeysToPascalCase(newPayload),
          headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
        };
      },
    }),

    putWorkflowTemplate: builder.mutation<{ id: number; templateId: number }, { data: PostOrPutWorkflowTemplateDto; teamId: number; templateId: number }>({
      query: (payload) => {
        const clonedSteps = payload.data.steps.map((step) => {
          const clonedStepConfigurations = step.stepConfigurations.map((config) => convertKeysToPascalCase({ ...config }));
          return {
            ...step,
            stepConfigurations: clonedStepConfigurations,
          };
        });
        const newPayload = {
          ...payload.data,
          steps: clonedSteps,
        };
        return {
          url: `${sysConfig.mahonUri}/api/v1/workflow/template/${payload.templateId}`,
          method: 'PUT',
          body: convertKeysToPascalCase(newPayload),
          headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
        };
      },
    }),

    putWorkflow: builder.mutation<{ id: number; templateId: number }, { data: PostOrPutWorkflowDto; teamId: number; workflowId: number }>({
      query: (payload) => {
        const clonedSteps = payload.data.steps.map((step) => {
          const clonedStepConfigurations = step.stepConfigurations.map((config) => convertKeysToPascalCase({ ...config }));
          return {
            ...step,
            stepConfigurations: clonedStepConfigurations,
          };
        });
        const newPayload = {
          ...payload.data,
          steps: clonedSteps,
        };
        return {
          url: `${sysConfig.mahonUri}/api/v1/workflow/${payload.workflowId}`,
          method: 'PUT',
          body: convertKeysToPascalCase(newPayload),
          headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
        };
      },
    }),

    putWorkerPools: builder.mutation<void, { data: WorkerPoolItem[]; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'PUT',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postWorkflowFilters: builder.mutation<PostWorflowFiltersResult, { data: WorkflowStepFilterDto[]; teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/filters/${payload.workflowId}`,
        method: 'POST',
        body: { WorkflowStepFilters: payload.data },
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putWorkflowFlag: builder.mutation<PostWorflowFiltersResult, { flagValue: boolean; teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/candirect/${payload.workflowId}`,
        method: 'PUT',
        body: JSON.stringify(payload.flagValue),
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putWorkflowTemplateLabels: builder.mutation<void, { labels: string[]; templateId: number; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/templatelabels/${payload.templateId}`,
        method: 'PUT',
        body: { Labels: payload.labels },
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    deleteWorkflowTemplate: builder.mutation<void, { teamId: number; templateId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/template/${payload.templateId}`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
  }),
});

export const {
  useGetTeamConfigurationQuery,
  usePutTeamConfigurationMutation,
  useGetTeamWorkerPoolsQuery,
  usePutWorkerPoolsMutation,
  usePostWorkerPoolMutation,
  useGetWorkerPoolCategoriesQuery,
  useGetTeamWorkFlowsQuery,
  useGetTeamWorkFlowStepFiltersQuery,
  usePostWorkflowFiltersMutation,
  useGetTeamWorflowFlagQuery,
  usePutWorkflowFlagMutation,
  useGetGlobalWorkFlowsQuery,
  useGetWorkflowByIdQuery,
  useGetGlobalMediaConfigurationQuery,
  useGetMediaConfigurationQuery,
  usePostWorkflowTemplateMutation,
  useGetAllWorkFlowsQuery,
  useGetWorkFlowTemplatesQuery,
  useGetAllLayoutsQuery,
  useGetStepLayoutQuery,
  usePutWorkflowTemplateLabelsMutation,
  usePutWorkflowTemplateMutation,
  useGetStepLayoutCommandMutation,
  useDeleteWorkflowTemplateMutation,
  usePostTeamWorkflowMutation,
  useGetWorflowServiceTemplatesQuery,
  useGetWorkflowLayoutsQuery,
  usePutWorkflowMutation,
  useGetWorkflowGridColumnsQuery,
  useGetWorkflowStepFiltersQuery,
} = mahonApi;
