import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '@apis';
import { UserItem, UserRights, NavContext, BreadcrumbItem } from '@interfaces';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { faArrowProgress, faCogs, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { sysConfig } from '@utils';
import { white } from '@constants';

export type AppState = {
  sideMenuDrawOpenStatus: boolean;
  isLoggingOut: boolean;
  activeTeam: { id: number; name: string; isSysAdmin: boolean } | null;
  userRights: UserRights;
  addedUser: UserItem;
  users: UserItem[];
  navContext: NavContext[];
  actionsList: JSX.Element | null;
  activeTeamContext: any;
  activeTeamContextTitle: string;
  permissionData: any;
  helmet: { title: string };
  breadcrumbs: BreadcrumbItem[];
  changeActiveTeamStatus: boolean;
  isDarkMode: boolean;
};

const initialState: AppState = {
  sideMenuDrawOpenStatus: false,
  isLoggingOut: false,
  activeTeam: null,
  addedUser: {} as UserItem,
  users: [],
  permissionData: {},
  isDarkMode: false,
  userRights: {
    isSysAdmin: false,
    isTeamOwner: false,
    isTeamAdmin: false,
    isEchoUser: false,
    sysAdminTeams: [],
    administeredTeams: [],
    ownedTeams: [],
    administeredAndOwnedTeamsCount: 0,
  },
  navContext: [],
  actionsList: null,
  activeTeamContext: null,
  activeTeamContextTitle: '',
  helmet: { title: 'Home - Lexacom Admin' },
  breadcrumbs: [],
  changeActiveTeamStatus: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUserPermissions.matchFulfilled, (state, { payload }) => {
      if (typeof payload == 'undefined' || payload == null || payload.length === 0) {
        console.debug(state);
      } else {
        state.permissionData = payload;
        let administeredTeams = [] as any[];
        const ownedTeams = payload.team_owner ? [...new Set(payload.team_owner)] : [];

        if (typeof payload.team_member != 'undefined') {
          administeredTeams = (payload['pano-team.edit'] ? [...new Set(payload['pano-team.edit'])] : [])
            .filter((at) => payload['team_member'].includes(at))
            .filter((at) => !ownedTeams.includes(at));
        }
        state.userRights.isSysAdmin = typeof payload.sys_admin != 'undefined';
        state.userRights.isTeamOwner = typeof payload.team_owner != 'undefined';
        state.userRights.isTeamAdmin = administeredTeams.length > 0;
        state.userRights.isEchoUser = typeof payload['echo-speechrec.live'] != 'undefined';
        state.userRights.sysAdminTeams = payload.sys_admin ? payload.sys_admin : [1];
        state.userRights.administeredTeams = administeredTeams;
        state.userRights.ownedTeams = ownedTeams as number[];
        state.userRights.administeredAndOwnedTeamsCount = administeredTeams.length + ownedTeams.length;
      }
    });
    builder.addMatcher(userApi.endpoints.getUsers.matchFulfilled, (state, { payload }) => {
      state.users = payload;
    });
  },
  reducers: {
    updateSideMenuDrawOpenStatus: (state, action: PayloadAction<boolean>) => {
      state.sideMenuDrawOpenStatus = action.payload;
    },
    updateUsers: (state, action: PayloadAction<UserItem>) => {
      state.users.push(action.payload);
    },
    logOut: (state) => {
      state.isLoggingOut = true;
      state.userRights = {
        isSysAdmin: false,
        isTeamOwner: false,
        isTeamAdmin: false,
        isEchoUser: false,
        sysAdminTeams: [],
        administeredTeams: [],
        ownedTeams: [],
        administeredAndOwnedTeamsCount: 0,
      };
      state.permissionData = [];
      state.sideMenuDrawOpenStatus = false;
      state.activeTeam = null;
    },
    logIn: (state) => {
      state.isLoggingOut = false;
    },
    setActiveTeam: (state, action: PayloadAction<{ id: number; name: string; isSysAdmin: boolean } | null>) => {
      state.activeTeam = action.payload;
    },
    setChangeActiveTeamStatus: (state, action: PayloadAction<boolean>) => {
      state.changeActiveTeamStatus = action.payload;
    },
    setHelmet: (state, action: PayloadAction<{ title: string }>) => {
      state.helmet = action.payload;
    },
    setBreadcrumbs: (state, action: PayloadAction<any[]>) => {
      state.breadcrumbs = action.payload;
    },
    setTeamNavigationContext: (state, action: PayloadAction<{ teamServices: any[]; teamId: string; t: TFunction<'pano', undefined, 'pano'> }>) => {
      let services = action.payload.teamServices
        .filter((ts) => ts.requiresActivation && ts.isActivated)
        .map((row, index) => {
          switch (row.id) {
            case parseInt(sysConfig.lexacomServiceId):
              return {
                key: index,
                header: action.payload.t('lexacomServiceTitle'),
                links: [
                  {
                    key: 'workerpools',
                    text: action.payload.t('workerpools'),
                    link: `/teams/${action.payload.teamId}/services/workerpools`,
                    icon: <FontAwesomeIcon fixedWidth size="lg" icon={faUserFriends} style={{ color: white[500] }} />,
                    tooltip: action.payload.t('teamWorkerPoolsPageSubtitle'),
                  },
                  {
                    key: 'workflows',
                    text: action.payload.t('workflows'),
                    link: `/teams/${action.payload.teamId}/services/workflows`,
                    icon: <FontAwesomeIcon fixedWidth size="lg" icon={faArrowProgress} style={{ color: white[500] }}/>,
                    tooltip: action.payload.t('teamWorkflowsPageSubtitle'),
                  },
                  {
                    key: 'mahonConfiguration',
                    text: action.payload.t('configuration'),
                    link: `/teams/${action.payload.teamId}/services/configuration`,
                    icon: <FontAwesomeIcon fixedWidth size="lg" icon={faCogs} style={{ color: white[500] }} />,
                    tooltip: action.payload.t('teamConfigurationPageSubtitle'),
                  },
                ],
              };
            case parseInt(sysConfig.echoServiceId):
              return {
                key: index,
                header: action.payload.t('echoServiceTitle'),
                links: [
                  {
                    key: 'echoCommands',
                    text: action.payload.t('echoCommandsButton'),
                    link: `/teams/${action.payload.teamId}/services/commands`,
                    icon: <FontAwesomeIcon fixedWidth size="lg" icon={faCogs} style={{ color: white[500] }}/>,
                    tooltip: action.payload.t('echoCommandsButton'),
                  },
                  {
                    key: 'echoConfiguration',
                    text: action.payload.t('configuration'),
                    link: `/teams/${action.payload.teamId}/services/echo-configuration`,
                    icon: <FontAwesomeIcon fixedWidth size="lg" icon={faCogs} style={{ color: white[500] }}/>,
                    tooltip: action.payload.t('teamEchoConfigurationPageSubtitle'),
                  },
                ],
              };
          }
        })
        .filter((s) => s !== undefined) as NavContext[];
      state.navContext = services;
      state.activeTeamContextTitle = services.length === 0 ? '' : action.payload.t('teamActions');
    },
    setNavigationContext: (state, action: PayloadAction<{ navContext: NavContext[]; title?: string }>) => {
      state.navContext = action.payload.navContext;
      state.activeTeamContextTitle = action.payload.title ?? '';
    },
    setNavigationActions: (state, action: PayloadAction<JSX.Element | null>) => {
      state.actionsList = action.payload;
    },
    changeTheme: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
    },
  },
});

export const {
  updateSideMenuDrawOpenStatus,
  setNavigationActions,
  logOut,
  updateUsers,
  setActiveTeam,
  setHelmet,
  setBreadcrumbs,
  setTeamNavigationContext,
  setNavigationContext,
  logIn,
  setChangeActiveTeamStatus,
  changeTheme,
} = appSlice.actions;

export const appReducer = persistReducer(
  {
    key: 'admin:app',
    storage,
    whitelist: ['sideMenuDrawOpenStatus', 'isLoggingOut', 'userRights', 'activeTeam', 'isDarkMode'],
  },
  appSlice.reducer,
);
