import { useGetTeamMembershipsQuery, useGetUserPermissionsQuery } from '@apis';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { setActiveTeam } from '@slices';
import { useAuth } from 'oidc-react';

const AppStateProvider = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  console.debug('AppStateProvider:location', location);
  const { userData, isLoading } = useAuth();
  const { userRights, permissionData, activeTeam } = useAppSelector((state) => state.app);
  const { teamMemberships } = useAppSelector((state) => state.user);
  const { isSuccess: getPermissionsSuccess, refetch } = useGetUserPermissionsQuery(undefined, { skip: !userData });
  const { isSuccess: getTeamMembershipSuccess } = useGetTeamMembershipsQuery(undefined, { skip: !userData });
  const { addedTeamId } = location.state ?? { addedTeamId: 0 };

  useEffect(() => {
    if (addedTeamId !== 0) refetch();
  }, [addedTeamId]);

  useEffect(() => {
    // if any of these states applies, we're not in a state to
    if (isLoading) return;
    if (!userData) return;

    if (!getPermissionsSuccess) return;
    if (!getTeamMembershipSuccess) return;

    let redirectUri = RoutePath.Home as RoutePath | string;
    // If the user is a sysAdmin
    if (userRights.isSysAdmin) {
      redirectUri = RoutePath.TeamsPath;

      const team = teamMemberships.filter((tm) => userRights.sysAdminTeams.includes(tm.id)).map((tm) => ({ id: tm.id, name: tm.name, isSysAdmin: true }))[0];

      if (location.pathname === RoutePath.Home) dispatch(setActiveTeam(team));
    }
    // If the user is a teamOwner or teamAdmin
    else if (userRights.isTeamOwner || userRights.isTeamAdmin) {
      const administeredOrOwnedCount = userRights.ownedTeams.length + userRights.administeredTeams.length;
      console.debug('AppStateProvider:owner/admin', administeredOrOwnedCount, userRights);

      // If the user is an owner of only 1 team and not an administrator
      if (userRights.ownedTeams.length === 1 && userRights.administeredTeams.length === 0) {
        const team = teamMemberships.find((tm) => tm.id === userRights.ownedTeams[0]);
        console.debug('AppStateProvider:ownedTeam', team);

        if (team === undefined) {
          redirectUri = RoutePath.UserAccountPath;
        } else {
          if (team.userMustEnableMfa) {
            redirectUri = RoutePath.UserMfaPathTeamRedirect.replace(':teamId', team.id.toString());
          } else {
            redirectUri = RoutePath.TeamPath.replace(':teamId', team.id.toString()).replace(':tabName', '');
          }
          if (location.pathname === RoutePath.Home)
            dispatch(
              setActiveTeam({
                id: team.id,
                name: team.name,
                isSysAdmin: false,
              }),
            );
        }
      }
      // If the user is an administrator of only 1 team and not an owner
      else if (userRights.administeredTeams.length === 1 && userRights.ownedTeams.length === 0) {
        const team = teamMemberships.find((tm) => tm.id === userRights.administeredTeams[0]);
        console.debug('AppStateProvider:administeredTeam', team);

        if (team === undefined) {
          redirectUri = RoutePath.UserAccountPath;
        } else {
          if (team.userMustEnableMfa) {
            redirectUri = RoutePath.UserMfaPathTeamRedirect.replace(':teamId', team.id.toString());
          } else {
            redirectUri = RoutePath.TeamPath.replace(':teamId', team.id.toString()).replace(':tabName', '');
          }
          if (location.pathname === RoutePath.Home)
            dispatch(
              setActiveTeam({
                id: team.id,
                name: team.name,
                isSysAdmin: false,
              }),
            );
        }
      } else if (location.pathname !== '/') {
        let params = location.pathname.split('/');
        if (params.length > 2 && params[1] === 'teams') {
          let teamId = parseInt(params[2]);
          if (
            userRights.ownedTeams.includes(teamId) ||
            userRights.administeredTeams.includes(teamId) ||
            permissionData.teams.includes(teamId) ||
            teamId === addedTeamId
          ) {
            const team = teamMemberships.find((tm) => tm.id === teamId);
            if (team && location.pathname === RoutePath.Home) {
              dispatch(
                setActiveTeam({
                  id: team.id,
                  name: team.name,
                  isSysAdmin: false,
                }),
              );
              if (team.userMustEnableMfa) {
                redirectUri = RoutePath.UserMfaPathTeamRedirect.replace(':teamId', team.id.toString());
              }
            }
          } else {
            navigate(RoutePath.Home);
          }
        }
      }
      // If the user is an owner of multiple teams
      else if (administeredOrOwnedCount > 1) {
        if (activeTeam) {
          redirectUri = RoutePath.TeamPath.replace(':teamId', activeTeam.id.toString()).replace(':tabName', '');
        } else {
          redirectUri = RoutePath.TeamsPath;
        }
      } else {
        // This is a fallback incase they have "isTeamAdmin" but no ownership
        redirectUri = RoutePath.UserAccountPath;
      }
    }
    // If none of the above conditions are hit, we move them to the user page
    else {
      console.debug('redirecting to user account path');
      redirectUri = RoutePath.UserAccountPath;
    }

    // only dispatch the redirect URI if we're at the base route
    if (location.pathname === RoutePath.Home) {
      console.debug('AppStateProvider:pushing to redirect URI', redirectUri);
      navigate(redirectUri);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRights, permissionData, userData, location]);

  return <>{children}</>;
};

export default AppStateProvider;
