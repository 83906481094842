import MaterialTable from '@material-table/core';
import { Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppSelector } from '@hooks';
import { useGetTeamEventsQuery } from '@apis';
import { useTranslation } from 'react-i18next';

type Props = {
  teamId: number;
};

const TeamApplicationStartupEvents = ({ teamId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');

  const [endDate, setEndDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 7)));
  const { teamEvents } = useAppSelector((x) => x.team);

  const { isLoading } = useGetTeamEventsQuery({ teamId, start: startDate, end: endDate }, { refetchOnMountOrArgChange: true, skip: teamId < 1 });

  const handleFromDateChange = (date: Date | null): void => {
    if (date) setStartDate(date);
  };

  const handleToDateChange = (date: Date | null): void => {
    if (date) setEndDate(date);
  };

  const tableComponents = {
    Action: (props: any) => {
      if (props.action.icon === 'from') {
        return (
          <div className="p-3 m-auto">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker label="From" format="dd/MM/yyyy" disableFuture value={startDate} maxDate={endDate} onChange={handleFromDateChange} />
            </LocalizationProvider>
          </div>
        );
      }
      if (props.action.icon === 'to') {
        return (
          <div className="p-3 m-auto">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker label="To" format="dd/MM/yyyy" disableFuture minDate={startDate} value={endDate} onChange={handleToDateChange} />
            </LocalizationProvider>
          </div>
        );
      }
      return <React.Fragment></React.Fragment>;
    },
  };

  return (
    <React.Fragment>
      <Paper className="w-full space-y-4 p-5" square>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            {t('teamEventsText')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamEventsSubText')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            isLoading={isLoading}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              search: true,
              pageSize: 5,
              draggable: false,
              filtering: false,
            }}
            columns={[
              { title: t('clientName'), field: 'clientName', defaultGroupOrder: 0 },
              { title: t('userName'), field: 'userName' },
              { title: t('email'), field: 'email' },
              { title: t('machineId'), field: 'machineId' },
              { title: t('date'), field: 'date', type: 'datetime', defaultSort: 'desc' },
              { title: t('clientVersion'), field: 'clientVersion', defaultSort: 'asc' },
            ]}
            data={teamEvents}
            title={t('teamEventsPageTitle')}
            actions={[
              {
                icon: 'from',
                isFreeAction: true,
                onClick: () => {},
              },
              {
                icon: 'to',
                isFreeAction: true,
                onClick: () => {},
              },
            ]}
            components={tableComponents}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default TeamApplicationStartupEvents;
