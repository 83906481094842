import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Fade, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { TableIcons } from '@components';
import { useAppDispatch, useAppSelector } from '@hooks';
import { openSnackbar, setActiveTeam, setBreadcrumbs, setHelmet } from '@slices';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  useGetLicencesQuery,
  useGetRoleQuery,
  useGetTeamMembersQuery,
  useGetTeamMembershipsQuery,
  useGetTeamQuery,
  useGetUserPermissionsQuery,
  usePutRoleMutation,
} from '@apis';
import { RoutePath, green, red } from '@constants';
import { LicenceItem, RoleTableData } from '@interfaces';
import { useAuth } from 'oidc-react';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TeamRole = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<RoleTableData[]>([]);
  const { userRights, changeActiveTeamStatus, activeTeam } = useAppSelector((x) => x.app);
  const { userData } = useAuth();
  const { team, teamMembers } = useAppSelector((x) => x.team);
  const { role } = useAppSelector((x) => x.role);
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const roleId = parms?.roleId ?? '';
  const [showText, setShowText] = useState<boolean>(true);
  const [licence, setLicence] = useState<LicenceItem>();
  const [consumeCount, setConsumeCount] = useState<number>(0);
  const [putRole, { isLoading: isUpdatingTeam }] = usePutRoleMutation();
  const {
    isLoading,
    refetch: refetchLicences,
    isSuccess: getLicencesSuccess,
  } = useGetLicencesQuery(parseInt(teamId), { skip: teamId === '', refetchOnMountOrArgChange: true });
  const { licences } = useAppSelector((x) => x.licence);
  const { isSuccess: getPermissionsSuccess, refetch: refetchUserPermissions } = useGetUserPermissionsQuery(undefined, { skip: !userData });
  const { isSuccess: getTeamMembershipSuccess, refetch: refetchTeamMembership } = useGetTeamMembershipsQuery(undefined, {
    skip: !userData,
  });
  const {
    isSuccess,
    isLoading: isGettingRole,
    refetch,
  } = useGetRoleQuery(
    { teamId: parseInt(teamId), roleId: parseInt(roleId) },
    {
      refetchOnMountOrArgChange: true,
      skip: teamId === '' || roleId === '',
    },
  );
  const { isSuccess: isTeamSuccess } = useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const [skip, setSkip] = useState<boolean>(true);
  const [searchRoleMemebersValue, setSearchRoleMemebersValue] = useState<string>('');
  const {
    isSuccess: isGetMembersSuccess,
    isLoading: isGettingMembersLoading,
    isFetching,
  } = useGetTeamMembersQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: skip,
  });

  const resetForm = () => {
    refetch();
  };

  const handleSubmit = () => {
    let selectedMemberIds = [] as number[];
    tableData
      .filter((o: any) => o.tableData.checked)
      .map((rowData: any) => {
        selectedMemberIds.push(rowData.id);
      });

    putRole({ teamId: parseInt(teamId), roleId: parseInt(roleId), memberIds: selectedMemberIds })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('Role members have been updated'), severity: 'success', display: true }));
        refetch();
        refetchLicences();
        if (!userRights.isSysAdmin) {
          refetchUserPermissions();
        }
      })
      .catch((err) => {
        refetch();
        console.debug('Failed while attempting to update role', err);
        return;
      });
  };

  useEffect(() => {
    if (isSuccess && isTeamSuccess) {
      let teamsLink = { text: t('teamsText'), link: '' };

      if (userRights.isSysAdmin || userRights.administeredTeams.length > 1 || userRights.ownedTeams.length > 1) {
        teamsLink = { text: t('teamsText'), link: '/teams' };
      }

      dispatch(
        setBreadcrumbs(
          activeTeam && activeTeam.id != parseInt(teamId) && !userRights.isSysAdmin
            ? [
                teamsLink,
                { text: activeTeam.name, link: `/teams/${activeTeam.id}` },
                { text: t('managedTeamsTitle'), link: `/teams/${activeTeam.id}/managedTeamsTab` },
                { text: team?.name, link: `/teams/${teamId}` },
                { text: t('teamRolesTab'), link: `/teams/${teamId}/teamRolesTab` },
                { text: role?.name ?? 'Loading Role' },
              ]
            : [
                teamsLink,
                { text: team?.name as string, link: `/teams/${teamId}` },
                { text: t('teamRolesTab'), link: `/teams/${teamId}/teamRolesTab` },
                { text: role?.name ?? 'Loading Role' },
              ],
        ),
      );
      setSkip(false);
    }
    dispatch(setHelmet({ title: t('htmlTitleRole') }));
  }, [isSuccess, isTeamSuccess]);

  useEffect(() => {
    if (getPermissionsSuccess && !userRights.isSysAdmin) {
      if (!userRights.administeredTeams.some((team) => team.toString() === teamId) && !userRights.ownedTeams.some((team) => team.toString() === teamId)) {
        if (userRights.administeredAndOwnedTeamsCount !== 0) {
          if (userRights.administeredAndOwnedTeamsCount > 1) {
            if (team && changeActiveTeamStatus) dispatch(setActiveTeam({ id: team?.id, name: team?.name, isSysAdmin: team?.isSysAdmin }));
          }
        } else {
          refetchTeamMembership();
          navigate(RoutePath.Home);
        }
      }
    }
  }, [getPermissionsSuccess]);

  useEffect(() => {
    if (getTeamMembershipSuccess && isGetMembersSuccess && isSuccess && getLicencesSuccess) {
      let roleTableData = [] as RoleTableData[];
      teamMembers.forEach((selectableMember) => {
        let isChecked = false;
        role?.members.map((selectedMember: any) => {
          if (selectedMember.id === selectableMember.id) {
            isChecked = true;
          }
        });

        roleTableData.push({
          id: selectableMember.id,
          name: `${selectableMember.fullname}`,
          emailAddress: selectableMember.email,
          tableData: {
            checked: isChecked,
          },
        });
      });
      const findLicence = licences.find((x) => x.serviceId === role?.serviceId);
      if (findLicence) {
        setShowText(false);
        setLicence(findLicence);
        setConsumeCount(0);
      } else {
        setShowText(true);
      }
      setShowText(licences.find((x) => x.serviceId === role?.serviceId) ? false : true);
      setTableData(roleTableData);
    }
  }, [getTeamMembershipSuccess, isGetMembersSuccess, isSuccess, role, licences, getLicencesSuccess, isFetching, isGettingMembersLoading]);

  const onSelectionChange = (data: RoleTableData[]): void => {
    let count = 0;
    tableData.forEach((rowData, index) => {
      if (data.find((x) => x.id === rowData.id)) tableData[index].tableData.checked = true;
      else if (tableRef?.current.dataManager.data.find((x: any) => x.id === rowData.id)) tableData[index].tableData.checked = false;
      if (tableData[index].tableData.checked && licence && !licence.userLicences.find((x) => x.userId === rowData.id)) {
        count++;
      }
    });
    setTableData(tableData);
    setConsumeCount(count);
  };

  const hasLicence = (rowData: RoleTableData): boolean => {
    const result = licence?.userLicences.find((x) => x.userId === rowData.id) ? true : false;
    return result;
  };

  return (
    <React.Fragment>
      <Grid container className="p-5">
        <Paper className="w-full p-5 space-y-4 min-h-[64px]">
          <Grid container item xs={12} sm={12} md={12} lg={12} className="relative">
            {isGettingRole || isLoading ? (
              <Fade in={isGettingRole} style={{ transitionDelay: isGettingRole ? '800ms' : '0ms' }} unmountOnExit>
                <CircularProgress className="absolute top-1/2 left-1/2 -mt-3 -ml-3" id="loadingProgress" />
              </Fade>
            ) : (
              <React.Fragment>
                <Grid className="pb-5" item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant="outlined"
                    label={t('searchMembers')}
                    fullWidth={true}
                    onChange={(e) => setSearchRoleMemebersValue(e.target.value)}
                    value={searchRoleMemebersValue}
                  />
                </Grid>

                {!showText && (
                  <Grid className="pb-5" item xs={12} sm={12} md={12} lg={12}>
                    <Typography className="text-red-400">
                      {licence && (
                        <>
                          {licence.trial && licence.count === 0
                            ? t('unlimitedTrialLicences')
                            : licence.unlimited
                            ? t('unlimitedLicences')
                            : licence.count - licence?.userLicences.length === 0
                            ? t('noLicencesAvailableToConsume')
                            : consumeCount > licence.count - licence?.userLicences.length
                            ? t('insufficientLicences')
                            : consumeCount === 0
                            ? `${licence.count - licence?.userLicences.length} ${t('remainingLicences')}`
                            : `${consumeCount} ${t('willBeConsumedOutOfThe')} ${licence.count - licence?.userLicences.length} ${t('remainingLicences')}`}
                        </>
                      )}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MaterialTable
                    icons={TableIcons}
                    tableRef={tableRef}
                    isLoading={isGettingMembersLoading || isLoading || isFetching}
                    options={{
                      sorting: false,
                      actionsColumnIndex: -1,
                      search: false,
                      selection: true,
                      draggable: false,
                      showTextRowsSelected: showText,
                    }}
                    columns={[
                      { title: t('name'), field: 'name' },
                      { title: t('emailAddress'), field: 'emailAddress' },
                      {
                        title: t('hasLicence'),
                        render: (rowData) =>
                          hasLicence(rowData) ? (
                            <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                          ),
                        hidden: showText,
                      },
                    ]}
                    onSelectionChange={onSelectionChange}
                    data={
                      searchRoleMemebersValue === ''
                        ? tableData
                        : tableData.filter(
                            (x) =>
                              x.name.toLowerCase().includes(searchRoleMemebersValue.toLowerCase()) ||
                              x.emailAddress.toLowerCase().includes(searchRoleMemebersValue.toLowerCase()),
                          )
                    }
                    title={role?.name ?? 'Loading Role'}
                  />
                </Grid>

                <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-5">
                  <Button className="!mr-5" variant="contained" color="secondary" onClick={() => resetForm()} disabled={isUpdatingTeam}>
                    {t('clearButtonText')}
                  </Button>
                  <Button variant="contained" color="primary" disabled={isUpdatingTeam} onClick={handleSubmit}>
                    {isUpdatingTeam && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                    {t('submitButtonText')}
                  </Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default TeamRole;
