import { useGetTeamMembershipsQuery } from '@apis';
import { useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

const UserTeamList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const { userData } = useAuth();
  const { isLoading } = useGetTeamMembershipsQuery(undefined, { refetchOnMountOrArgChange: true, skip: !userData });
  const { teamMemberships } = useAppSelector((x) => x.user);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6">{t('yourTeams')}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          isLoading={isLoading}
          options={{
            sorting: false,
            search: false,
            toolbar: false,
            paging: false,
            actionsColumnIndex: -1,
            draggable: false,
          }}
          columns={[
            { title: t('teamName'), field: 'name' },
            {
              title: t('teamOwner'),
              field: 'teamOwner',
              render: (data) => (
                <a href={'mailto:' + data.ownerEmail} className="underline text-lex-blue-500">
                  {data.ownerGivenName} {data.ownerFamilyName}
                </a>
              ),
            },
          ]}
          data={teamMemberships.filter((tm) => !tm.membershipPending)}
        />
      </Grid>
    </Grid>
  );
};

export default UserTeamList;
