import React, { useState } from 'react';
import { Modal, TextField, Button, Grid, Paper, Typography, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TeamSelector } from '@components';
import { TeamDetailedItem } from '@interfaces';
import { useAppDispatch, useAppSelector } from '@hooks';
import { openSnackbar } from '@slices';

type Props = {
  isOpen: boolean;
  selectValue: TeamDetailedItem | null;
  teams: TeamDetailedItem[];
  setSelectValue: React.Dispatch<React.SetStateAction<TeamDetailedItem | null>>;
  setSearchTeam: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  onClose: () => void;
  onSave: (workflowName: string, navigateAfter: boolean) => void;
};

const AssignWorkflowModal = ({ isOpen, onClose, onSave, isLoading, teams, selectValue, setSearchTeam, setSelectValue }: Props) => {
  const { t } = useTranslation('pano');
  const [name, setName] = useState<string>('');
  const [navigateAfter, setNavigateAfter] = useState<boolean>(true);
  const { workFlows } = useAppSelector((x) => x.mahon);
  const dispatch = useAppDispatch();

  const handleSave = () => {
    if (!name) dispatch(openSnackbar({ message: t('nameEmptyError'), severity: 'error', display: true }));
    if (!selectValue) dispatch(openSnackbar({ message: t('teamIsRequired'), severity: 'error', display: true }));
    const isNameUnique = workFlows.filter((x) => x.workflowTemplateState !== 0).every((template) => template.name.toLowerCase() !== name.toLowerCase());
    if (!isNameUnique) {
      dispatch(openSnackbar({ message: t('errorWorkflowNameUnique'), severity: 'error', display: true }));
      return;
    }
    if (name && selectValue) onSave(name, navigateAfter);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Grid container className="h-screen">
        <Paper className="p-5 space-y-6 w-96 m-auto">
          <Typography variant="h6" gutterBottom>
            {t('createTeamWorkflowFromTemplate')}
          </Typography>
          <Grid item xs={12}>
            <TextField fullWidth label={t('name')} value={name} onChange={(e) => setName(e.target.value)} />
          </Grid>

          <Grid item xs={12}>
            <TeamSelector teams={teams} isLoading={isLoading} selectValue={selectValue} setSelectValue={setSelectValue} setSearchTeam={setSearchTeam} />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={navigateAfter}
                    onChange={(e) => {
                      setNavigateAfter(e.target.checked);
                    }}
                    color="primary"
                    name="navigateAfter"
                  />
                }
                label={t('navigateToWorflowFilter')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end">
            <Button variant="contained" className="!mr-2" color="secondary" onClick={onClose}>
              {t('cancelButtonText')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {t('submitButtonText')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AssignWorkflowModal;
