import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks';
import { useAcceptTeamInviteMutation, useGetTeamMembershipsQuery } from '@apis';
import React from 'react';
import { TableIcons } from '../shared';
import { useAuth } from 'oidc-react';

const UserTeamsInvitationDialog = (): JSX.Element => {
  const { t } = useTranslation('pano');

  const [acceptTeam, { isLoading: isAcceptingTeam }] = useAcceptTeamInviteMutation();
  const { teamMemberships, acceptTeamPendingIds } = useAppSelector((x) => x.user);
  const { userData } = useAuth();
  const isDialogOpen = teamMemberships.filter((tm) => tm.membershipPending).length > 0;
  const { refetch } = useGetTeamMembershipsQuery(undefined, { skip: !userData });

  const handleAcceptInvite = (teamId: number, accepted: boolean): void => {
    acceptTeam({ teamId, accepted, userId: parseInt(userData?.profile.sub ?? '0') })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to accept invite', err);
        return;
      });
  };

  const tableComponents = {
    Action: (actionProps: any) => {
      if (actionProps.action.icon != undefined && actionProps.action.icon === 'tick') {
        return (
          <Button onClick={() => handleAcceptInvite(actionProps.data.id, true)} disabled={actionProps.action.disabled}>
            {isAcceptingTeam && acceptTeamPendingIds.includes(actionProps.data.id) && (
              <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
            )}
            <TableIcons.Tick />
          </Button>
        );
      } else if (actionProps.action.icon != undefined && actionProps.action.icon === 'cross') {
        return (
          <Button onClick={() => handleAcceptInvite(actionProps.data.id, false)} disabled={actionProps.action.disabled}>
            {isAcceptingTeam && acceptTeamPendingIds.includes(actionProps.data.id) && (
              <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
            )}
            <TableIcons.Cross />
          </Button>
        );
      } else {
        return <React.Fragment />;
      }
    },
  };
  return (
    <Dialog open={isDialogOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogContent>
        <Grid container className="p-5">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6">{t('pendingInvitationDialogTitle')}</Typography>
            <br />
            <Typography variant="body1">{t('pendingInvitationDialogHelpText')}</Typography>
          </Grid>

          <Grid item className="pt-4" xs={12} sm={12} md={12} lg={12}>
            <MaterialTable
              icons={TableIcons}
              options={{
                sorting: false,
                search: false,
                toolbar: false,
                paging: false,
                actionsColumnIndex: -1,
                draggable: false,
              }}
              localization={{
                header: {
                  actions: t('confirmMembership'),
                },
              }}
              columns={[
                { title: t('teamName'), field: 'name' },
                {
                  title: t('teamOwner'),
                  field: 'teamOwner',
                  render: (data) => (
                    <a href={'mailto:' + data.ownerEmail} className="underline text-lex-blue-500">
                      {data.ownerGivenName} {data.ownerFamilyName}
                    </a>
                  ),
                },
              ]}
              data={teamMemberships.filter((tm) => tm.membershipPending)}
              actions={[
                {
                  icon: 'tick',
                  tooltip: t('PendingTeaminviteAcceptToolTip') as string,
                  onClick: () => {},
                  disabled: isAcceptingTeam,
                },
                {
                  icon: 'cross',
                  tooltip: t('IgnorePendingTeaminviteAcceptToolTip') as string,
                  onClick: () => {},
                  disabled: isAcceptingTeam,
                },
              ]}
              components={tableComponents}
            />
          </Grid>
        </Grid>
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default UserTeamsInvitationDialog;
