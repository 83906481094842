import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowFlow } from '@components';
import { PostOrPutWorkflowTemplateDto, WorkflowStepDto, WorkflowServiceDto, WorkflowTemplateDto, PostOrPutWorkflowDto } from '@interfaces';
import { Grid, Button, TextField } from '@mui/material';
import { addOrUpdateStepLayout, addTemplateStep, initializeTemplate, openSnackbar, resetTemplate, updateTemplateName } from '@slices';
import { encodeAndCompress } from '@utils';
import {
  useGetWorflowServiceTemplatesQuery,
  useGetWorkflowGridColumnsQuery,
  useGetWorkflowLayoutsQuery,
  useGetWorkflowStepFiltersQuery,
  usePutWorkflowMutation,
} from '@apis';
import { useNavigate } from 'react-router';
import { RoutePath } from '@constants';

interface TeamWorkflowEditProps {
  teamId: string;
  workflowId: string;
  isGettingWorkFlows: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

const TeamWorkflowEdit: React.FC<TeamWorkflowEditProps> = ({ teamId, workflowId, isGettingWorkFlows, refetch, isSuccess }) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentTemplate, workflowStepNewId, workflowServiceNewId, workflowServiceTemplates, workFlows } = useAppSelector((x) => x.mahon);
  const [workflowItem, setWorkflowItem] = useState<WorkflowTemplateDto>();
  const [putWorkflow, { isLoading: putIsLoading }] = usePutWorkflowMutation();

  useGetWorflowServiceTemplatesQuery(parseInt(teamId ?? '1'), {
    refetchOnMountOrArgChange: true,
  });

  const { isSuccess: isWorkflowLayoutsSuccess } = useGetWorkflowLayoutsQuery(
    { teamId: parseInt(teamId ?? '1'), workflowId: parseInt(workflowId) },
    {
      refetchOnMountOrArgChange: true,
      skip: !workflowId,
    },
  );
  const { isSuccess: isWorkflowGridColSuccess } = useGetWorkflowGridColumnsQuery(
    { teamId: parseInt(teamId ?? '1'), workflowId: workflowItem?.workflowDto.workflowId ?? 1 },
    {
      refetchOnMountOrArgChange: true,
      skip: !workflowId || !workflowItem,
    },
  );
  const { isSuccess: isWorkflowStepFiltersSuccess } = useGetWorkflowStepFiltersQuery(
    { teamId: parseInt(teamId ?? '1'), workflowId: parseInt(workflowId) },
    {
      refetchOnMountOrArgChange: true,
      skip: !workflowId,
    },
  );

  useEffect(() => {
    if (workflowId && workFlows.length && isSuccess) {
      const findWorkflow = workFlows.find((x) => x.id === parseInt(workflowId));
      if (findWorkflow) {
        const tempWorkflow = {
          id: 0,
          workflowDto: findWorkflow,
          labels: [],
          version: 1,
        } as WorkflowTemplateDto;
        setWorkflowItem(tempWorkflow);
      }
    }
  }, [workflowId, workFlows, isSuccess]);

  useEffect(() => {
    if (workflowId && workflowItem && isSuccess && isWorkflowLayoutsSuccess && isWorkflowGridColSuccess && isWorkflowStepFiltersSuccess) {
      dispatch(initializeTemplate(workflowItem));
    }
  }, [workflowId, workflowItem, isSuccess, isWorkflowLayoutsSuccess, isWorkflowGridColSuccess, isWorkflowStepFiltersSuccess]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTemplateName(event.target.value));
  };

  const handleAddStep = (isService = false): WorkflowStepDto => {
    const newStep = {
      id: workflowStepNewId,
      name: isService ? 'New Service' : 'New Step',
      authorOnly: false,
      stepConfigurations: [],
      stepIndex: currentTemplate?.steps ? currentTemplate.steps.length + 1 : 1,
      workflowId: parseInt(workflowId),
      version: 1,
      isService,
      workflowServiceId: null,
      service: isService
        ? ({
            id: workflowServiceNewId,
            workflowId: parseInt(workflowId),
            version: 1,
            workFlowServiceTemplateId: workflowServiceTemplates[0]?.id,
            statusRoutes:
              workflowServiceTemplates[0]?.statuses.map((item) => ({
                ...item,
                progressStepIndex: item.progressStepIndex > 0 ? 0 : item.progressStepIndex,
              })) ?? [],
          } as WorkflowServiceDto)
        : null,
    };
    dispatch(addTemplateStep(newStep));
    if (!isService) {
      dispatch(
        addOrUpdateStepLayout({
          stepId: newStep.id,
          layoutType: 'DataView',
          description: '<EmptyLayout>',
          xml: encodeAndCompress(''),
        }),
      );
    }
    return newStep;
  };

  const handleReset = () => {
    dispatch(resetTemplate(workflowItem));
  };

  const handleSubmit = async (): Promise<void> => {
    if (currentTemplate) {
      if (!currentTemplate.name.trim()) {
        dispatch(openSnackbar({ message: t('nameEmptyError'), severity: 'error', display: true }));
        return;
      }

      const isNameUnique = workFlows.every(
        (template) => template.id === parseInt(workflowId) || template.name.toLowerCase() !== currentTemplate.name.toLowerCase(),
      );

      if (!isNameUnique) {
        dispatch(openSnackbar({ message: t('errorWorkflowNameUnique'), severity: 'error', display: true }));
        return;
      }

      if (currentTemplate.steps.length === 0) {
        dispatch(openSnackbar({ message: t('errorAtLeastOneStep'), severity: 'error', display: true }));
        return;
      }

      const stepNames = new Set();
      for (const step of currentTemplate.steps) {
        if (!step.name.trim()) {
          dispatch(openSnackbar({ message: t('errorAllStepsNeedName'), severity: 'error', display: true }));
          return;
        }
        if (stepNames.has(step.name)) {
          dispatch(openSnackbar({ message: t('errorUniqueStepNames'), severity: 'error', display: true }));
          return;
        }
        stepNames.add(step.name);

        if (step.isService && step.service) {
          const invalidStatusRoutes = step.service.statusRoutes.some((route) => route.progressStepIndex !== -1 && route.progressStepIndex === 0);
          if (invalidStatusRoutes) {
            dispatch(
              openSnackbar({
                message: t('errorStatusRouteNotConnectedToStep'),
                severity: 'error',
                display: true,
              }),
            );
            return;
          }
        }
      }

      const templateData = JSON.parse(JSON.stringify(currentTemplate)) as PostOrPutWorkflowTemplateDto;
      const modifiedTemplateData = modifyFlowchart(templateData);

      const payload: PostOrPutWorkflowDto = {
        layouts: modifiedTemplateData.layouts,
        name: modifiedTemplateData.name,
        publishTemplateAction: 0,
        templateState: workflowItem?.workflowDto.workflowTemplateState as number,
        steps: modifiedTemplateData.steps,
        workerPoolGridColumnSettings: modifiedTemplateData.workerPoolGridColumnSettings,
        workflowStepFilters: modifiedTemplateData.workflowStepFilters,
      };

      try {
        const result = await putWorkflow({
          data: payload,
          teamId: parseInt(teamId),
          workflowId: workflowItem?.workflowDto.workflowId ?? 1,
        }).unwrap();

        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetch();
        navigate(RoutePath.TeamWorkFlowEditPath.replace(':teamId', teamId ? teamId : '').replace(':workflowId', result.id.toString()));
      } catch (err) {
        console.error('Failed while attempting to submit workflow', err);
        dispatch(openSnackbar({ message: t('errorSavingWorkflow'), severity: 'error', display: true }));
      }
    }
  };

  const modifyFlowchart = (templateData: PostOrPutWorkflowTemplateDto): PostOrPutWorkflowTemplateDto => {
    let firstFlowMedia = null;
    const allDestinationSteps = new Map<string, any>();

    for (const step of templateData.steps) {
      const flowMedia = step.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');

      if (flowMedia) {
        if (!firstFlowMedia) {
          firstFlowMedia = flowMedia;
        }

        if (flowMedia.mediaConfiguration.destinationSteps) {
          for (const destStep of flowMedia.mediaConfiguration.destinationSteps) {
            const key = `${destStep.stepId}-${destStep.targetStepIndex}`;
            allDestinationSteps.set(key, destStep);
          }
        }
      }
    }

    if (firstFlowMedia) {
      firstFlowMedia.mediaConfiguration.destinationSteps = Array.from(allDestinationSteps.values());
    }

    return templateData;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField name="name" label={t('name')} fullWidth value={currentTemplate?.name || ''} onChange={handleNameChange} variant="outlined" />
      </Grid>
      <Grid item xs={12} className="flex items-center gap-2 justify-end">
        <Button variant="contained" color="secondary" onClick={handleReset} disabled={isGettingWorkFlows}>
          {t('resetButtonText')}
        </Button>
        <Button variant="contained" color="info" onClick={() => handleAddStep(false)} disabled={isGettingWorkFlows}>
          {t('addStep')}
        </Button>
        <Button variant="contained" color="info" onClick={() => handleAddStep(true)} disabled={isGettingWorkFlows || !workflowServiceTemplates.length}>
          {t('addService')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={putIsLoading || isGettingWorkFlows}>
          {t('submitButtonText')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <WorkflowFlow workflow={currentTemplate} isLoading={isGettingWorkFlows} addStep={handleAddStep} />
      </Grid>
    </Grid>
  );
};

export default TeamWorkflowEdit;
