import { createApi } from '@reduxjs/toolkit/query/react';
import { PermissionItem } from '@interfaces';
import { baseQueryHandler } from './base.api';

export const permissionApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'permissionApi',
  endpoints: (builder) => ({
    getPermissions: builder.query<PermissionItem[], void>({
      query: () => ({
        url: `/api/v1/permission/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPermissionsQuery } = permissionApi;
