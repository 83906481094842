import { faLongArrowLeft, faSyncAlt, faCopy, faPrint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { blue } from '@constants';
import { useTranslation } from 'react-i18next';

type Props = {
  keysCopied: boolean;
  buildRecoveryKeys: () => JSX.Element[];
  copyRecoveryKeys: () => void;
  printRecoveryKeys: () => void;
  completeMFA: () => void;
  isRecovery: boolean;
  recoveryKeys: string[];
  newRecoveryKeys: () => void;
  navigateUserPage: () => void;
};

const MfaStepThree = ({
  keysCopied,
  buildRecoveryKeys,
  completeMFA,
  copyRecoveryKeys,
  printRecoveryKeys,
  recoveryKeys,
  newRecoveryKeys,
  navigateUserPage,
}: Props) => {
  const { t } = useTranslation('pano');

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center" justifyItems="center">
      <Grid container item justifyContent="center" alignItems="center" justifyItems="center" xs={12} sm={12} md={6} lg={6} spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
          <Typography variant="body1" gutterBottom>
            <strong>{t('mfaRecoveryTitle')}</strong>
          </Typography>

          <br />

          <Typography variant="body1" gutterBottom>
            {t('mfaRecoveryBody')}
          </Typography>

          <br />

          {buildRecoveryKeys()}

          <br />

          {recoveryKeys.length === 0 ? (
            <div className="flex justify-center space-x-3">
              <Tooltip title={t('mfaReturnToUserPage')}>
                <Button variant="contained" color="secondary" onClick={navigateUserPage} startIcon={<FontAwesomeIcon icon={faLongArrowLeft} />}>
                  {t('mfaReturnToUserPage')}
                </Button>
              </Tooltip>

              <Tooltip title={t('mfaNewRecoveryKeys')}>
                <Button variant="contained" onClick={newRecoveryKeys} startIcon={<FontAwesomeIcon icon={faSyncAlt} style={{ color: blue[500] }} />}>
                  {t('mfaNewRecoveryKeys')}
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="flex justify-center space-x-3">
              <Tooltip title={t('mfaCopyRecoveryKeys')}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={copyRecoveryKeys}
                  startIcon={<FontAwesomeIcon icon={faCopy} style={{ color: blue[500] }} />}>
                  {t('mfaCopyRecoveryKeys')}
                </Button>
              </Tooltip>
              <Tooltip title={t('mfaPrintKeys')}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={printRecoveryKeys}
                  startIcon={<FontAwesomeIcon icon={faPrint} style={{ color: blue[500] }} />}>
                  {t('mfaPrintKeys')}
                </Button>
              </Tooltip>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
          <Typography variant="body1" gutterBottom>
            <strong>{t('mfaRecoveryTitle2')}</strong>
          </Typography>

          <br />

          <div>
            <Button variant="contained" color="primary" onClick={completeMFA} disabled={!keysCopied}>
              {t('mfaRecoveryFinish')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MfaStepThree;
