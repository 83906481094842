import { permissionApi } from '@apis';
import { PermissionItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

export type PermissionState = {
  publicPermissions: PermissionItem[];
  allPermissions: PermissionItem[];
};

const initialState: PermissionState = {
  allPermissions: [],
  publicPermissions: [],
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(permissionApi.endpoints.getPermissions.matchFulfilled, (state, { payload }) => {
      state.publicPermissions = payload.filter((x) => x.public);
      state.allPermissions = payload;
    });
  },
  reducers: {},
});
