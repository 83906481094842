import { Navigate } from 'react-router-dom';

type Props = {
  children: JSX.Element;
  requiredPermissions?: [];
};

const RequirePermission = ({ children, requiredPermissions }: Props): JSX.Element => {
  return true ? children : <Navigate to="/Unauthorized" />;
};

export default RequirePermission;
