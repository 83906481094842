export const red = {
  500: '#C93131',
};

export const green = {
  500: '#53B216',
};
export const blue = {
  500: '#0077C8',
};
export const white = {
  500: '#fff',
};

export const colorsList = [
  { value: '#0077C8', label: 'Comment Local User' },
  { value: '#29353A', label: 'Comment Remote User' },
  { value: '#F1F1F1', label: 'Comment Text Local User' },
  { value: '#565B60', label: 'Comment Text Remote User' },
  { value: '#000000', label: 'Corporate Color Black' },
  { value: '#0077C8', label: 'Corporate Color Blue' },
  { value: '#565B60', label: 'Corporate Color Grey' },
  { value: '#0077C8', label: 'Corporate Color Health' },
  { value: '#0077C8', label: 'Corporate Color Legal' },
  { value: '#FFE5E5E5', label: 'Corporate Color Light Grey' },
  { value: '#0077C8', label: 'Corporate Color Pro' },
  { value: '#565B60', label: 'Corporate Color Border' },
  { value: '#D3812F', label: 'Corporate Color Orange' },
  { value: '#53B216', label: 'Corporate Color Green' },
  { value: '#E2C727', label: 'Corporate Color Yellow' },
  { value: '#C93131', label: 'Corporate Color Red' },
  { value: '#77542D', label: 'Corporate Color Dark Orange' },
  { value: '#476B26', label: 'Corporate Color Dark Green' },
  { value: '#837732', label: 'Corporate Color Dark Yellow' },
  { value: '#702B28', label: 'Corporate Color Dark Red' },
  { value: '#192320', label: 'Corporate Color Dark Grey' },
  { value: '#024b99', label: 'Corporate Color Dark Blue' },
  { value: '#FFE5E5E5', label: 'Corporate Color Dark White' },
  { value: '#FFFFFF', label: 'Corporate Color White' },
  { value: '#53B216', label: 'Player Active' },
  { value: '#53B216', label: 'Player Green' },
  { value: '#565B60', label: 'Player Inactive' },
  { value: '#C93131', label: 'Player Red' },
  { value: '#079bff', label: 'Corporate Color Light Blue' },
  { value: '#45b4ff', label: 'Corporate Color Lighter Blue' },
  { value: '#babdc1', label: 'Corporate Color Lighter Grey' },
  { value: '#C93131', label: 'Recorder Active' },
  { value: '#565B60', label: 'Recorder Inactive' },
];
