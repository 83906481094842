import { useGetUsersSearchQuery, useSendAccountInviteMutation } from '@apis';
import { TableIcons } from '@components';
import { RoutePath, tableConstants } from '@constants';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Grid, IconButton, InputAdornment, Paper, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { green } from '@constants';
import { setHelmet, setBreadcrumbs, openSnackbar } from '@slices';
import React from 'react';
import { createRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

const UserList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const tableRef = createRef();
  const [rowsPerPage, setRowsPerPage] = useState<number>(tableConstants.pageRowSize);
  const [page, setPage] = useState<number>(0);
  const [searchUser, setSearchUser] = useState<string>('');
  const [debouncedSearchUser] = useDebounce(searchUser, 200);
  const navigate = useNavigate();
  const { totalResults, users } = useAppSelector((state) => state.user);
  const { userRights } = useAppSelector((state) => state.app);
  const [resendInvite] = useSendAccountInviteMutation();

  const { isLoading } = useGetUsersSearchQuery(
    { pageNo: page, pageSize: rowsPerPage, searchTerm: debouncedSearchUser },
    {
      refetchOnMountOrArgChange: true,
      skip: !userRights.isSysAdmin,
    },
  );

  const navigateUser = (userId: number): void => {
    navigate(RoutePath.UserPath.replace(':userId', `${userId}`));
  };

  const resendAccountInvite = (userId: number): void => {
    resendInvite(userId)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('userAccountInviteSuccess'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to resend account invite', err);
        return;
      });
  };

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleUsers') }));
    dispatch(setBreadcrumbs([{ text: t('userPageTitle') }]));
  }, []);

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number): void => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchUser(e.target.value);
  };

  const tableComponents = {
    Groupbar: () => <div></div>,
    Pagination: (props: any) => (
      <TablePagination
        {...props}
        rowsPerPageOptions={tableConstants.rowsPerPageArrayOptions}
        rowsPerPage={rowsPerPage}
        count={totalResults}
        page={page}
        onPageChange={(_e, page) => handleChangePage(page)}
      />
    ),

    Action: (props: any) => {
      if (props.action.icon === 'refresh') {
        return !props.action.emailConfirmed ? (
          <Tooltip title={props.action.tooltip}>
            <IconButton className="!w-20 flex justify-center" onClick={(event) => props.action.onClick(event, props.data)}>
              <TableIcons.RefreshIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment />
        );
      }

      if (props.action.icon === 'navigate') {
        return (
          <Tooltip title={props.action.tooltip}>
            <IconButton className="!w-20 flex justify-center" onClick={(event) => props.action.onClick(event, props.data)}>
              <TableIcons.KeyboardArrowRight />
            </IconButton>
          </Tooltip>
        );
      }

      return <React.Fragment />;
    },
  };
  return (
    <Grid container>
      <Paper className="w-full py-2 px-4 !rounded-none">
        <Grid container item xs={12} className="items-center min-h-[40px]">
          <Grid item xs={4}>
            <Typography variant="h5">{t('usersText')}</Typography>
          </Grid>
          <Grid item xs={8} className="flex justify-end space-x-2 items-center">
            <TextField
              className="max-w-xs"
              variant="standard"
              id="search-user-input"
              fullWidth={true}
              label=""
              placeholder={t('searchUsers') as string}
              onChange={(e) => handleSearchInput(e)}
              value={searchUser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchUser} onClick={() => setSearchUser('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading}
          options={{
            sorting: false,
            actionsColumnIndex: -1,
            search: false,
            grouping: false,
            pageSize: rowsPerPage,
            pageSizeOptions: tableConstants.rowsPerPageArrayOptions,
            draggable: false,
            showTitle: false,
            toolbar: false,
          }}
          columns={[
            { title: t('id'), field: 'id' },
            { title: t('name'), field: 'fullname' },
            { title: t('email'), field: 'email' },
            {
              title: t('emailConfirmed'),
              field: 'emailConfirmed',
              type: 'boolean',
              align: 'center',
              render: (rowData) =>
                rowData.emailConfirmed ? (
                  <FontAwesomeIcon icon={faCheck} fixedWidth size="lg" style={{ color: green[500] }} />
                ) : (
                  <strong>{t('teamMembershipPending')}</strong>
                ),
            },
          ]}
          data={users}
          actions={[
            {
              icon: 'refresh',
              tooltip: t('resendAccountInvite') as string,
              onClick: (_event, rowData) => {
                //@ts-ignore
                resendAccountInvite(rowData.id);
              },
            },
            {
              icon: 'navigate',
              tooltip: t('navigateToUser') as string,
              onClick: (_event, rowData) => {
                //@ts-ignore
                navigateUser(rowData.id);
              },
            },
          ]}
          components={tableComponents}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default UserList;
