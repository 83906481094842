import { Icons, TableIcons } from '@components';
import { RoutePath } from '@constants';
import { TeamDetailedItem, WorkflowItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {
  isLoading: boolean;
  workflows: WorkflowItem[];
  refetch: () => void;
  team: TeamDetailedItem;
};

const TeamWorkflowsTable = ({ isLoading, workflows, team }: Props) => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const navigate = useNavigate();

  const editTeamWorkflow = (id: number): void => {
    navigate(RoutePath.TeamWorkFlowEditPath.replace(':teamId', team ? team.id.toString() : '').replace(':workflowId', id.toString()));
  };

  return (
    <MaterialTable
      icons={TableIcons}
      tableRef={tableRef}
      isLoading={isLoading}
      options={{
        tableLayout: 'auto',
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
      }}
      actions={[
        {
          icon: Icons.Edit,
          tooltip: t('editTeamWorkflow') as string,
          onClick: (_e, data) => {
            if (data) {
              //@ts-ignore
              editTeamWorkflow(data.id);
            }
          },
        },
      ]}
      columns={[
        { title: t('id'), field: 'id' },
        { title: t('name'), field: 'name' },
        { title: t('version'), field: 'version' },
      ]}
      data={workflows}
      title={t('editableWorkflows')}
    />
  );
};

export default TeamWorkflowsTable;
