import { useAppSelector } from '@hooks';
import { Typography, Breadcrumbs, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const UiLink = (props: any) => <Link {...props} component={RouterLink} className="!no-underline" />;

const AppBreadcrumbs = (): JSX.Element => {
  const { breadcrumbs } = useAppSelector((state) => state.app);

  if (!breadcrumbs || breadcrumbs.length == 0) {
    console.debug('No breadcrumbs, returning empty div');
    return <div></div>;
  }

  const links = breadcrumbs.map((b, index) => {
    console.debug('appBreadcrumbs:map', b);
    if (!b.text)
      return (
        <Typography color="textPrimary" key={index}>
          ???
        </Typography>
      );

    if (b.link) {
      return (
        <UiLink color="inherit" to={b.link} key={b.link}>
          {b.text}
        </UiLink>
      );
    } else {
      return (
        <Typography color="textPrimary" key={b.text}>
          {b.text}
        </Typography>
      );
    }
  });

  console.debug('appBreadcrumbs:links', links);

  return (
    <div className="mb-6">
      <Breadcrumbs aria-label="breadcrumb">{links}</Breadcrumbs>
    </div>
  );
};

export default AppBreadcrumbs;
