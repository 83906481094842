import React from 'react';
import { Paper } from '@mui/material';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from '@xyflow/react';
import { WorkflowStepEdgeItem } from '@interfaces';
import { LexFontAwesomeIcon } from '@components';

const CustomEdge: React.FC<EdgeProps<WorkflowStepEdgeItem>> = ({ id, data, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const markerSize = 8;
  const strokeWidth = 2;

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={data?.marker ? `url(#arrowhead-${id})` : undefined} style={{ stroke: data?.color, strokeWidth }} />
      <EdgeLabelRenderer>
        {data?.marker && (
          <Paper
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              padding: 5,
              borderRadius: 5,
              fontSize: 8,
              color: data.color,
              boxShadow: '0 0 2px rgba(0,0,0,0.3)',
            }}>
            {data.icon && <LexFontAwesomeIcon unicode={data.icon.unicode} className="mr-1" />}
            {data.label}
          </Paper>
        )}
      </EdgeLabelRenderer>
      {data?.marker && (
        <defs>
          <marker
            id={`arrowhead-${id}`}
            markerWidth={markerSize}
            markerHeight={markerSize}
            refX={markerSize - 2}
            refY={markerSize / 2}
            orient="auto"
            markerUnits="userSpaceOnUse">
            <path d={`M0,0 L0,${markerSize} L${markerSize},${markerSize / 2} z`} fill={data.color} />
          </marker>
        </defs>
      )}
    </>
  );
};

export default CustomEdge;
