import React, { createRef, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { Fab, Grid, IconButton, InputAdornment, Paper, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddTeamForm, TableIcons } from '@components';
import { tableConstants, RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useNavigate } from 'react-router';
import { useGetOwnerTeamsQuery, useGetTeamsQuery } from '@apis';
import { useDebounce } from 'use-debounce';
import { setBreadcrumbs, setChangeActiveTeamStatus, setHelmet } from '@slices';
import { useAuth } from 'oidc-react';

const TeamList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const tableRef = createRef();
  const [rowsPerPage, setRowsPerPage] = useState<number>(tableConstants.pageRowSize);
  const [page, setPage] = useState<number>(0);
  const [dialogTeam, setDialogTeam] = useState<boolean>(false);
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);
  const navigate = useNavigate();
  const { totalResults, teams } = useAppSelector((state) => state.team);
  const { userRights } = useAppSelector((state) => state.app);
  const { userData } = useAuth();
  const { teamMemberships } = useAppSelector((state) => state.user);
  const { isLoading } = useGetTeamsQuery(
    { pageNo: page, pageSize: rowsPerPage, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && userRights.isSysAdmin),
    },
  );
  const { isLoading: isOwnerTeamsLoading } = useGetOwnerTeamsQuery(
    { pageNo: page, pageSize: rowsPerPage, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && !userRights.isSysAdmin),
    },
  );

  const navigateToTeam = (id: number, _name: string): void => {
    dispatch(setChangeActiveTeamStatus(true));
    navigate(RoutePath.TeamPath.replace(':teamId', id.toString()).replace(':tabName', ''));
  };

  useEffect(() => {
    if (debouncedSearchTeam !== null || debouncedSearchTeam !== undefined || debouncedSearchTeam !== '') {
      setPage(0);
    }
  }, [debouncedSearchTeam]);

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleTeams') }));
    dispatch(setBreadcrumbs([{ text: t('teamsText') }]));
  }, []);

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number): void => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchTeam(e.target.value);
  };

  const closeTeam = (): void => {
    setDialogTeam(false);
  };

  const buildTableAction = (rowData: any) => {
    const teamMembership = teamMemberships.find((x) => x.id == rowData.id);

    if (userRights.isSysAdmin || !teamMembership || !teamMembership.userMustEnableMfa) {
      return {
        icon: TableIcons.KeyboardArrowRight,
        tooltip: t('navigateToTeam'),
        onClick: (_event: any, rowData: any) => {
          navigateToTeam(rowData.id, rowData.name);
        },
        disabled: false,
      };
    }

    return {
      icon: TableIcons.LockIcon,
      tooltip: t('EnableMfaTooltip'),
      onClick: () => {
        navigate(RoutePath.UserMfaPath);
      },
      disabled: false,
    };
  };

  const tableComponents = {
    Groupbar: () => <div></div>,
    Pagination: (props: any) => (
      <TablePagination
        {...props}
        rowsPerPageOptions={tableConstants.rowsPerPageArrayOptions}
        rowsPerPage={rowsPerPage}
        count={totalResults}
        page={page}
        onPageChange={(_e, page) => handleChangePage(page)}
      />
    ),

    Action: (props: any) => {
      return (
        <div className="flex w-20 justify-center">
          <Tooltip title={t('navigateToTeam')}>
            <IconButton className="!w-10" onClick={() => navigateToTeam(props.data.id, props.data.name)}>
              <TableIcons.KeyboardArrowRight />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  };

  return (
    <Grid container>
      <Paper className="w-full py-2 px-4 !rounded-none">
        <Grid container item xs={12} className="items-center min-h-[40px]">
          <Grid item xs={4}>
            <Typography variant="h5">{t('teamsText')}</Typography>
          </Grid>
          <Grid item xs={8} className="flex justify-end space-x-2 items-center">
            <TextField
              className="max-w-xs"
              variant="standard"
              id="search-teams-input"
              fullWidth={true}
              placeholder={t('searchTeams') as string}
              onChange={(e) => handleSearchInput(e)}
              value={searchTeam}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchTeam} onClick={() => setSearchTeam('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {userRights.isSysAdmin && (
              <Fab
                className="shrink-0"
                color="primary"
                size="medium"
                aria-label="add"
                onClick={() => setDialogTeam(true)}
                disabled={isLoading || isOwnerTeamsLoading}>
                <TableIcons.Add />
              </Fab>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading || isOwnerTeamsLoading}
          options={{
            sorting: false,
            actionsColumnIndex: -1,
            search: false,
            grouping: false,
            pageSize: rowsPerPage,
            pageSizeOptions: tableConstants.rowsPerPageArrayOptions,
            draggable: false,
            showTitle: false,
            toolbar: false,
          }}
          columns={[
            { title: t('id'), field: 'id' },
            { title: t('name'), field: 'name' },
            { title: t('owner'), field: 'owner' },
            {
              title: t('active'),
              field: 'active',
              type: 'boolean',
              align: 'center',
              render: (rowData) => (rowData.active ? <TableIcons.Tick /> : <TableIcons.Cross />),
            },
            { title: t('type'), field: 'type', defaultGroupOrder: -1 },
          ]}
          data={teams}
          //@ts-ignore
          actions={[(rowData) => buildTableAction(rowData)]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          components={tableComponents}
        />
      </Grid>
      <AddTeamForm closeDialog={closeTeam} isDialogOpen={dialogTeam} />
    </Grid>
  );
};

export default TeamList;
