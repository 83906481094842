import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string | JSX.Element;
  onConfirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
};

const ConfirmationDialog = ({ onClose, onConfirm, open, title, message, cancelButtonText, confirmButtonText }: Props) => {
  const { t } = useTranslation('pano');
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog" fullWidth disableEscapeKeyDown>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {cancelButtonText ?? t('cancelButtonText')}
        </Button>

        <Button variant="contained" color="primary" onClick={() => onConfirm()}>
          {confirmButtonText ?? t('confirmButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
