import { useGetLicencesQuery, useGetTeamRolesQuery, useGetTeamServicesQuery } from '@apis';
import { Icons, TableIcons } from '@components';
import { RoutePath, tableConstants } from '@constants';
import { useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

type Props = {
  teamId: number;
};

const TeamRoles = ({ teamId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const { teamRolesTableData, teamServices } = useAppSelector((x) => x.team);
  const { licences } = useAppSelector((x) => x.licence);
  const { userRights } = useAppSelector((x) => x.app);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchRole] = useDebounce(searchTerm, 200);
  const [focus, setFocus] = useState<boolean>(false);
  const { isLoading: isLicencesLoading } = useGetLicencesQuery(teamId, { skip: !teamId, refetchOnMountOrArgChange: true });
  const { isLoading } = useGetTeamRolesQuery({ teamId, searchTerm: debouncedSearchRole }, { refetchOnMountOrArgChange: true, skip: teamId < 1 });
  const { isLoading: isServicesLoading } = useGetTeamServicesQuery(teamId, { refetchOnMountOrArgChange: true, skip: teamId < 1 });

  const navigateRole = (roleId: number) => {
    navigate(RoutePath.TeamRolePath.replace(':teamId', `${teamId}`).replace(':roleId', `${roleId}`));
  };

  const tableComponents = {
    Action: (actionProps: any) => {
      if (actionProps.action.icon === 'search') {
        return (
          <div className="p-3 m-auto">
            <TextField
              variant="standard"
              id="search-role-input"
              fullWidth={true}
              label=""
              placeholder={t('TeamEditSearchRolesHintText') as string}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
                setFocus(true);
              }}
              value={searchTerm}
              autoFocus={focus}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchTerm} onClick={() => setSearchTerm('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      }
      return (
        <Tooltip title={actionProps.action.tooltip}>
          <IconButton
            className="!w-20 flex justify-center"
            disabled={!actionProps.data.active}
            onClick={(event) => actionProps.action.onClick(event, actionProps.data)}>
            <TableIcons.KeyboardArrowRight />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <React.Fragment>
      <Paper className="w-full p-5 space-y-4" square>
        <Grid item xs={12} sm={12} md={12} lg={12} className="padding-area-bottom">
          <Typography variant="h6" gutterBottom>
            {t('teamRolesText')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamRolesSubText')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            isLoading={isLoading || isLicencesLoading || isServicesLoading}
            options={{
              actionsColumnIndex: -1,
              search: false,
              sorting: false,
              pageSize: tableConstants.pageRowSize,
              pageSizeOptions: tableConstants.rowsPerPageArrayOptions,
              draggable: false,
            }}
            columns={[
              {
                title: t('logoListHeader'),
                field: 'logo',
                render: (rowData) => <img src={rowData.logo} width="38" height="38" />,
              },
              { title: t('name'), field: 'name' },
              { title: t('membersListHeader'), field: 'members' },
              {
                title: t('active'),
                field: 'active',
                render: (rowData) => (rowData.active ? <TableIcons.Tick /> : <TableIcons.Cross />),
              },
            ]}
            data={
              userRights.isSysAdmin
                ? teamRolesTableData
                : teamRolesTableData.filter(
                    (role) =>
                      !teamServices.some((service) => service.id === role.serviceId) ||
                      (teamServices.some((service) => service.id === role.serviceId) && licences.some((licence) => licence.serviceId === role.serviceId)),
                  )
            }
            title={t('teamRolesText')}
            actions={[
              {
                icon: 'search',
                isFreeAction: true,
                onClick: (_event, _rowData) => {
                  //@ts-ignore
                  console.debug('search');
                },
              },
              {
                icon: Icons.KeyboardArrowRight,
                tooltip: t('navigateToRole') as string,
                onClick: (_event, rowData) => {
                  //@ts-ignore
                  navigateRole(rowData.id);
                },
              },
            ]}
            components={tableComponents}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
export default TeamRoles;
