import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import links from 'src/constants/links';

type Props = {
  handleNoSmartphone: () => void;
  handleReadyScanCode: () => void;
};

const MfaStepOne = ({ handleNoSmartphone, handleReadyScanCode }: Props) => {
  const { t } = useTranslation('pano');

  return (
    <Grid container spacing={6} justifyContent="center" alignItems="center" justifyItems="center">
      <Grid container item justifyContent="center" alignItems="center" justifyItems="center" xs={12} sm={12} md={6} lg={6} spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
          <Typography variant="body1" gutterBottom>
            <strong>{t('mfaStep1title')}</strong>
          </Typography>

          <br />

          <Typography variant="body1" gutterBottom>
            {t('mfaStep1body')}
          </Typography>

          <br />

          <div>
            <a href={links.iOSLink}>
              <img src={links.iOSSrc} alt={t('mfaiOSDownloadText') as string} className="!w-[200px] ml-auto mr-auto mb-2" />
            </a>

            <a href={links.androidLink}>
              <img alt={t('mfaAndroidDownloadText') as string} src={links.androidSrc} className="!w-[240px] ml-auto mr-auto" />
            </a>
          </div>

          <a className="cursor-pointer text-blue-600" onClick={handleNoSmartphone}>
            {t('mfaNoPhone')}
          </a>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
          <Typography variant="body1" gutterBottom>
            <strong>{t('mfaAppOpen')}</strong>
          </Typography>
          <br />
          <React.Fragment>
            <Button variant="contained" color="primary" onClick={handleReadyScanCode}>
              {t('readyToScanCode')}
            </Button>
          </React.Fragment>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MfaStepOne;
