import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubstitutionForm } from '@interfaces';

type Props = {
  disabled: boolean;
  handleChange: (
    e: any,
    checked?: React.ReactNode,
    selectedValueId?: string | number | undefined,
    slectedValueKey?: string | undefined,
    mode?: 'normal' | 'upper' | 'lower' | undefined,
  ) => void;
  errors: Partial<Record<keyof SubstitutionForm, string>> | undefined;
  formData: SubstitutionForm;
};

const ValidateSubstitutionModal = ({ disabled, handleChange, errors, formData }: Props) => {
  const { t } = useTranslation('pano');
  const [input, setInput] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(null);

  const handleValidate = () => {
    const regexPattern = new RegExp(formData.pattern, 'gi');

    const matched = input.replace(regexPattern, formData.replacement);

    if (matched !== input) {
      setResult(matched);
    } else {
      setResult(t('noMatchFound'));
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    setOpenDialog(false);
  };

  return (
    <>
      <Button className="!mr-5" variant="contained" color="info" type="button" onClick={handleOpenDialog} disabled={disabled}>
        {t('validateSubstitution')}
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={(e, reason) => handleCloseDialog(e, reason)}
        open={openDialog}
        onKeyDown={(event) => event.stopPropagation()}>
        <DialogTitle component="div" className="flex justify-between items-center">
          {t('validateSubstitution')}
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="space-y-3">
          <TextField
            name="pattern"
            label={t('pattern')}
            value={formData.pattern}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            {...(errors?.pattern && { error: true, helperText: errors.pattern })}
          />
          <TextField
            name="replacement"
            label={t('replacement')}
            value={formData.replacement}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            {...(errors?.replacement && { error: true, helperText: errors.replacement })}
          />

          <TextField label={t('testString')} variant="outlined" fullWidth value={input} onChange={(e) => setInput(e.target.value)} />

          {result && (
            <div>
              <Typography variant="h6">{t('result')}:</Typography>
              <Typography variant="body1">{result}</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" disabled={!!errors?.pattern} onClick={() => handleValidate()}>
            {t('validate')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidateSubstitutionModal;
