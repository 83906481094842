import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: any;
  originalValue?: any;
  onChange?: (value: any) => void;
  type?: string;
  validationData?: any;
  readonly?: boolean;
};

const SnippetEditor = (props: Props) => {
  const { t } = useTranslation('pano');
  const [state, setState] = useState({
    value: '',
    onChange: (_value: any) => {},
    readonly: false,
  });

  const [validation, setValidation] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    setState({
      value: props.value,
      onChange: props.onChange as (value: any) => {},
      readonly: props.readonly === undefined ? false : props.readonly,
    });

    setOpenDialog(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setState({
      ...state,
      value: e.target.value,
    });
  };

  const handleSave = () => {
    if (state.readonly) return;

    state.onChange(state.value);

    setOpenDialog(false);
  };

  useEffect(() => {
    setState({
      value: props.value,
      onChange: props.onChange as (value: any) => {},
      readonly: props.readonly === undefined ? false : props.readonly,
    });
  }, [props]);

  useEffect(() => {
    if (!state.value) {
      if (state.value.length < 1) {
        setValidation(true);

        return;
      }
    }
    setValidation(false);
  }, [state.value]);

  const errorMessage =
    props.validationData && props.validationData.validationError == 'shortcuts' ? (
      <Typography variant="caption" className="red">
        {props.validationData.validationText}
      </Typography>
    ) : null;

  return (
    <>
      <Button variant="contained" onClick={() => handleOpenDialog()}>
        {state.readonly ? t('viewSnippetButton') : t('editSnippetButton')}
      </Button>

      <div className={`${errorMessage ? 'pt-5' : ''}`}>{errorMessage}</div>

      <Dialog fullWidth={true} maxWidth="sm" open={openDialog} onClose={(e, reason) => handleCloseDialog(e, reason)} aria-labelledby="editOnlyShortcuts">
        <DialogTitle component="div" id="editOnlyShortcuts" className="flex justify-between items-center">
          {state.readonly ? t('snippetTitleRead') : t('snippetTitleEdit')}
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <TextField
              variant="outlined"
              multiline
              disabled={state.readonly}
              fullWidth={true}
              autoCorrect="off"
              value={state.value}
              onChange={(e) => handleChange(e)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          {state.readonly ? (
            <Button autoFocus onClick={() => handleCloseDialog()}>
              {t('closeButtonText')}
            </Button>
          ) : (
            <Button disabled={validation} autoFocus color="primary" onClick={() => handleSave()}>
              {t('submitButtonText')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SnippetEditor;
