import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import {
  appReducer,
  appSlice,
  echoSlice,
  licenceSlice,
  mahonSlice,
  mfaSlice,
  permissionSlice,
  roleSlice,
  serviceSlice,
  snackbarSlice,
  substitutionSlice,
  teamSlice,
  userReducer,
  userSlice,
} from '@slices';
import { echoApi, licenceApi, mahonApi, mfaApi, permissionApi, roleApi, serviceApi, substitutionApi, teamApi, userApi } from '@apis';
import { rtkQueryErrorLogger } from '@utils';

const reducers = {
  [appSlice.name]: appReducer,
  [userSlice.name]: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [teamSlice.name]: teamSlice.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [echoSlice.name]: echoSlice.reducer,
  [echoApi.reducerPath]: echoApi.reducer,
  [mfaSlice.name]: mfaSlice.reducer,
  [mfaApi.reducerPath]: mfaApi.reducer,
  [mahonSlice.name]: mahonSlice.reducer,
  [mahonApi.reducerPath]: mahonApi.reducer,
  [permissionSlice.name]: permissionSlice.reducer,
  [permissionApi.reducerPath]: permissionApi.reducer,
  [serviceSlice.name]: serviceSlice.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [substitutionSlice.name]: substitutionSlice.reducer,
  [substitutionApi.reducerPath]: substitutionApi.reducer,
  [licenceSlice.name]: licenceSlice.reducer,
  [licenceApi.reducerPath]: licenceApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const store = configureStore({
  reducer: combinedReducer,
  devTools: process.env.NODE_ENV === 'production' ? false : true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      userApi.middleware,
      teamApi.middleware,
      roleApi.middleware,
      echoApi.middleware,
      mfaApi.middleware,
      mahonApi.middleware,
      permissionApi.middleware,
      serviceApi.middleware,
      substitutionApi.middleware,
      licenceApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
