import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const zipFeedback = (feedback: any[]) => {
  const csv = convertToCsv(feedback);

  const zip = new JSZip();

  zip.file('feedback.csv', csv);

  const audioFolder = zip.folder('audio') as JSZip;

  let count = 0;

  feedback.forEach((i) => {
    audioFolder.file(`feedback-${count++}.ogg`, i['feedbackAudio'], { base64: true });
  });

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, 'feedback.zip');
  });
};

const convertToCsv = (feedback: any[]) => {
  if (!Array.isArray(feedback)) throw 'feedback is not an array';

  let csv = '';

  csv += 'ID,';
  csv += 'GivenName,';
  csv += 'FamilyName,';
  csv += 'Email,';
  csv += 'TeamName,';
  csv += 'WhatTheSpeakerSaid,';
  csv += 'WhatTheSystemWrote,';
  csv += 'WhatWasExpected,';
  csv += 'LanguageModel,';
  csv += 'FileName\r\n';

  let count = 0;

  feedback.forEach((i) => {
    csv += escapeCvsField(i['id']) + ',';
    csv += escapeCvsField(i['givenName']) + ',';
    csv += escapeCvsField(i['familyName']) + ',';
    csv += escapeCvsField(i['email']) + ',';
    csv += escapeCvsField(i['teamName']) + ',';
    csv += escapeCvsField(i['whatTheSpeakerSaid']) + ',';
    csv += escapeCvsField(i['whatTheSystemWrote']) + ',';
    csv += escapeCvsField(i['whatWasExpected']) + ',';
    csv += escapeCvsField(i['languageModel']) + ',';
    csv += escapeCvsField(`feedback-${count++}.ogg`);
    csv += '\r\n';
  });

  return csv;
};
const escapeCvsField = (value: any): string => {
  value = value.replace('"', '""');
  value = value.replace('\n', '\\n');
  value = value.replace('\r', '\\r');
  value = value.replace('\f', '\\f');
  return '"' + value + '"';
};
export default zipFeedback;
