import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  serviceName: string;
  open: boolean;
  handleClose: () => void;
  handleActivate: (isChecked: boolean) => void;
};

const ServiceDialog = ({ serviceName, open, handleClose, handleActivate }: Props) => {
  const { t } = useTranslation('pano');
  const [checked, setChecked] = useState(false);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Activate {serviceName}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please set options for {serviceName} and click Activate.</DialogContentText>
        <FormControlLabel control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />} label={t('scribeOnly')} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleActivate(checked);
            setChecked(false);
          }}>
          Activate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceDialog;
