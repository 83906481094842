import { useValidatePasswordMutation } from '@apis';
import { RoutePath } from '@constants';
import { useAppDispatch } from '@hooks';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { openSnackbar } from '@slices';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  onSuccessAction?: (value?: string) => void;
  navigateOnClose?: boolean;
};

const PasswordDialog = ({ setOpen, open, onSuccessAction, navigateOnClose }: Props) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [validatePassword, { isLoading }] = useValidatePasswordMutation();
  const handlePasswordPromptSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    validatePassword(password)
      .unwrap()
      .then((result) => {
        if (onSuccessAction) {
          onSuccessAction(result.token);
        }
        setPassword('');
        setOpen(false);
      })
      .catch((err) => {
        console.debug('Failed while attempting to validate password', err);
        dispatch(openSnackbar({ message: t('passwordIsIncorrect'), severity: 'error', display: true }));
        return;
      });
  };

  const handlePasswordPromptCancel = (_event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setPassword('');
      setOpen(false);
      if (navigateOnClose === undefined || navigateOnClose) navigate(RoutePath.UserAccountPath);
    }
  };

  return (
    <Dialog open={open} onClose={handlePasswordPromptCancel} aria-labelledby="form-dialog-title" fullWidth disableEscapeKeyDown>
      <DialogTitle id="dialog-title">{t('passwordPromptTitle')}</DialogTitle>
      <form onSubmit={handlePasswordPromptSubmit}>
        <DialogContent>
          {t('passwordPromptBody')}

          <TextField
            variant="outlined"
            placeholder={t('password') as string}
            fullWidth={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handlePasswordPromptCancel} disabled={isLoading}>
            {t('cancelButtonText')}
          </Button>

          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PasswordDialog;
