import React, { Ref, forwardRef } from 'react';
import {
  faAddressBook,
  faPhone,
  faAngleRight,
  faTimes,
  faSearch,
  faArrowDown,
  faPlus,
  faPlusCircle,
  faPencil,
  faTimesCircle,
  faSave,
  faColumns,
  faMinus,
  faCheck,
  faLock,
  faSyncAlt,
  faTrashAlt,
  faQuestionCircle,
  faArrowToBottom,
  faBars,
  faTrash,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blue, green, red } from '@constants';

// These icons are for custom use and usage elsewhere in the system - add to this if you want new icons
export const TableIcons = {
  AddressIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faAddressBook} style={{ color: blue[500] }} {...props} ref={ref} />),
  PhoneNumberIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faPhone} style={{ color: green[500] }} {...props} ref={ref} />),
  AddBox: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faPlusCircle} {...props} ref={ref} />),
  Cancel: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faTimesCircle} {...props} ref={ref} size="sm" style={{ color: red[500] }} />),
  Save: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faSave} {...props} ref={ref} size="sm" style={{ color: green[500] }} />),
  Cross: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <FontAwesomeIcon icon={faTimes} fixedWidth size="lg" {...props} ref={ref} style={{ color: red[500] }} />
  )),
  Tick: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <FontAwesomeIcon icon={faCheck} fixedWidth size="lg" {...props} ref={ref} style={{ color: green[500] }} />
  )),
  KeyboardArrowRight: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faAngleRight} {...props} ref={ref} />),
  LockIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faLock} {...props} ref={ref} />),
  RefreshIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faSyncAlt} {...props} ref={ref} style={{ color: blue[500] }} />),
  DeleteForeverIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faTrashAlt} {...props} ref={ref} style={{ color: red[500] }} />),
  HelpIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faQuestionCircle} style={{ color: blue[500] }} {...props} ref={ref} />),
  DownloadIcon: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faArrowToBottom} style={{ color: green[500] }} {...props} ref={ref} />),

  // These icons must be kept as they are for material-table - do not change the name of these icons as they rely on the names to be correct
  Add: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faPlus} {...props} ref={ref} />),
  Check: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faCheck} {...props} ref={ref} size="lg" style={{ color: green[500] }} />),
  Clear: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faTimes} {...props} ref={ref} size="lg" style={{ color: red[500] }} />),
  Delete: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faTrashAlt} {...props} ref={ref} size="sm" style={{ color: red[500] }} />),
  DetailPanel: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faAngleRight} {...props} ref={ref} />),
  Edit: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faPencil} {...props} ref={ref} size="sm" style={{ color: blue[500] }} />),
  Export: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faArrowToBottom} {...props} ref={ref} />),
  Filter: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faBars} {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faChevronDoubleLeft} {...props} ref={ref} />),
  LastPage: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faChevronDoubleRight} {...props} ref={ref} />),
  NextPage: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faChevronRight} {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faChevronLeft} {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faTimes} fixedWidth {...props} ref={ref} />),
  Search: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faSearch} {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faArrowDown} {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faMinus} {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref: Ref<SVGSVGElement>) => <FontAwesomeIcon icon={faColumns} {...props} ref={ref} />),
};
