import { AlertColor, SnackbarOrigin } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SnackbarState = {
  display: boolean;
  message: string;
  severity: AlertColor;
  position?: SnackbarOrigin;
  duration?: number;
};

const initialState: SnackbarState = {
  display: false,
  message: '',
  severity: 'success',
  position: { vertical: 'bottom', horizontal: 'center' },
  duration: 5000,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<SnackbarState>) => {
      state.display = action.payload.display;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.duration = action.payload.duration ?? state.duration;
      state.position = action.payload.position ?? state.position;
    },
    closeSnackbar: (state) => {
      state.display = false;
      state.message = '';
      state.severity = 'success';
      state.duration = 5000;
      state.position = { vertical: 'bottom', horizontal: 'center' };
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;
