import { serviceApi } from '@apis';
import { ServiceDetailsItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

export type ServiceState = {
  services: ServiceDetailsItem[];
};

const initialState: ServiceState = {
  services: [],
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(serviceApi.endpoints.getServices.matchFulfilled, (state, { payload }) => {
      state.services = payload;
    });
  },
  reducers: {},
});
