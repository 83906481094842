import { userApi } from '@apis';
import { CurrentUserItem, TeamMembershipItem, UserAppItem, UserDetailedItem, UserTeamItem, UserValidationItem, UserWorkerItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage/session';

export type UserState = {
  teamMemberships: TeamMembershipItem[];
  userRoles: any[];
  users: UserDetailedItem[];
  pageCount: number;
  pageSize: number;
  totalResults: number;
  userEditData: UserWorkerItem | null;
  userTeams: UserTeamItem[];
  userApps: UserAppItem[];
  currentUserData: CurrentUserItem | null;
  newPassword: string;
  userValidation: UserValidationItem | null;
  isEmailConfirmed: boolean;
  acceptTeamPendingIds: number[];
};

const initialState: UserState = {
  teamMemberships: [],
  userRoles: [],
  users: [],
  pageCount: 0,
  pageSize: 10,
  totalResults: 0,
  userEditData: null,
  userTeams: [],
  currentUserData: null,
  userApps: [],
  newPassword: '',
  userValidation: null,
  isEmailConfirmed: false,
  acceptTeamPendingIds: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getTeamMemberships.matchFulfilled, (state, { payload }) => {
      state.teamMemberships = payload;
    });
    builder.addMatcher(userApi.endpoints.getEmailConfirmationStatus.matchFulfilled, (state, { payload }) => {
      state.isEmailConfirmed = payload;
    });
    builder.addMatcher(userApi.endpoints.getUserRoles.matchFulfilled, (state, { payload }) => {
      state.userRoles = payload;
    });
    builder.addMatcher(userApi.endpoints.getUserApp.matchFulfilled, (state, { payload }) => {
      state.userApps = payload;
    });
    builder.addMatcher(userApi.endpoints.resetUserPassword.matchFulfilled, (state, { payload }) => {
      state.newPassword = payload;
    });
    builder.addMatcher(userApi.endpoints.getCurrentUser.matchFulfilled, (state, { payload }) => {
      state.currentUserData = payload;
    });
    builder.addMatcher(userApi.endpoints.validatePassword.matchFulfilled, (state, { payload }) => {
      state.userValidation = payload;
    });
    builder.addMatcher(userApi.endpoints.acceptTeamInvite.matchPending, (state, { meta }) => {
      state.acceptTeamPendingIds.push(meta.arg.originalArgs.teamId);
    });
    builder.addMatcher(userApi.endpoints.acceptTeamInvite.matchFulfilled, (state, { meta }) => {
      state.acceptTeamPendingIds = state.acceptTeamPendingIds.filter((x) => x != meta.arg.originalArgs.teamId);
    });
    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.userEditData = payload[0];
      state.userTeams = payload[0].teams;
    });
    builder.addMatcher(userApi.endpoints.getUsersSearch.matchFulfilled, (state, { payload }) => {
      state.users = payload.results.map((value: UserDetailedItem) => {
        value.name = value.givenName + ' ' + value.familyName;
        return value;
      });
      state.totalResults = payload.totalResults;
      state.pageSize = payload.pageSize;
      state.pageCount = payload.pageCount;
    });
  },
  reducers: {},
});

export const userReducer = persistReducer(
  {
    key: 'user_validation',
    storage,
    whitelist: ['userValidation'],
  },
  userSlice.reducer,
);
