import { createApi } from '@reduxjs/toolkit/query/react';
import { ServiceDetailsItem } from '@interfaces';
import { baseQueryHandler } from './base.api';

export const serviceApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'serviceApi',
  endpoints: (builder) => ({
    getServices: builder.query<ServiceDetailsItem[], void>({
      query: () => ({
        url: `/api/v1/service/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetServicesQuery } = serviceApi;
