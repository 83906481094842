import { mahonApi } from '@apis';
import {
  DestinationStep,
  GlobalWorkflowItem,
  MediaConfiguration,
  PostOrPutWorkflowTemplateDto,
  PublishAction,
  TeamConfigurationItem,
  TeamConfigurationLookupItem,
  ViewLayoutGetDto,
  ViewLayoutPutDto,
  ViewWorkflowLayoutDto,
  WorkerPoolCategoryItem,
  WorkerPoolGridColumnSettingsDto,
  WorkerPoolItem,
  WorkflowItem,
  WorkflowServiceDto,
  WorkflowServiceTemplateDto,
  WorkflowStepDto,
  WorkflowStepFilterDto,
  WorkflowStepNodeItem,
  WorkflowTemplateDto,
} from '@interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Edge } from '@xyflow/react';

type PendingAction = { type: 'changeTab'; payload: number } | { type: 'selectNode'; payload: WorkflowStepNodeItem } | { type: 'close' };

export type MahonState = {
  teamConfiguration: TeamConfigurationItem | null;
  workflow: WorkflowItem | null;
  workerPools: WorkerPoolItem[];
  workerPoolCategories: WorkerPoolCategoryItem[];
  unlockedWorkerPools: WorkerPoolItem[];
  workFlows: WorkflowItem[];
  workflowTemplates: WorkflowTemplateDto[];
  workflowStepFilters: WorkflowStepFilterDto[];
  workflowGridColumns: WorkerPoolGridColumnSettingsDto[];
  workflowFlag: boolean;
  globalWorkflows: GlobalWorkflowItem[];
  globalMediaConfiguration: any[];
  mediaConfiguration: MediaConfiguration[];
  currentTemplate: PostOrPutWorkflowTemplateDto | null;
  nodePositions: any;
  isReset: boolean;
  edges: Edge[];
  layouts: ViewLayoutGetDto[];
  stepLayout: ViewLayoutGetDto | null;
  workflowStepNewId: number;
  workflowServiceNewId: number;
  saveStepLayout: boolean;
  unsavedChanges: {
    hasUnsavedChanges: boolean;
    pendingAction: PendingAction | null;
  };
  workflowServiceTemplates: WorkflowServiceTemplateDto[];
  workflowLayouts: ViewWorkflowLayoutDto[];
};

const createTemplateState = (
  payload: WorkflowTemplateDto,
  currentWorkflowLayouts: ViewWorkflowLayoutDto[],
  currentWorkflowStepFilters: WorkflowStepFilterDto[],
  currentWorkflowGridCols: WorkerPoolGridColumnSettingsDto[],
): PostOrPutWorkflowTemplateDto => {
  const layouts: ViewLayoutPutDto[] = [];

  payload.workflowDto.steps.forEach((step) => {
    const matchingLayout = currentWorkflowLayouts.find((layout) => layout.stepId === step.id && layout.stepVersion === step.version);

    if (matchingLayout) {
      layouts.push({
        layoutType: matchingLayout.layoutType,
        description: matchingLayout.description,
        stepId: step.id,
        xml: matchingLayout.xml,
      });
    }
  });

  return {
    id: payload.id,
    labels: payload.labels || [],
    layouts: layouts,
    name: payload.workflowDto.name,
    publishAction:
      payload.workflowDto.workflowTemplateState === 3
        ? PublishAction.UpdateOnly
        : payload.workflowDto.workflowTemplateState === 1
        ? PublishAction.ToPublic
        : PublishAction.ToPrivate,
    steps: payload.workflowDto.steps.map((step) => ({
      authorOnly: step.authorOnly,
      name: step.name,
      stepConfigurations: step.stepConfigurations
        .filter((x) => x.workflowStepId === step.id)
        .map((config) => ({
          isReadOnly: config.isReadOnly || false,
          mediaConfiguration: config.mediaConfiguration,
          workflowStepId: config.workflowStepId,
          typeName: config.typeName || '',
        })),
      stepIndex: step.stepIndex,
      workflowId: step.workflowId,
      id: step.id,
      version: step.version,
      isService: step.isService,
      workflowServiceId: step.workflowServiceId,
      service: step.service,
    })),
    workerPoolGridColumnSettings: currentWorkflowGridCols,
    workflowStepFilters: currentWorkflowStepFilters,
  };
};

const initialState: MahonState = {
  teamConfiguration: null,
  workflow: null,
  workerPools: [],
  unlockedWorkerPools: [],
  workerPoolCategories: [],
  workFlows: [],
  workflowTemplates: [],
  workflowStepFilters: [],
  workflowFlag: false,
  globalWorkflows: [],
  globalMediaConfiguration: [],
  mediaConfiguration: [],
  currentTemplate: {
    id: 0,
    labels: [],
    layouts: [],
    name: '',
    publishAction: PublishAction.UpdateOnly,
    steps: [],
    workerPoolGridColumnSettings: [],
    workflowStepFilters: [],
  },
  nodePositions: {},
  isReset: true,
  edges: [],
  layouts: [],
  stepLayout: null,
  workflowStepNewId: -1,
  workflowServiceNewId: -1,
  saveStepLayout: false,
  unsavedChanges: {
    hasUnsavedChanges: false,
    pendingAction: null,
  },
  workflowServiceTemplates: [],
  workflowLayouts: [],
  workflowGridColumns: [],
};

export const mahonSlice = createSlice({
  name: 'mahon',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(mahonApi.endpoints.getWorkflowGridColumns.matchFulfilled, (state, { payload }) => {
      state.workflowGridColumns = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkflowLayouts.matchFulfilled, (state, { payload }) => {
      state.workflowLayouts = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorflowServiceTemplates.matchFulfilled, (state, { payload }) => {
      state.workflowServiceTemplates = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamConfiguration.matchFulfilled, (state, { payload }) => {
      state.teamConfiguration = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getAllLayouts.matchFulfilled, (state, { payload }) => {
      state.layouts = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getStepLayout.matchFulfilled, (state, { payload }) => {
      state.stepLayout = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getGlobalWorkFlows.matchFulfilled, (state, { payload }) => {
      state.globalWorkflows = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkFlowTemplates.matchFulfilled, (state, { payload }) => {
      state.workflowTemplates = payload.sort((a, b) => a.id - b.id);
    });
    builder.addMatcher(mahonApi.endpoints.getWorkflowById.matchFulfilled, (state, { payload }) => {
      state.workflow = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorflowFlag.matchFulfilled, (state, { payload }) => {
      state.workflowFlag = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkerPoolCategories.matchFulfilled, (state, { payload }) => {
      state.workerPoolCategories = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkFlows.matchFulfilled, (state, { payload }) => {
      state.workFlows = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getAllWorkFlows.matchFulfilled, (state, { payload }) => {
      state.workFlows = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkFlowStepFilters.matchFulfilled, (state, { payload }) => {
      state.workflowStepFilters = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkflowStepFilters.matchFulfilled, (state, { payload }) => {
      state.workflowStepFilters = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkerPools.matchFulfilled, (state, { payload }) => {
      state.workerPools = payload.sort((a, b) => a.id - b.id);
      state.unlockedWorkerPools = payload.filter((item) => !item.locked);
    });
    builder.addMatcher(mahonApi.endpoints.getGlobalMediaConfiguration.matchFulfilled, (state, { payload }) => {
      state.globalMediaConfiguration = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getMediaConfiguration.matchFulfilled, (state, { payload }) => {
      const mediaGroupedByTeams = payload
        .sort((a, b) => b.teamIds.length - a.teamIds.length)
        .flatMap((group) => group.mediaConfigurations)
        .filter((config) => config.visualId !== '00000000-0000-0000-0000-000000000000');

      state.mediaConfiguration = Array.from(new Map(mediaGroupedByTeams.map((config) => [config.visualId, config])).values());
    });
  },
  reducers: {
    updateTeamConfigurationLookups: (state, action: PayloadAction<TeamConfigurationLookupItem>) => {
      if (state.teamConfiguration) {
        const index = state.teamConfiguration.lookups.findIndex((x) => x.mediaConfigurationId === action.payload.mediaConfigurationId);
        if (index !== -1) {
          state.teamConfiguration.lookups[index] = action.payload;
        }
      }
    },
    initializeTemplate: (state, action: PayloadAction<WorkflowTemplateDto | undefined>) => {
      if (action.payload) {
        state.currentTemplate = createTemplateState(action.payload, state.workflowLayouts, state.workflowStepFilters, state.workflowGridColumns);
      } else {
        state.currentTemplate = initialState.currentTemplate;
      }
      state.isReset = true;
    },
    updateTemplateName: (state, action: PayloadAction<string>) => {
      if (state.currentTemplate) {
        state.currentTemplate.name = action.payload;
        state.isReset = false;
      }
    },

    updateTemplateLabels: (state, action: PayloadAction<{ labels: string[]; templateId: number }>) => {
      const index = state.workflowTemplates.findIndex((x) => x.id === action.payload.templateId);
      if (index > -1) {
        state.workflowTemplates[index].labels = action.payload.labels;
        state.isReset = false;
      }
    },

    updateCurrentTemplateLabels: (state, action: PayloadAction<string[]>) => {
      if (state.currentTemplate) {
        state.currentTemplate.labels = action.payload;
        state.isReset = false;
      }
    },
    addTemplateStep: (state, action: PayloadAction<WorkflowStepDto>) => {
      if (state.currentTemplate) {
        state.currentTemplate.steps.push(action.payload);
        state.workflowStepNewId--;
        state.isReset = true;
      }
    },
    updateTemplateStep: (state, action: PayloadAction<WorkflowStepDto>) => {
      if (state.currentTemplate) {
        const index = state.currentTemplate.steps.findIndex((step) => step.id === action.payload.id);
        if (index !== -1) {
          state.currentTemplate.steps[index] = action.payload;
        }
        state.isReset = true;
      }
    },
    updateStepService: (state, action: PayloadAction<{ service: WorkflowServiceDto; stepId: number }>) => {
      if (state.currentTemplate) {
        const index = state.currentTemplate.steps.findIndex((step) => step.id === action.payload.stepId);
        if (index !== -1) {
          state.currentTemplate.steps[index].service = action.payload.service;
        }
        state.isReset = true;
      }
    },
    updateTemplatePublishAction: (state, action: PayloadAction<number>) => {
      if (state.currentTemplate) {
        state.currentTemplate.publishAction = action.payload;
        state.isReset = false;
      }
    },
    removeTemplateStep: (state, action: PayloadAction<number>) => {
      if (state.currentTemplate) {
        state.currentTemplate.steps = state.currentTemplate.steps.filter((step) => step.id !== action.payload);
        state.currentTemplate.layouts = state.currentTemplate.layouts.filter((layout) => layout.stepId !== action.payload);

        state.currentTemplate.steps = state.currentTemplate.steps.map((step, index) => ({
          ...step,
          stepIndex: index + 1,
        }));
        state.isReset = true;
      }
    },
    resetTemplate: (state, action: PayloadAction<WorkflowTemplateDto | undefined>) => {
      if (action.payload) {
        state.currentTemplate = createTemplateState(action.payload, state.workflowLayouts, state.workflowStepFilters, state.workflowGridColumns);
      } else {
        state.currentTemplate = initialState.currentTemplate;
      }
      state.isReset = true;
    },
    updateNodePosition: (state, action: PayloadAction<{ id: string; position: { x: number; y: number } }>) => {
      state.nodePositions[action.payload.id] = action.payload.position;
    },
    setResetFlag: (state, action: PayloadAction<boolean>) => {
      state.isReset = action.payload;
    },
    addEdge: (state, action: PayloadAction<Edge>) => {
      state.edges.push(action.payload);
    },
    removeEdge: (state, action: PayloadAction<string>) => {
      state.edges = state.edges.filter((edge) => edge.id !== action.payload);
    },
    addDestinationStep: (state, action: PayloadAction<DestinationStep>) => {
      const step = state.currentTemplate?.steps.find((step) => step.id === action.payload.stepId);
      if (step) {
        step.stepConfigurations.forEach((config) => {
          if (config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto') {
            if (!config.mediaConfiguration.destinationSteps) {
              config.mediaConfiguration.destinationSteps = [];
            }
            config.mediaConfiguration.destinationSteps.push(action.payload);
          }
        });
      }
      state.isReset = false;
    },
    removeDestinationStep: (state, action: PayloadAction<{ stepId: number; targetStepIndex: number }>) => {
      if (!state.currentTemplate) return;

      const { stepId, targetStepIndex } = action.payload;

      const stepToUpdate = state.currentTemplate.steps.find((step) => step.id === stepId);
      if (!stepToUpdate) return;

      const flowControlConfig = stepToUpdate.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');
      if (!flowControlConfig || !flowControlConfig.mediaConfiguration.destinationSteps) return;

      state.currentTemplate = {
        ...state.currentTemplate,
        steps: state.currentTemplate.steps.map((step) => {
          if (step.id !== stepId) return step;

          return {
            ...step,
            stepConfigurations: step.stepConfigurations.map((config) => {
              if (config.mediaConfiguration.typeName !== 'FlowControlMediaConfigurationDto') return config;

              return {
                ...config,
                mediaConfiguration: {
                  ...config.mediaConfiguration,
                  destinationSteps: config.mediaConfiguration.destinationSteps!.filter((ds) => ds.targetStepIndex !== targetStepIndex && ds.stepId === stepId),
                },
              };
            }),
          };
        }),
      };
      state.isReset = false;
    },
    updateDestinationStep: (state, action: PayloadAction<DestinationStep>) => {
      if (!state.currentTemplate) return;

      const { stepId, targetStepIndex, colour, icon, tooltip } = action.payload;

      const stepToUpdate = state.currentTemplate.steps.find((step) => step.id === stepId);
      if (!stepToUpdate) return;

      const flowControlConfig = stepToUpdate.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');
      if (!flowControlConfig || !flowControlConfig.mediaConfiguration.destinationSteps) return;

      const destinationStepIndex = flowControlConfig.mediaConfiguration.destinationSteps.findIndex(
        (ds) => ds.targetStepIndex === targetStepIndex && ds.stepId === stepId,
      );
      if (destinationStepIndex === -1) return;

      state.currentTemplate = {
        ...state.currentTemplate,
        steps: state.currentTemplate.steps.map((step) => {
          if (step.id !== stepId) return step;

          return {
            ...step,
            stepConfigurations: step.stepConfigurations.map((config) => {
              if (config.mediaConfiguration.typeName !== 'FlowControlMediaConfigurationDto') return config;

              return {
                ...config,
                mediaConfiguration: {
                  ...config.mediaConfiguration,
                  destinationSteps: config.mediaConfiguration.destinationSteps!.map((ds, index) => {
                    if (index !== destinationStepIndex) return ds;

                    return { ...ds, colour, icon, tooltip };
                  }),
                },
              };
            }),
          };
        }),
      };
    },
    addOrUpdateStepLayout: (state, action: PayloadAction<ViewLayoutPutDto>) => {
      if (state.currentTemplate) {
        const findIndex = state.currentTemplate.layouts.findIndex((x) => x.stepId === action.payload.stepId);
        if (findIndex > -1) {
          state.currentTemplate.layouts[findIndex].xml = action.payload.xml;
          state.currentTemplate.layouts[findIndex].description = action.payload.description;
        } else {
          state.currentTemplate.layouts.push(action.payload);
        }
      }
    },
    updateSaveStepLayout: (state, action: PayloadAction<boolean>) => {
      state.saveStepLayout = action.payload;
    },
    setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.unsavedChanges.hasUnsavedChanges = action.payload;
    },
    setPendingAction: (state, action: PayloadAction<MahonState['unsavedChanges']['pendingAction']>) => {
      state.unsavedChanges.pendingAction = action.payload;
    },
    clearPendingAction: (state) => {
      state.unsavedChanges.pendingAction = null;
    },
  },
});

export const {
  updateTeamConfigurationLookups,
  initializeTemplate,
  updateTemplateName,
  updateCurrentTemplateLabels,
  addTemplateStep,
  updateTemplateStep,
  removeTemplateStep,
  resetTemplate,
  updateNodePosition,
  setResetFlag,
  updateTemplatePublishAction,
  addEdge,
  removeEdge,
  addDestinationStep,
  addOrUpdateStepLayout,
  updateTemplateLabels,
  updateDestinationStep,
  removeDestinationStep,
  updateSaveStepLayout,
  setUnsavedChanges,
  setPendingAction,
  clearPendingAction,
  updateStepService,
} = mahonSlice.actions;
