import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UnsavedChangesDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onDiscard: () => void;
}

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({ open, onClose, onSave, onDiscard }) => {
  const { t } = useTranslation('pano');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('unsavedChanges')}</DialogTitle>
      <DialogContent>
        <Typography>{t('unsavedChangesMessage')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="secondary" variant="contained">
          {t('discard')}
        </Button>
        <Button onClick={onSave} color="primary" variant="contained">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
