import {
  useEnableMfaMutation,
  useGetAuthenticatorKeyMutation,
  useGetRecoveryKeysMutation,
  useGetSMSAuthenticatorKeyMutation,
  useGetTeamMembershipsQuery,
} from '@apis';
import { MfaStepOne, MfaStepThree, MfaStepTwo, MfaStepper, PasswordDialog } from '@components';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import { logOut, openSnackbar, setBreadcrumbs, setHelmet } from '@slices';
import { userManager } from '@utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const MultifactorAuthentication = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('pano');
  const { userValidation, teamMemberships } = useAppSelector((x) => x.user);
  const { recoveryKeys } = useAppSelector((x) => x.mfa);
  const parms = useParams();
  const teamId = parms?.teamId ?? undefined;
  const { isSuccess } = useGetTeamMembershipsQuery(undefined, { skip: !teamId });

  const [isPasswordDialogOpen, setPasswordIsDialogOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<string[]>([]);
  const [qrCodeLink, setQrCodeLink] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [keysCopied, setKeysCopied] = useState<boolean>(false);
  const [codeInput, setCodeInput] = useState<boolean>(false);
  const [phoneInput, setPhoneInput] = useState<boolean>(false);
  const [isSMS, setIsSMS] = useState<boolean>(false);
  const [isRecovery, setIsRecovery] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);
  const [teamName, setTeamName] = useState<string>('');

  const [enableMfa] = useEnableMfaMutation();
  const [getAuthenticatorKey] = useGetAuthenticatorKeyMutation();
  const [getRecoveryKeys] = useGetRecoveryKeysMutation();
  const [getSMSAuthenticatorKey] = useGetSMSAuthenticatorKeyMutation();

  const navigateUserPage = (): void => {
    navigate(RoutePath.UserAccountPath);
  };

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  const getSteps = (): string[] => {
    return [t('mfaGetApp'), t('mfaConnectPhone'), t('mfaSaveRecoveryKeys')];
  };

  const handleEnableMfa = (): void => {
    enableMfa({ token: userValidation?.token as string, verificationCode: verificationCode, phoneNumber: phoneNumber })
      .unwrap()
      .then(() => handleNext())
      .catch((err) => {
        console.debug('Failed while attempting to enable MFA', err);
        return;
      });
  };

  const handleGetAuthenticatorKey = (): void => {
    getAuthenticatorKey(userValidation?.token as string)
      .unwrap()
      .then((result) => {
        setQrCodeLink(result.authenticatorUri);
        handleNext();
      })
      .catch((err) => {
        console.debug('Failed while attempting to get authenticator key', err);
        return;
      });
  };

  const handleGetRecoveryKeys = (): void => {
    getRecoveryKeys(userValidation?.token as string)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('mfaNewRecoveryKeysMessage'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to get recovery keys', err);
        return;
      });
  };

  const handleGetSMSAuthenticatorKey = (): void => {
    getSMSAuthenticatorKey({ token: userValidation?.token as string, phoneNumber: phoneNumber })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: `SMS sent to ${phoneNumber} successfully`, severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to get sms auth key', err);
        return;
      });
  };

  const handleReadyScanCode = (): void => {
    validatePasswordToken(handleGetAuthenticatorKey);
  };

  const handleConnectPhone = (): void => {
    validatePasswordToken(handleEnableMfa);
  };

  const handleTextPhone = (): void => {
    validatePasswordToken(handleGetSMSAuthenticatorKey);
  };

  const newRecoveryKeys = (): void => {
    validatePasswordToken(handleGetRecoveryKeys);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVerificationCode(event.target.value);
    if (event.target.value.length === 6) {
      setCodeInput(true);
    } else {
      setCodeInput(false);
    }
  };

  const handlePhoneNumberChange = (value: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valueAsString = value as string;
    setPhoneNumber(valueAsString);
    if (valueAsString.length > 0) {
      setPhoneInput(true);
    } else {
      setPhoneInput(false);
    }
  };

  const handleNoSmartphone = () => {
    setIsSMS(true);
    handleNext();
  };

  const copyRecoveryKeys = () => {
    navigator.clipboard
      .writeText(recoveryKeys.join('\n'))
      .then(() => {
        setKeysCopied(true);

        dispatch(openSnackbar({ message: t('mfaCopiedToClipboard'), severity: 'success', display: true }));
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: err, severity: 'error', display: true }));
      });
  };

  const printRecoveryKeys = () => {
    setKeysCopied(true);
    window.print();
  };

  const completeMFA = () => {
    setIsLogoutDialogOpen(true);
  };

  const closeLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
    dispatch(logOut());
    userManager.signoutRedirect();
  };

  const buildRecoveryKeys = (): JSX.Element[] => {
    let keys = [];

    if (recoveryKeys.length > 0) {
      recoveryKeys.forEach((key) => {
        keys.push(
          <React.Fragment key={key}>
            <Chip key={key} label={key} className="mr-4 text-xl" />
            <br />
            <br />
          </React.Fragment>,
        );
      });
    } else {
      keys.push(<Typography key={t('mfaNoRecoveryKeys')}>{t('mfaNoRecoveryKeys')}</Typography>);
    }

    return keys;
  };

  const validatePasswordToken = (dispatchAction?: () => void): void => {
    let dateNow = new Date();
    let dateToken = userValidation ? new Date(userValidation.validUntil) : null;

    if (!dateToken || dateNow > dateToken) {
      setPasswordIsDialogOpen(true);
    } else {
      if (dispatchAction) {
        dispatchAction();
      }
    }
  };

  useEffect(() => {
    if (isSuccess && teamId) {
      const team = teamMemberships.find((tm) => tm.id === parseInt(teamId));
      setIsDialogOpen(true);
      setTeamName(team?.name ?? '');
    }
  }, [isSuccess]);

  useEffect(() => {
    let searchParam = new URLSearchParams(location.search).get('recovery');
    let result = searchParam === 'true';

    setIsRecovery(result);

    if (result) {
      setActiveStep(2);
    } else {
      setSteps(getSteps());
    }

    dispatch(setBreadcrumbs([{ text: t('userText'), link: '/user' }, { text: t('mfaTwoStepText') }]));
    dispatch(setHelmet({ title: t('htmlTitleMFASetup') }));
    validatePasswordToken();
  }, []);

  useEffect(() => {
    validatePasswordToken();
  });

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return <MfaStepOne handleNoSmartphone={handleNoSmartphone} handleReadyScanCode={handleReadyScanCode} />;
      case 1:
        return (
          <MfaStepTwo
            qrCodeLink={qrCodeLink}
            codeInput={codeInput}
            phoneInput={phoneInput}
            isSMS={isSMS}
            handleTextPhone={handleTextPhone}
            handleConnectPhone={handleConnectPhone}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handleCodeChange={handleCodeChange}
            phoneNumber={phoneNumber}
            verificationCode={verificationCode}
          />
        );
      case 2:
        return (
          <MfaStepThree
            keysCopied={keysCopied}
            buildRecoveryKeys={buildRecoveryKeys}
            copyRecoveryKeys={copyRecoveryKeys}
            printRecoveryKeys={printRecoveryKeys}
            completeMFA={completeMFA}
            isRecovery={isRecovery}
            recoveryKeys={recoveryKeys}
            newRecoveryKeys={newRecoveryKeys}
            navigateUserPage={navigateUserPage}
          />
        );
      default:
        return (
          <Paper>
            <Typography>{t('mfaNoStep')}</Typography>
          </Paper>
        );
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Paper className="p-5 w-full space-y-6">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              {t('mfaPageTitle')}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {t('mfaPageSubtitle')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} className="space-y-4">
            <MfaStepper activeStep={activeStep} steps={steps} />
            {getStepContent(activeStep)}
          </Grid>
        </Paper>
      </Grid>

      <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title" className="dialog-content" fullWidth disableEscapeKeyDown>
        <DialogTitle id="dialog-title">{t('MfaRequiredTitle')}</DialogTitle>

        <DialogContent className="dialog-body">{t('MfaRequiredBody', { 0: teamName })}</DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeDialog}>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isLogoutDialogOpen} onClose={closeLogoutDialog} className="dialog-content" fullWidth disableEscapeKeyDown>
        <DialogTitle>{t('mfaLogoutTitle')}</DialogTitle>

        <DialogContent className="dialog-body">
          <p>{t('mfaLogoutBody1')}</p>
          <p>{t('mfaLogoutBody2')}</p>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeLogoutDialog}>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>

      <PasswordDialog setOpen={setPasswordIsDialogOpen} open={isPasswordDialogOpen} />
    </React.Fragment>
  );
};

export default MultifactorAuthentication;
