import { substitutionApi } from '@apis';
import { createSlice } from '@reduxjs/toolkit';
import { SubstitutionDto, SubstitutionTypeDto } from '@interfaces';
import { v4 as uuidv4 } from 'uuid';

export type SubstitutionState = {
  substitution: SubstitutionDto | null;
  substitutions: SubstitutionDto[];
  substitutionTypes: SubstitutionTypeDto[];
};

const initialState: SubstitutionState = {
  substitution: null,
  substitutions: [],
  substitutionTypes: [],
};

export const substitutionSlice = createSlice({
  name: 'substitution',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(substitutionApi.endpoints.getSubstitutions.matchFulfilled, (state, { payload }) => {
      state.substitutions = payload;
    });
    builder.addMatcher(substitutionApi.endpoints.getSubstitution.matchFulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.replacementKeycodes.replacementKeycodes) {
          payload.replacementKeycodes.replacementKeycodes.map((item) => {
            item.keycodes.map((keycode) => {
              keycode.id = uuidv4();
            });
          });
          state.substitution = payload;
        }
      }
    });
    builder.addMatcher(substitutionApi.endpoints.getSubstitutionTypes.matchFulfilled, (state, { payload }) => {
      state.substitutionTypes = payload;
    });
  },
  reducers: {},
});
