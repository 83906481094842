import MaterialTable from '@material-table/core';
import { Grid, MenuItem, Typography, TextField } from '@mui/material';
import { Icons, TableIcons } from '../shared';
import { useAppSelector } from '@hooks';
import { useTranslation } from 'react-i18next';
import { useGetUserAppQuery } from '@apis';
import { useAuth } from 'oidc-react';
import { ChangeEvent, useEffect, useState } from 'react';

const UserAppList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const { userApps, teamMemberships } = useAppSelector((x) => x.user);
  const { userData } = useAuth();
  const [value, setValue] = useState<number | undefined>();
  const { isLoading } = useGetUserAppQuery(value ?? 0, { refetchOnMountOrArgChange: true, skip: !userData || !value });

  useEffect(() => {
    if (teamMemberships.length) setValue(teamMemberships[0].id);
  }, [teamMemberships]);

  const handleTeamChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setValue(parseInt(event.target.value));
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} sm={12} md={12} lg={12}>
        <Grid container item xs={12} sm={12} md={6} lg={6} className="items-center">
          <Typography variant="h6">{t('myApplications')}</Typography>
        </Grid>
        {teamMemberships && teamMemberships.length > 1 && (
          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <TextField
              select
              name="selectTeam"
              label={t('team')}
              placeholder={t('selectTeam') as string}
              fullWidth
              variant="outlined"
              value={value ?? ''}
              onChange={(event) => handleTeamChange(event)}>
              {teamMemberships.map((team, index) => (
                <MenuItem key={`${index}`} tabIndex={index} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          isLoading={isLoading}
          options={{
            sorting: false,
            search: false,
            toolbar: false,
            paging: false,
            actionsColumnIndex: -1,
            draggable: false,
            actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
          }}
          localization={{
            header: {
              actions: t('download'),
            },
            body: { emptyDataSourceMessage: t('contactYourSysAdminForInstallers') },
          }}
          columns={[
            { title: t('logo'), field: 'logoUri', render: (rowData) => <img src={rowData.logoUri} alt={rowData.logoUri} width="50" height="50" /> },
            { title: t('name'), field: 'name' },
          ]}
          data={userApps}
          actions={[
            {
              icon: Icons.DownloadIcon,
              //@ts-ignore
              onClick: (_event, rowData) => (window.location.href = rowData.downloadUri),
            },

            (rowData) => ({
              icon: Icons.HelpIcon,
              //@ts-ignore
              onClick: (_event, rowData) => (window.location.href = rowData.supportLink),
              hidden: rowData.supportLink === null ? true : false,
            }),
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UserAppList;
