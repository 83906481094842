import { useResetUserPasswordMutation } from '@apis';
import { useAppSelector } from '@hooks';
import { Button, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: number;
};

const RandomizePasswordButton = ({ userId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const [resetPassword, { isLoading }] = useResetUserPasswordMutation();
  const { newPassword } = useAppSelector((x) => x.user);

  const handleResetPasswordClicked = (): void => {
    resetPassword(userId);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button color="info" variant="contained" key={t('changePasswordHeader')} disabled={isLoading} onClick={handleResetPasswordClicked}>
          {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
          {t('resetPassword')}
        </Button>
      </Grid>

      {newPassword && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="text-red-600 mt-4 ml-4">{newPassword}</div>
        </Grid>
      )}
    </Grid>
  );
};

export default RandomizePasswordButton;
