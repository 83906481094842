import { roleApi } from '@apis';
import { ListRolesResponseItem, RoleItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

export type RoleState = {
  role: RoleItem | null;
  roles: ListRolesResponseItem[];
};

const initialState: RoleState = {
  role: null,
  roles: [],
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(roleApi.endpoints.getRole.matchFulfilled, (state, { payload }) => {
      state.role = payload;
    });
    builder.addMatcher(roleApi.endpoints.getRoles.matchFulfilled, (state, { payload }) => {
      state.roles = payload;
    });
  },
  reducers: {},
});
