import React, { useCallback, useRef, useState, useEffect } from 'react';
import { DestinationStep, WorkflowStepDto } from '@interfaces';
import { Paper, Typography, Tabs, Tab, Box, List, ListItem, ListItemText, ListItemIcon, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useClickAway } from 'react-use';
import { faChevronDown, faGripVertical, faPenSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconsList, red } from '@constants';
import StepLayout from './StepLayout';
import { useAppSelector, useAppDispatch } from '@hooks';
import { useTranslation } from 'react-i18next';
import { setUnsavedChanges, clearPendingAction, setPendingAction, updateTemplateStep } from '@slices';
import UnsavedChangesDialog from './UnsavedChangesDialog';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { LexFontAwesomeIcon } from '@components';

type Props = {
  onClose: () => void;
  step: WorkflowStepDto;
  isLoading: boolean;
  open: boolean;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
};

const StepSidePanel: React.FC<Props> = ({ step, open, onClose, isLoading, onSaveChanges, onDiscardChanges }) => {
  const panelRef = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pano');
  const { currentTemplate, unsavedChanges } = useAppSelector((x) => x.mahon);
  const [tabIndex, setTabIndex] = useState(0);
  const [localUnsavedChanges, setLocalUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [pendingTabIndex, setPendingTabIndex] = useState<number | null>(null);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      if (localUnsavedChanges) {
        dispatch(setPendingAction({ type: 'changeTab', payload: newValue }));
        setShowUnsavedDialog(true);
        setPendingTabIndex(newValue);
      } else {
        setTabIndex(newValue);
      }
    },
    [localUnsavedChanges],
  );
  const handleLocalSaveChanges = useCallback(() => {
    onSaveChanges();
    if (pendingTabIndex !== null) {
      setTabIndex(pendingTabIndex);
    }
    setLocalUnsavedChanges(false);
    setShowUnsavedDialog(false);
    setPendingTabIndex(null);
  }, [onSaveChanges, pendingTabIndex]);

  const handleLocalDiscardChanges = useCallback(() => {
    onDiscardChanges();
    if (pendingTabIndex !== null) {
      setTabIndex(pendingTabIndex);
    }
    setLocalUnsavedChanges(false);
    setShowUnsavedDialog(false);
    setPendingTabIndex(null);
  }, [onDiscardChanges, pendingTabIndex]);

  const handleUnsavedChanges = useCallback(
    (hasChanges: boolean) => {
      setLocalUnsavedChanges(hasChanges);
      dispatch(setUnsavedChanges(hasChanges));
    },
    [dispatch],
  );

  useClickAway(panelRef, () => {
    if (open) {
      if (localUnsavedChanges && !unsavedChanges.pendingAction?.type) {
        setShowUnsavedDialog(true);
        dispatch(setPendingAction({ type: 'close' }));
      } else if (!localUnsavedChanges) {
        onClose();
      }
    }
  });

  useEffect(() => {
    return () => {
      dispatch(clearPendingAction());
    };
  }, [dispatch]);

  const onDragEnd = (result: any) => {
    if (!result.destination || !currentTemplate) {
      return;
    }

    const updatedStep = structuredClone(step) as WorkflowStepDto;
    const flowControlConfig = updatedStep.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');

    if (flowControlConfig && flowControlConfig.mediaConfiguration.destinationSteps) {
      const allItems = flowControlConfig.mediaConfiguration.destinationSteps;
      const filteredItems = allItems.filter((x) => x.stepId === step.id);

      const [reorderedItem] = filteredItems.splice(result.source.index, 1);
      filteredItems.splice(result.destination.index, 0, reorderedItem);

      const updatedDestinationSteps = allItems.map((item) => {
        if (item.stepId === step.id) {
          return filteredItems.shift() || item;
        }
        return item;
      });

      flowControlConfig.mediaConfiguration.destinationSteps = updatedDestinationSteps;

      dispatch(updateTemplateStep(updatedStep));
      handleUnsavedChanges(true);
    }
  };

  const getFlowchartItems = (): DestinationStep[] => {
    const flowControlConfig = step.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');
    return flowControlConfig?.mediaConfiguration.destinationSteps?.filter((x) => x.stepId === step.id) || [];
  };

  return (
    <Paper ref={panelRef} className="h-full p-4 overflow-y-auto overflow-x-hidden border">
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Step Details Tabs" centered>
        <Tab label={t('details')} />
        <Tab label={t('layout')} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Typography variant="body1">
          {step.stepIndex}. {step.name}
        </Typography>
        <Accordion className="!mt-5 border">
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography>{t('media')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {step.stepConfigurations.map((config, index) => (
                <Paper key={index} className="w-full mb-2">
                  <ListItem className="border">
                    <ListItemText
                      primary={<Typography fontSize={12}>{config.mediaConfiguration.description}</Typography>}
                      secondary={<Typography fontSize={10}>{config.mediaConfiguration.name}</Typography>}
                    />
                    <ListItemIcon className="flex justify-end">
                      {config.isReadOnly && <FontAwesomeIcon icon={faPenSlash} style={{ color: red[500] }} />}
                    </ListItemIcon>
                  </ListItem>
                </Paper>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion className="!mt-5 border">
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography>{t('customButtons')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="flowchart-list">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {getFlowchartItems().map((item, index) => (
                      <Draggable key={`${item.stepId}-${item.targetStepIndex}`} draggableId={`${item.stepId}-${item.targetStepIndex}`} index={index}>
                        {(provided) => (
                          <Paper ref={provided.innerRef} {...provided.draggableProps} className="w-full mb-2">
                            <ListItem className="border">
                              <ListItemIcon {...provided.dragHandleProps}>
                                <FontAwesomeIcon icon={faGripVertical} />
                              </ListItemIcon>
                              <ListItemText
                                primary={<Typography fontSize={12}>{`${t('targetStepIndex')}: ${item.targetStepIndex}`}</Typography>}
                                secondary={<Typography fontSize={10}>{item.tooltip}</Typography>}
                              />
                              <ListItemIcon className="flex justify-end">
                                <LexFontAwesomeIcon unicode={iconsList.find((x) => x.id == item.icon)?.unicode ?? ''} className="mr-2" />
                              </ListItemIcon>
                            </ListItem>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </AccordionDetails>
        </Accordion>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <StepLayout
          step={step}
          isTabOpen={tabIndex === 1}
          existingLayout={currentTemplate?.layouts.find((x) => x.stepId === step.id)}
          onUnsavedChanges={handleUnsavedChanges}
        />
      </TabPanel>
      <UnsavedChangesDialog
        open={showUnsavedDialog}
        onClose={() => setShowUnsavedDialog(false)}
        onSave={handleLocalSaveChanges}
        onDiscard={handleLocalDiscardChanges}
      />
    </Paper>
  );
};

const TabPanel: React.FC<{ children?: React.ReactNode; value: number; index: number }> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box paddingY={2}>{children}</Box>}
    </div>
  );
};

export default StepSidePanel;
