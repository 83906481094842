import { Route, Routes } from 'react-router';
import { RequireAuth, RequirePermission } from '.';
import { routes } from '../../../constants/routes';
import { RouteType } from '../../../interfaces';

const RenderRoutes = (): JSX.Element => {
  const renderRoute = (route: RouteType, index: number): JSX.Element => {
    const renderLayout = (): JSX.Element => {
      return route.layout ? (
        <>
          <route.layout>
            <route.element />
          </route.layout>
        </>
      ) : (
        <main>
          <route.element />
        </main>
      );
    };

    const renderRouteWithAuthentication = (): JSX.Element => {
      return route.isAuthRequired ? <RequireAuth redirectTo={route.redirectPath ? route.redirectPath : ''}>{renderLayout()}</RequireAuth> : renderLayout();
    };

    const renderRouteWithPermissions = (): JSX.Element => {
      return route.requiredPermissions ? (
        <RequirePermission requiredPermissions={route.requiredPermissions}>{renderRouteWithAuthentication()}</RequirePermission>
      ) : (
        renderRouteWithAuthentication()
      );
    };

    return <Route key={`${index}`} path={route.path} element={renderRouteWithPermissions()} />;
  };

  return <Routes>{routes.map(renderRoute)}</Routes>;
};

export default RenderRoutes;
