import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { sysConfig } from '@utils';
import {
  ApplicationCommandItem,
  ApplicationItem,
  FormattedTokenReplacementItem,
  TeamEchoConfigurationClinicalSystemForm,
  TeamEchoConfigurationClinicalSystemItem,
  UpdateApplicationCommandItem,
  UserFeedbackResponseItem,
} from '@interfaces';

export const echoApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'echoApi',
  endpoints: (builder) => ({
    getTokenReplacement: builder.query<FormattedTokenReplacementItem, void>({
      query: () => ({
        url: `${sysConfig.echoUri}/api/v1/formatting`,
        method: 'GET',
      }),
    }),
    getApplications: builder.query<ApplicationItem[], void>({
      query: () => ({
        url: `${sysConfig.echoUri}/api/v1/applications`,
        method: 'GET',
      }),
    }),
    getFeedback: builder.query<UserFeedbackResponseItem[], void>({
      query: () => ({
        url: `${sysConfig.echoUri}/api/v1/feedback/all`,
        method: 'GET',
      }),
    }),
    getCommands: builder.query<ApplicationCommandItem[], { type: string; teamId?: number }>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v2/commands/${payload.type}`,
        method: 'GET',
        headers: payload.teamId ? { 'X-PANOPTICON-TEAM': `${payload.teamId}` } : undefined,
      }),
    }),
    getTeamEchoConfiguration: builder.query<TeamEchoConfigurationClinicalSystemItem, number>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v1/team/clinicalsystem`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putCommands: builder.mutation<void, { data: UpdateApplicationCommandItem; teamId?: number; type: string }>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v2/commands/${payload.type}`,
        method: 'PUT',
        headers: payload.teamId ? { 'X-PANOPTICON-TEAM': `${payload.teamId}` } : undefined,
        body: payload.data,
      }),
    }),
    putTeamEchoConfiguration: builder.mutation<void, { data: TeamEchoConfigurationClinicalSystemForm; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v1/team/clinicalsystem`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
        body: payload.data,
      }),
    }),
    deleteTeamEchoConfiguration: builder.mutation<void, number>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v1/team/clinicalsystem`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    deletefeedback: builder.mutation<void, string[]>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v1/feedback`,
        method: 'DELETE',
        body: JSON.stringify(payload),
      }),
    }),
    putTokenReplacement: builder.mutation<void, string>({
      query: (payload) => ({
        url: `${sysConfig.echoUri}/api/v1/formatting`,
        method: 'PUT',
        body: {
          DateFormat: payload,
          TypeName: 'FormattedTokenReplacementDto',
        },
      }),
    }),
  }),
});

export const {
  useGetTokenReplacementQuery,
  usePutTokenReplacementMutation,
  useDeleteTeamEchoConfigurationMutation,
  useGetTeamEchoConfigurationQuery,
  usePutTeamEchoConfigurationMutation,
  useGetApplicationsQuery,
  useGetCommandsQuery,
  usePutCommandsMutation,
  useGetFeedbackQuery,
  useDeletefeedbackMutation,
} = echoApi;
