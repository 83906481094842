import { ReplacementKeyCodeRow } from '@components';
import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from '@hooks';
import { ReplacementKeycode, SubstitutionForm } from '@interfaces';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { openSnackbar } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  setKeycodes: React.Dispatch<React.SetStateAction<ReplacementKeycode[]>>;
  keycodes: ReplacementKeycode[];
  isLoading: boolean;
  formData: SubstitutionForm;
};

const KeyCodeEditor = ({ setKeycodes, keycodes, isLoading, formData }: Props) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editKeyCodes, setEditKeyCodes] = useState<ReplacementKeycode[]>([]);

  useEffect(() => {
    setEditKeyCodes([...keycodes]);
  }, [keycodes]);

  const isValidStartIndex = (): boolean => {
    let uniqueStartIndices = new Set();

    for (let keycode of editKeyCodes) {
      if (uniqueStartIndices.has(keycode.startIndex)) {
        dispatch(
          openSnackbar({
            message: t('Start indices should be unique!'),
            severity: 'error',
            display: true,
          }),
        );
        return false;
      }
      uniqueStartIndices.add(keycode.startIndex);

      if (!formData.replacement || keycode.startIndex > formData.replacement.length) {
        dispatch(
          openSnackbar({
            message: t('Ensure the start index is not out of bounds with the replacement string length.'),
            severity: 'error',
            display: true,
          }),
        );
        return false;
      }
    }
    return true; // All checks passed
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSave = () => {
    if (!isValidStartIndex()) {
      dispatch(
        openSnackbar({
          message: t('Please ensure that all start indices are unique and are not out of bounds for their respective keys.'),
          severity: 'error',
          display: true,
        }),
      );
      return;
    }
    setKeycodes([...editKeyCodes]);
    setOpenDialog(false);
  };

  const handleAddReplacementKeyCodes = () => {
    editKeyCodes.push({ commandPauseInMilliseconds: 0, startIndex: 0, keycodes: [] });
    setEditKeyCodes([...editKeyCodes]);
  };

  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    setEditKeyCodes([...keycodes]);
    setOpenDialog(false);
  };

  return (
    <>
      <Button variant="contained" onClick={() => handleOpenDialog()} disabled={isLoading}>
        {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
        {t('replacementKeycodesButton')}
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={(e, reason) => handleCloseDialog(e, reason)}
        open={openDialog}
        onKeyDown={(event) => event.stopPropagation()}>
        <DialogTitle component="div" className="flex justify-between items-center">
          {t('replacementKeycodesButton')}
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ whiteSpace: 'pre-line' }}>
            {t('replacementKeycodesHelperText')}
          </Typography>
          <Grid container className="pt-4">
            <Grid item xs={12} sm={12} md={12} lg={12} className="space-y-2">
              {editKeyCodes.map((item, index) => {
                return (
                  <React.Fragment key={`${index}`}>
                    <ReplacementKeyCodeRow
                      keyCodes={editKeyCodes}
                      item={item}
                      index={index}
                      setKeycodes={setEditKeyCodes}
                      startIndexMax={formData.replacement.length}
                    />
                  </React.Fragment>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="p-5">
              <Button variant="outlined" color="primary" onClick={() => handleAddReplacementKeyCodes()} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                {t('addRowText')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={() => handleSave()}>
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KeyCodeEditor;
