import { TeamDetailedItem } from '@interfaces';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  selectValue: TeamDetailedItem | null;
  teams: TeamDetailedItem[];
  setSelectValue: React.Dispatch<React.SetStateAction<TeamDetailedItem | null>>;
  setSearchTeam: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
};

const TeamSelector = ({ selectValue, teams, isLoading, setSelectValue, setSearchTeam }: Props) => {
  const { t } = useTranslation('pano');
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      //@ts-ignore
      value={selectValue}
      disableClearable={true}
      options={teams}
      getOptionLabel={(option: TeamDetailedItem) => option.name}
      autoComplete={false}
      onChange={(_event: SyntheticEvent<Element, Event>, value: TeamDetailedItem) => setSelectValue(value)}
      loading={isLoading}
      renderTags={() => null}
      filterSelectedOptions={true}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label={t('selectTeam')}
          fullWidth
          InputProps={{
            ...params.InputProps,
            onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchTeam(e.target.value),
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default TeamSelector;
