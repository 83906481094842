import { faCalendarEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getUserLocale } from 'get-user-locale';
import { useGetTokenReplacementQuery, usePutTokenReplacementMutation } from '@apis';
import { useAppDispatch, useAppSelector } from '@hooks';
import { openSnackbar } from '@slices';
import { white } from '@constants';

const TokenFormatListButton = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { tokenReplacement } = useAppSelector((x) => x.echo);
  const [valueDate, setValueDate] = useState<string>('');
  const [finalDateFormat, setFinalDateFormat] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [putTokenReplacement] = usePutTokenReplacementMutation();
  const { isSuccess, refetch } = useGetTokenReplacementQuery(undefined, { refetchOnMountOrArgChange: true, skip: !openDialog });

  const handleCloseDialog = () => {
    setOpenDialog(false);
    refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      switchDateFormat(tokenReplacement ?? '');
    }
  }, [isSuccess, tokenReplacement]);

  const handleSave = () => {
    putTokenReplacement(valueDate)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        handleCloseDialog();
      })
      .catch((err) => {
        console.debug('Failed while attempting to put token replacement', err);
        return;
      });
  };

  const handleDateChange = (event: SelectChangeEvent<string>): void => {
    switchDateFormat(event.target.value);
  };

  const switchDateFormat = (dateFormat: string): void => {
    switch (dateFormat) {
      case 'd':
        // Short date format for momentJS
        setFinalDateFormat('L');
        setValueDate(dateFormat);
        return;
      case 'D':
        // Long date format for momentJS
        setFinalDateFormat('LL');
        setValueDate(dateFormat);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (valueDate !== '' && finalDateFormat !== '') {
      // Get the browser language
      var browserLanguage = getUserLocale({ fallbackLocale: 'en-GB', useFallbackLocale: true }) as string;
      // Set the format based on browser language
      moment.locale(browserLanguage);
      // Build todays date using above formats including the JS format for readability
      setDate(moment().format(finalDateFormat));
    }
  }, [valueDate, finalDateFormat]);

  return (
    <>
      <Tooltip title={t('tokenReplacementButtonTooltip')}>
        <ListItemButton onClick={() => setOpenDialog(true)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCalendarEdit} size="lg" fixedWidth style={{ color: white[500] }}/>
          </ListItemIcon>
          <ListItemText primary={t('tokenReplacementButton')}></ListItemText>
        </ListItemButton>
      </Tooltip>
      <Dialog fullWidth={true} maxWidth="md" onClose={handleCloseDialog} aria-labelledby="editOnlyShortcuts" open={openDialog}>
        <DialogTitle id="editOnlyShortcuts">{t('tokenReplacementTitle')}</DialogTitle>
        <DialogContent dividers>
          <Grid container className="padding-area">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography gutterBottom>{t('tokenReplacementHelperText')}</Typography>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} className="padding-area-top">
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <Select variant="outlined" value={valueDate} onChange={handleDateChange} fullWidth>
                  <MenuItem value="D">{t('tokenReplacementLongDate')}</MenuItem>
                  <MenuItem value="d">{t('tokenReplacementShortDate')}</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} className="justify-center items-center flex">
                {date}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseDialog}>
            {t('cancelButtonText')}
          </Button>

          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TokenFormatListButton;
