import React from 'react';
import './i18n';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import './styles/global.css';
import { persistor, store } from './store';
import userManager from './utils/userManager';
import { App } from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { Spinner } from './components/shared';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'oidc-react';

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        {/*@ts-ignore*/}
        <AuthProvider store={store} userManager={userManager}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
