import { useConfirmAccountMutation, useGetEmailConfirmationStatusQuery } from '@apis';
import { RoutePath } from '@constants';
import { faUserShield, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector, useForm } from '@hooks';
import { ConfirmAccountForm } from '@interfaces';
import { Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { green, red } from '@constants';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const parsedData = queryString.parse(location.search);

const formInitialValues = {
  newPassword: '',
  confirmPassword: '',
} as ConfirmAccountForm;

const ConfirmAccount = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const parms = useParams();
  const userId = parms?.userId ?? '';
  const [confirmAccount, { status, isLoading: isSubmitting }] = useConfirmAccountMutation();
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useGetEmailConfirmationStatusQuery(parseInt(userId), { refetchOnMountOrArgChange: true, skip: userId === '' });

  const handleConfirmAccountSubmit = (): void => {
    confirmAccount({ userId: parseInt(userId ?? '0'), data: formData, code: parsedData.code as string })
      .unwrap()
      .then(() => {
        resetForm();
      })
      .catch((error) => {
        setErrorMessage(error?.message ?? 'Error');
      });
  };

  const matchPassword = (value: string, matchValue?: string): boolean => {
    return value === matchValue;
  };

  const { handleSubmit, handleChange, formData, setFormData, errors } = useForm<ConfirmAccountForm>({
    validations: {
      newPassword: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      confirmPassword: {
        custom: {
          isValid: matchPassword,
          matchKey: 'newPassword',
          message: t('passwordsDoNotMatch'),
        },
      },
    },
    onSubmit: handleConfirmAccountSubmit,
    initialValues: { ...formInitialValues },
  });

  useEffect(() => {
    if (parsedData.firstname != null && parsedData.lastname != null) {
      setUserName(parsedData.firstname.concat(' ', parsedData.lastname as string) as string);
    }

    if (parsedData.email != null) {
      setUserEmail(parsedData.email as string);
    }
  }, []);

  const { isEmailConfirmed } = useAppSelector((x) => x.user);

  const resetForm = (): void => {
    setFormData(formInitialValues);
  };

  const populateContent = () => {
    switch (status) {
      case QueryStatus.uninitialized:
        return (
          <Paper className="p-5 space-y-6 w-full">
            <Typography variant="h6">{t('ConfirmAccountDetails')}</Typography>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Typography variant="subtitle1">{userName}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Typography variant="subtitle1">{userEmail}</Typography>
            </Grid>

            <form className="flex flex-col space-y-6 w-full items-center" autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                name="newPassword"
                type="password"
                autoComplete="new-password"
                label={t('newPassword')}
                placeholder={t('newPassword') as string}
                fullWidth
                value={formData.newPassword}
                onChange={handleChange}
                variant="outlined"
                {...(errors?.newPassword && { error: true, helperText: errors?.newPassword })}
              />

              <TextField
                className="margin-top-bot"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                label={t('confirmPassword')}
                placeholder={t('confirmPassword') as string}
                fullWidth
                value={formData.confirmPassword}
                onChange={handleChange}
                variant="outlined"
                {...(errors?.confirmPassword && { error: true, helperText: errors?.confirmPassword })}
              />

              <div className="justify-end pt-5 flex">
                <Button className="!mr-5" variant="contained" color="secondary" onClick={resetForm} disabled={isSubmitting}>
                  {t('resetButtonText')}
                </Button>
                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                  {t('submitButtonText')}
                </Button>
              </div>
            </form>
          </Paper>
        );
      case QueryStatus.pending:
        return (
          <Paper className="p-5 space-y-4 w-full">
            <Typography variant="h6">{t('PleaseWait')}</Typography>

            <Typography variant="subtitle1">{t('ConfirmingAccount')}</Typography>

            <CircularProgress id="ConfirmingUserProgress" />
          </Paper>
        );
      case QueryStatus.fulfilled:
        return (
          <Paper className="p-5 space-y-4 w-full">
            <Typography variant="h6">{t('AccountConfirmed')}</Typography>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <FontAwesomeIcon icon={faCheck} className="my-0 mx-auto w-[50px] h-[50px]" color={green[500]} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Button variant="contained" color="primary" type="button" onClick={() => navigate(RoutePath.Home)}>
                {t('ContinueLogin')}
              </Button>
            </Grid>
          </Paper>
        );
      case QueryStatus.rejected:
        return (
          <Paper className="p-5 space-y-4 w-full">
            <Typography variant="h6">{t('AccountConfirmationUnsuccessful')}</Typography>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <FontAwesomeIcon icon={faTimes} className="my-0 mx-auto w-[50px] h-[50px]" color={red[500]} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Typography variant="subtitle1">{t('FailedAccountConfirmation')}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Typography variant="subtitle1" className="padding-area red">
                {errorMessage}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Button color="primary" onClick={() => window.location.reload()}>
                {t('retryButton')}
              </Button>
            </Grid>
          </Paper>
        );
    }
  };

  return (
    <Grid container>
      <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
      <Grid
        sx={(theme) => ({
          backgroundColor: theme.custom.appBarBackground,
        })}
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        className="flex justify-center items-center">
        <FontAwesomeIcon icon={faUserShield} className="my-0 mx-auto w-[72px] h-[72px] py-12" color="white" />
      </Grid>
      <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />

      <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
      <Grid item xs={12} sm={6} md={6} lg={6} className="flex justify-center items-center">
        {isEmailConfirmed ? (
          <Paper className="p-5 space-y-6 w-full">
            <Typography variant="h6">{t('AccountAlreadyConfirmed')}</Typography>

            <Grid item xs={12} sm={12} md={12} lg={12} className="flex justify-center items-center">
              <FontAwesomeIcon icon={faCheck} className="my-0 mx-auto w-[50px] h-[50px]" color={green[500]} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="justify-center items-center flex">
              <Button variant="contained" color="primary" type="button" onClick={() => navigate(RoutePath.Home)}>
                {t('ContinueLogin')}
              </Button>
            </Grid>
          </Paper>
        ) : (
          populateContent()
        )}
      </Grid>
      <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
    </Grid>
  );
};

export default ConfirmAccount;
