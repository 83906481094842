import { useDeleteWorkflowTemplateMutation, usePutWorkflowTemplateLabelsMutation } from '@apis';
import { Icons, TableIcons } from '@components';
import { RoutePath } from '@constants';
import { useAppSelector } from '@hooks';
import { WorkflowTemplateDto } from '@interfaces';
import MaterialTable, { Action } from '@material-table/core';
import { Grid, Autocomplete, Paper, Chip, TextField } from '@mui/material';
import { openSnackbar, updateTemplateLabels } from '@slices';
import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

type Props = {
  isLoading: boolean;
  workflowTemplates: WorkflowTemplateDto[];
  refetch: () => void;
  handleAssignAction: (templateId: number) => void;
};

const WorkflowTemplatesTable = ({ isLoading, workflowTemplates, refetch, handleAssignAction }: Props) => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const { activeTeam } = useAppSelector((x) => x.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [putWorkflowTemplateLabels] = usePutWorkflowTemplateLabelsMutation();
  const [deleteWorkflowTemplate] = useDeleteWorkflowTemplateMutation();

  const navigateToWorkflow = (id: number): void => {
    navigate(RoutePath.WorkflowTemplatePath.replace(':templateId', id.toString()));
  };

  const handleLabelsChange = (_event: React.ChangeEvent<{}>, value: string[], workflowTemplateId: number) => {
    putWorkflowTemplateLabels({ labels: value, templateId: workflowTemplateId, teamId: activeTeam?.id ?? 1 })
      .unwrap()
      .then(() => {
        dispatch(updateTemplateLabels({ labels: value, templateId: workflowTemplateId }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update labels', err);
        return;
      });
  };

  const handleDelete = (id: number): void => {
    deleteWorkflowTemplate({ teamId: activeTeam?.id ?? 1, templateId: id })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('workflowTemplateDeletedSuccessfully'), severity: 'success', display: true }));
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to delete workflow template', err);
        return;
      });
  };

  const getDeleteAction = (rowData: WorkflowTemplateDto): Action<WorkflowTemplateDto> | undefined => {
    if (rowData.workflowDto.workflowTemplateState !== 1) {
      return {
        icon: Icons.DeleteForeverIcon,
        tooltip: t('deleteWorkflowTemplate') as string,
        onClick: () => {
          if (rowData) {
            handleDelete(rowData.id);
          }
        },
      };
    }
    return undefined;
  };

  const getAssignAction = (rowData: WorkflowTemplateDto): Action<WorkflowTemplateDto> | undefined => {
    if (rowData.workflowDto.workflowTemplateState === 1) {
      return {
        icon: Icons.AssignIcon,
        tooltip: t('createTeamWorkflowFromTemplate') as string,
        onClick: () => {
          if (rowData) {
            handleAssignAction(rowData.id);
          }
        },
      };
    }
    return undefined;
  };

  return (
    //@ts-ignore
    <MaterialTable
      icons={TableIcons}
      tableRef={tableRef}
      isLoading={isLoading}
      options={{
        tableLayout: 'auto',
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
      }}
      actions={[
        //@ts-ignore
        (rowData) => getAssignAction(rowData),
        {
          icon: Icons.KeyboardArrowRight,
          tooltip: t('navigateToWorkflowTemplate') as string,
          onClick: (_e, data) => {
            if (data) {
              //@ts-ignore
              navigateToWorkflow(data.id);
            }
          },
        },

        //@ts-ignore
        (rowData) => getDeleteAction(rowData),
        {
          icon: Icons.Add,
          tooltip: t('addWorkflowTemplate') as string,
          isFreeAction: true,
          onClick: () => {
            navigateToWorkflow(0);
          },
        },
      ]}
      columns={[
        { title: t('templateId'), field: 'id' },
        { title: t('name'), render: (data) => <>{data.workflowDto.name}</> },
        { title: t('version'), field: 'version' },
      ]}
      data={workflowTemplates}
      title={t('workflowTemplatesTooltip')}
      detailPanel={(rowData) => {
        return (
          <Paper className="p-5 w-full">
            <Grid item xs={12}>
              <Autocomplete
                className="py-4"
                multiple
                id="tags-filled"
                options={[] as string[]}
                freeSolo
                value={rowData?.rowData.labels || []}
                onChange={(e, value) => handleLabelsChange(e, value, rowData.rowData.id)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Chip variant="filled" label={option} key={key} {...tagProps} />;
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={t('typeAndPressEnterToAddLabels')} placeholder={t('typeAndPressEnterToAdd') as string} />
                )}
              />
            </Grid>
          </Paper>
        );
      }}
      onRowClick={(_event, _rowData, togglePanel) => {
        if (togglePanel) return togglePanel.toggleDetailPanel();
      }}
    />
  );
};

export default WorkflowTemplatesTable;
