import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useState, useRef } from 'react';

type Props = {
  children: JSX.Element[];
};

const LookupConfigMediaSlider = ({ children }: Props): JSX.Element => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const sliderRef = useRef<Slider | null>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const visibleItems = isSmallScreen ? 1 : 2;

  const settings = {
    arrows: false,
    infinite: false,
    slidesToShow: visibleItems,
    speed: 500,
    swipe: true,
    slidesToScroll: 1,
  };

  return (
    <div className="flex flex-col w-full h-full relative">
      <div className="flex w-full justify-between absolute z-20 top-1/2">
        {slideIndex !== 0 && (
          <IconButton
            className="!absolute !-left-[18px]"
            onClick={() => {
              sliderRef.current?.slickPrev();
            }}>
            <FontAwesomeIcon icon={faChevronLeft} className="h-5 text-gray-500" />
          </IconButton>
        )}

        {slideIndex < children?.length - visibleItems && (
          <IconButton
            className="!absolute !-right-[18px]"
            onClick={() => {
              sliderRef.current?.slickNext();
            }}>
            <FontAwesomeIcon icon={faChevronRight} className="h-5 text-gray-500" />
          </IconButton>
        )}
      </div>

      <div className="flex relative h-full overflow-hidden -mt-4">
        <div className="w-full flex justify-end">
          <Slider
            {...settings}
            ref={(slider) => (sliderRef.current = slider)}
            beforeChange={(_, next) => {
              setSlideIndex(next);
            }}
            className="w-full">
            {children}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LookupConfigMediaSlider;
