import React, { SyntheticEvent, createRef, useState } from 'react';
import { Autocomplete, CircularProgress, Paper, TextField } from '@mui/material';
import MaterialTable from '@material-table/core';
import { TeamDetailedItem, TeamIdDto, TeamNameDto } from '@interfaces';
import { TableIcons, Icons } from '@components';
import { useTranslation } from 'react-i18next';

interface TeamSelectionTableProps {
  selectValue: TeamDetailedItem[];
  handleAddTeam: (event: React.SyntheticEvent<Element, Event>, value: TeamDetailedItem[]) => void;
  tableData: TeamIdDto[];
  handleRemoveTeam: (value: number) => void;
  isLoadingTeams: boolean;
  teams: TeamDetailedItem[];
  setSearchTeam: React.Dispatch<React.SetStateAction<string>>;
  teamNames: TeamNameDto[];
  isLoading: boolean;
}

const TeamSelectionTable: React.FC<TeamSelectionTableProps> = ({
  selectValue,
  handleAddTeam,
  tableData,
  handleRemoveTeam,
  isLoadingTeams,
  teams,
  setSearchTeam,
  teamNames,
  isLoading,
}) => {
  const { t } = useTranslation('pano');
  const [open, setOpen] = useState<boolean>(false);
  const tableTeamsRef = createRef();
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <Paper className="space-y-3 p-4">
      <Autocomplete
        open={open}
        multiple
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={selectValue}
        disableClearable={true}
        filterOptions={(options) => options.filter((x) => !tableData.map((item) => item.teamId).includes(x.id))}
        options={teams}
        getOptionLabel={(option: TeamDetailedItem) => option.name}
        autoComplete={false}
        onChange={(event: SyntheticEvent<Element, Event>, value: TeamDetailedItem[]) => handleAddTeam(event, value)}
        loading={isLoadingTeams}
        renderTags={() => null}
        filterSelectedOptions={true}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            label={t('addTeam')}
            fullWidth
            InputProps={{
              ...params.InputProps,
              onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchTeam(e.target.value),
              endAdornment: (
                <React.Fragment>
                  {isLoadingTeams ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <MaterialTable
        icons={TableIcons}
        localization={{
          body: { emptyDataSourceMessage: searchQuery ? t('noResults') : t('global') },
        }}
        tableRef={tableTeamsRef}
        isLoading={isLoading}
        options={{
          tableLayout: 'auto',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
        }}
        columns={[{ title: t('name'), field: 'name' }]}
        data={teamNames.filter((x) => tableData.map((item) => item.teamId).includes(x.id))}
        title={t('teams')}
        onSearchChange={(searchText) => setSearchQuery(searchText ?? '')}
        actions={[
          {
            icon: Icons.DeleteForeverIcon,
            tooltip: t('removeTeam') as string,
            onClick: (_event, data) => {
              //@ts-ignore
              handleRemoveTeam(data.id);
            },
          },
        ]}
      />
    </Paper>
  );
};

export default TeamSelectionTable;
