import { usePostTeamMembersMutation, useRevokeMembersMutation } from '@apis';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { TeamMemberItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import { openSnackbar } from '@slices';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVDownloader, CSVReader } from 'react-papaparse';

type Props = {
  onClose: () => void;
  open: boolean;
  teamId: number;
  teamOwnerData?: TeamMemberItem;
  refetch?: () => void;
};

const ImportTeamMembers = ({ onClose, open, teamId, teamOwnerData, refetch }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  const { userRoles } = useAppSelector((x) => x.user);
  const [resetReader, setResetReader] = useState(false);
  const [isImportValid, setIsImportValid] = useState(false);
  const [showMembersToBeRemoved, setShowMembersToBeRemoved] = useState(false);
  const [importData, setImportData] = useState<any[]>([]);
  const [importDataErrors, setImportDataErrors] = useState([]);
  const [tableData, setTableData] = useState<TeamMemberItem[]>([]);
  const [addImportedMembers, { isLoading: isUpdatingMembers }] = usePostTeamMembersMutation();
  const [revokeImportedMembers, { isLoading: isRevokingMembers }] = useRevokeMembersMutation();

  const templateData = [
    {
      givenName: teamOwnerData?.givenName,
      familyName: teamOwnerData?.familyName,
      email: teamOwnerData?.email,
      roles: userRoles.toString(),
    },
  ];
  const handleOnDrop = (data: any[], file: any): void => {
    if (file.type !== 'text/csv' && file.type !== '.csv' && file.type !== 'application/vnd.ms-excel') {
      setResetReader(true);
      dispatch(openSnackbar({ message: t('incorrectFileType'), severity: 'error', display: true }));
      return;
    }

    if (data === null || !Array.isArray(data)) {
      setResetReader(true);
      dispatch(openSnackbar({ message: t('incorrectFileFormat'), severity: 'error', display: true }));
      return;
    }

    setIsImportValid(true);
    setImportData(data);
  };

  const handleOnError = (err: any): void => {
    console.log(err);
  };

  const handleOnRemoveFile = () => {
    setIsImportValid(false);
    setImportDataErrors([]);
  };

  const onSubmitClick = () => {
    let payload = [] as any[];
    importData.map((member) => {
      payload.push(member.data);
    });
    addImportedMembers({ teamId, importData: payload })
      .unwrap()
      .then((data) => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        if (refetch) {
          refetch();
        }
        if (data.length > 0) {
          setShowMembersToBeRemoved(true);
          const resultData = data.map((member) => {
            return { ...member, name: member.givenName + ' ' + member.familyName };
          });
          setTableData(resultData);
        } else {
          onCancelClick();
        }
      })
      .catch((error) => {
        if (Array.isArray(error.message) && error.message.length > 0) {
          setImportDataErrors(error.message);
          setIsImportValid(false);
        }
      });
  };

  const revokeMembers = () => {
    let userIds = [] as number[];
    tableData.map((data) => {
      userIds.push(data.id);
    });
    revokeImportedMembers({ teamId, userIds })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        if (refetch) {
          refetch();
        }
        onCancelClick();
      })
      .catch((err) => {
        console.debug('Failed while attempting to revoke imported members', err);
        return;
      });
  };

  const onCancelClick = () => {
    onClose();
    setIsImportValid(false);
    setImportDataErrors([]);
    setResetReader(true);
    setShowMembersToBeRemoved(false);
  };

  const renderErrors = (value: string, index: number): JSX.Element => {
    return (
      <Typography key={index} variant="subtitle1" color="error" gutterBottom>
        {value}
      </Typography>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth={true}>
      {showMembersToBeRemoved ? (
        <React.Fragment>
          <DialogTitle id="dialog-title">
            <Typography variant="h6" gutterBottom>
              {t('importTeamMembersTitle')}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {t('removeTeamMembersText')}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <MaterialTable
              options={{
                sorting: false,
                actionsColumnIndex: -1,
                search: false,
                toolbar: false,
                showTitle: false,
                paging: false,
                draggable: false,
                minBodyHeight: 250,
                maxBodyHeight: 300,
              }}
              columns={[
                { title: t('id'), field: 'id' },
                { title: t('name'), field: 'name' },
                { title: t('email'), field: 'email' },
              ]}
              data={tableData}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="secondary" onClick={revokeMembers} disabled={isRevokingMembers}>
              {isRevokingMembers && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('removeButton')}
            </Button>
            <Button variant="contained" color="primary" onClick={onCancelClick} disabled={isRevokingMembers}>
              {t('keepButton')}
            </Button>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogTitle className="flex w-full justify-between">
            {t('importTeamMembersTitle')}

            <CSVDownloader data={templateData} filename="Import-Members-Template" bom={true}>
              <Tooltip title={t('downloadButtonTooltip')}>
                <Button variant="contained" color="info" startIcon={<FontAwesomeIcon icon={faDownload} />}>
                  {t('downloadButtonText')}
                </Button>
              </Tooltip>
            </CSVDownloader>
          </DialogTitle>

          <DialogContent>
            <CSVReader
              config={{ header: true, skipEmptyLines: true }}
              isReset={resetReader}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              removeButtonColor="#659cef"
              onRemoveFile={handleOnRemoveFile}>
              <span>{t('uploadCsvFileText')}</span>
            </CSVReader>
            {isImportValid ? (
              <Typography variant="subtitle1" gutterBottom>
                {t('importFileSuccessText')}
              </Typography>
            ) : (
              importDataErrors.length > 0 && importDataErrors.map(renderErrors)
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="secondary" onClick={onCancelClick} disabled={isUpdatingMembers}>
              {t('cancelButtonText')}
            </Button>

            <Button variant="contained" color="primary" onClick={onSubmitClick} disabled={!isImportValid || isUpdatingMembers}>
              {isUpdatingMembers && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('submitButtonText')}
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default ImportTeamMembers;
