import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryHandler } from './base.api';
import { AuthenticatorKeyItem } from '@interfaces';

export const mfaApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'mfaApi',
  endpoints: (builder) => ({
    getRecoveryKeys: builder.mutation<string[], string>({
      query: (payload) => ({
        url: `/api/v1/mfa/recoverycodes`,
        method: 'GET',
        headers: { 'X-Authorization-StepUp': payload },
      }),
    }),
    getSMSAuthenticatorKey: builder.mutation<void, { token: string; phoneNumber: string }>({
      query: (payload) => ({
        url: `/api/v1/mfa/phonetoken?phoneNumber=${encodeURIComponent(payload.phoneNumber)}`,
        method: 'GET',
        headers: { 'X-Authorization-StepUp': payload.token },
      }),
    }),
    getAuthenticatorKey: builder.mutation<AuthenticatorKeyItem, string>({
      query: (payload) => ({
        url: `/api/v1/mfa/authenticatorkey`,
        method: 'GET',
        headers: { 'X-Authorization-StepUp': payload },
      }),
    }),
    enableMfa: builder.mutation<string[], { token: string; verificationCode: string; phoneNumber: string }>({
      query: (payload) => ({
        url: `/api/v1/mfa`,
        method: 'PUT',
        body: {
          VerificationCode: payload.verificationCode,
          PhoneNumber: payload.phoneNumber,
        },
        headers: { 'X-Authorization-StepUp': payload.token },
      }),
    }),
    disableMfa: builder.mutation<void, string>({
      query: (payload) => ({
        url: `/api/v1/mfa`,
        method: 'DELETE',
        headers: { 'X-Authorization-StepUp': payload },
      }),
    }),
  }),
});

export const { useDisableMfaMutation, useEnableMfaMutation, useGetAuthenticatorKeyMutation, useGetRecoveryKeysMutation, useGetSMSAuthenticatorKeyMutation } =
  mfaApi;
