import { faArrowToBottom, faMobileAlt, faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses, styled } from '@mui/material';
import React from 'react';

type Props = {
  activeStep: number;
  steps: string[];
};

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgba(0,160,223,1) 0%, rgba(0,117,201,1) 54%, rgba(0,73,126,1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgba(0,160,223,1) 0%, rgba(0,117,201,1) 54%, rgba(0,73,126,1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(136deg, rgba(0,160,223,1) 0%, rgba(0,117,201,1) 50%, rgba(0,117,201,1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(136deg, rgba(0,160,223,1) 0%, rgba(0,117,201,1) 50%, rgba(0,117,201,1) 100%)',
  }),
}));

const ColorlibStepIcon = (props: StepIconProps): JSX.Element => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <FontAwesomeIcon icon={faArrowToBottom} />,
    2: <FontAwesomeIcon icon={faMobileAlt} />,
    3: <FontAwesomeIcon icon={faSave} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

const MfaStepper = ({ activeStep, steps }: Props): JSX.Element => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default MfaStepper;
