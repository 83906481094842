import { useGetTeamQuery, useGetTeamServicesQuery } from '@apis';
import { TeamApplicationStartupEvents, TeamDetailsEdit, TeamLicences, TeamManagedTeams, TeamMembers, TeamRoles, TeamServices } from '@components';
import { RoutePath, TeamType } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { BreadcrumbItem, TeamDetailedItem } from '@interfaces';
import { AppBar, Grid, Tab, Tabs } from '@mui/material';
import { setActiveTeam, setBreadcrumbs, setHelmet, setTeamNavigationContext } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'oidc-react';

const Team = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { team, teamServices } = useAppSelector((state) => state.team);
  const { userData } = useAuth();
  const { userRights, activeTeam, changeActiveTeamStatus } = useAppSelector((state) => state.app);
  const { teamMemberships } = useAppSelector((state) => state.user);
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const tabName = parms?.tabName ?? 'teamDetailsTab';

  const { isSuccess } = useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const [openTab, setOpenTab] = useState(0);

  const handleTabsChange = (_event: React.SyntheticEvent<Element, Event>, value: number): void => {
    let name = '';
    switch (value) {
      case 0:
        name = 'teamDetailsTab';
        break;
      case 1:
        name = 'teamServicesTab';
        break;
      case 2:
        name = 'teamMembersTab';
        break;
      case 3:
        name = 'teamRolesTab';
        break;
      case 4:
        name = 'teamLicencesTab';
        break;
      case 5:
        name = 'teamEventsTab';
        break;
      case 6:
        name = 'managedTeamsTab';
        break;
    }
    navigate(RoutePath.TeamPath.replace(':teamId', teamId).replace(':tabName', name));
  };

  const showManagedTeamsTab = () => {
    return team != null && (team.type === TeamType.Reseller || team.type === TeamType.Distributor);
  };

  useEffect(() => {
    switch (tabName) {
      case 'teamDetailsTab':
        setOpenTab(0);
        dispatch(setHelmet({ title: t('htmlTitleTeamDetails') }));
        break;
      case 'teamServicesTab':
        setOpenTab(1);
        dispatch(setHelmet({ title: t('htmlTitleTeamServices') }));
        break;
      case 'teamMembersTab':
        setOpenTab(2);
        dispatch(setHelmet({ title: t('htmlTitleTeamMembers') }));
        break;
      case 'teamRolesTab':
        setOpenTab(3);
        dispatch(setHelmet({ title: t('htmlTitleTeamRoles') }));
        break;
      case 'teamLicencesTab':
        setOpenTab(4);
        dispatch(setHelmet({ title: t('htmlTitleTeamLicences') }));
        break;
      case 'teamEventsTab':
        setOpenTab(5);
        dispatch(setHelmet({ title: t('htmlTitleTeamEvents') }));
        break;
      case 'managedTeamsTab':
        setOpenTab(6);
        dispatch(setHelmet({ title: t('htmlTitleTeamManagedTeams') }));
        break;

      default:
        setOpenTab(0);
        dispatch(setHelmet({ title: t('htmlTitleTeamDetails') }));
        break;
    }
  }, [tabName]);

  useEffect(() => {
    if (isSuccess) {
      let teamsLink = { text: t('teamsText') } as BreadcrumbItem;

      if (teamMemberships) {
        const teamOwnerships = teamMemberships.filter((tm) => tm.ownerEmail === userData?.profile.email);

        if (teamOwnerships.length > 1) {
          teamsLink = { text: t('teamsText'), link: '/teams' };
        }
      }

      // Overwrite if sysadmin or you are team admin or owner
      if (userRights.isSysAdmin || userRights.administeredAndOwnedTeamsCount > 1) {
        teamsLink = { text: t('teamsText'), link: '/teams' };
      }

      const breadcrumbs =
        activeTeam && activeTeam.id != parseInt(teamId) && !userRights.isSysAdmin
          ? [
              teamsLink,
              { text: activeTeam.name, link: `/teams/${activeTeam.id}` },
              { text: t('managedTeamsTitle'), link: `/teams/${activeTeam.id}/managedTeamsTab` },
              { text: team?.name, link: `/teams/${teamId}` },
              { text: t(tabName) },
            ]
          : [teamsLink, { text: team?.name, link: `/teams/${teamId}` }, { text: t(tabName) }];
      dispatch(setBreadcrumbs(breadcrumbs));
      if (team && !userRights.isSysAdmin && changeActiveTeamStatus) {
        dispatch(setActiveTeam({ id: team?.id, name: team?.name, isSysAdmin: team?.isSysAdmin }));
      }
    }
  }, [isSuccess, tabName, team, changeActiveTeamStatus]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices, tabName]);

  let tabs = [];
  tabs.push(<Tab className="w-40" label={t('teamDetailsTab')} key="teamDetails" />);
  tabs.push(<Tab className="w-40" label={t('teamServicesTab')} key="teamServices" />);
  tabs.push(<Tab className="w-40" label={t('teamMembersTab')} key="teamMembers" />);
  tabs.push(<Tab className="w-40" label={t('teamRolesTab')} key="teamRoles" />);
  tabs.push(
    <Tab
      className="w-40"
      label={t('teamLicencesTab')}
      key="teamLicences"
      disabled={!userRights.isSysAdmin && !userRights.isTeamAdmin && !userRights.isTeamOwner}
    />,
  );
  tabs.push(<Tab className="w-40" label={t('teamEventsTab')} key="teamEvents" disabled={!userRights.isSysAdmin} />);
  if (showManagedTeamsTab()) tabs.push(<Tab className="w-40" label={t('managedTeamsTab')} key="managedTeams" />);

  return (
    <>
      {isSuccess && (
        <Grid container>
          <AppBar position="static" color="default" elevation={4} className="z-40">
            <Tabs value={openTab > tabs.length - 1 ? 0 : openTab} onChange={handleTabsChange} variant="scrollable" scrollButtons>
              {tabs}
            </Tabs>
          </AppBar>

          {openTab === 0 && <TeamDetailsEdit teamId={parseInt(teamId)} />}

          {openTab === 1 && <TeamServices teamId={parseInt(teamId)} />}

          {openTab === 2 && <TeamMembers teamId={parseInt(teamId)} />}

          {openTab === 3 && <TeamRoles teamId={parseInt(teamId)} />}

          {openTab === 4 && <TeamLicences teamId={parseInt(teamId)} />}

          {openTab === 5 && <TeamApplicationStartupEvents teamId={parseInt(teamId)} />}

          {openTab === 6 && <TeamManagedTeams currentTeam={team as TeamDetailedItem} teamId={parseInt(teamId)} />}
        </Grid>
      )}
    </>
  );
};

export default Team;
