import { SubstitutionForm } from '@interfaces';

export function transformSubstitutionDto(originalJson: SubstitutionForm) {
  const transformedJson = {
    Applications: originalJson.applications.map((app) => ({
      Id: app.id,
      Name: app.name,
      Process: app.process,
      SendInputExceptions: app.sendInputExceptions,
      ThirdPartySystemCode: app.thirdPartySystemCode,
      WindowText: app.windowText,
    })),
    Description: originalJson.description,
    Id: originalJson.id,
    Name: originalJson.name,
    Pattern: originalJson.pattern,
    Replacement: originalJson.replacement,
    ReplacementKeycodes: {
      ReplacementKeycodes: originalJson.replacementKeycodes.replacementKeycodes.map((keycode) => ({
        CommandPauseInMilliseconds: keycode.commandPauseInMilliseconds,
        Keycodes: keycode.keycodes.map((command) => ({
          CommandKeyCodes: command.commandKeyCodes.map((key) => ({
            Key: key.key,
          })),
          CommandPause: command.commandPause,
        })),
        StartIndex: keycode.startIndex,
      })),
    },
    SubType: originalJson.subType,
    SubTypeId: originalJson.subTypeId,
    TeamIds: originalJson.teamIds.map((team) => ({
      TeamId: team.teamId,
    })),
    Type: originalJson.type,
    TypeName: 'SubstitutionDto',
  };
  return transformedJson;
}

export function toPascalCase(key: string): string {
  return key.charAt(0).toUpperCase() + key.slice(1);
}

export function convertKeysToPascalCase(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToPascalCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalKey = toPascalCase(key);
      acc[pascalKey] = convertKeysToPascalCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}
