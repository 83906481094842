import { licenceApi } from '@apis';
import { LicenceItem } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

export type LicenceState = {
  licences: LicenceItem[];
};

const initialState: LicenceState = {
  licences: [],
};

export const licenceSlice = createSlice({
  name: 'licence',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(licenceApi.endpoints.getLicences.matchFulfilled, (state, { payload }) => {
      state.licences = payload.sort((a, b) => a.serviceId - b.serviceId);
    });
  },
  reducers: {},
});
