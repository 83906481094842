import React from 'react';
import { Autocomplete, TextField, Box, AutocompleteProps } from '@mui/material';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { LexFontAwesomeIcon } from '@components';
import { IconOption } from '@interfaces';

const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const { key, ...optionProps } = dataSet[0];

  return (
    <Box
      component="li"
      {...optionProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        padding: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      {...style}
      key={dataSet[1].id}>
      <LexFontAwesomeIcon unicode={dataSet[1].unicode} className="mr-2" /> {dataSet[1].label}
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child: React.ReactElement) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

interface VirtualizedAutocompleteProps extends Omit<AutocompleteProps<IconOption, false, false, false>, 'renderInput'> {
  label: string;
}

const VirtualizedIconAutocomplete: React.FC<VirtualizedAutocompleteProps> = ({ options, value, onChange, label, ...rest }) => {
  return (
    <Autocomplete
      options={options}
      value={value}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
      ListboxComponent={ListboxComponent}
      onChange={(event, value, reason) => {
        if (onChange) {
          onChange(event, value, reason);
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} fullWidth />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...rest}
    />
  );
};

export default VirtualizedIconAutocomplete;
