import {
  useGetTeamMembersSearchQuery,
  useGetTeamRolesQuery,
  useGetUserRolesQuery,
  usePostTeamMemberMutation,
  useRevokeMembershipMutation,
  useSendMembershipInviteMutation,
} from '@apis';
import { AddTeamMember, ImportTeamMembers, ReasonDialog, TableIcons } from '@components';
import { faCheck, faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { TeamMemberForm, TeamMemberItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Button, Fab, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { green } from '@constants';
import { openSnackbar } from '@slices';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

type Props = {
  teamId: number;
};

const TeamMembers = ({ teamId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { userRights } = useAppSelector((x) => x.app);
  const { team, teamMembers } = useAppSelector((x) => x.team);
  const [teamOwnerData, setTeamOwnerData] = useState<TeamMemberItem>();
  const tableRef = createRef();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [importMembersDialog, setImportMembersDialog] = useState<boolean>(false);
  const [dialogOpenMembers, setDialogOpenMembers] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number>(0);
  const [debouncedSearchMember] = useDebounce(searchTerm, 200);
  const [addTeamMember, { isLoading: isAddingMember }] = usePostTeamMemberMutation();
  const [sendMembershipInvite] = useSendMembershipInviteMutation();
  const [revokeMembership] = useRevokeMembershipMutation();
  const {
    isLoading: isGettingMembers,
    isSuccess,
    refetch,
  } = useGetTeamMembersSearchQuery({ teamId, searchTerm: debouncedSearchMember }, { refetchOnMountOrArgChange: true, skip: teamId < 1 });

  useGetTeamRolesQuery({ teamId }, { refetchOnMountOrArgChange: true, skip: teamId < 1 });
  const { isLoading: isGettingOwnerRoles } = useGetUserRolesQuery(
    { teamId, userId: team?.ownerId ?? 0 },
    { refetchOnMountOrArgChange: true, skip: teamId < 1 || !team },
  );

  useEffect(() => {
    const result = teamMembers.find((x) => x.id === team?.ownerId);
    if (result) {
      setTeamOwnerData(result);
    }
    if (isSuccess) {
    }
  }, [isSuccess]);

  const closeImportMembersDialog = (): void => {
    setImportMembersDialog(false);
  };

  const handleRefetch = (): void => {
    refetch();
  };

  const closeMembers = (): void => {
    setDialogOpenMembers(false);
  };

  const onAddMember = (newMember: TeamMemberForm): void => {
    addTeamMember({ newMember, teamId })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetch();
        closeMembers();
      })
      .catch((err) => {
        console.debug('Failed while attempting to add a new member', err);
        return;
      });
  };

  const tableOptions = {
    sorting: false,
    actionsColumnIndex: -1,
    search: false,
    toolbar: true,
    pageSize: 5,
    draggable: false,
    filtering: false,
  };

  const onResend = (userId: number): void => {
    sendMembershipInvite({
      userId,
      teamId,
    })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('membershipInviteResent'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to send membership invite', err);
        return;
      });
  };

  const onRevokeMemberClick = (userId: number) => {
    setIsDialogOpen(true);
    setUserIdToDelete(userId);
  };

  const revokeMember = (reason: string): void => {
    console.info('revokeMember', userIdToDelete);
    revokeMembership({
      userId: userIdToDelete,
      teamId,
      reason,
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('membershipRevoked'), severity: 'success', display: true }));
        setIsDialogOpen(false);
        setUserIdToDelete(0);
      })
      .catch((err) => {
        console.debug('Failed while attempting to revoke membership', err);
        return;
      });
  };

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  const tableComponents = {
    Action: (actionProps: any) => {
      if (actionProps.action.icon === 'search') {
        return (
          <div className="p-3 m-auto">
            <TextField
              variant="standard"
              id="search-member-input"
              fullWidth={true}
              label=""
              autoFocus={focus}
              placeholder={t('TeamEditSearchMemberHintText') as string}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
                setFocus(true);
              }}
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchTerm} onClick={() => setSearchTerm('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      }
      if (actionProps.action.icon === 'add') {
        return (
          <div className="p-3 m-auto">
            <Fab
              color="primary"
              size="medium"
              aria-label="add"
              className="top-right-fab-button"
              onClick={() => setDialogOpenMembers(true)}
              disabled={isGettingMembers}>
              <TableIcons.Add />
            </Fab>
          </div>
        );
      }
      if (actionProps.action.icon === 'refresh') {
        return actionProps.data.membershipPending || !actionProps.data.emailConfirmed ? (
          <Tooltip title={actionProps.action.tooltip}>
            <IconButton className="!w-20 flex justify-end" onClick={(event) => actionProps.action.onClick(event, actionProps.data)}>
              <TableIcons.RefreshIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment />
        );
      }
      if (actionProps.action.icon === 'delete') {
        return team?.ownerId !== actionProps.data.id ? (
          <Tooltip title={actionProps.action.tooltip}>
            <IconButton className="!w-20 flex justify-end" onClick={(event) => actionProps.action.onClick(event, actionProps.data)}>
              <TableIcons.DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment />
        );
      }

      return <React.Fragment />;
    },
  };

  return (
    <React.Fragment>
      <Paper className="w-full p-5 space-y-4" square>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={9} md={9} lg={10}>
            <Typography variant="h6" gutterBottom>
              {t('teamMembersText')}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {t('teamMembersSubText')}
            </Typography>
          </Grid>

          {userRights.isSysAdmin && !(isGettingMembers || isGettingOwnerRoles) ? (
            <Grid item xs={12} sm={3} md={3} lg={2} className="justify-end items-center flex">
              <Tooltip title={t('importButtonTooltip')}>
                <Button
                  variant="contained"
                  color="primary"
                  className="h-10"
                  onClick={() => setImportMembersDialog(true)}
                  startIcon={<FontAwesomeIcon icon={faFileImport} />}>
                  {t('importButtonText')}
                </Button>
              </Tooltip>
            </Grid>
          ) : (
            <React.Fragment />
          )}
        </Grid>

        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MaterialTable
              icons={TableIcons}
              tableRef={tableRef}
              options={tableOptions}
              title={t('teamMembersText')}
              columns={[
                { title: t('name'), field: 'fullname' },
                { title: t('emailAddress'), field: 'email' },
                {
                  title: t('active'),
                  align: 'center',
                  field: 'membershipPending',
                  render: (rowData: any) =>
                    !rowData.membershipPending ? (
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                    ) : (
                      <strong>{t('teamMembershipPending')}</strong>
                    ),
                },
                {
                  title: t('emailConfirmed'),
                  align: 'center',
                  field: 'emailConfirmed',
                  render: (rowData: any) =>
                    rowData.emailConfirmed ? <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} /> : <strong>{t('teamMembershipPending')}</strong>,
                },
                {
                  title: t('mfaEnabled'),
                  align: 'center',
                  field: 'mfaEnabled',
                  render: (rowData: any) =>
                    rowData.mfaEnabled ? <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} /> : <strong>{t('teamMembershipPending')}</strong>,
                },
                { title: t('lastLogin'), field: 'lastLogin', type: 'datetime' },
              ]}
              data={teamMembers}
              isLoading={isGettingMembers}
              components={tableComponents}
              actions={[
                {
                  icon: 'search',
                  isFreeAction: true,
                  onClick: (_event, _rowData) => {
                    return;
                  },
                },
                {
                  icon: 'add',
                  isFreeAction: true,
                  onClick: (_event, _rowData) => {
                    return;
                  },
                },
                {
                  icon: 'refresh',
                  tooltip: t('resendTeamInvite') as string,
                  onClick: (_event, rowData) => {
                    //@ts-ignore
                    onResend(rowData.id);
                  },
                },
                {
                  icon: 'delete',
                  tooltip: t('removeMember') as string,
                  onClick: (_event, rowData) => {
                    //@ts-ignore
                    onRevokeMemberClick(rowData.id);
                  },
                },
              ]}
            />
          </Grid>

          <ReasonDialog
            isDialogOpen={isDialogOpen}
            closeDialog={closeDialog}
            action={revokeMember}
            title={t('revokeMemberReasonDialogTitle')}
            text={t('revokeMemberReasonDialogText')}
          />
        </React.Fragment>

        {isGettingOwnerRoles ? (
          <React.Fragment />
        ) : (
          <ImportTeamMembers
            onClose={closeImportMembersDialog}
            open={importMembersDialog}
            teamOwnerData={teamOwnerData}
            teamId={teamId}
            refetch={handleRefetch}
          />
        )}
        <AddTeamMember onClose={closeMembers} open={dialogOpenMembers} teamId={teamId} onSubmit={onAddMember} isAddingMember={isAddingMember} />
      </Paper>
    </React.Fragment>
  );
};
export default TeamMembers;
