import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItem, TextField, Typography } from '@mui/material';
import { green } from '@constants';
import { getKeyNameFromKeyCode } from '@utils';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: any | any[];
  onChange?: (value: any | any[]) => void;
  type?: string;
  validationData?: any;
  readonly?: boolean;
};

const ShortcutEditor = ({ value, onChange, type, validationData, readonly }: Props) => {
  const { t } = useTranslation('pano');
  const [modalData, setModalData] = useState({
    type: '',
    shortcutData: [] as any[],
    originalData: [] as any[],
    onChange: (_value: any) => {},
    readonly: false,
    newKeyIndex: '',
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [validation, setValidation] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    setModalData({
      ...modalData,
      type: modalData.type,
      shortcutData: _.cloneDeep(modalData.originalData),
      originalData: _.cloneDeep(modalData.originalData),
      onChange: onChange as (value: any | any[]) => void,
      readonly: readonly === undefined ? false : readonly,
    });

    setOpenDialog(false);
  };

  useEffect(() => {
    let shortcutData = [] as any[];
    if (value && Array.isArray(value) && type === 'SimpleCommandDescriptor') {
      value.map((item) => {
        shortcutData.push({
          shortcut: item,
        });
      });

      setModalData({
        ...modalData,
        type,
        shortcutData,
        originalData: _.cloneDeep(shortcutData),
        onChange: onChange as (value: any | any[]) => void,
        readonly: readonly === undefined ? false : readonly,
      });
    }
  }, [value, onchange, type, validationData, readonly]);

  useEffect(() => {
    if (modalData.shortcutData.length === 0) {
      setValidation(true);

      return;
    }

    let validationState = modalData.shortcutData.some((item) => {
      if (item.shortcut.commandKeyCodes === undefined || item.shortcut.commandKeyCodes.length === 0) {
        return true;
      }
      //@ts-ignore
      let validationStateKey = item.shortcut.commandKeyCodes.some((keyCode) => {
        if (!keyCode.key) {
          keyCode.key = [];
        }
        if (keyCode.key.length < 1) {
          return true;
        }
      });

      return validationStateKey;
    });

    setValidation(validationState);

    // Get and set focus on new item added.
    var newKeyItem = document.getElementById(modalData.newKeyIndex);
    if (newKeyItem !== null) {
      newKeyItem.focus();
    }

    return;
  }, [modalData.shortcutData]);

  const handleSave = () => {
    if (modalData.readonly) return;

    let dataToGoBack = [] as any[];

    modalData.shortcutData.map((item) => {
      dataToGoBack.push(item.shortcut);
    });
    if (onChange) {
      modalData.onChange(dataToGoBack);
    }

    setOpenDialog(false);
  };

  // Handle keybinding event tracking
  const handleKeyDown = (itemIndex: any, keyCodeIndex: any, event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (!modalData.readonly) {
      // 1. Make a shallow copy of the items
      let shortcutItems = [...modalData.shortcutData];
      // 2. Make a shallow copy of the item you want to mutate
      let itemCopy = { ...shortcutItems[itemIndex] };
      // 2b. Go deeper into item to get key
      let keyCodeToChange = itemCopy.shortcut.commandKeyCodes[keyCodeIndex];
      // 3. Replace the property you're intested in
      keyCodeToChange.key = getKeyNameFromKeyCode(event.keyCode);
      keyCodeToChange.keyCode = event.keyCode;
      // 4. Put it back into our array. We *are* mutating the array here, but that's why we made a copy first
      shortcutItems[itemIndex] = itemCopy;
      // 5. Set the state to our new copy
      setModalData({
        ...modalData,
        type: modalData.type,
        shortcutData: shortcutItems,
        originalData: modalData.originalData,
        onChange: onChange as (value: any | any[]) => void,
        readonly: modalData.readonly,
      });
    }
  };

  const handleAddKeyCode = (itemIndex: any) => {
    let newShortcutData = [...modalData.shortcutData];
    if (!newShortcutData[itemIndex].shortcut.commandKeyCodes) {
      newShortcutData[itemIndex].shortcut.commandKeyCodes = [];
    }
    if (!newShortcutData[itemIndex].shortcut.commandKeyCodes || newShortcutData[itemIndex].shortcut.commandKeyCodes.length < 4) {
      newShortcutData[itemIndex].shortcut.commandKeyCodes.push({
        key: '',
      });

      var newKeyString = `${itemIndex}--${newShortcutData[itemIndex].shortcut.commandKeyCodes.length - 1}`;

      setModalData({
        type: modalData.type,
        shortcutData: newShortcutData,
        originalData: modalData.originalData,
        onChange: onChange as (value: any | any[]) => void,
        readonly: modalData.readonly,
        newKeyIndex: newKeyString,
      });
    }
  };

  const handleDeleteKeyCode = (itemIndex: any) => {
    let newShortcutData = [...modalData.shortcutData];

    if (newShortcutData[itemIndex].shortcut.commandKeyCodes) {
      if (newShortcutData[itemIndex].shortcut.commandKeyCodes.length <= 1) {
        newShortcutData.splice(itemIndex, 1);
      } else {
        newShortcutData[itemIndex].shortcut.commandKeyCodes.pop();
      }
    }

    setModalData({
      ...modalData,
      type: modalData.type,
      shortcutData: newShortcutData,
      originalData: modalData.originalData,
      onChange: onChange as (value: any | any[]) => void,
      readonly: modalData.readonly,
    });
  };

  const handleAddKeyCodeSequence = () => {
    let newShortcutData = [...modalData.shortcutData];

    var newShortcut = {
      shortcut: {
        commandKeyCodes: [],
      },
    };

    newShortcutData.push(newShortcut);

    setModalData({
      ...modalData,
      type: modalData.type,
      shortcutData: newShortcutData,
      originalData: modalData.originalData,
      onChange: onChange as (value: any | any[]) => void,
      readonly: modalData.readonly,
    });
  };

  const errorMessage =
    validationData && validationData.validationError == 'shortcuts' ? (
      <Typography variant="caption" className="red">
        {validationData.validationText}
      </Typography>
    ) : null;

  return (
    <>
      <Button variant="contained" onClick={() => handleOpenDialog()}>
        {modalData.readonly ? t('viewShortcutsButton') : t('editShortcutsButton')}
      </Button>

      <div className={`${errorMessage ? 'pt-5' : ''}`}>{errorMessage}</div>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={(e, reason) => handleCloseDialog(e, reason)}
        aria-labelledby="editOnlyShortcuts"
        open={openDialog}
        onKeyDown={(event) => event.stopPropagation()}>
        <DialogTitle component="div" id="editOnlyShortcuts" className="flex justify-between items-center">
          {modalData.readonly ? t('shortcutTitleRead') : t('shortcutTitleEdit')}
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{t('shortcutHelperText')}</Typography>
          <Grid container>
            {modalData.shortcutData &&
              modalData.shortcutData.map((item, shortcutIndex) => {
                return (
                  <ListItem key={`${shortcutIndex}`}>
                    <Grid container item xs={12} sm={12} md={12} lg={12} className="items-center">
                      {item.shortcut.commandKeyCodes &&
                        item.shortcut.commandKeyCodes.map((keyCode: any, keyCodeIndex: number) => {
                          return (
                            <div key={`${shortcutIndex}-${keyCode.key}-${keyCodeIndex}`}>
                              <TextField
                                disabled={modalData.readonly}
                                variant="outlined"
                                placeholder="Set keybind"
                                key={`${shortcutIndex}-${keyCode.key}-${keyCodeIndex}`}
                                id={`${shortcutIndex}-${keyCode.key}-${keyCodeIndex}`}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                  event.stopPropagation();
                                  handleKeyDown(shortcutIndex, keyCodeIndex, event);
                                }}
                                value={keyCode.key}
                              />
                              {keyCodeIndex < item.shortcut.commandKeyCodes.length - 1 ? (
                                <span>
                                  <FontAwesomeIcon icon={faPlus} style={{ color: green[500] }} className="!m-3" />
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          );
                        })}

                      {!modalData.readonly ? (
                        <div className="flex p-5">
                          <Button
                            variant="outlined"
                            color="primary"
                            className="!mr-5"
                            onClick={() => handleAddKeyCode(shortcutIndex)}
                            startIcon={<FontAwesomeIcon icon={faPlus} />}>
                            {t('addText')}
                          </Button>

                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeleteKeyCode(shortcutIndex)}
                            startIcon={<FontAwesomeIcon icon={faTimes} />}>
                            {t('removeText')}
                          </Button>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </ListItem>
                );
              })}

            {!modalData.readonly ? (
              <div className="p-5">
                <Button variant="outlined" color="primary" onClick={() => handleAddKeyCodeSequence()} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                  {t('addRowText')}
                </Button>
              </div>
            ) : (
              <span></span>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {modalData.readonly ? (
            <Button autoFocus onClick={() => handleCloseDialog()}>
              {t('closeButtonText')}
            </Button>
          ) : (
            <Button disabled={validation} autoFocus color="primary" onClick={() => handleSave()}>
              {t('submitButtonText')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShortcutEditor;
