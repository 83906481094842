import { useEffect, useState } from 'react';
import { Modal, TextField, Button, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { iconsList, colorsList } from '@constants';
import { IconOption, WorkflowStepEdgeItem } from '@interfaces';
import { VirtualizedIconAutocomplete } from '@components';
import { useAppDispatch } from '@hooks';
import { openSnackbar } from '@slices';

type Props = {
  isOpen: boolean;
  edge: WorkflowStepEdgeItem | null;
  onClose: () => void;
  onRemove: () => void;

  onSave: (properties: { color: string; icon: IconOption; tooltip: string }) => void;
};

const EdgePropertiesModal = ({ isOpen, onClose, onSave, edge, onRemove }: Props) => {
  const { t } = useTranslation('pano');
  const [color, setColor] = useState<string>(edge ? edge.data?.color ?? '#476B26' : '#476B26');
  const [icon, setIcon] = useState<IconOption | null>(edge ? edge.data?.icon ?? null : null);
  const [tooltip, setTooltip] = useState<string>(edge ? edge.data?.label ?? '' : '');
  const dispatch = useAppDispatch();

  const handleSave = () => {
    if (!icon) {
      dispatch(openSnackbar({ message: t('pleaseSelectAnIcon'), severity: 'error', display: true }));
      return;
    }
    if (!tooltip) {
      dispatch(openSnackbar({ message: t('tooltipRequired'), severity: 'error', display: true }));
      return;
    }
    onSave({ color, icon, tooltip });
  };

  useEffect(() => {
    setColor(edge ? edge.data?.color ?? '#476B26' : '#476B26');
    setIcon(edge ? edge.data?.icon ?? null : null);
    setTooltip(edge ? edge.data?.label ?? '' : '');
  }, [edge, isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Grid container className="h-screen">
        <Paper className="p-5 space-y-6 w-96 m-auto">
          <Typography variant="h6" gutterBottom>
            {t('properties')}
          </Typography>
          <Grid item xs={12}>
            <TextField fullWidth label={t('tooltip')} value={tooltip} onChange={(e) => setTooltip(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField select fullWidth label={t('Color')} value={color} onChange={(e) => setColor(e.target.value)}>
              {colorsList.map((colorOption, index) => (
                <MenuItem key={index} value={colorOption.value}>
                  <div className="flex items-center">
                    <div
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        height: '20px',
                        backgroundColor: colorOption.value,
                        marginRight: '10px',
                      }}
                    />
                    {colorOption.label}
                  </div>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <VirtualizedIconAutocomplete options={iconsList} value={icon} onChange={(_event, value) => setIcon(value)} label={t('Icon')} />
          </Grid>
          <Grid item xs={12} className="flex justify-end">
            <Button variant="contained" className="!mr-2" color="secondary" onClick={onClose}>
              {t('cancelButtonText')}
            </Button>
            {edge && (
              <Button variant="contained" className="!mr-2" color="secondary" onClick={onRemove}>
                {t('removeText')}
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={handleSave}>
              {t('submitButtonText')}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Modal>
  );
};

export default EdgePropertiesModal;
