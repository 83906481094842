import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (cb) => cb('en'),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: ['pano', 'shared'],
    defaultNS: 'pano',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react!
      formatSeparator: ',',
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
